import React from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Grid, Checkbox, FormControlLabel, CircularProgress, TextField, Icon } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getRoleList } from "app/redux/actions/RoleActions";
import { updateUser, setTableContentLoading } from "app/redux/actions/UserActions";
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

class UpdateUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            userToUpdate: this.props.userToUpdate,
            RoleList: [],
            defaultSelectedRoleList: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectListChange = this.handleSelectListChange.bind(this);
        this.getOptionsFromRoleList = this.getOptionsFromRoleList.bind(this);

    }

    componentDidMount() {
        if (this.props.storeRole.roleList.length > 0) {
            this.getOptionsFromRoleList(this.props.storeRole.roleList);
        }
    }

    componentWillReceiveProps(nextProps) {
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleSave = event => {
        this.handleSubmit(this.state.userToUpdate)
    }

    handleSubmit = value => {
        this.props.updateUser(value).then(data => {
            if (data.Success) {
                this.handleClose();
                this.setState({
                    user: {
                        UserId: "",
                        FirstName: "",
                        LastName: "",
                        PhoneNumber1: "",
                        PhoneNumber2: "",
                        Email: "",
                        Password: "",
                        ConfirmPassword: "",
                        Roles: [
                            {
                                RoleId: "",
                                Name: ""
                            }
                        ]
                    }
                }, () => {
                    this.props.setTableContentLoading(true);
                    setTimeout(() => {
                        this.props.setTableContentLoading(false);
                    }, 500);
                });
                this.getOptionsFromRoleList(this.props.storeRole.roleList);
            }
        });
    };

    handleChange = event => {
        event.persist();
        let userCopy = this.state.userToUpdate;
        for (let key in userCopy) {
            if (key === event.target.name) {
                userCopy[`${key}`] = event.target.value
            }
        }
        this.setState({ user: userCopy });
    };

    handleSelectListChange = (event, value) => {
        let result = [];
        if (value !== []) {
            value.forEach(element => {
                result.push({ RoleId: element.id, Name: element.title });
            });
            let userCopy = this.state.userToUpdate;
            userCopy.Roles = result;
            this.setState({ user: userCopy });
        }
    }

    getOptionsFromRoleList = (roles) => {
        if (roles !== undefined && roles.length > 0) {
            let result = [];
            roles.forEach(element => {
                result.push({ title: element.Name, id: element.RoleId });
            });
            this.setState({ RoleList: result }, () => {
                let defaultSelectedRoleList = [];
                if (this.state.userToUpdate.Roles !== null && this.state.userToUpdate.Roles !== undefined && this.state.userToUpdate.Roles.length > 0) {
                    let userCopy = this.state.userToUpdate;
                    userCopy.Roles.forEach(element => {
                        defaultSelectedRoleList.push(this.state.RoleList.find((d) => d.id === element.RoleId));
                    });

                    this.setState({ userToUpdate: userCopy, defaultSelectedRoleList: defaultSelectedRoleList });
                }
            });
        }
    }



    render() {
        let { UserId, FirstName, LastName, PhoneNumber1, PhoneNumber2, Email } = this.state.userToUpdate;
        return (
            <div>                
                <a href="javascript:void(0);" onClick={this.handleClickOpen} className="" title="Modifier" style={{marginLeft: '-3px' }}>
                    <EditIcon />
                </a> 
                <Dialog
                    disableBackdropClick
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Modification d'un nouvel utilisateur
                    </DialogTitle>
                    <DialogContent dividers>
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit}
                            onError={errors => null}
                            style={{ marginTop: "10px" }}
                        >
                            <Grid container spacing={12}>

                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Prénom"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="FirstName"
                                        value={FirstName}
                                        validators={["required"]}
                                        errorMessages={["Le nom de l'utilisateur est recquis"]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Nom"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="LastName"
                                        value={LastName}
                                        validators={["required"]}
                                        errorMessages={["Le prénom de l'utilisateur est recquis"]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Email"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="Email"
                                        value={Email}
                                        validators={["required", "isEmail"]}
                                        errorMessages={["this field is required", "email is not valid"]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Numéro de téléphone 1"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="PhoneNumber1"
                                        value={PhoneNumber1}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Numéro de téléphone 2"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="PhoneNumber2"
                                        value={PhoneNumber2}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={this.state.RoleList}
                                        getOptionLabel={option => option.title}
                                        defaultValue={this.state.defaultSelectedRoleList}
                                        filterSelectedOptions
                                        onChange={(event, value) => this.handleSelectListChange(event, value)}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Rôles"
                                                fullWidth

                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSave} color="secondary" variant="contained" disabled={this.props.storeUser.submitting}>
                            Soumettre {this.props.storeUser.submitting ? <CircularProgress size={20} /> : <span></span>}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

//export default CreateEntityDialog; setTableContentLoading

const mapStateToProps = state => ({
    updateUser: PropTypes.func.isRequired,
    setTableContentLoading: PropTypes.func.isRequired,
    getRoleList: PropTypes.func.isRequired,
    storeRole: state.role,
    storeUser: state.user,
});
export default (
    withRouter(connect(mapStateToProps, { getRoleList, updateUser, setTableContentLoading })(UpdateUser))
);