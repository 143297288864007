import {
    GET_PROJECT_LIST,
    PROJECT_ACTION_LOADING,
    PROJECT_ACTION_SUCCESS,
    PROJECT_ACTION_ERROR,
    PROJECT_ACTION_SUBMITING,
    PROJECT_ACTION_SUBMITED,
    SHOW_ALERT,
    HIDE_ALERT,
    GET_PROJECT,
    GET_QUIZ_LIST,
    QUIZ_ACTION_SUCCESS,
    QUIZ_ACTION_ERROR,
    QUIZ_ACTION_LOADING,
    GET_QUIZ_RESPONSE_LIST
} from "../actions/ProjectActions";

const initialState = {
    projectList: [],
    quizList: [],
    quizResponseList: [],
    storeCurrentProject: {},
    loading: false,
    success: false,
    failed: false,
    submitting: false,
    errorMessage: "",
    showAlert: false,
    alertMessage: "",
    alertVariant: "",
    projectListTableContentLoading: false
};

const ProjectReducer = function (state = initialState, action) {
    switch (action.type) {
        // Statut des actions
        case PROJECT_ACTION_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                failed: false
            };
        }
        case PROJECT_ACTION_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                failed: false,
            };
        }
        case PROJECT_ACTION_SUBMITING: {
            return {
                ...state,
                submitting: true
            };
        }
        case PROJECT_ACTION_SUBMITED: {
            return {
                ...state,
                submitting: false
            };
        }        
        case PROJECT_ACTION_ERROR: {
            return {
                ...state,
                failed: true,
                success: false,
                loading: false,
                showAlert: true,
                alertMessage: action.data,
                alertVariant: "error"
            };
        }
        case GET_PROJECT: {
            return {
                ...state,
                storeCurrentProject: { ...action.data }
            };
        }
        case GET_PROJECT_LIST: {
            return {
                ...state,
                projectList: [...action.data],
            };
        }
        case GET_QUIZ_LIST: {
            return {
                ...state,
                quizList: [...action.data]
            };
        }
        case GET_QUIZ_RESPONSE_LIST: {
            return {
                ...state,
                quizResponseList: [...action.data]
            };
        }
        case QUIZ_ACTION_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                failed: false
            };
        }
        case QUIZ_ACTION_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                failed: false,
            };
        }
        case QUIZ_ACTION_ERROR: {
            return {
                ...state,
                failed: true,
                success: false,
                loading: false,
                showAlert: true,
                alertMessage: action.data,
                alertVariant: "error"
            };
        }
        // Alert action
        case SHOW_ALERT: {
            return {
                ...state,
                showAlert: true,
                alertMessage: action.data,
                alertVariant: "success"
            };
        }
        case HIDE_ALERT: {
            return {
                ...state,
                showAlert: false,
                alertMessage: "",
                alertVariant: ""
            };
        }
        default: {
            return state;
        }
    }
};

export default ProjectReducer;
