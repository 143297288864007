import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRoleList, } from "app/redux/actions/RoleActions";
import { getUserList, hideAlert } from "app/redux/actions/UserActions";
import { Table, TableBody, TableCell, TableRow, TableHead, CircularProgress, TablePagination, Menu, MenuItem } from '@material-ui/core';
import { SimpleCard, Breadcrumb } from 'matx';
import CreateUser from "./dialogs/CreateUser";
import UpdateUser from "./dialogs/UpdateUser";
import DeleteUser from "./dialogs/DeleteUser";
import SnackbarContentWrapper from "../alert/SnackbarContentWrapper"
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import UserRolePopover from "./popovers/UserRolesPopover";
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles2 = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1)
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));


let roleListLoaded = false;
let userListLoaded = false;

function UserListPage(props) {
    const {
        theme,
        storeRole = {},
        storeUser = {},
        getRoleList,
        getUserList,
        hideAlert,
    } = props;

    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const classes = useStyles2();
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openPop = Boolean(anchorEl);

    useEffect(() => {
        if (storeUser.showAlert) {
            setOpen(true);
            setTimeout(() => {
                hideAlert();
            }, 15000);

        }
        if (storeRole.loading || storeUser.loading) {
            setIsLoading(true);
        }
        else if (storeRole.success && storeUser.success) {
            setIsLoading(false);
        }

    }, [storeRole, storeUser])

    if (!roleListLoaded && !userListLoaded) {
        getRoleList();
        getUserList();
        roleListLoaded = true;
        userListLoaded = true;
    }


    function handleClose(event) {
        setOpen(false);
        hideAlert();
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
    };
    return (
        <div className="m-sm-30">
            {isLoading ? (
                <div style={{ margin: "auto", textAlign: "center", fontSize: "18px", width: "50%", height: "50%", marginTop: "180px" }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {storeUser.showAlert && (
                        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                        >
                            <SnackbarContentWrapper onClose={handleClose} variant={storeUser.alertVariant} message={storeUser.alertMessage} />
                        </Snackbar>
                    )}
                    <div className="mb-sm-30">
                        <Breadcrumb routeSegments={[{ name: "Liste des utitlisateurs" }]} />
                    </div>
                    <SimpleCard title="Liste des utilisateurs"
                        icon={<span style={{ float: "right" }}>
                            <CreateUser roleList={storeRole.roleList} />
                        </span>}
                    >
                        <Table className="product-table" style={{ width: "100%", whiteSpace: "nowrap" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: "16%" }}>Prénom</TableCell>
                                    <TableCell style={{ width: "16%" }}>Nom</TableCell>
                                    <TableCell style={{ width: "24%" }}>Email</TableCell>
                                    <TableCell style={{ width: "18%" }}>Téléphone</TableCell>
                                    <TableCell style={{ width: "20%" }}>Rôle</TableCell>
                                    <TableCell style={{ width: "2%" }}></TableCell>
                                    <TableCell style={{ width: "2%" }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {storeUser.userListTableContentLoading ? (
                                    <TableRow>

                                    </TableRow>
                                ) : (
                                    storeUser.userList
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((user, index) => (
                                            <TableRow key={index}>
                                                <TableCell >{user.FirstName}</TableCell>
                                                <TableCell >{user.LastName}</TableCell>
                                                <TableCell >{user.Email}</TableCell>
                                                <TableCell>{user.PhoneNumber1}</TableCell>
                                                {user.Roles.length > 1 ? (
                                                    <UserRolePopover user={user} />
                                                ) : (
                                                    <TableCell>{user.Roles[0].Name}</TableCell>
                                                )}
                                                <TableCell>
                                                    <UpdateUser userToUpdate={user} />
                                                </TableCell>
                                                <TableCell>
                                                    <DeleteUser userToDelete={user} />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                )}
                                { }
                            </TableBody>
                        </Table>
                        <TablePagination
                            className="px-4"
                            rowsPerPageOptions={[25]}
                            component="div"
                            count={storeUser.userList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "Previous Page"
                            }}
                            nextIconButtonProps={{
                                "aria-label": "Next Page"
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </SimpleCard>
                </>
            )}

        </div>
    );
}

UserListPage.propTypes = {
    hideAlert: PropTypes.func.isRequired,
    storeRole: PropTypes.object.isRequired,
    storeUser: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getUserList: PropTypes.func.isRequired,
    getRoleList: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    storeRole: state.role,
    storeUser: state.user,
});

export default withStyles({}, { withTheme: true })(connect(mapStateToProps, { getRoleList, getUserList, hideAlert })(UserListPage));
