import React from "react";
import { Breadcrumb, SimpleCard } from "matx";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Icon, Grid, CircularProgress, Box, Typography, LinearProgress, TextField, Table, TableBody, TableCell, TableRow, TableHead } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import SnackbarContentWrapper from "./../alert/SnackbarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { getUserList, updateUser } from "app/redux/actions/UserActions";
import ChangeUserPasswordDialog from "./dialogs/ChangeUserPassord";

const styles = theme => ({
    button: {
        margin: theme.spacing(1)
    },
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

class UserProfilePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentUser: this.props.storeUsers.currentUser,
            SnackbarOpen: false,
            showSnackbar: false,
            isLoading: true,
            defaultSelectedRoleList: [],
            Roles: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getOptionsFromRoleList = this.getOptionsFromRoleList.bind(this);

    }

    componentDidMount() {
        if (this.props.storeUsers.currentUser.Roles) {
            this.getOptionsFromRoleList();
        }
        console.log(this.props.storeUsers.currentUser.User);
    };
    componentWillReceiveProps(nextProps) {


    };
    getOptionsFromRoleList = () => {
        if (this.props.storeUsers.currentUser.Roles != undefined && this.props.storeUsers.currentUser.Roles.length > 0) {
            let result = [];
            this.props.storeUsers.currentUser.Roles.forEach(element => {
                result.push({ title: element });
            });
            this.setState({ defaultSelectedRoleList: result }, () => {
                this.setState({ isLoading: false });
            });
        }
    }


    snackbarHandleClose = () => {
        this.setState({ SnackbarOpen: false });
    };
    handleSubmit = value => {
        // let request = { Mail: this.state.mailToUpdate, ActionAuthorName: this.props.storeUsers.DisplayName, ActionAuthorId: this.props.storeUsers.UserId }
        this.props.updateUser(value).then(data => {
            if (data !== null) {
                this.props.getUserList(this.props.storeUsers.UserId);
            }
        });
    };
    handleChange = event => {
        event.persist();
        let userCopy = this.state.userToUpdate;
        for (let key in userCopy) {
            if (key === event.target.name) {
                userCopy[`${key}`] = event.target.value
            }
        }
        this.setState({ userToUpdate: userCopy });
    };




    render() {
        let { FirstName, LastName, PhoneNumber2, PhoneNumber1, Email, Roles } = this.state.currentUser.User;
        return (
            <div className="m-sm-30">
                {this.state.isLoading ? (
                    <div style={{
                        margin: "auto", textAlign: "center", fontSize: "18px",
                        width: "50%", height: "50%", marginTop: "180px"
                    }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {(this.state.showSnackbar) && (
                            <Snackbar
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}
                                open={this.state.SnackbarOpen}
                                autoHideDuration={6000}
                                onClose={this.snackbarHandleClose}
                            >
                                <SnackbarContentWrapper
                                    onClose={this.snackbarHandleClose}
                                    variant="success"
                                    message={this.state.message}
                                />
                            </Snackbar>
                        )}
                        <div className="mb-sm-30">
                            <Breadcrumb
                                routeSegments={[
                                   
                                    { name: "Profile de : " + " "+ FirstName + " " + LastName }
                                ]}
                            />
                        </div>
                        <SimpleCard title={"Profile de : "} icon={<span style={{ float: "right" }}> <ChangeUserPasswordDialog /></span>} >
                            <div>
                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleSubmit}
                                    onError={errors => null}
                                >
                                    <Grid container spacing={6}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label="Prénom"
                                                onChange={this.handleChange}
                                                type="text"
                                                name="FirstName"
                                                value={FirstName}
                                                validators={["required"]}
                                                errorMessages={["Le champs est recquis"]}
                                                fullWidth
                                            />
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label="Téléphone 1"
                                                onChange={this.handleChange}
                                                type="text"
                                                name="PhoneNumber"
                                                value={PhoneNumber1}
                                                fullWidth
                                            />
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label="Email"
                                                onChange={this.handleChange}
                                                type="text"
                                                name="Email"
                                                value={Email}
                                                validators={["required"]}
                                                errorMessages={["Le champs est recquis"]}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label="Nom"
                                                onChange={this.handleChange}
                                                type="text"
                                                name="LastName"
                                                value={LastName}
                                                validators={["required"]}
                                                errorMessages={["Le champs est recquis"]}
                                                fullWidth
                                            />
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label="Téléphone 2"
                                                onChange={this.handleChange}
                                                type="text"
                                                name="PhoneNumber2"
                                                value={PhoneNumber2}
                                                fullWidth
                                            />
                                            <Autocomplete
                                                disabled
                                                multiple
                                                id="tags-standard"
                                                options={this.state.currentUser.Roles}
                                                getOptionLabel={option => option.title}
                                                defaultValue={this.state.defaultSelectedRoleList}
                                                filterSelectedOptions
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Rôles"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button color="secondary" variant="contained" type="submit" disabled={this.props.storeUsers.submitting}>
                                        <Icon>send</Icon>
                                        <span className="pl-2 capitalize">Sauvegarder</span> {this.props.storeUsers.submitting ? <CircularProgress size={20} /> : <span></span>}
                                    </Button>
                                </ValidatorForm>
                            </div>
                        </SimpleCard>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    getUserList: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    storeUsers: state.user,
    storeRole: state.role
});
export default (
    withRouter(connect(mapStateToProps, { getUserList, updateUser })(UserProfilePage))
);