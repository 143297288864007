import React from "react";
import { Breadcrumb, SimpleCard } from "matx";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Icon, Grid, CircularProgress, LinearProgress, Table, TableBody, TableCell, TableRow, TableHead, MenuItem, Checkbox } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import SnackbarContentWrapper from "../panelists/alert/SnackbarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import _, { without } from 'underscore';
import { getGroupList, updateGroup, affectPanelistToUser, deletePanelistFromPanelistGroup, hideAlert } from "app/redux/actions/PanelistGroupActions";
import guid from "guid";
import moment from 'moment';
import DeletePanelistFromGroup from "./dialog/DeletePanelistFromGroup";
import { MatxMenu, MatxSearchBox } from "matx";
import { reject } from "lodash";
import IconButton from "@material-ui/core/IconButton";
import { useEffect } from "react";


const styles = theme => ({
    button: {
        margin: theme.spacing(1)
    },
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);


function PanelistGroupDetail(props) {

    const {
        storeUser = {},
        storePanelistGroup = {},
        disabled = false,
        getGroupList,
        updateGroup,
        deletePanelistFromPanelistGroup,
    } = props;

    const [isLoading, setIsLoading] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [id, setId] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [permissions, setPermissions] = React.useState([]);
    const [panelistGroupToUpdate, setPanelistGroupToUpdate] = React.useState({
        PanelistGroupId: "",
        Panelists: [],
        Name: "",
        Description: ""
    });

    useEffect(() => {
        let match = props.match;
        if (match != undefined) {
            let params = match.params;
            if (params != undefined && params.id != undefined) {
                setId(params.id);
                if (storePanelistGroup.panelistGroupList.length == 0 && panelistGroupToUpdate.PanelistGroupId == "") {
                    getGroupList().then(response => {
                        if (response) {
                            let groupFromRequest = response.find(g => g.PanelistGroupId == params.id);
                            if (groupFromRequest) {
                                setPanelistGroupToUpdate(groupFromRequest);
                                console.log("groupFromRequest", groupFromRequest);
                            }
                        }
                    })
                }
                else if (storePanelistGroup.panelistGroupList.length > 0 && panelistGroupToUpdate.PanelistGroupId == "") {
                    let groupFromStore = storePanelistGroup.panelistGroupList.find(g => g.PanelistGroupId == params.id);
                    if (groupFromStore) {
                        setPanelistGroupToUpdate(groupFromStore);
                    }
                }
            }
        }
    }, [])

    useEffect(() => {
        if (storeUser.currentUser.Permissions) {
            setPermissions(storeUser.currentUser.Permissions);
        }
        if (storeUser.loading != isLoading) {
            setIsLoading(storeUser.loading)
        }
    }, [storeUser])

    useEffect(() => {
        if (panelistGroupToUpdate?.Name == "") {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);

        }
    }, [panelistGroupToUpdate])

    let snackbarHandleClose = () => {
        setSnackbarOpen(false);
    };

    let handleSubmit = value => {
        setIsSubmitting(true);
        updateGroup(panelistGroupToUpdate).then(data => {
            if (data.Success) {
                setIsSubmitting(false);
            }
        });
    };

    let handleDeletePanelistFromPanelistGroup = (request) => {
        return new Promise((resolve, reject) => {
            deletePanelistFromPanelistGroup(request).then(response => {
                if (response.Success) {
                    setPanelistGroupToUpdate(response.PanelistGroup);
                    resolve(response);
                }
            })
        });
    }

    return (
        <div className="m-sm-30">
            {isLoading ? (
                <div style={{
                    margin: "auto", textAlign: "center", fontSize: "18px",
                    width: "50%", height: "50%", marginTop: "180px"
                }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {storePanelistGroup.showAlert && (
                        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={snackbarOpen}
                            autoHideDuration={6000}
                        // onClose={handleClose}
                        >
                            <SnackbarContentWrapper
                                // onClose={handleClose} 
                                variant={storePanelistGroup.alertVariant}
                                message={storePanelistGroup.alertMessage}
                            />
                        </Snackbar>
                    )}
                    <div className="mb-sm-30">
                        <Breadcrumb
                            routeSegments={[
                                { name: "Groupes", path: "/panelist/groups" },
                                { name: panelistGroupToUpdate?.Name }
                            ]}
                        />
                    </div>
                    <SimpleCard title={"Détail du groupe:" + " " + `${panelistGroupToUpdate?.Name}`}>
                        <div>
                            <ValidatorForm
                                // ref="form"
                                onSubmit={handleSubmit}
                                onError={errors => null}
                            >
                                <Grid container spacing={6}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextValidator
                                            className="mb-16 w-100"
                                            label="Nom"
                                            onChange={(e) => {
                                                let val = e.target?.value;
                                                setPanelistGroupToUpdate(prevState => { return { ...prevState, Name: val } })
                                            }}
                                            type="text"
                                            name="Name"
                                            value={panelistGroupToUpdate?.Name}
                                            fullWidth
                                        />
                                        <TextValidator
                                            className="mb-16 w-100"
                                            label="Date de création"
                                            type="text"
                                            name="CreatedDate"
                                            value={moment(panelistGroupToUpdate?.CreatedDate).format('Do MMMM YYYY')}
                                            fullWidth
                                            disabled
                                        />

                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextValidator
                                            className="mb-16 w-100"
                                            label="Description"
                                            onChange={(e) => {
                                                let val = e.target?.value;
                                                setPanelistGroupToUpdate(prevState => { return { ...prevState, Description: val } })
                                            }}
                                            type="text"
                                            name="Description"
                                            value={panelistGroupToUpdate?.Description}
                                            fullWidth
                                        />
                                        <TextValidator
                                            className="mb-16 w-100"
                                            label="Créé(e) par"
                                            type="text"
                                            name="Author"
                                            value={panelistGroupToUpdate?.Author}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                                <Button color="secondary" variant="contained" type="submit" disabled={isSubmitting || hasMissingValue}>
                                    <Icon>send</Icon>
                                    <span className="pl-8 capitalize">Sauvegarder</span> {isSubmitting ? <CircularProgress size={20} /> : <span></span>}
                                </Button>
                            </ValidatorForm>
                        </div>
                    </SimpleCard>
                    <div className="py-12" />
                    <div className="py-12" />
                    <SimpleCard title="Liste des panélistes">
                        <Table className="product-table" style={{ width: "100%", whiteSpace: "nowrap" }}>
                            <TableHead>
                                {panelistGroupToUpdate?.Type == "NaturalPerson" ?
                                    (
                                        <TableRow>
                                            <TableCell style={{ width: "20%" }}>Prénom</TableCell>
                                            <TableCell style={{ width: "20%" }}>Nom</TableCell>
                                            <TableCell style={{ width: "20%" }}>Date de naissance</TableCell>
                                            <TableCell style={{ width: "15%" }}>Sexe</TableCell>
                                            <TableCell style={{ width: "20%" }}>Numéro de téléphone</TableCell>
                                            <TableCell style={{ width: "5%" }}></TableCell>
                                        </TableRow>
                                    )
                                    :
                                    (
                                        <TableRow>
                                            <TableCell style={{ width: "28%" }}>Nom</TableCell>
                                            <TableCell style={{ width: "27%" }}>Email </TableCell>
                                            <TableCell style={{ width: "20%" }}>Téléphone</TableCell>
                                            <TableCell style={{ width: "20%" }}>Date de création</TableCell>
                                            <TableCell style={{ width: "5%" }}></TableCell>
                                        </TableRow>
                                    )}

                            </TableHead>
                            <TableBody>
                                {panelistGroupToUpdate?.Panelists?.map((panelist, index) => (
                                    <TableRow key={index} >
                                        {panelistGroupToUpdate?.Type == "NaturalPerson" ?
                                            (
                                                <>
                                                    <TableCell>{panelist.FirstName}</TableCell>
                                                    <TableCell>{panelist.LastName}</TableCell>
                                                    <TableCell>{moment(panelist.BirthDate).format('Do MMMM YYYY')}</TableCell>
                                                    <TableCell >{panelist.Gender == "Male" ? "Masculin" : "Féminin"} </TableCell>
                                                    <TableCell>{panelist.PhoneNumber1}</TableCell>
                                                    <TableCell>
                                                        <DeletePanelistFromGroup
                                                            panelistGroupId={panelistGroupToUpdate.PanelistGroupId}
                                                            panelistFromGroupToDelete={panelist}
                                                            onDeletePanelistGroup={handleDeletePanelistFromPanelistGroup}
                                                        />
                                                    </TableCell>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <TableCell>{panelist.LastName}</TableCell>
                                                    <TableCell>{panelist.Email}</TableCell>
                                                    <TableCell>{panelist.PhoneNumber1}</TableCell>
                                                    <TableCell>{moment(panelist.BirthDate).format('Do MMMM YYYY')}</TableCell>
                                                    <TableCell>
                                                        <DeletePanelistFromGroup
                                                            panelistGroupId={panelistGroupToUpdate.PanelistGroupId}
                                                            panelistFromGroupToDelete={panelist}
                                                            onDeletePanelistGroup={handleDeletePanelistFromPanelistGroup}
                                                        />
                                                    </TableCell>
                                                </>
                                            )}

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </SimpleCard>
                </>
            )}
        </div>
    );
}






const mapStateToProps = state => ({
    updateGroup: PropTypes.func.isRequired,
    affectPanelistToUser: PropTypes.func.isRequired,
    deletePanelistFromPanelistGroup: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    getGroupList: PropTypes.func.isRequired,
    storePanelistGroup: state.panelistgroup,
    storeUser: state.user,
});
export default (
    withRouter(connect(mapStateToProps,
        {
            getGroupList,
            updateGroup,
            affectPanelistToUser,
            deletePanelistFromPanelistGroup,
            hideAlert
        })(PanelistGroupDetail))
);