import axios from "axios";
import localStorageService from "./localStorageService";
import { api } from "./apiHelpers"

class AuthentificationService {

    // User object example, just for the demo
    // user = {
    //   userId: "1",
    //   role: 'ADMIN',
    //   displayName: "Jason Alexander",
    //   email: "jasonalexander@gmail.com",
    //   photoURL: "/assets/images/face-6.jpg",
    //   age: 25,
    //   token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh"
    // }

    // Permet de se connecter avec un email et un mot de passe
    loginWithEmailAndPassword = (email, password) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // Exemple d'appel API pour avoir les info de nouvel utilisateur

                axios.post(`${api}` + "account/login", { email, password }).then(res => {
                    resolve(res.data);
                })
            }, 1000);
        }).then(response => {
            // on vérifie si l'objet répresentant l'utilisateur et son propriété Token sont définis
            if (response.Success && response.User && response.User.Token) {
                this.setSession(response.User.Token); // Alors on enregistre la session avec le nouveau token
                this.setUser(response); // Alors on enregistre les infos du nouvel utilisateur
            }
            return response;
        });
    };

    // Permet la connexion avec un token
    loginWithToken = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                axios.get(`${api}` + "account/getuserbytoken/" + localStorage.getItem("dc_token")).then(res => {
                    resolve(res.data);
                })
            }, 5);
        }).then(response => {
            // on vérifie si l'objet répresentant l'utilisateur et son propriété Token sont définis
            if (response.Success && response.User && response.User.Token) {
                // Token is valid
                if (response.User.Token != localStorage.getItem("dc_token")) {
                    this.setSession(response.User.Token);
                    this.setUser(response);
                }
            }
            return response;
        });
    };

    // Permet de se déconnecter en annulant la session et en supprimant les infos de l'utilisateur du local storage
    logout = () => {
        this.setSession(null);
        this.removeUser();
    }

    // Set token to all http request header, so you don't need to attach everytime
    setSession = token => {
        if (token) {
            localStorage.setItem("dc_token", token);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        } else {
            localStorage.removeItem("dc_token");
            delete axios.defaults.headers.common["Authorization"];
        }
    };

    // Save user to localstorage
    setUser = (user) => {
        localStorageService.setItem("auth_user", user);
    }
    // Remove user from localstorage
    removeUser = () => {
        localStorage.removeItem("auth_user");
    }
}

export default new AuthentificationService();
