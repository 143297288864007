import {
  GET_PANELIST,
  GET_PANELIST_LIST,
  ADD_PANELIST_TO_LIST,
  DELETE_PANELIST_FROM_LIST,
  UPDATE_PANELIST,
  PANELIST_ACTION_SUBMITTING,
  PANELIST_ACTION_SUBMITED,
  PANELIST_ACTION_LOADING,
  PANELIST_ACTION_SUCCESS,
  PANELIST_ACTION_ERROR,
  SHOW_ALERT,
  HIDE_ALERT,
  PANELIST_ADVANCEDSEARCH_TABLE_CONTENT_LOADING,
  PANELIST_ADVANCEDSEARCH_TABLE_CONTENT_LOADED,
  GET_REGIONLIST
} from "../actions/PanelistActions";

const initialState = {
  submitting: false,
  success: false,
  loading: false,
  failed: false,
  showAlert: false,
  alertMessage: "",
  alertVariant: "",
  panelistList: [],
  panelistAsNaturalPersonList: [],
  panelistAsLegalPersonList: [],
  currentPanelist: {},
  panelistAdvancedSearchTableContentLoading: false,
  regionList: [],
  advancedSearchTermList: [
    {
      key: "Type",
      value: "Type"
    },
    {
      key: "Nombre d'employés",
      value: "EmployeesCount"
    },
    {
      key: "Prénom",
      value: "FirstName"
    },
    {
      key: "Nom",
      value: "LastName"
    },
    {
      key: "Date de naissance/création",
      value: "BirthDate"
    },
    {
      key: "Sexe",
      value: "Gender"
    },
    {
      key: "Revenu",
      value: "Income"
    },
    {
      key: "Téléphone 1",
      value: "PhoneNumber1"
    },
    {
      key: "Téléphone 2",
      value: "PhoneNumber2"
    },
    {
      key: "Email",
      value: "Email"
    },
    {
      key: "Niveau d'étude",
      value: "EducationLevel"
    },
    {
      key: "Catégorie",
      value: "Category"
    },
    {
      key: "Profession",
      value: "Profession"
    },
    {
      key: "Région/Vile",
      value: "RegionName"
    },
    {
      key: "Commune",
      value: "TownName"
    },
    {
      key: "Quartier",
      value: "DistictName"
    },
  ],
  educationLevelOptions: [
    { label: "Pas d'éducation scolaire" },
    { label: "Ecole coranique" },
    { label: "Primaire" },
    { label: "Secondaire" },
    { label: "Universitaire" }
  ],
  incomeOptions: [
    { label: "Moins de 150 000 FCFA" },
    { label: "150 000 à 450 000 FCFA" },
    { label: "450 000 à 750 000 FCFA" },
    { label: "750 000 à 1 500 000 FCFA" },
    { label: "Plus de 1 500 000 FCFA" }
  ],
  professionOptions: [
    { label: "Agriculture", type: "LegalPerson" },
    { label: "Architecte", type: "LegalPerson" },
    { label: "Artisan", type: "LegalPerson" },
    { label: "Artiste", type: "LegalPerson" },
    { label: "Autres", type: "LegalPerson" },
    { label: "Autre technicien", type: "LegalPerson" },
    { label: "Banquier", type: "LegalPerson" },
    { label: "Cadre d'entreprise", type: "LegalPerson" },
    { label: "Chauffeur, routier", type: "LegalPerson" },
    { label: "Chef d'entreprise", type: "LegalPerson" },
    { label: "Chercheur, scientifique", type: "LegalPerson" },
    { label: "Chimiste", type: "LegalPerson" },
    { label: "Chômeur", type: "LegalPerson" },
    { label: "Clergé,religieux", type: "LegalPerson" },
    { label: "Commerçant", type: "LegalPerson" },
    { label: "Diplomate", type: "LegalPerson" },
    { label: "Electronicien", type: "LegalPerson" },
    { label: "Elève,Etudiant,Stagiaire", type: "NaturalPerson" },
    { label: "Employé", type: "NaturalPerson" },
    { label: "Employé Privé au Service de Diplomate", type: "NaturalPerson" },
    { label: "Enseignant", type: "NaturalPerson" },
    { label: "Fonctionnaire", type: "NaturalPerson" },
    { label: "Homme Politique", type: "NaturalPerson" },
    { label: "Informaticien", type: "NaturalPerson" },
    { label: "Journaliste", type: "NaturalPerson" },
    { label: "Magistrat", type: "NaturalPerson" },
    { label: "Marin", type: "NaturalPerson" },
    { label: "Mode, Cosmétique", type: "NaturalPerson" },
    { label: "Ouvrier", type: "NaturalPerson" },
    { label: "Personnel de Service, Administratif ou Technique (Postes dipl./consul.)", type: "NaturalPerson" },
    { label: "Policier, Militaire", type: "NaturalPerson" },
    { label: "Profession Jurique", type: "NaturalPerson" },
    { label: "Profession Fiscaliste", type: "NaturalPerson" },
    { label: "Profession Libérale", type: "NaturalPerson" },
    // { label: "Analyste Programmeur", type: "NaturalPerson" },
    // { label: "Actuaire Tarification", type: "NaturalPerson" },
    // { label: "Analyste Réalisateur", type: "NaturalPerson" },
    // { label: "Actuaire Vie", type: "NaturalPerson" },
    // { label: "Analyste Réseaux", type: "NaturalPerson" },
    // { label: "Adjoint de Direction", type: "NaturalPerson" },
    // { label: "Analyste Risques", type: "NaturalPerson" },
    // { label: "Adjoint Magasin", type: "NaturalPerson" },
    // { label: "Analyste Technique", type: "NaturalPerson" },
    // { label: "Adjoint Responsable Magasin", type: "NaturalPerson" },
    // { label: "Analyste Test", type: "NaturalPerson" },
    // { label: "Admin IT", type: "NaturalPerson" },
    // { label: "Animateur", type: "NaturalPerson" },
    // { label: "Administrateur Base de Données", type: "NaturalPerson" },
    // { label: "Animateur 2D", type: "NaturalPerson" },
    // { label: "Administrateur des Ventes", type: "NaturalPerson" },
    // { label: "Animateur 3D", type: "NaturalPerson" },
    // { label: "Administrateur Réseau", type: "NaturalPerson" },
    // { label: "Animateur Avant-Vente", type: "NaturalPerson" },
    // { label: "Administrateur Site Web", type: "NaturalPerson" },
    // { label: "Animateur Club de Vacances", type: "NaturalPerson" },
    // { label: "Administrateur Support", type: "NaturalPerson" },
    // { label: "Animateur Commercial", type: "NaturalPerson" },
    // { label: "Administrateur Système", type: "NaturalPerson" },
    // { label: "Animateur de Loisirs", type: "NaturalPerson" },
    // { label: "Administrateur Technique", type: "NaturalPerson" },
    // { label: "Animateur de Stand", type: "NaturalPerson" },
    // { label: "Agent Accueil Touristique", type: "NaturalPerson" },
    // { label: "Animateur de Télévision", type: "NaturalPerson" },
    // { label: "Agent Administratif", type: "NaturalPerson" },
    // { label: "Animateur de Vente", type: "NaturalPerson" },
    // { label: "Agent Archivage", type: "NaturalPerson" },
    // { label: "Animateur Enfants", type: "NaturalPerson" },
    // { label: "Agent Assainissement", type: "NaturalPerson" },
    // { label: "Animateur Fitness", type: "NaturalPerson" },
    // { label: "Agent Assurance", type: "NaturalPerson" },
    // { label: "Animateur HSE", type: "NaturalPerson" },
    // { label: "Agent Call Center", type: "NaturalPerson" },
    // { label: "Animateur Qualité", type: "NaturalPerson" },
    // { label: "Agent Commercial", type: "NaturalPerson" },
    // { label: "Animateur Radio", type: "NaturalPerson" },
    // { label: "Agent de Billetterie", type: "NaturalPerson" },
    // { label: "Animateur Réseau", type: "NaturalPerson" },
    // { label: "Agent de Blanchisserie", type: "NaturalPerson" },
    // { label: "Animateur Socio-Culturel", type: "NaturalPerson" },
    // { label: "Agent de Bureau", type: "NaturalPerson" },
    // { label: "Animateur Soirée", type: "NaturalPerson" },
    // { label: "Agent de Change", type: "NaturalPerson" },
    // { label: "Animateur Spectacle", type: "NaturalPerson" },
    // { label: "Agent de Classement", type: "NaturalPerson" },
    // { label: "Animateur Sportif", type: "NaturalPerson" },
    // { label: "Agent de Comptoir", type: "NaturalPerson" },
    // { label: "Animateur Touristique", type: "NaturalPerson" },
    // { label: "Agent de Contrôle", type: "NaturalPerson" },
    // { label: "Apporteur Affaires", type: "NaturalPerson" },
    // { label: "Agent de Copropriété", type: "NaturalPerson" },
    // { label: "Approvisionneur", type: "NaturalPerson" },
    // { label: "Agent de Développement", type: "NaturalPerson" },
    // { label: "Aquaculteur", type: "NaturalPerson" },
    // { label: "Agent de Fabrication", type: "NaturalPerson" },
    // { label: "Architecte", type: "NaturalPerson" },
    // { label: "Agent de Fabrication Automobile", type: "NaturalPerson" },
    // { label: "Architecte Applicatif", type: "NaturalPerson" },
    // { label: "Agent de Fabrication Industrielle", type: "NaturalPerson" },
    // { label: "Architecte Auditeur Technique", type: "NaturalPerson" },
    // { label: "Agent de Facturation", type: "NaturalPerson" },
    // { label: "Architecte Décisionnel", type: "NaturalPerson" },
    // { label: "Agent de Finition", type: "NaturalPerson" },
    // { label: "Architecte Développeur", type: "NaturalPerson" },
    // { label: "Agent de Liaison", type: "NaturalPerson" },
    // { label: "Architecte d´Intérieur", type: "NaturalPerson" },
    // { label: "Agent de Lingerie", type: "NaturalPerson" },
    // { label: "Architecte Fonctionnel", type: "NaturalPerson" },
    // { label: "Agent de Lutte Antiparasitaire", type: "NaturalPerson" },
    // { label: "Architecte Informatique", type: "NaturalPerson" },
    // { label: "Agent de Maintenance", type: "NaturalPerson" },
    // { label: "Architecte Infrastructure", type: "NaturalPerson" },
    // { label: "Agent de Maintenance des Bâtiments", type: "NaturalPerson" },
    // { label: "Architecte Logiciel", type: "NaturalPerson" },
    // { label: "Agent de Médiation Sociale", type: "NaturalPerson" },
    // { label: "Architecte Paysagiste", type: "NaturalPerson" },
    // { label: "Agent de Montage", type: "NaturalPerson" },
    // { label: "Architecte Poste de Travail", type: "NaturalPerson" },
    // { label: "Agent de Nettoyage Hospitalier", type: "NaturalPerson" },
    // { label: "Architecte Réseaux", type: "NaturalPerson" },
    // { label: "Agent de Phoning", type: "NaturalPerson" },
    // { label: "Architecte Réseaux Sécurité", type: "NaturalPerson" },
    // { label: "Agent de Piste Aéroport", type: "NaturalPerson" },
    // { label: "Architecte Sécurité", type: "NaturalPerson" },
    // { label: "Agent de Planification", type: "NaturalPerson" },
    // { label: "Architecte SI", type: "NaturalPerson" },
    // { label: "Agent de Planning Transport", type: "NaturalPerson" },
    // { label: "Architecte Système", type: "NaturalPerson" },
    // { label: "Agent de Pointage", type: "NaturalPerson" },
    // { label: "Architecte Technique", type: "NaturalPerson" },
    // { label: "Agent de Prévention et de Médiation", type: "NaturalPerson" },
    // { label: "Architecte Test", type: "NaturalPerson" },
    // { label: "Agent de Production", type: "NaturalPerson" },
    // { label: "Archiviste", type: "NaturalPerson" },
    // { label: "Agent de Programmation", type: "NaturalPerson" },
    // { label: "Area Manager", type: "NaturalPerson" },
    // { label: "Agent de Promotion", type: "NaturalPerson" },
    // { label: "Area Sales Manager", type: "NaturalPerson" },
    // { label: "Agent de Propreté et d´Hygiène", type: "NaturalPerson" },
    // { label: "Art Thérapeute", type: "NaturalPerson" },
    // { label: "Agent de Quai", type: "NaturalPerson" },
    // { label: "Artisan", type: "NaturalPerson" },
    // { label: "Agent de Recensement", type: "NaturalPerson" },
    // { label: "Artiste Peintre", type: "NaturalPerson" },
    // { label: "Agent de Recouvrement", type: "NaturalPerson" },
    // { label: "Ascensoriste", type: "NaturalPerson" },
    // { label: "Agent de Réservation", type: "NaturalPerson" },
    // { label: "Assembleur Aéronautique", type: "NaturalPerson" },
    // { label: "Agent de Restauration", type: "NaturalPerson" },
    // { label: "Assembleur Monteur de Meubles", type: "NaturalPerson" },
    // { label: "Agent de Sécurité", type: "NaturalPerson" },
    // { label: "Assembleur Soudeur", type: "NaturalPerson" },
    // { label: "Agent de Sécurité et de Surveillance", type: "NaturalPerson" },
    // { label: "Assistant", type: "NaturalPerson" },
    // { label: "Agent de Service Clients", type: "NaturalPerson" },
    // { label: "Assistant Achats et Approvisionnements...", type: "NaturalPerson" },
    // { label: "Agent de Service en Restauration...", type: "NaturalPerson" },
    // { label: "Assistant Administratif", type: "NaturalPerson" },
    // { label: "Agent de Service Hospitalier", type: "NaturalPerson" },
    // { label: "Assistant Administratif et Financier", type: "NaturalPerson" },
    // { label: "Agent de Stérilisation", type: "NaturalPerson" },
    // { label: "Assistant Administration des Ventes", type: "NaturalPerson" },
    // { label: "Agent de Sûreté Aéroportuaire", type: "NaturalPerson" },
    // { label: "Assistant Affaires Réglementaires", type: "NaturalPerson" },
    // { label: "Agent de Télémarketing", type: "NaturalPerson" },
    // { label: "Assistant Back-Office", type: "NaturalPerson" },
    // { label: "Agent de Télémarketing à Domicile", type: "NaturalPerson" },
    // { label: "Assistant Bilingue", type: "NaturalPerson" },
    // { label: "Agent de Tourisme", type: "NaturalPerson" },
    // { label: "Assistant Bureau", type: "NaturalPerson" },
    // { label: "Agent de Trafic", type: "NaturalPerson" },
    // { label: "Assistant Chef de Produit", type: "NaturalPerson" },
    // { label: "Agent de Train", type: "NaturalPerson" },
    // { label: "Assistant Chef de Projet", type: "NaturalPerson" },
    // { label: "Agent de Traitement de l´Eau", type: "NaturalPerson" },
    // { label: "Assistant Commercial", type: "NaturalPerson" },
    // { label: "Agent de Traitement du Courrier", type: "NaturalPerson" },
    // { label: "Assistant Commercial Export", type: "NaturalPerson" },
    // { label: "Agent de Transit", type: "NaturalPerson" },
    // { label: "Assistant Communication", type: "NaturalPerson" },
    // { label: "Agent de Tri", type: "NaturalPerson" },
    // { label: "Assistant Comptable", type: "NaturalPerson" },
    // { label: "Agent de Voyage", type: "NaturalPerson" },
    // { label: "Assistant Contrôleur de Gestion", type: "NaturalPerson" },
    // { label: "Agent Déclarant en Douane", type: "NaturalPerson" },
    // { label: "Assistant de Gestion", type: "NaturalPerson" },
    // { label: "Agent des Services Communautaires et...", type: "NaturalPerson" },
    // { label: "Assistant Dentaire", type: "NaturalPerson" },
    // { label: "Agent Entretien des Cours d´Eau", type: "NaturalPerson" },
    // { label: "Assistant Directeur de Site", type: "NaturalPerson" },
    // { label: "Agent Entretien des Espaces Naturels", type: "NaturalPerson" },
    // { label: "Assistant Edition", type: "NaturalPerson" },
    // { label: "Agent Entretien Nature", type: "NaturalPerson" },
    // { label: "Assistant Facturation", type: "NaturalPerson" },
    // { label: "Agent Escale", type: "NaturalPerson" },
    // { label: "Assistant Formation", type: "NaturalPerson" },
    // { label: "Agent Exploitation", type: "NaturalPerson" },
    // { label: "Assistant Funéraire", type: "NaturalPerson" },
    // { label: "Agent Exploitation Aérien", type: "NaturalPerson" },
    // { label: "Assistant Import Export", type: "NaturalPerson" },
    // { label: "Agent Exploitation de Parking", type: "NaturalPerson" },
    // { label: "Assistant Juridique", type: "NaturalPerson" },
    // { label: "Agent Exploitation en Centre de Stockage...", type: "NaturalPerson" },
    // { label: "Assistant Logistique", type: "NaturalPerson" },
    // { label: "Agent Exploitation en Centre de Tri des...", type: "NaturalPerson" },
    // { label: "Assistant Marketing", type: "NaturalPerson" },
    // { label: "Agent Exploitation Maritime", type: "NaturalPerson" },
    // { label: "Assistant Médical", type: "NaturalPerson" },
    // { label: "Agent Exploitation Transport", type: "NaturalPerson" },
    // { label: "Assistant Paie", type: "NaturalPerson" },
    // { label: "Agent Help Desk", type: "NaturalPerson" },
    // { label: "Assistant Polyvalent", type: "NaturalPerson" },
    // { label: "Agent Hospitalier", type: "NaturalPerson" },
    // { label: "Assistant Production", type: "NaturalPerson" },
    // { label: "Agent Immatriculation Véhicule", type: "NaturalPerson" },
    // { label: "Assistant Qualité", type: "NaturalPerson" },
    // { label: "Agent Immobilier", type: "NaturalPerson" },
    // { label: "Assistant Recrutement", type: "NaturalPerson" },
    // { label: "Agent Import Export", type: "NaturalPerson" },
    // { label: "Assistant RH", type: "NaturalPerson" },
    // { label: "Agent Incendie", type: "NaturalPerson" },
    // { label: "Assistant Service Social", type: "NaturalPerson" },
    // { label: "Agent Information", type: "NaturalPerson" },
    // { label: "Assistant Social", type: "NaturalPerson" },
    // { label: "Agent Inside Sales", type: "NaturalPerson" },
    // { label: "Assistant Soins en Gérontologie", type: "NaturalPerson" },
    // { label: "Agent Laboratoire", type: "NaturalPerson" },
    // { label: "Assistant Son", type: "NaturalPerson" },
    // { label: "Agent Logistique", type: "NaturalPerson" },
    // { label: "Assistant Technique", type: "NaturalPerson" },
    // { label: "Agent Mailer", type: "NaturalPerson" },
    // { label: "Assistant Technique Clientèle", type: "NaturalPerson" },
    // { label: "Agent Méthodes Habillement", type: "NaturalPerson" },
    // { label: "Assistante de Direction", type: "NaturalPerson" },
    // { label: "Agent Polyvalent", type: "NaturalPerson" },
    // { label: "Assistante Familiale", type: "NaturalPerson" },
    // { label: "Agent Relogement", type: "NaturalPerson" },
    // { label: "Assistante Maternelle", type: "NaturalPerson" },
    // { label: "Agent Service Après-Vente", type: "NaturalPerson" },
    // { label: "Attaché Administration", type: "NaturalPerson" },
    // { label: "Agent Services Généraux", type: "NaturalPerson" },
    // { label: "Attaché Commercial", type: "NaturalPerson" },
    // { label: "Agent Sinistre", type: "NaturalPerson" },
    // { label: "Attaché de Presse", type: "NaturalPerson" },
    // { label: "Agent Supply Chain Planner", type: "NaturalPerson" },
    // { label: "Attaché de Recherche Clinique", type: "NaturalPerson" },
    // { label: "Agent Supply Planner", type: "NaturalPerson" },
    // { label: "Attaché Relations Presse", type: "NaturalPerson" },
    // { label: "Agent Support Administratif RH", type: "NaturalPerson" },
    // { label: "Audioprothésiste", type: "NaturalPerson" },
    // { label: "Agent Support Applicatif", type: "NaturalPerson" },
    // { label: "Auditeur", type: "NaturalPerson" },
    // { label: "Agent Support Applicatif Achat", type: "NaturalPerson" },
    // { label: "Auditeur Consultant", type: "NaturalPerson" },
    // { label: "Agent Technico-Commercial", type: "NaturalPerson" },
    // { label: "Auditeur Financier", type: "NaturalPerson" },
    // { label: "Agent Technique", type: "NaturalPerson" },
    // { label: "Auditeur Interne", type: "NaturalPerson" },
    // { label: "Agent Technique Agricole", type: "NaturalPerson" },
    // { label: "Auditeur Qualité", type: "NaturalPerson" },
    // { label: "Agriculteur Biologique", type: "NaturalPerson" },
    // { label: "Auxiliaire de Puériculture", type: "NaturalPerson" },
    // { label: "Agriculteur en Grande Culture", type: "NaturalPerson" },
    // { label: "Auxiliaire de Vie", type: "NaturalPerson" },
    // { label: "Agronome", type: "NaturalPerson" },
    // { label: "Auxiliaire de Vie Sociale", type: "NaturalPerson" },
    // { label: "Aide à Domicile", type: "NaturalPerson" },
    // { label: "Avocat", type: "NaturalPerson" },
    // { label: "Aide à la Personne", type: "NaturalPerson" },
    // { label: "Avocat Collaborateur", type: "NaturalPerson" },
    // { label: "Aide Agricole Saisonnier", type: "NaturalPerson" },
    // { label: "Avocat Conseil", type: "NaturalPerson" },
    // { label: "Aide Caissier", type: "NaturalPerson" },
    // { label: "Avocat d´Affaires", type: "NaturalPerson" },
    // { label: "Aide Comptable", type: "NaturalPerson" },
    // { label: "Bagagiste", type: "NaturalPerson" },
    // { label: "Aide Constructeur Métallique", type: "NaturalPerson" },
    // { label: "Balayeur", type: "NaturalPerson" },
    // { label: "Aide Cuisinier", type: "NaturalPerson" },
    // { label: "Barista", type: "NaturalPerson" },
    // { label: "Aide Documentaliste", type: "NaturalPerson" },
    // { label: "Barman", type: "NaturalPerson" },
    // { label: "Aide Educatrice", type: "NaturalPerson" },
    // { label: "Batelier", type: "NaturalPerson" },
    // { label: "Aide Enseignante", type: "NaturalPerson" },
    // { label: "Beta Testeur", type: "NaturalPerson" },
    // { label: "Aide Familiale", type: "NaturalPerson" },
    // { label: "Bibliothécaire", type: "NaturalPerson" },
    // { label: "Aide Gouvernante", type: "NaturalPerson" },
    // { label: "Bijoutier", type: "NaturalPerson" },
    // { label: "Aide Laborantin", type: "NaturalPerson" },
    // { label: "Billettiste", type: "NaturalPerson" },
    // { label: "Aide Livreur", type: "NaturalPerson" },
    // { label: "Bio Informaticien", type: "NaturalPerson" },
    // { label: "Aide Médico-Psychologique", type: "NaturalPerson" },
    // { label: "Biochimiste", type: "NaturalPerson" },
    // { label: "Aide Ménagère", type: "NaturalPerson" },
    // { label: "Biologiste", type: "NaturalPerson" },
    // { label: "Aide Menuisier", type: "NaturalPerson" },
    // { label: "Biologiste Médical", type: "NaturalPerson" },
    // { label: "Aide Personnes Agées", type: "NaturalPerson" },
    // { label: "Biostatisticien", type: "NaturalPerson" },
    // { label: "Aide Pharmacien", type: "NaturalPerson" },
    // { label: "Blanchisseur Industriel", type: "NaturalPerson" },
    // { label: "Aide Soignante", type: "NaturalPerson" },
    // { label: "Boucher Charcutier", type: "NaturalPerson" },
    // { label: "Aide Vendeur", type: "NaturalPerson" },
    // { label: "Boulanger", type: "NaturalPerson" },
    // { label: "Aide Vétérinaire", type: "NaturalPerson" },
    // { label: "Boulanger Patissier", type: "NaturalPerson" },
    // { label: "Aiguilleur du Ciel", type: "NaturalPerson" },
    // { label: "Boutique Manager", type: "NaturalPerson" },
    // { label: "Aiguilleur du Rail", type: "NaturalPerson" },
    // { label: "Brancardier", type: "NaturalPerson" },
    // { label: "Ajusteur", type: "NaturalPerson" },
    // { label: "Brand Manager", type: "NaturalPerson" },
    // { label: "Ajusteur Monteur", type: "NaturalPerson" },
    // { label: "Brodeur", type: "NaturalPerson" },
    // { label: "Ajusteur Monteur Aéronautique", type: "NaturalPerson" },
    // { label: "Broker", type: "NaturalPerson" },
    // { label: "Ajusteur Mouliste", type: "NaturalPerson" },
    // { label: "Buraliste", type: "NaturalPerson" },
    // { label: "Ambassadeur de Marque", type: "NaturalPerson" },
    // { label: "Business Analyst", type: "NaturalPerson" },
    // { label: "Ambulancier", type: "NaturalPerson" },
    // { label: "Business Consultant", type: "NaturalPerson" },
    // { label: "Analyste", type: "NaturalPerson" },
    // { label: "Business Developer", type: "NaturalPerson" },
    // { label: "Analyste Concepteur", type: "NaturalPerson" },
    // { label: "Business Planner", type: "NaturalPerson" },
    // { label: "Analyste Crédit", type: "NaturalPerson" },
    // { label: "Business Unit Manager", type: "NaturalPerson" },
    // { label: "Cableur", type: "NaturalPerson" },
    // { label: "Conseiller Education", type: "NaturalPerson" },
    // { label: "Cableur Aéronautique", type: "NaturalPerson" },
    // { label: "Conseiller en Assurance", type: "NaturalPerson" },
    // { label: "Cadre Achats", type: "NaturalPerson" },
    // { label: "Conseiller en Création d´Entreprise", type: "NaturalPerson" },
    // { label: "Cadre Back-Office", type: "NaturalPerson" },
    // { label: "Conseiller en Défense des Droits", type: "NaturalPerson" },
    // { label: "Cadre Bureau d´Etudes", type: "NaturalPerson" },
    // { label: "Conseiller en Efficacité Energétique", type: "NaturalPerson" },
    // { label: "Cadre Commercial", type: "NaturalPerson" },
    // { label: "Conseiller en Gestion de Patrimoine", type: "NaturalPerson" },
    // { label: "Cadre Comptable", type: "NaturalPerson" },
    // { label: "Conseiller en Image", type: "NaturalPerson" },
    // { label: "Cadre de Santé", type: "NaturalPerson" },
    // { label: "Conseiller en Socio-Anthropologie", type: "NaturalPerson" },
    // { label: "Cadre Dirigeant", type: "NaturalPerson" },
    // { label: "Conseiller en Transformation Digitale...", type: "NaturalPerson" },
    // { label: "Cadre Export", type: "NaturalPerson" },
    // { label: "Conseiller Evaluation", type: "NaturalPerson" },
    // { label: "Cadre Financier", type: "NaturalPerson" },
    // { label: "Conseiller Financier", type: "NaturalPerson" },
    // { label: "Cadre Infirmier", type: "NaturalPerson" },
    // { label: "Conseiller Immobilier", type: "NaturalPerson" },
    // { label: "Cadre Informatique", type: "NaturalPerson" },
    // { label: "Conseiller Insertion", type: "NaturalPerson" },
    // { label: "Cadre Ingénieur Technico-Commercial", type: "NaturalPerson" },
    // { label: "Conseiller Insertion Professionnelle", type: "NaturalPerson" },
    // { label: "Cadre Supérieur", type: "NaturalPerson" },
    // { label: "Conseiller Juridique", type: "NaturalPerson" },
    // { label: "Cadre Technique", type: "NaturalPerson" },
    // { label: "Conseiller Multimedia", type: "NaturalPerson" },
    // { label: "Cadre Trésorier", type: "NaturalPerson" },
    // { label: "Conseiller National", type: "NaturalPerson" },
    // { label: "Cadreur", type: "NaturalPerson" },
    // { label: "Conseiller Orientation", type: "NaturalPerson" },
    // { label: "Cadreur Audiovisuel", type: "NaturalPerson" },
    // { label: "Conseiller Orientation Pédagogique", type: "NaturalPerson" },
    // { label: "Caissier", type: "NaturalPerson" },
    // { label: "Conseiller Patrimonial", type: "NaturalPerson" },
    // { label: "Calculateur Projeteur", type: "NaturalPerson" },
    // { label: "Conseiller Pédagogique", type: "NaturalPerson" },
    // { label: "Calligraphe", type: "NaturalPerson" },
    // { label: "Conseiller Psychologue en Orientation...", type: "NaturalPerson" },
    // { label: "Cambiste", type: "NaturalPerson" },
    // { label: "Conseiller Recrutement et Mobilité", type: "NaturalPerson" },
    // { label: "Cancérologue", type: "NaturalPerson" },
    // { label: "Conseiller Ressources Humaines", type: "NaturalPerson" },
    // { label: "Capability Manager", type: "NaturalPerson" },
    // { label: "Conseiller Sports et Loisirs", type: "NaturalPerson" },
    // { label: "Capitaine de la Marine Marchande", type: "NaturalPerson" },
    // { label: "Conseiller Technico-Commercial", type: "NaturalPerson" },
    // { label: "Capitaine de la Navigation Maritime", type: "NaturalPerson" },
    // { label: "Conseiller Technique", type: "NaturalPerson" },
    // { label: "Capitaine de Navire", type: "NaturalPerson" },
    // { label: "Conseiller Téléphonique", type: "NaturalPerson" },
    // { label: "Cardiologue", type: "NaturalPerson" },
    // { label: "Conseiller Voyages", type: "NaturalPerson" },
    // { label: "Caricaturiste", type: "NaturalPerson" },
    // { label: "Conservateur Bibliothèque", type: "NaturalPerson" },
    // { label: "Cariste", type: "NaturalPerson" },
    // { label: "Conservateur en Archéologie", type: "NaturalPerson" },
    // { label: "Carreleur", type: "NaturalPerson" },
    // { label: "Conservateur Musée", type: "NaturalPerson" },
    // { label: "Carrossier", type: "NaturalPerson" },
    // { label: "Consignataire Maritime", type: "NaturalPerson" },
    // { label: "Carrossier Réparateur", type: "NaturalPerson" },
    // { label: "Consolideur", type: "NaturalPerson" },
    // { label: "Carrossier Tôlier", type: "NaturalPerson" },
    // { label: "Constructeur Métallique", type: "NaturalPerson" },
    // { label: "Cash Collection Manager", type: "NaturalPerson" },
    // { label: "Construction Cost Manager", type: "NaturalPerson" },
    // { label: "Cash Manager", type: "NaturalPerson" },
    // { label: "Consultant", type: "NaturalPerson" },
    // { label: "Category Manager", type: "NaturalPerson" },
    // { label: "Consultant Accessibilité", type: "NaturalPerson" },
    // { label: "Céramiste", type: "NaturalPerson" },
    // { label: "Consultant Accompagnement Collectif", type: "NaturalPerson" },
    // { label: "Céramiste Industriel", type: "NaturalPerson" },
    // { label: "Consultant Accompagnement Individuel", type: "NaturalPerson" },
    // { label: "Change Manager", type: "NaturalPerson" },
    // { label: "Consultant Achats", type: "NaturalPerson" },
    // { label: "Chanteur", type: "NaturalPerson" },
    // { label: "Consultant Actuaire", type: "NaturalPerson" },
    // { label: "Character Designer", type: "NaturalPerson" },
    // { label: "Consultant Actuariat Assurance", type: "NaturalPerson" },
    // { label: "Chargé de Clientèle", type: "NaturalPerson" },
    // { label: "Consultant Administratif", type: "NaturalPerson" },
    // { label: "Chargé de Communication", type: "NaturalPerson" },
    // { label: "Consultant Advisory Insurance", type: "NaturalPerson" },
    // { label: "Chargé de Consolidation", type: "NaturalPerson" },
    // { label: "Consultant Affaires Internationales", type: "NaturalPerson" },
    // { label: "Chargé de Contenu Web", type: "NaturalPerson" },
    // { label: "Consultant Affaires Techniques", type: "NaturalPerson" },
    // { label: "Chargé de Développement RH", type: "NaturalPerson" },
    // { label: "Consultant AGILE", type: "NaturalPerson" },
    // { label: "Chargé de Mission", type: "NaturalPerson" },
    // { label: "Consultant Aides et Subventions", type: "NaturalPerson" },
    // { label: "Chargé de Missions Humanitaires", type: "NaturalPerson" },
    // { label: "Consultant Analyste Risque de Crédit", type: "NaturalPerson" },
    // { label: "Chargé de Planification Ordonnancement...", type: "NaturalPerson" },
    // { label: "Consultant Appels d´Offres", type: "NaturalPerson" },
    // { label: "Chargé de Production Audiovisuelle", type: "NaturalPerson" },
    // { label: "Consultant Aquaculture et Gestion des...", type: "NaturalPerson" },
    // { label: "Chargé de Projet", type: "NaturalPerson" },
    // { label: "Consultant Assesment", type: "NaturalPerson" },
    // { label: "Chargé de Promotion des Ventes", type: "NaturalPerson" },
    // { label: "Consultant Assurance", type: "NaturalPerson" },
    // { label: "Chargé de Recherche", type: "NaturalPerson" },
    // { label: "Consultant Audit et Contrôle de Gestion...", type: "NaturalPerson" },
    // { label: "Chargé de Recherche et Développement", type: "NaturalPerson" },
    // { label: "Consultant Audit et Systèmes de Gestion...", type: "NaturalPerson" },
    // { label: "Chargé de Recherche Pharmaceutique", type: "NaturalPerson" },
    // { label: "Consultant Avant-Vente", type: "NaturalPerson" },
    // { label: "Chargé de Recouvrement", type: "NaturalPerson" },
    // { label: "Consultant Banking", type: "NaturalPerson" },
    // { label: "Chargé de Recouvrement Terrain", type: "NaturalPerson" },
    // { label: "Consultant Business et Management", type: "NaturalPerson" },
    // { label: "Chargé de Recrutement", type: "NaturalPerson" },
    // { label: "Consultant Business Transformation", type: "NaturalPerson" },
    // { label: "Chargé de Référencement", type: "NaturalPerson" },
    // { label: "Consultant Centre de Formation", type: "NaturalPerson" },
    // { label: "Chargé de Sourcing BPO", type: "NaturalPerson" },
    // { label: "Consultant Chef de Mission", type: "NaturalPerson" },
    // { label: "Chargé de Sponsoring", type: "NaturalPerson" },
    // { label: "Consultant Commercial en Technologies de l...", type: "NaturalPerson" },
    // { label: "Chargé de Trésorerie", type: "NaturalPerson" },
    // { label: "Consultant Communication", type: "NaturalPerson" },
    // { label: "Chargé de Veille Stratégique", type: "NaturalPerson" },
    // { label: "Consultant Communication Interculturelle...", type: "NaturalPerson" },
    // { label: "Chargé des Achats", type: "NaturalPerson" },
    // { label: "Consultant Communication Multimédia", type: "NaturalPerson" },
    // { label: "Chargé des Recettes", type: "NaturalPerson" },
    // { label: "Consultant Communication NTIC", type: "NaturalPerson" },
    // { label: "Chargé des Réclamations", type: "NaturalPerson" },
    // { label: "Consultant Communication RH", type: "NaturalPerson" },
    // { label: "Chargé des Relations Extérieures", type: "NaturalPerson" },
    // { label: "Consultant Comptabilité", type: "NaturalPerson" },
    // { label: "Chargé des Relations Institutionnels", type: "NaturalPerson" },
    // { label: "Consultant Comptabilité Paie", type: "NaturalPerson" },
    // { label: "Chargé des Relations Publiques", type: "NaturalPerson" },
    // { label: "Consultant Conduite de Changement", type: "NaturalPerson" },
    // { label: "Chargé des Tests", type: "NaturalPerson" },
    // { label: "Consultant Conseil", type: "NaturalPerson" },
    // { label: "Chargé du Reporting", type: "NaturalPerson" },
    // { label: "Consultant Conseil et Expertise", type: "NaturalPerson" },
    // { label: "Chargé du Suivi Opérationnel", type: "NaturalPerson" },
    // { label: "Consultant Consolidation et IFRS", type: "NaturalPerson" },
    // { label: "Chargé d´Accueil", type: "NaturalPerson" },
    // { label: "Consultant Construction Passive", type: "NaturalPerson" },
    // { label: "Chargé d´Affaires", type: "NaturalPerson" },
    // { label: "Consultant Contrôle Interne Risques Audit...", type: "NaturalPerson" },
    // { label: "Chargé d´Affaires Techniques", type: "NaturalPerson" },
    // { label: "Consultant Corporate Finance", type: "NaturalPerson" },
    // { label: "Chargé d´Assistance Technique Automobile...", type: "NaturalPerson" },
    // { label: "Consultant CRM", type: "NaturalPerson" },
    // { label: "Chargé d´Enquêtes", type: "NaturalPerson" },
    // { label: "Consultant Dévelopement et Gestion", type: "NaturalPerson" },
    // { label: "Chargé d´Etudes en Economie de la...", type: "NaturalPerson" },
    // { label: "Consultant Développement International...", type: "NaturalPerson" },
    // { label: "Chargé d´Etudes Environnement", type: "NaturalPerson" },
    // { label: "Consultant Développement Rural", type: "NaturalPerson" },
    // { label: "Chargé d´Etudes Marketing", type: "NaturalPerson" },
    // { label: "Consultant Digital", type: "NaturalPerson" },
    // { label: "Chargé d´Etudes Média", type: "NaturalPerson" },
    // { label: "Consultant Directions Financières", type: "NaturalPerson" },
    // { label: "Chargé d´Etudes Statistiques", type: "NaturalPerson" },
    // { label: "Consultant E-Commerce et Places de Marchés...", type: "NaturalPerson" },
    // { label: "Chargé d´Etudes Techniques", type: "NaturalPerson" },
    // { label: "Consultant Economique Juridique et Fiscal...", type: "NaturalPerson" },
    // { label: "Chargé d´Insertion Socioprofessionnelle...", type: "NaturalPerson" },
    // { label: "Consultant Education et Formation", type: "NaturalPerson" },
    // { label: "Chargé d´Opérations Immobilières", type: "NaturalPerson" },
    // { label: "Consultant en Communication Marketing...", type: "NaturalPerson" },
    // { label: "Charpentier", type: "NaturalPerson" },
    // { label: "Consultant en Développement Commercial...", type: "NaturalPerson" },
    // { label: "Charpentier Bois", type: "NaturalPerson" },
    // { label: "Consultant en Développement des...", type: "NaturalPerson" },
    // { label: "Charpentier Métallique", type: "NaturalPerson" },
    // { label: "Consultant en Diagnostic Economique et...", type: "NaturalPerson" },
    // { label: "Chasseur de Têtes", type: "NaturalPerson" },
    // { label: "Consultant en Droit Foncier", type: "NaturalPerson" },
    // { label: "Chaudronnier Aéronautique", type: "NaturalPerson" },
    // { label: "Consultant en Economie", type: "NaturalPerson" },
    // { label: "Chaudronnier Tôlier", type: "NaturalPerson" },
    // { label: "Consultant en Entrepreneuriat à l´...", type: "NaturalPerson" },
    // { label: "Chauffagiste", type: "NaturalPerson" },
    // { label: "Consultant en Environnement", type: "NaturalPerson" },
    // { label: "Chauffeur", type: "NaturalPerson" },
    // { label: "Consultant en Finance Internationale", type: "NaturalPerson" },
    // { label: "Chauffeur Camion", type: "NaturalPerson" },
    // { label: "Consultant en Finances des Marchés", type: "NaturalPerson" },
    // { label: "Chauffeur Coursier", type: "NaturalPerson" },
    // { label: "Consultant en Gestion de Groupe", type: "NaturalPerson" },
    // { label: "Chauffeur de Bus", type: "NaturalPerson" },
    // { label: "Consultant en Gestion de Temps", type: "NaturalPerson" },
    // { label: "Chauffeur de Maître", type: "NaturalPerson" },
    // { label: "Consultant en Gestion du Stress", type: "NaturalPerson" },
    // { label: "Chauffeur de Taxi", type: "NaturalPerson" },
    // { label: "Consultant en Gestion Statutaire", type: "NaturalPerson" },
    // { label: "Chauffeur Entreprise", type: "NaturalPerson" },
    // { label: "Consultant en Innovation", type: "NaturalPerson" },
    // { label: "Chauffeur Livreur", type: "NaturalPerson" },
    // { label: "Consultant en Lean Management", type: "NaturalPerson" },
    // { label: "Chauffeur Mécanicien", type: "NaturalPerson" },
    // { label: "Consultant en Lean Qualité Sécurité", type: "NaturalPerson" },
    // { label: "Chauffeur Particulier", type: "NaturalPerson" },
    // { label: "Consultant en Liquidation", type: "NaturalPerson" },
    // { label: "Chauffeur SPL", type: "NaturalPerson" },
    // { label: "Consultant en Management Commercial", type: "NaturalPerson" },
    // { label: "Chauffeur VL", type: "NaturalPerson" },
    // { label: "Consultant en Management de Projets", type: "NaturalPerson" },
    // { label: "Chef Architecte", type: "NaturalPerson" },
    // { label: "Consultant en Management des Connaissances...", type: "NaturalPerson" },
    // { label: "Chef Atelier", type: "NaturalPerson" },
    // { label: "Consultant en Management des Equipes", type: "NaturalPerson" },
    // { label: "Chef Atelier Agroalimentaire", type: "NaturalPerson" },
    // { label: "Consultant en Management des Risques", type: "NaturalPerson" },
    // { label: "Chef Atelier Carrosserie", type: "NaturalPerson" },
    // { label: "Consultant en Management Industriel", type: "NaturalPerson" },
    // { label: "Chef Atelier Chimie", type: "NaturalPerson" },
    // { label: "Consultant en Management Industriel et...", type: "NaturalPerson" },
    // { label: "Chef Atelier Fabrication", type: "NaturalPerson" },
    // { label: "Consultant en Management Spécialiste de la...", type: "NaturalPerson" },
    // { label: "Chef Atelier Impression Cartonnage", type: "NaturalPerson" },
    // { label: "Consultant en Marketing et Gestion...", type: "NaturalPerson" },
    // { label: "Chef Atelier Industrie", type: "NaturalPerson" },
    // { label: "Consultant en Marketing Stratégique", type: "NaturalPerson" },
    // { label: "Chef Atelier Mécanique", type: "NaturalPerson" },
    // { label: "Consultant en Médiation", type: "NaturalPerson" },
    // { label: "Chef Atelier Métallique", type: "NaturalPerson" },
    // { label: "Consultant en Mobilité", type: "NaturalPerson" },
    // { label: "Chef Atelier Production", type: "NaturalPerson" },
    // { label: "Consultant en Organisation", type: "NaturalPerson" },
    // { label: "Chef Chantier Métreur", type: "NaturalPerson" },
    // { label: "Consultant en Organisation d´Entreprise...", type: "NaturalPerson" },
    // { label: "Chef Comptable", type: "NaturalPerson" },
    // { label: "Consultant en Performance Commerciale...", type: "NaturalPerson" },
    // { label: "Chef de Bureau Technique", type: "NaturalPerson" },
    // { label: "Consultant en Psychologie du Travail", type: "NaturalPerson" },
    // { label: "Chef de Cabinet", type: "NaturalPerson" },
    // { label: "Consultant en Recrutement", type: "NaturalPerson" },
    // { label: "Chef de Caisse", type: "NaturalPerson" },
    // { label: "Consultant en Relations Publiques", type: "NaturalPerson" },
    // { label: "Chef de Carrière", type: "NaturalPerson" },
    // { label: "Consultant en Ressources Humaines", type: "NaturalPerson" },
    // { label: "Chef de Chantier BTP", type: "NaturalPerson" },
    // { label: "Consultant en Risques Professionnels", type: "NaturalPerson" },
    // { label: "Chef de Chantier Minier", type: "NaturalPerson" },
    // { label: "Consultant en Risques Psychosociaux", type: "NaturalPerson" },
    // { label: "Chef de Chur", type: "NaturalPerson" },
    // { label: "Consultant en Santé", type: "NaturalPerson" },
    // { label: "Chef de Cuisine", type: "NaturalPerson" },
    // { label: "Consultant en Santé Publique", type: "NaturalPerson" },
    // { label: "Chef de Fabrication en Imprimerie", type: "NaturalPerson" },
    // { label: "Consultant en Sciences Politiques", type: "NaturalPerson" },
    // { label: "Chef de Groupe", type: "NaturalPerson" },
    // { label: "Consultant en Socio-Anthropologie", type: "NaturalPerson" },
    // { label: "Chef de Laboratoire", type: "NaturalPerson" },
    // { label: "Consultant en Storytelling", type: "NaturalPerson" },
    // { label: "Chef de Maintenance", type: "NaturalPerson" },
    // { label: "Consultant en Stratégie", type: "NaturalPerson" },
    // { label: "Chef de Mission", type: "NaturalPerson" },
    // { label: "Consultant en Stratégie Développement d´...", type: "NaturalPerson" },
    // { label: "Chef de Parc Automobile", type: "NaturalPerson" },
    // { label: "Consultant en Stratégie Digitale", type: "NaturalPerson" },
    // { label: "Chef de Partie", type: "NaturalPerson" },
    // { label: "Consultant en Stratégie et Transactions...", type: "NaturalPerson" },
    // { label: "Chef de Plateau", type: "NaturalPerson" },
    // { label: "Consultant en Stratégie Mergers...", type: "NaturalPerson" },
    // { label: "Chef de Production", type: "NaturalPerson" },
    // { label: "Consultant en Sûreté Aérienne", type: "NaturalPerson" },
    // { label: "Chef de Produit", type: "NaturalPerson" },
    // { label: "Consultant en Tourisme", type: "NaturalPerson" },
    // { label: "Chef de Produit Digital Multimédia", type: "NaturalPerson" },
    // { label: "Consultant en Tourisme Rural", type: "NaturalPerson" },
    // { label: "Chef de Produit Industrie Pharmaceutique...", type: "NaturalPerson" },
    // { label: "Consultant en Transactions d´...", type: "NaturalPerson" },
    // { label: "Chef de Produit Marketing", type: "NaturalPerson" },
    // { label: "Consultant en Transfert de Technologie...", type: "NaturalPerson" },
    // { label: "Chef de Produit Prix", type: "NaturalPerson" },
    // { label: "Consultant en Transformation", type: "NaturalPerson" },
    // { label: "Chef de Produit Textile", type: "NaturalPerson" },
    // { label: "Consultant en Trésorerie", type: "NaturalPerson" },
    // { label: "Chef de Produit Web", type: "NaturalPerson" },
    // { label: "Consultant en Voyages", type: "NaturalPerson" },
    // { label: "Chef de Projet", type: "NaturalPerson" },
    // { label: "Consultant Energie Renouvelable et...", type: "NaturalPerson" },
    // { label: "Chef de Projet AMO", type: "NaturalPerson" },
    // { label: "Consultant Entrepreneuriat et Business...", type: "NaturalPerson" },
    // { label: "Chef de Projet Architecte Systèmes", type: "NaturalPerson" },
    // { label: "Consultant Executive Search", type: "NaturalPerson" },
    // { label: "Chef de Projet CRM", type: "NaturalPerson" },
    // { label: "Consultant Expert Bilan Carbone", type: "NaturalPerson" },
    // { label: "Chef de Projet Culture et Patrimoine", type: "NaturalPerson" },
    // { label: "Consultant Expert en Stratégie", type: "NaturalPerson" },
    // { label: "Chef de Projet Ecologue", type: "NaturalPerson" },
    // { label: "Consultant Fastsourcing", type: "NaturalPerson" },
    // { label: "Chef de Projet Energie", type: "NaturalPerson" },
    // { label: "Consultant Finance", type: "NaturalPerson" },
    // { label: "Chef de Projet Fonctionnel", type: "NaturalPerson" },
    // { label: "Consultant Finances Publiques", type: "NaturalPerson" },
    // { label: "Chef de Projet Industriel", type: "NaturalPerson" },
    // { label: "Consultant Financial Risk", type: "NaturalPerson" },
    // { label: "Chef de Projet Informatique", type: "NaturalPerson" },
    // { label: "Consultant Fiscaliste", type: "NaturalPerson" },
    // { label: "Chef de Projet Marketing", type: "NaturalPerson" },
    // { label: "Consultant Formateur", type: "NaturalPerson" },
    // { label: "Chef de Projet Multimédia", type: "NaturalPerson" },
    // { label: "Consultant Géopolitique", type: "NaturalPerson" },
    // { label: "Chef de Projet Poste de Travail", type: "NaturalPerson" },
    // { label: "Consultant Gestion", type: "NaturalPerson" },
    // { label: "Chef de Projet Qualité", type: "NaturalPerson" },
    // { label: "Consultant Gestion de Projets...", type: "NaturalPerson" },
    // { label: "Chef de Projet Sites et Sols Pollués", type: "NaturalPerson" },
    // { label: "Consultant Gestion des Eaux", type: "NaturalPerson" },
    // { label: "Chef de Projet Systèmes", type: "NaturalPerson" },
    // { label: "Consultant Gestion des Risques", type: "NaturalPerson" },
    // { label: "Chef de Projet Technique", type: "NaturalPerson" },
    // { label: "Consultant Gestion des Risques Marchés...", type: "NaturalPerson" },
    // { label: "Chef de Projet Télécom", type: "NaturalPerson" },
    // { label: "Consultant Gestion Patrimoine", type: "NaturalPerson" },
    // { label: "Chef de Projet Transverse", type: "NaturalPerson" },
    // { label: "Consultant Hôtellerie", type: "NaturalPerson" },
    // { label: "Chef de Projet Web", type: "NaturalPerson" },
    // { label: "Consultant Hygiène Sécurité du Travail...", type: "NaturalPerson" },
    // { label: "Chef de Publicité", type: "NaturalPerson" },
    // { label: "Consultant Immigration", type: "NaturalPerson" },
    // { label: "Chef de Quai", type: "NaturalPerson" },
    // { label: "Consultant Industries Agroalimentaires...", type: "NaturalPerson" },
    // { label: "Chef de Rang", type: "NaturalPerson" },
    // { label: "Consultant Innovation Produits et Marchés...", type: "NaturalPerson" },
    // { label: "Chef de Rayon", type: "NaturalPerson" },
    // { label: "Consultant Intelligence Economique et...", type: "NaturalPerson" },
    // { label: "Chef de Secteur en GMS", type: "NaturalPerson" },
    // { label: "Consultant International", type: "NaturalPerson" },
    // { label: "Chef de Service", type: "NaturalPerson" },
    // { label: "Consultant Interne", type: "NaturalPerson" },
    // { label: "Chef de Service Educatif", type: "NaturalPerson" },
    // { label: "Consultant Juridique et Fiscal", type: "NaturalPerson" },
    // { label: "Chef de Service Logistique", type: "NaturalPerson" },
    // { label: "Consultant Lean Six Sigma", type: "NaturalPerson" },
    // { label: "Chef de Service Sécurité Incendie et...", type: "NaturalPerson" },
    // { label: "Consultant Liens Sponsorisés", type: "NaturalPerson" },
    // { label: "Chef de Travaux", type: "NaturalPerson" },
    // { label: "Consultant Logistique", type: "NaturalPerson" },
    // { label: "Chef de Zone", type: "NaturalPerson" },
    // { label: "Consultant Marchés Publics", type: "NaturalPerson" },
    // { label: "Chef des Ventes", type: "NaturalPerson" },
    // { label: "Consultant Marketing", type: "NaturalPerson" },
    // { label: "Chef du Personnel", type: "NaturalPerson" },
    // { label: "Consultant Marketing Clients", type: "NaturalPerson" },
    // { label: "Chef d´Agence", type: "NaturalPerson" },
    // { label: "Consultant Marketing Opérationnel", type: "NaturalPerson" },
    // { label: "Chef d´Equipe", type: "NaturalPerson" },
    // { label: "Consultant Métier", type: "NaturalPerson" },
    // { label: "Chef d´Equipe Construction Métallique...", type: "NaturalPerson" },
    // { label: "Consultant Nutrition", type: "NaturalPerson" },
    // { label: "Chef d´Equipe Entretien de Locaux", type: "NaturalPerson" },
    // { label: "Consultant Opération et Support", type: "NaturalPerson" },
    // { label: "Chef d´Equipe Maintenance", type: "NaturalPerson" },
    // { label: "Consultant Opérationnel", type: "NaturalPerson" },
    // { label: "Chef d´Equipe Nettoyage", type: "NaturalPerson" },
    // { label: "Consultant Organisation et Management de...", type: "NaturalPerson" },
    // { label: "Chef d´Equipe Production", type: "NaturalPerson" },
    // { label: "Consultant Organisation Stratégie", type: "NaturalPerson" },
    // { label: "Chef d´Equipe BTP", type: "NaturalPerson" },
    // { label: "Consultant PME", type: "NaturalPerson" },
    // { label: "Chef Exploitation", type: "NaturalPerson" },
    // { label: "Consultant Prévention des Risques", type: "NaturalPerson" },
    // { label: "Chef Garde Manger", type: "NaturalPerson" },
    // { label: "Consultant Procurement et Sourcing", type: "NaturalPerson" },
    // { label: "Chef Garde Manger", type: "NaturalPerson" },
    // { label: "Consultant Produit", type: "NaturalPerson" },
    // { label: "Chef Gérant", type: "NaturalPerson" },
    // { label: "Consultant Propriété Industrielle", type: "NaturalPerson" },
    // { label: "Chef Ingénieur", type: "NaturalPerson" },
    // { label: "Consultant Psychologue", type: "NaturalPerson" },
    // { label: "Chef Machiniste", type: "NaturalPerson" },
    // { label: "Consultant Publicité en Ligne", type: "NaturalPerson" },
    // { label: "Chef Magasinier", type: "NaturalPerson" },
    // { label: "Consultant Réalité Virtuelle", type: "NaturalPerson" },
    // { label: "Chef Mécanicien", type: "NaturalPerson" },
    // { label: "Consultant Relation Clientèle", type: "NaturalPerson" },
    // { label: "Chef Monteur", type: "NaturalPerson" },
    // { label: "Consultant Reporting et Consolidation...", type: "NaturalPerson" },
    // { label: "Chef Opérateur", type: "NaturalPerson" },
    // { label: "Consultant Réseaux Sociaux", type: "NaturalPerson" },
    // { label: "Chef Orchestre", type: "NaturalPerson" },
    // { label: "Consultant Retraite", type: "NaturalPerson" },
    // { label: "Chef Projet Immobilier", type: "NaturalPerson" },
    // { label: "Consultant RH", type: "NaturalPerson" },
    // { label: "Chef Steward", type: "NaturalPerson" },
    // { label: "Consultant Risques Environnementaux", type: "NaturalPerson" },
    // { label: "Chercheur", type: "NaturalPerson" },
    // { label: "Consultant Salesforce", type: "NaturalPerson" },
    // { label: "Chercheur en Sciences Exactes", type: "NaturalPerson" },
    // { label: "Consultant Santé Social", type: "NaturalPerson" },
    // { label: "Chercheur en Sciences Humaines ou Sociales...", type: "NaturalPerson" },
    // { label: "Consultant SAP", type: "NaturalPerson" },
    // { label: "Chimiste", type: "NaturalPerson" },
    // { label: "Consultant Secteur Pharmaceutique", type: "NaturalPerson" },
    // { label: "Chirurgien", type: "NaturalPerson" },
    // { label: "Consultant Secteur Public", type: "NaturalPerson" },
    // { label: "Chirurgien Dentiste", type: "NaturalPerson" },
    // { label: "Consultant SEO", type: "NaturalPerson" },
    // { label: "Chocolatier", type: "NaturalPerson" },
    // { label: "Consultant Sociologue", type: "NaturalPerson" },
    // { label: "Chocolatier Confiseur", type: "NaturalPerson" },
    // { label: "Consultant Talent Acquisition", type: "NaturalPerson" },
    // { label: "Chorégraphe", type: "NaturalPerson" },
    // { label: "Consultant Technico-Commercial", type: "NaturalPerson" },
    // { label: "Chroniqueur", type: "NaturalPerson" },
    // { label: "Consultant Tests et Mesures Electroniques...", type: "NaturalPerson" },
    // { label: "Claviste", type: "NaturalPerson" },
    // { label: "Consultant Traitement des Déchets", type: "NaturalPerson" },
    // { label: "Clerc de Notaire", type: "NaturalPerson" },
    // { label: "Consultant Transaction Services", type: "NaturalPerson" },
    // { label: "Clown", type: "NaturalPerson" },
    // { label: "Consultant Transformation Digitale", type: "NaturalPerson" },
    // { label: "Coach", type: "NaturalPerson" },
    // { label: "Consultant Ventes et Marketing", type: "NaturalPerson" },
    // { label: "Coach Arts Martiaux", type: "NaturalPerson" },
    // { label: "Consultant Yield Management", type: "NaturalPerson" },
    // { label: "Coach en Evolution Professionnelle ou...", type: "NaturalPerson" },
    // { label: "Consultant Accompagnement au Changement...", type: "NaturalPerson" },
    // { label: "Coach Professionnelle", type: "NaturalPerson" },
    // { label: "Consultant Accompagnement Entrepreneurial...", type: "NaturalPerson" },
    // { label: "Coach Sportif", type: "NaturalPerson" },
    // { label: "Consultant Actuariat et Risque des...", type: "NaturalPerson" },
    // { label: "Coffreur", type: "NaturalPerson" },
    // { label: "Consultant Approvisionnement Logistique...", type: "NaturalPerson" },
    // { label: "Coiffeur", type: "NaturalPerson" },
    // { label: "Consultant Bureaux", type: "NaturalPerson" },
    // { label: "Coiffeuse Visagiste", type: "NaturalPerson" },
    // { label: "Consultant Capital Markets", type: "NaturalPerson" },
    // { label: "Collaborateur Architecte", type: "NaturalPerson" },
    // { label: "Consultant Category Management", type: "NaturalPerson" },
    // { label: "Collaborateur Comptable", type: "NaturalPerson" },
    // { label: "Consultant Développement Nouveaux Marchés...", type: "NaturalPerson" },
    // { label: "Collecteur de Fonds", type: "NaturalPerson" },
    // { label: "Consultant E-Commerce", type: "NaturalPerson" },
    // { label: "Comédien", type: "NaturalPerson" },
    // { label: "Consultant E-Santé", type: "NaturalPerson" },
    // { label: "Commandant de Bord", type: "NaturalPerson" },
    // { label: "Consultant en Accompagnement Comptable de...", type: "NaturalPerson" },
    // { label: "Commerçant", type: "NaturalPerson" },
    // { label: "Consultant en Accompagnement des...", type: "NaturalPerson" },
    // { label: "Commercial Assurance", type: "NaturalPerson" },
    // { label: "Consultant en Actuariat Assurance Vie...", type: "NaturalPerson" },
    // { label: "Commercial B to B", type: "NaturalPerson" },
    // { label: "Consultant en Actuariat Compensation et...", type: "NaturalPerson" },
    // { label: "Commercial B to C", type: "NaturalPerson" },
    // { label: "Consultant en Administration du Personnel...", type: "NaturalPerson" },
    // { label: "Commercial Export", type: "NaturalPerson" },
    // { label: "Consultant en Business Modelling", type: "NaturalPerson" },
    // { label: "Commercial Grands Comptes", type: "NaturalPerson" },
    // { label: "Consultant en Contrôle de Gestion Sociale...", type: "NaturalPerson" },
    // { label: "Commercial Immobilier", type: "NaturalPerson" },
    // { label: "Consultant en Credit Management", type: "NaturalPerson" },
    // { label: "Commercial Itinérant", type: "NaturalPerson" },
    // { label: "Consultant en Développement Commercial...", type: "NaturalPerson" },
    // { label: "Commercial Pièces Détachées et Accessoires...", type: "NaturalPerson" },
    // { label: "Consultant en Evaluation de Politiques...", type: "NaturalPerson" },
    // { label: "Commercial Sédentaire", type: "NaturalPerson" },
    // { label: "Consultant en Expatriation", type: "NaturalPerson" },
    // { label: "Commercial Sédentaire en Assurance", type: "NaturalPerson" },
    // { label: "Consultant en Intelligence Collective...", type: "NaturalPerson" },
    // { label: "Commercial Showroom", type: "NaturalPerson" },
    // { label: "Consultant en Litigation et Forensic", type: "NaturalPerson" },
    // { label: "Commercial Terrain", type: "NaturalPerson" },
    // { label: "Consultant en Maîtrise des Risques et...", type: "NaturalPerson" },
    // { label: "Commercial Vente", type: "NaturalPerson" },
    // { label: "Consultant en Management de l´Entreprise...", type: "NaturalPerson" },
    // { label: "Commis", type: "NaturalPerson" },
    // { label: "Consultant en Optimisation de Charges...", type: "NaturalPerson" },
    // { label: "Commis de Cuisine", type: "NaturalPerson" },
    // { label: "Consultant en Organisation et Méthodologie...", type: "NaturalPerson" },
    // { label: "Commis de Rang", type: "NaturalPerson" },
    // { label: "Consultant en People and Change", type: "NaturalPerson" },
    // { label: "Commis Patissier", type: "NaturalPerson" },
    // { label: "Consultant en Protection Sociale", type: "NaturalPerson" },
    // { label: "Commissaire aux Comptes", type: "NaturalPerson" },
    // { label: "Consultant en Relocation", type: "NaturalPerson" },
    // { label: "Community Manager", type: "NaturalPerson" },
    // { label: "Consultant en Rémunération et en...", type: "NaturalPerson" },
    // { label: "Compliance Officer", type: "NaturalPerson" },
    // { label: "Consultant en Stratégie CRM", type: "NaturalPerson" },
    // { label: "Compositeur", type: "NaturalPerson" },
    // { label: "Consultant en Talent Management", type: "NaturalPerson" },
    // { label: "Comptable", type: "NaturalPerson" },
    // { label: "Consultant Finance Risques et Conformité...", type: "NaturalPerson" },
    // { label: "Comptable Assurance", type: "NaturalPerson" },
    // { label: "Consultant Fonctionnel Supply Chain", type: "NaturalPerson" },
    // { label: "Comptable Auxiliaire", type: "NaturalPerson" },
    // { label: "Consultant Formation et Documentation...", type: "NaturalPerson" },
    // { label: "Comptable Clients", type: "NaturalPerson" },
    // { label: "Consultant Immobilier Bureaux", type: "NaturalPerson" },
    // { label: "Comptable Clients Fournisseurs", type: "NaturalPerson" },
    // { label: "Consultant Immobilier et Hôtels", type: "NaturalPerson" },
    // { label: "Comptable Clients Recouvrement", type: "NaturalPerson" },
    // { label: "Consultant Investissement en Immobilier d´...", type: "NaturalPerson" },
    // { label: "Comptable Copropriété", type: "NaturalPerson" },
    // { label: "Consultant Investment Banking", type: "NaturalPerson" },
    // { label: "Comptable Fournisseur", type: "NaturalPerson" },
    // { label: "Consultant Maintien dans lEmploi", type: "NaturalPerson" },
    // { label: "Comptable Général", type: "NaturalPerson" },
    // { label: "Consultant Organisations Internationales...", type: "NaturalPerson" },
    // { label: "Comptable Réassurance", type: "NaturalPerson" },
    // { label: "Consultant Outplacement", type: "NaturalPerson" },
    // { label: "Comptable Trésorerie", type: "NaturalPerson" },
    // { label: "Consultant Patrimonial Gestion Privée...", type: "NaturalPerson" },
    // { label: "Concepteur", type: "NaturalPerson" },
    // { label: "Consultant Recrutement Travail Temporaire...", type: "NaturalPerson" },
    // { label: "Concepteur Dessinateur", type: "NaturalPerson" },
    // { label: "Consultant Rédaction de Contenu", type: "NaturalPerson" },
    // { label: "Concepteur Développeur", type: "NaturalPerson" },
    // { label: "Consultant Restructuring", type: "NaturalPerson" },
    // { label: "Concepteur Fonctionnel", type: "NaturalPerson" },
    // { label: "Consultant Stratégie et Opérations", type: "NaturalPerson" },
    // { label: "Concepteur Paysagiste", type: "NaturalPerson" },
    // { label: "Consultant Strategy Customer and...", type: "NaturalPerson" },
    // { label: "Concepteur Projeteur", type: "NaturalPerson" },
    // { label: "Consultant Supervision", type: "NaturalPerson" },
    // { label: "Concepteur Réalisateur", type: "NaturalPerson" },
    // { label: "Consultant Support Technique", type: "NaturalPerson" },
    // { label: "Concepteur Rédacteur", type: "NaturalPerson" },
    // { label: "Consultant Tannerie", type: "NaturalPerson" },
    // { label: "Concepteur Technique", type: "NaturalPerson" },
    // { label: "Content Planner", type: "NaturalPerson" },
    // { label: "Concierge", type: "NaturalPerson" },
    // { label: "Content Writer", type: "NaturalPerson" },
    // { label: "Concierge Hôtel", type: "NaturalPerson" },
    // { label: "Contract Manager", type: "NaturalPerson" },
    // { label: "Conducteur", type: "NaturalPerson" },
    // { label: "Contremaître", type: "NaturalPerson" },
    // { label: "Conducteur Autocar de Tourisme", type: "NaturalPerson" },
    // { label: "Contrôleur", type: "NaturalPerson" },
    // { label: "Conducteur Camion", type: "NaturalPerson" },
    // { label: "Contrôleur de Gestion", type: "NaturalPerson" },
    // { label: "Conducteur Chargeuse", type: "NaturalPerson" },
    // { label: "Contrôleur de Trafic Voyageur", type: "NaturalPerson" },
    // { label: "Conducteur Chaudière", type: "NaturalPerson" },
    // { label: "Contrôleur Financier", type: "NaturalPerson" },
    // { label: "Conducteur de Bus", type: "NaturalPerson" },
    // { label: "Contrôleur Général", type: "NaturalPerson" },
    // { label: "Conducteur de Car", type: "NaturalPerson" },
    // { label: "Contrôleur HSE", type: "NaturalPerson" },
    // { label: "Conducteur de Grue", type: "NaturalPerson" },
    // { label: "Contrôleur Qualité", type: "NaturalPerson" },
    // { label: "Conducteur de Ligne", type: "NaturalPerson" },
    // { label: "Contrôleur Qualité Usine", type: "NaturalPerson" },
    // { label: "Conducteur de Ligne Automatisée", type: "NaturalPerson" },
    // { label: "Contrôleur Sécurité", type: "NaturalPerson" },
    // { label: "Conducteur de Ligne Plasturgie", type: "NaturalPerson" },
    // { label: "Contrôleur Travaux", type: "NaturalPerson" },
    // { label: "Conducteur de Ligne Production...", type: "NaturalPerson" },
    // { label: "Coordinateur", type: "NaturalPerson" },
    // { label: "Conducteur de Machine", type: "NaturalPerson" },
    // { label: "Coordinateur Association", type: "NaturalPerson" },
    // { label: "Conducteur de Machine à Commande Numérique...", type: "NaturalPerson" },
    // { label: "Coordinateur des Ventes", type: "NaturalPerson" },
    // { label: "Conducteur de Machine Agroalimentaire...", type: "NaturalPerson" },
    // { label: "Coordinateur Etudes Cliniques", type: "NaturalPerson" },
    // { label: "Conducteur de Machine Impression", type: "NaturalPerson" },
    // { label: "Coordinateur Financier", type: "NaturalPerson" },
    // { label: "Conducteur de Machine Industrielle", type: "NaturalPerson" },
    // { label: "Coordinateur Général", type: "NaturalPerson" },
    // { label: "Conducteur de Machine Offset", type: "NaturalPerson" },
    // { label: "Coordinateur Import Export", type: "NaturalPerson" },
    // { label: "Conducteur de Machines à Imprimer Offset...", type: "NaturalPerson" },
    // { label: "Coordinateur Logistique", type: "NaturalPerson" },
    // { label: "Conducteur de Machines de Filature Textile...", type: "NaturalPerson" },
    // { label: "Coordinateur Projet", type: "NaturalPerson" },
    // { label: "Conducteur de Machines de Production...", type: "NaturalPerson" },
    // { label: "Coordinateur RH", type: "NaturalPerson" },
    // { label: "Conducteur de Plieuse", type: "NaturalPerson" },
    // { label: "Coordinateur SAV", type: "NaturalPerson" },
    // { label: "Conducteur de Poids Lourd", type: "NaturalPerson" },
    // { label: "Coordinateur Sécurité", type: "NaturalPerson" },
    // { label: "Conducteur de Presse Numérique", type: "NaturalPerson" },
    // { label: "Coordinateur Sécurité Applicative", type: "NaturalPerson" },
    // { label: "Conducteur de Tramway", type: "NaturalPerson" },
    // { label: "Coordinateur Sécurité Informatique", type: "NaturalPerson" },
    // { label: "Conducteur de Transport en Commun", type: "NaturalPerson" },
    // { label: "Coordinateur Social", type: "NaturalPerson" },
    // { label: "Conducteur de Travaux", type: "NaturalPerson" },
    // { label: "Coordinateur Support", type: "NaturalPerson" },
    // { label: "Conducteur de Travaux BTP", type: "NaturalPerson" },
    // { label: "Coordinateur Technique", type: "NaturalPerson" },
    // { label: "Conducteur de Travaux de Terrassement...", type: "NaturalPerson" },
    // { label: "Coordonnateur Formation", type: "NaturalPerson" },
    // { label: "Conducteur de Travaux Gros Oeuvre", type: "NaturalPerson" },
    // { label: "Coordonnier Serrurier", type: "NaturalPerson" },
    // { label: "Conducteur de Travaux Paysagers", type: "NaturalPerson" },
    // { label: "Cordonnier", type: "NaturalPerson" },
    // { label: "Conducteur Engins", type: "NaturalPerson" },
    // { label: "Correcteur", type: "NaturalPerson" },
    // { label: "Conducteur Engins BTP", type: "NaturalPerson" },
    // { label: "Costumier", type: "NaturalPerson" },
    // { label: "Conducteur Engins de Chantier", type: "NaturalPerson" },
    // { label: "Country Manager", type: "NaturalPerson" },
    // { label: "Conducteur Routier", type: "NaturalPerson" },
    // { label: "Coupeur", type: "NaturalPerson" },
    // { label: "Conducteur Routier Marchandises", type: "NaturalPerson" },
    // { label: "Coupeur Confection", type: "NaturalPerson" },
    // { label: "Conducteur Routier Voyageurs", type: "NaturalPerson" },
    // { label: "Coupeur en Cuir", type: "NaturalPerson" },
    // { label: "Conducteur Silos", type: "NaturalPerson" },
    // { label: "Coursier", type: "NaturalPerson" },
    // { label: "Conducteur SPL", type: "NaturalPerson" },
    // { label: "Courtier Assurances", type: "NaturalPerson" },
    // { label: "Conférencier", type: "NaturalPerson" },
    // { label: "Couturier", type: "NaturalPerson" },
    // { label: "Confirmateur", type: "NaturalPerson" },
    // { label: "Couvreur", type: "NaturalPerson" },
    // { label: "Conseiller Activités Sportives", type: "NaturalPerson" },
    // { label: "Couvreur Bardeur", type: "NaturalPerson" },
    // { label: "Conseiller Agricole", type: "NaturalPerson" },
    // { label: "Credit Manager", type: "NaturalPerson" },
    // { label: "Conseiller aux Entreprises Coopératives...", type: "NaturalPerson" },
    // { label: "Crêpier", type: "NaturalPerson" },
    // { label: "Conseiller Clientèle", type: "NaturalPerson" },
    // { label: "CRM Salesforce Manager", type: "NaturalPerson" },
    // { label: "Conseiller Clientèle Assurances", type: "NaturalPerson" },
    // { label: "Cuisinier", type: "NaturalPerson" },
    // { label: "Conseiller Clientèle Entreprise", type: "NaturalPerson" },
    // { label: "Cuisinier Restauration Collective", type: "NaturalPerson" },
    // { label: "Conseiller Commercial Automobile", type: "NaturalPerson" },
    // { label: "Cuisinier Saucier", type: "NaturalPerson" },
    // { label: "Conseiller Commercial Immobilier", type: "NaturalPerson" },
    // { label: "Cuisiniste", type: "NaturalPerson" },
    // { label: "Conseiller Conjugal et Familial", type: "NaturalPerson" },
    // { label: "Cultivateur", type: "NaturalPerson" },
    // { label: "Conseiller de Vente", type: "NaturalPerson" },
    // { label: "Customer Advisor", type: "NaturalPerson" },
    // { label: "Conseiller Développement", type: "NaturalPerson" },
    // { label: "Customer Consultant", type: "NaturalPerson" },
    // { label: "Conseiller Développement Local", type: "NaturalPerson" },
    // { label: "Customer Relationship Manager", type: "NaturalPerson" },
    // { label: "Déclarant en Douane", type: "NaturalPerson" },
    // { label: "Directeur Informatique", type: "NaturalPerson" },
    // { label: "Délégué Médical", type: "NaturalPerson" },
    // { label: "Directeur Juridique", type: "NaturalPerson" },
    // { label: "Délégué Vétérinaire", type: "NaturalPerson" },
    // { label: "Directeur Logistique", type: "NaturalPerson" },
    // { label: "Designer Produit", type: "NaturalPerson" },
    // { label: "Directeur Logistique et Stockage", type: "NaturalPerson" },
    // { label: "Dessinateur Béton Armé", type: "NaturalPerson" },
    // { label: "Directeur Marketing", type: "NaturalPerson" },
    // { label: "Dessinateur Projeteur Electricité", type: "NaturalPerson" },
    // { label: "Directeur Moyens Généraux", type: "NaturalPerson" },
    // { label: "Dessinateur Projeteur Mécanique", type: "NaturalPerson" },
    // { label: "Directeur Pédagogique", type: "NaturalPerson" },
    // { label: "Développeur", type: "NaturalPerson" },
    // { label: "Directeur Qualité", type: "NaturalPerson" },
    // { label: "Développeur ABAP", type: "NaturalPerson" },
    // { label: "Directeur Régional", type: "NaturalPerson" },
    // { label: "Développeur ACCESS", type: "NaturalPerson" },
    // { label: "Directeur Régional des Ventes", type: "NaturalPerson" },
    // { label: "Développeur Administrateur", type: "NaturalPerson" },
    // { label: "Directeur Réseaux", type: "NaturalPerson" },
    // { label: "Développeur ANDROID", type: "NaturalPerson" },
    // { label: "Directeur Restauration", type: "NaturalPerson" },
    // { label: "Développeur APPLE", type: "NaturalPerson" },
    // { label: "Directeur Supply Chain", type: "NaturalPerson" },
    // { label: "Développeur Applicatif", type: "NaturalPerson" },
    // { label: "Directeur Technique", type: "NaturalPerson" },
    // { label: "Développeur AS3", type: "NaturalPerson" },
    // { label: "Directeur Usine", type: "NaturalPerson" },
    // { label: "Développeur ASP", type: "NaturalPerson" },
    // { label: "Distributeur de Flyers", type: "NaturalPerson" },
    // { label: "Développeur C", type: "NaturalPerson" },
    // { label: "Docteur", type: "NaturalPerson" },
    // { label: "Développeur Chef de Projet", type: "NaturalPerson" },
    // { label: "Doctorant", type: "NaturalPerson" },
    // { label: "Développeur COBOL", type: "NaturalPerson" },
    // { label: "Documentaliste", type: "NaturalPerson" },
    // { label: "Développeur Décisionnel", type: "NaturalPerson" },
    // { label: "E-Category Manager", type: "NaturalPerson" },
    // { label: "Développeur DOTNET", type: "NaturalPerson" },
    // { label: "E-Marketer", type: "NaturalPerson" },
    // { label: "Développeur DRUPAL", type: "NaturalPerson" },
    // { label: "E-Reputation Manager", type: "NaturalPerson" },
    // { label: "Développeur Expert", type: "NaturalPerson" },
    // { label: "Ebéniste", type: "NaturalPerson" },
    // { label: "Développeur FLASH", type: "NaturalPerson" },
    // { label: "Echafaudeur", type: "NaturalPerson" },
    // { label: "Développeur FLEX", type: "NaturalPerson" },
    // { label: "Eclairagiste", type: "NaturalPerson" },
    // { label: "Développeur HTML", type: "NaturalPerson" },
    // { label: "Ecologue", type: "NaturalPerson" },
    // { label: "Développeur Informatique", type: "NaturalPerson" },
    // { label: "Econome", type: "NaturalPerson" },
    // { label: "Développeur Intégrateur", type: "NaturalPerson" },
    // { label: "Economiste", type: "NaturalPerson" },
    // { label: "Développeur IPHONE", type: "NaturalPerson" },
    // { label: "Ecrivain Public", type: "NaturalPerson" },
    // { label: "Développeur J2EE", type: "NaturalPerson" },
    // { label: "Editeur", type: "NaturalPerson" },
    // { label: "Développeur JAVA", type: "NaturalPerson" },
    // { label: "Educateur", type: "NaturalPerson" },
    // { label: "Développeur JOOMLA", type: "NaturalPerson" },
    // { label: "Educateur de Jeunes Enfants", type: "NaturalPerson" },
    // { label: "Développeur JQUERY", type: "NaturalPerson" },
    // { label: "Educateur Football", type: "NaturalPerson" },
    // { label: "Développeur LINUX", type: "NaturalPerson" },
    // { label: "Educateur Spécialisé", type: "NaturalPerson" },
    // { label: "Développeur Logiciel", type: "NaturalPerson" },
    // { label: "Educateur Sportif", type: "NaturalPerson" },
    // { label: "Développeur LOTUS NOTES", type: "NaturalPerson" },
    // { label: "Educateur Technique Spécialisé", type: "NaturalPerson" },
    // { label: "Développeur MAGENTO", type: "NaturalPerson" },
    // { label: "Electricien", type: "NaturalPerson" },
    // { label: "Développeur Mobile", type: "NaturalPerson" },
    // { label: "Electricien Automobile", type: "NaturalPerson" },
    // { label: "Développeur Multimedia", type: "NaturalPerson" },
    // { label: "Electricien Bâtiment", type: "NaturalPerson" },
    // { label: "Développeur NET", type: "NaturalPerson" },
    // { label: "Electricien Câbleur", type: "NaturalPerson" },
    // { label: "Développeur ORACLE", type: "NaturalPerson" },
    // { label: "Electricien Chantier", type: "NaturalPerson" },
    // { label: "Développeur Photo", type: "NaturalPerson" },
    // { label: "Electricien Chariots Elévateurs", type: "NaturalPerson" },
    // { label: "Développeur PHP", type: "NaturalPerson" },
    // { label: "Electricien de Maintenance", type: "NaturalPerson" },
    // { label: "Développeur PRESTASHOP", type: "NaturalPerson" },
    // { label: "Electricien Equipement Industriel", type: "NaturalPerson" },
    // { label: "Développeur SAP", type: "NaturalPerson" },
    // { label: "Electricien Industriel", type: "NaturalPerson" },
    // { label: "Développeur SHAREPOINT", type: "NaturalPerson" },
    // { label: "Electricien Installateur", type: "NaturalPerson" },
    // { label: "Développeur SQL", type: "NaturalPerson" },
    // { label: "Electricien Monteur", type: "NaturalPerson" },
    // { label: "Développeur SYMFONY", type: "NaturalPerson" },
    // { label: "Electromécanicien", type: "NaturalPerson" },
    // { label: "Développeur Systèmes Embarqués", type: "NaturalPerson" },
    // { label: "Electromécanicien Maintenance", type: "NaturalPerson" },
    // { label: "Développeur VB6", type: "NaturalPerson" },
    // { label: "Electronicien", type: "NaturalPerson" },
    // { label: "Développeur VBA", type: "NaturalPerson" },
    // { label: "Electronicien Maintenance", type: "NaturalPerson" },
    // { label: "Développeur VISUAL BASIC", type: "NaturalPerson" },
    // { label: "Electronicien Multimédia", type: "NaturalPerson" },
    // { label: "Développeur Web", type: "NaturalPerson" },
    // { label: "Electrotechnicien", type: "NaturalPerson" },
    // { label: "Développeur WINDEV", type: "NaturalPerson" },
    // { label: "Eleveur", type: "NaturalPerson" },
    // { label: "Développeur WORDPRESS", type: "NaturalPerson" },
    // { label: "Eleveur Canins", type: "NaturalPerson" },
    // { label: "Développeur ZEND", type: "NaturalPerson" },
    // { label: "Employé Administratif", type: "NaturalPerson" },
    // { label: "Diététicienne", type: "NaturalPerson" },
    // { label: "Employé Assurance", type: "NaturalPerson" },
    // { label: "Digital Brand Manager", type: "NaturalPerson" },
    // { label: "Employé de Banque", type: "NaturalPerson" },
    // { label: "Directeur Achats", type: "NaturalPerson" },
    // { label: "Employé de Libre-Service", type: "NaturalPerson" },
    // { label: "Directeur Adjoint", type: "NaturalPerson" },
    // { label: "Employé de Ménage", type: "NaturalPerson" },
    // { label: "Directeur Adjoint Magasin", type: "NaturalPerson" },
    // { label: "Employé de Pressing", type: "NaturalPerson" },
    // { label: "Directeur Administratif", type: "NaturalPerson" },
    // { label: "Employé Etage", type: "NaturalPerson" },
    // { label: "Directeur Administratif et Financier", type: "NaturalPerson" },
    // { label: "Employé Librairie", type: "NaturalPerson" },
    // { label: "Directeur Agence Bancaire", type: "NaturalPerson" },
    // { label: "Employé Restauration", type: "NaturalPerson" },
    // { label: "Directeur Agence de Voyages", type: "NaturalPerson" },
    // { label: "Employé Restauration Rapide", type: "NaturalPerson" },
    // { label: "Directeur Agence Immobilière", type: "NaturalPerson" },
    // { label: "Employé Station-Service", type: "NaturalPerson" },
    // { label: "Directeur Aménagement", type: "NaturalPerson" },
    // { label: "Encadreur", type: "NaturalPerson" },
    // { label: "Directeur Artistique", type: "NaturalPerson" },
    // { label: "Energéticien", type: "NaturalPerson" },
    // { label: "Directeur Business Unit", type: "NaturalPerson" },
    // { label: "Enquêteur", type: "NaturalPerson" },
    // { label: "Directeur Commercial", type: "NaturalPerson" },
    // { label: "Enquêteurs Etude de Satisfaction", type: "NaturalPerson" },
    // { label: "Directeur Commercial Export", type: "NaturalPerson" },
    // { label: "Enseignant", type: "NaturalPerson" },
    // { label: "Directeur Communication", type: "NaturalPerson" },
    // { label: "Enseignant Art Plastique", type: "NaturalPerson" },
    // { label: "Directeur Comptable", type: "NaturalPerson" },
    // { label: "Enseignant Chercheur", type: "NaturalPerson" },
    // { label: "Directeur Contrôle de Gestion", type: "NaturalPerson" },
    // { label: "Enseignant Chercheur en Médecine", type: "NaturalPerson" },
    // { label: "Directeur de Bureau d´Etudes", type: "NaturalPerson" },
    // { label: "Enseignant Chercheur en Sciences Physiques...", type: "NaturalPerson" },
    // { label: "Directeur de Centre de Formation", type: "NaturalPerson" },
    // { label: "Enseignant Collège et Lycée", type: "NaturalPerson" },
    // { label: "Directeur de Centre de Loisirs", type: "NaturalPerson" },
    // { label: "Enseignant Primaire", type: "NaturalPerson" },
    // { label: "Directeur de Centre de Profit", type: "NaturalPerson" },
    // { label: "Enseignant Spécialisé", type: "NaturalPerson" },
    // { label: "Directeur de Clinique", type: "NaturalPerson" },
    // { label: "Entraîneur", type: "NaturalPerson" },
    // { label: "Directeur de Crèche", type: "NaturalPerson" },
    // { label: "Entraîneur de Chevaux", type: "NaturalPerson" },
    // { label: "Directeur de Département", type: "NaturalPerson" },
    // { label: "Entraîneur de Football", type: "NaturalPerson" },
    // { label: "Directeur de Filiale", type: "NaturalPerson" },
    // { label: "Entraineur Public Professionnel", type: "NaturalPerson" },
    // { label: "Directeur de Magasin", type: "NaturalPerson" },
    // { label: "Ergothérapeute", type: "NaturalPerson" },
    // { label: "Directeur de Musée", type: "NaturalPerson" },
    // { label: "Esthéticienne", type: "NaturalPerson" },
    // { label: "Directeur de Production", type: "NaturalPerson" },
    // { label: "Etalagiste", type: "NaturalPerson" },
    // { label: "Directeur de Projet", type: "NaturalPerson" },
    // { label: "Etalagiste Décorateur", type: "NaturalPerson" },
    // { label: "Directeur de Projet Fonctionnel", type: "NaturalPerson" },
    // { label: "Evaluateur Technique Automobile", type: "NaturalPerson" },
    // { label: "Directeur de Projet Réseaux", type: "NaturalPerson" },
    // { label: "Event Planner", type: "NaturalPerson" },
    // { label: "Directeur de Projet Technique", type: "NaturalPerson" },
    // { label: "Expert Administrateur", type: "NaturalPerson" },
    // { label: "Directeur de Projet Télécom", type: "NaturalPerson" },
    // { label: "Expert Automobile", type: "NaturalPerson" },
    // { label: "Directeur de Restaurant", type: "NaturalPerson" },
    // { label: "Expert Décisionnel", type: "NaturalPerson" },
    // { label: "Directeur de Salle de Cinéma", type: "NaturalPerson" },
    // { label: "Expert Développement", type: "NaturalPerson" },
    // { label: "Directeur de Site", type: "NaturalPerson" },
    // { label: "Expert en Assurance Automobile", type: "NaturalPerson" },
    // { label: "Directeur de Supermarché", type: "NaturalPerson" },
    // { label: "Expert en Assurances", type: "NaturalPerson" },
    // { label: "Directeur de Travaux", type: "NaturalPerson" },
    // { label: "Expert Fonctionnel", type: "NaturalPerson" },
    // { label: "Directeur des Opérations", type: "NaturalPerson" },
    // { label: "Expert Immobilier", type: "NaturalPerson" },
    // { label: "Directeur des Ressources Humaines", type: "NaturalPerson" },
    // { label: "Expert Infrastructure Réseaux", type: "NaturalPerson" },
    // { label: "Directeur d´Agence", type: "NaturalPerson" },
    // { label: "Expert Infrastructure Systèmes", type: "NaturalPerson" },
    // { label: "Directeur d´Exploitation", type: "NaturalPerson" },
    // { label: "Expert Judiciaire", type: "NaturalPerson" },
    // { label: "Directeur Ecole Maternelle", type: "NaturalPerson" },
    // { label: "Expert Maritime", type: "NaturalPerson" },
    // { label: "Directeur Etablissement Scolaire", type: "NaturalPerson" },
    // { label: "Expert Microsoft", type: "NaturalPerson" },
    // { label: "Directeur Etablissement Social", type: "NaturalPerson" },
    // { label: "Expert Outils de Test", type: "NaturalPerson" },
    // { label: "Directeur Exécutif", type: "NaturalPerson" },
    // { label: "Expert Réseaux", type: "NaturalPerson" },
    // { label: "Directeur Financier", type: "NaturalPerson" },
    // { label: "Expert Sécurité", type: "NaturalPerson" },
    // { label: "Directeur Formation", type: "NaturalPerson" },
    // { label: "Expert Stockage", type: "NaturalPerson" },
    // { label: "Directeur Général", type: "NaturalPerson" },
    // { label: "Expert Systèmes", type: "NaturalPerson" },
    // { label: "Directeur Général Adjoint", type: "NaturalPerson" },
    // { label: "Expert Technique", type: "NaturalPerson" },
    // { label: "Directeur Groupe", type: "NaturalPerson" },
    // { label: "Expert Windows", type: "NaturalPerson" },
    // { label: "Directeur Hébergement", type: "NaturalPerson" },
    // { label: "Expert-Comptable", type: "NaturalPerson" },
    // { label: "Directeur Hôtel", type: "NaturalPerson" },
    // { label: "Explorateur", type: "NaturalPerson" },
    // { label: "Directeur Hypermarché", type: "NaturalPerson" },
    // { label: "Export Sales Manager", type: "NaturalPerson" },
    // { label: "Directeur Industriel", type: "NaturalPerson" },
    // { label: "External Sales Consultant", type: "NaturalPerson" },
    // { label: "Facility Manager", type: "NaturalPerson" },
    // { label: "Grossiste", type: "NaturalPerson" },
    // { label: "Facteur", type: "NaturalPerson" },
    // { label: "Grutier", type: "NaturalPerson" },
    // { label: "Femme de Chambre", type: "NaturalPerson" },
    // { label: "Guichetier", type: "NaturalPerson" },
    // { label: "Femme de Ménage", type: "NaturalPerson" },
    // { label: "Guide", type: "NaturalPerson" },
    // { label: "Ferrailleur", type: "NaturalPerson" },
    // { label: "Guide Accompagnateur", type: "NaturalPerson" },
    // { label: "Ferronnier", type: "NaturalPerson" },
    // { label: "Guide de Montagne", type: "NaturalPerson" },
    // { label: "Fiduciaire", type: "NaturalPerson" },
    // { label: "Guide de Randonnée", type: "NaturalPerson" },
    // { label: "Financier", type: "NaturalPerson" },
    // { label: "Guide Musée", type: "NaturalPerson" },
    // { label: "Finisseur sur Bois", type: "NaturalPerson" },
    // { label: "Guide Touristique", type: "NaturalPerson" },
    // { label: "Fiscaliste", type: "NaturalPerson" },
    // { label: "Gynécologue", type: "NaturalPerson" },
    // { label: "Fleuriste", type: "NaturalPerson" },
    // { label: "Gynécologue Obstétricien", type: "NaturalPerson" },
    // { label: "Fondeur", type: "NaturalPerson" },
    // { label: "Herboriste", type: "NaturalPerson" },
    // { label: "Foreur", type: "NaturalPerson" },
    // { label: "Horloger", type: "NaturalPerson" },
    // { label: "Forfaitiste", type: "NaturalPerson" },
    // { label: "Horticulteur", type: "NaturalPerson" },
    // { label: "Formateur", type: "NaturalPerson" },
    // { label: "Horticulteur Pépiniériste", type: "NaturalPerson" },
    // { label: "Formateur Animateur", type: "NaturalPerson" },
    // { label: "Hôtesse", type: "NaturalPerson" },
    // { label: "Formateur en Assurances", type: "NaturalPerson" },
    // { label: "Hôtesse de l'Air", type: "NaturalPerson" },
    // { label: "Formateur en Stylisme Modélisme", type: "NaturalPerson" },
    // { label: "Hôtesse d´Accueil", type: "NaturalPerson" },
    // { label: "Formateur en TIC", type: "NaturalPerson" },
    // { label: "Hotliner", type: "NaturalPerson" },
    // { label: "Formateur Informatique", type: "NaturalPerson" },
    // { label: "Hotliner Mobile", type: "NaturalPerson" },
    // { label: "Formateur Relation Abonnés", type: "NaturalPerson" },
    // { label: "HR Business Consultant", type: "NaturalPerson" },
    // { label: "Formateur Technique", type: "NaturalPerson" },
    // { label: "HR Manager", type: "NaturalPerson" },
    // { label: "Fraiseur", type: "NaturalPerson" },
    // { label: "Hydraulicien", type: "NaturalPerson" },
    // { label: "Frigoriste", type: "NaturalPerson" },
    // { label: "Hydrothérapeute", type: "NaturalPerson" },
    // { label: "Fromager", type: "NaturalPerson" },
    // { label: "Hygiéniste", type: "NaturalPerson" },
    // { label: "Front-Office Manager", type: "NaturalPerson" },
    // { label: "Hygiéniste Dentaire", type: "NaturalPerson" },
    // { label: "Game Designer", type: "NaturalPerson" },
    // { label: "Illustrateur", type: "NaturalPerson" },
    // { label: "Garçon de Café", type: "NaturalPerson" },
    // { label: "Imprimeur", type: "NaturalPerson" },
    // { label: "Garde du Corps", type: "NaturalPerson" },
    // { label: "Infirmier Anesthésie Réanimation", type: "NaturalPerson" },
    // { label: "Gardien", type: "NaturalPerson" },
    // { label: "Infirmier Anesthésiste", type: "NaturalPerson" },
    // { label: "Gardien de Nuit", type: "NaturalPerson" },
    // { label: "Infirmier Bloc Opératoire", type: "NaturalPerson" },
    // { label: "Gardien d´Animaux", type: "NaturalPerson" },
    // { label: "Infirmier Diplômé d´Etat", type: "NaturalPerson" },
    // { label: "Garnisseur", type: "NaturalPerson" },
    // { label: "Infirmier Soins à Domicile", type: "NaturalPerson" },
    // { label: "General Manager", type: "NaturalPerson" },
    // { label: "Infographiste", type: "NaturalPerson" },
    // { label: "Géographe", type: "NaturalPerson" },
    // { label: "Ingénieur", type: "NaturalPerson" },
    // { label: "Géologue", type: "NaturalPerson" },
    // { label: "Ingénieur Support Maintenance", type: "NaturalPerson" },
    // { label: "Géomaticien", type: "NaturalPerson" },
    // { label: "Inhalothérapeute", type: "NaturalPerson" },
    // { label: "Géomètre", type: "NaturalPerson" },
    // { label: "Inséminateur", type: "NaturalPerson" },
    // { label: "Géomètre Expert Foncier", type: "NaturalPerson" },
    // { label: "Inside Sales Executive", type: "NaturalPerson" },
    // { label: "Géomètre Topographe", type: "NaturalPerson" },
    // { label: "Inspecteur Commercial", type: "NaturalPerson" },
    // { label: "Géotechnicien", type: "NaturalPerson" },
    // { label: "Inspecteur des Ventes", type: "NaturalPerson" },
    // { label: "Gérant", type: "NaturalPerson" },
    // { label: "Inspecteur Génie Civil", type: "NaturalPerson" },
    // { label: "Gérant de Portefeuille", type: "NaturalPerson" },
    // { label: "Inspecteur Mécanique", type: "NaturalPerson" },
    // { label: "Gérant de Pressing", type: "NaturalPerson" },
    // { label: "Installateur en Chauffage Climatisation...", type: "NaturalPerson" },
    // { label: "Gérant de Station Service", type: "NaturalPerson" },
    // { label: "Installateur Matériel Médical", type: "NaturalPerson" },
    // { label: "Gérant Exploitation", type: "NaturalPerson" },
    // { label: "Installateur Photovoltaique", type: "NaturalPerson" },
    // { label: "Gestionnaire Administratif", type: "NaturalPerson" },
    // { label: "Installateur Plomberie Sanitaire", type: "NaturalPerson" },
    // { label: "Gestionnaire Back-Office", type: "NaturalPerson" },
    // { label: "Installateur Systèmes Electriques", type: "NaturalPerson" },
    // { label: "Gestionnaire Base de Données", type: "NaturalPerson" },
    // { label: "Instructeur", type: "NaturalPerson" },
    // { label: "Gestionnaire Comptable et Administratif...", type: "NaturalPerson" },
    // { label: "Instrumentiste", type: "NaturalPerson" },
    // { label: "Gestionnaire Contentieux", type: "NaturalPerson" },
    // { label: "Intégrateur", type: "NaturalPerson" },
    // { label: "Gestionnaire Contrats", type: "NaturalPerson" },
    // { label: "Intégrateur de Logiciels", type: "NaturalPerson" },
    // { label: "Gestionnaire Copropriété", type: "NaturalPerson" },
    // { label: "Intégrateur d´Application", type: "NaturalPerson" },
    // { label: "Gestionnaire de Campagnes Marketing", type: "NaturalPerson" },
    // { label: "Intégrateur d´Exploitation", type: "NaturalPerson" },
    // { label: "Gestionnaire de Configuration", type: "NaturalPerson" },
    // { label: "Intégrateur Systèmes", type: "NaturalPerson" },
    // { label: "Gestionnaire de Magasin", type: "NaturalPerson" },
    // { label: "Intégrateur Technique", type: "NaturalPerson" },
    // { label: "Gestionnaire de Paie", type: "NaturalPerson" },
    // { label: "Intégrateur Test", type: "NaturalPerson" },
    // { label: "Gestionnaire de Patrimoine", type: "NaturalPerson" },
    // { label: "Intendant", type: "NaturalPerson" },
    // { label: "Gestionnaire de Stocks", type: "NaturalPerson" },
    // { label: "International Sales Manager", type: "NaturalPerson" },
    // { label: "Gestionnaire des Risques", type: "NaturalPerson" },
    // { label: "Interprète", type: "NaturalPerson" },
    // { label: "Gestionnaire d´Applications", type: "NaturalPerson" },
    // { label: "Intervenant", type: "NaturalPerson" },
    // { label: "Gestionnaire en Assurance", type: "NaturalPerson" },
    // { label: "Jardinier", type: "NaturalPerson" },
    // { label: "Gestionnaire Financier", type: "NaturalPerson" },
    // { label: "Jardinier Paysagiste", type: "NaturalPerson" },
    // { label: "Gestionnaire Formation", type: "NaturalPerson" },
    // { label: "Jongleur", type: "NaturalPerson" },
    // { label: "Gestionnaire Immobilier", type: "NaturalPerson" },
    // { label: "Journaliste", type: "NaturalPerson" },
    // { label: "Gestionnaire Import Export", type: "NaturalPerson" },
    // { label: "Journaliste Radio Télé", type: "NaturalPerson" },
    // { label: "Gestionnaire Infrastructures Sportives...", type: "NaturalPerson" },
    // { label: "Journaliste Rédacteur", type: "NaturalPerson" },
    // { label: "Gestionnaire Locatif", type: "NaturalPerson" },
    // { label: "Journaliste Reporteur Images", type: "NaturalPerson" },
    // { label: "Gestionnaire Middle-Office", type: "NaturalPerson" },
    // { label: "Journaliste Sportif", type: "NaturalPerson" },
    // { label: "Gestionnaire Paie et Administratif", type: "NaturalPerson" },
    // { label: "Journaliste Web", type: "NaturalPerson" },
    // { label: "Gestionnaire Parc Informatique", type: "NaturalPerson" },
    // { label: "Juriste Consultant", type: "NaturalPerson" },
    // { label: "Gestionnaire Portefeuille Clients", type: "NaturalPerson" },
    // { label: "Juriste Contentieux", type: "NaturalPerson" },
    // { label: "Gestionnaire Prestations de Santé", type: "NaturalPerson" },
    // { label: "Juriste Droit Immobilier", type: "NaturalPerson" },
    // { label: "Gestionnaire Sinistres", type: "NaturalPerson" },
    // { label: "Juriste Droit International", type: "NaturalPerson" },
    // { label: "Gestionnaire Site", type: "NaturalPerson" },
    // { label: "Juriste Droit Social", type: "NaturalPerson" },
    // { label: "Gestionnaires en Assurance Prévoyance...", type: "NaturalPerson" },
    // { label: "Juriste Entreprise", type: "NaturalPerson" },
    // { label: "Glacier", type: "NaturalPerson" },
    // { label: "Key Account Manager", type: "NaturalPerson" },
    // { label: "Gouvernant Etage", type: "NaturalPerson" },
    // { label: "Kinésithérapeute", type: "NaturalPerson" },
    // { label: "Gouvernant Général", type: "NaturalPerson" },
    // { label: "Laborantin", type: "NaturalPerson" },
    // { label: "Gouvernante", type: "NaturalPerson" },
    // { label: "Lamineur", type: "NaturalPerson" },
    // { label: "Graphiste", type: "NaturalPerson" },
    // { label: "Laveur de Vitre", type: "NaturalPerson" },
    // { label: "Graphiste Décorateur", type: "NaturalPerson" },
    // { label: "Liaison Officer", type: "NaturalPerson" },
    // { label: "Graphiste Multimédia", type: "NaturalPerson" },
    // { label: "Linguiste", type: "NaturalPerson" },
    // { label: "Graphiste Photoshop", type: "NaturalPerson" },
    // { label: "Livreur", type: "NaturalPerson" },
    // { label: "Graphiste Web", type: "NaturalPerson" },
    // { label: "Livreur VL", type: "NaturalPerson" },
    // { label: "Graphologue", type: "NaturalPerson" },
    // { label: "Logisticien", type: "NaturalPerson" },
    // { label: "Graveur", type: "NaturalPerson" },
    // { label: "Loss Prevention Advisor", type: "NaturalPerson" },
    // { label: "Machiniste", type: "NaturalPerson" },
    // { label: "Moniteur Natation", type: "NaturalPerson" },
    // { label: "Maçon", type: "NaturalPerson" },
    // { label: "Moniteur Sportif", type: "NaturalPerson" },
    // { label: "Maçon Coffreur", type: "NaturalPerson" },
    // { label: "Moniteur Tennis", type: "NaturalPerson" },
    // { label: "Magasinier", type: "NaturalPerson" },
    // { label: "Monteur", type: "NaturalPerson" },
    // { label: "Magasinier Cariste", type: "NaturalPerson" },
    // { label: "Monteur Aéronautique", type: "NaturalPerson" },
    // { label: "Maître Chien", type: "NaturalPerson" },
    // { label: "Monteur Ascenseurs", type: "NaturalPerson" },
    // { label: "Maître d´Hôtel", type: "NaturalPerson" },
    // { label: "Monteur Câbleur", type: "NaturalPerson" },
    // { label: "Maître Nageur", type: "NaturalPerson" },
    // { label: "Monteur Câbleur Electronique", type: "NaturalPerson" },
    // { label: "Maître Nageur Sauveteur", type: "NaturalPerson" },
    // { label: "Monteur Echafaudage", type: "NaturalPerson" },
    // { label: "Maître Rembourreur", type: "NaturalPerson" },
    // { label: "Monteur en Charpente Métallique", type: "NaturalPerson" },
    // { label: "Manager Commercial", type: "NaturalPerson" },
    // { label: "Monteur Frigoriste", type: "NaturalPerson" },
    // { label: "Manager de Club", type: "NaturalPerson" },
    // { label: "Monteur Image Son", type: "NaturalPerson" },
    // { label: "Manager de Plateau", type: "NaturalPerson" },
    // { label: "Monteur Installations Sanitaires", type: "NaturalPerson" },
    // { label: "Manager des Ventes", type: "NaturalPerson" },
    // { label: "Monteur Lignes Electriques", type: "NaturalPerson" },
    // { label: "Manager Equipe", type: "NaturalPerson" },
    // { label: "Monteur Mécanicien", type: "NaturalPerson" },
    // { label: "Manager Indépendant", type: "NaturalPerson" },
    // { label: "Monteur Opérateur de Prise de Vue", type: "NaturalPerson" },
    // { label: "Manager Rayon", type: "NaturalPerson" },
    // { label: "Monteur Régleur", type: "NaturalPerson" },
    // { label: "Manager Restauration", type: "NaturalPerson" },
    // { label: "Monteur Truquiste", type: "NaturalPerson" },
    // { label: "Manager Retail Innovation", type: "NaturalPerson" },
    // { label: "Monteur Vidéo", type: "NaturalPerson" },
    // { label: "Manager Service Desk", type: "NaturalPerson" },
    // { label: "Motion Designer", type: "NaturalPerson" },
    // { label: "Manager Transition", type: "NaturalPerson" },
    // { label: "Mouleur Plastique", type: "NaturalPerson" },
    // { label: "Manipulateur Radiologie", type: "NaturalPerson" },
    // { label: "Mouliste", type: "NaturalPerson" },
    // { label: "Manoeuvre", type: "NaturalPerson" },
    // { label: "Mouliste Machine d´Injection", type: "NaturalPerson" },
    // { label: "Manoeuvre Bâtiment", type: "NaturalPerson" },
    // { label: "Musicien", type: "NaturalPerson" },
    // { label: "Manucure", type: "NaturalPerson" },
    // { label: "Musicien Intervenant", type: "NaturalPerson" },
    // { label: "Manutentionnaire", type: "NaturalPerson" },
    // { label: "Négociateur", type: "NaturalPerson" },
    // { label: "Manutentionnaire Cariste", type: "NaturalPerson" },
    // { label: "Négociateur Immobilier", type: "NaturalPerson" },
    // { label: "Maquettiste", type: "NaturalPerson" },
    // { label: "Nettoyeur de Textiles", type: "NaturalPerson" },
    // { label: "Maquilleur", type: "NaturalPerson" },
    // { label: "Notaire", type: "NaturalPerson" },
    // { label: "Maraîcher", type: "NaturalPerson" },
    // { label: "Nutritionniste", type: "NaturalPerson" },
    // { label: "Marchandiseur", type: "NaturalPerson" },
    // { label: "Office Manager", type: "NaturalPerson" },
    // { label: "Marin", type: "NaturalPerson" },
    // { label: "Officier de Port", type: "NaturalPerson" },
    // { label: "Marin Pêcheur", type: "NaturalPerson" },
    // { label: "Officier Marine Marchande", type: "NaturalPerson" },
    // { label: "Maroquinier", type: "NaturalPerson" },
    // { label: "Opérateur", type: "NaturalPerson" },
    // { label: "Marqueteur", type: "NaturalPerson" },
    // { label: "Opérateur Agence", type: "NaturalPerson" },
    // { label: "Matelot", type: "NaturalPerson" },
    // { label: "Opérateur Cablage", type: "NaturalPerson" },
    // { label: "Mathématicien Informaticien", type: "NaturalPerson" },
    // { label: "Opérateur Call Center", type: "NaturalPerson" },
    // { label: "Mécanicien", type: "NaturalPerson" },
    // { label: "Opérateur Centrale à Béton", type: "NaturalPerson" },
    // { label: "Mécanicien Aéronautique", type: "NaturalPerson" },
    // { label: "Opérateur Commande Numérique", type: "NaturalPerson" },
    // { label: "Mécanicien Agricole", type: "NaturalPerson" },
    // { label: "Opérateur Commande Numérique Aéronautique...", type: "NaturalPerson" },
    // { label: "Mécanicien Automobile", type: "NaturalPerson" },
    // { label: "Opérateur Conditionnement", type: "NaturalPerson" },
    // { label: "Mécanicien Avion", type: "NaturalPerson" },
    // { label: "Opérateur de Caméra de Surveillance", type: "NaturalPerson" },
    // { label: "Mécanicien Cellules Hydrauliques", type: "NaturalPerson" },
    // { label: "Opérateur de Coupe", type: "NaturalPerson" },
    // { label: "Mécanicien Engins", type: "NaturalPerson" },
    // { label: "Opérateur de Machinerie", type: "NaturalPerson" },
    // { label: "Mécanicien Engins de Chantier", type: "NaturalPerson" },
    // { label: "Opérateur de Maintenance", type: "NaturalPerson" },
    // { label: "Mécanicien Engins de Chantier de Levage et...", type: "NaturalPerson" },
    // { label: "Opérateur de Marché Trader", type: "NaturalPerson" },
    // { label: "Mécanicien Industriel", type: "NaturalPerson" },
    // { label: "Opérateur de Production", type: "NaturalPerson" },
    // { label: "Mécanicien Industriel Naval", type: "NaturalPerson" },
    // { label: "Opérateur de Production Agroalimentaire...", type: "NaturalPerson" },
    // { label: "Mécanicien Machines à Coudre", type: "NaturalPerson" },
    // { label: "Opérateur de Saisie", type: "NaturalPerson" },
    // { label: "Mécanicien Machines Agricoles", type: "NaturalPerson" },
    // { label: "Opérateur en Maintenance Industrielle...", type: "NaturalPerson" },
    // { label: "Mécanicien Machines de Production", type: "NaturalPerson" },
    // { label: "Opérateur Fabrication", type: "NaturalPerson" },
    // { label: "Mécanicien Maintenance", type: "NaturalPerson" },
    // { label: "Opérateur Logistique", type: "NaturalPerson" },
    // { label: "Mécanicien Marine Marchande", type: "NaturalPerson" },
    // { label: "Opérateur Machine d´Injection Plastique...", type: "NaturalPerson" },
    // { label: "Mécanicien Poids Lourd", type: "NaturalPerson" },
    // { label: "Opérateur Machine Outil à Commande...", type: "NaturalPerson" },
    // { label: "Mécanicien Robinetier", type: "NaturalPerson" },
    // { label: "Opérateur Marché de Change", type: "NaturalPerson" },
    // { label: "Mécanicien Service Rapide Automobile", type: "NaturalPerson" },
    // { label: "Opérateur Nettoyage Industriel", type: "NaturalPerson" },
    // { label: "Médecin Anesthésiste Réanimateur", type: "NaturalPerson" },
    // { label: "Opérateur PAO", type: "NaturalPerson" },
    // { label: "Médecin Biologiste", type: "NaturalPerson" },
    // { label: "Opérateur Plasturgie Injection Extrusion...", type: "NaturalPerson" },
    // { label: "Médecin Comportementaliste", type: "NaturalPerson" },
    // { label: "Opérateur Polyvalent", type: "NaturalPerson" },
    // { label: "Médecin du Travail", type: "NaturalPerson" },
    // { label: "Opérateur Régleur", type: "NaturalPerson" },
    // { label: "Médecin Généraliste", type: "NaturalPerson" },
    // { label: "Opérateur Régleur de Machines", type: "NaturalPerson" },
    // { label: "Médecin Radiologue", type: "NaturalPerson" },
    // { label: "Opérateur sur Machine", type: "NaturalPerson" },
    // { label: "Médecin Spécialiste", type: "NaturalPerson" },
    // { label: "Opérateur sur Machine de Finition", type: "NaturalPerson" },
    // { label: "Médecin Urgentiste", type: "NaturalPerson" },
    // { label: "Opérateur sur Machines Automatiques en...", type: "NaturalPerson" },
    // { label: "Media Planner", type: "NaturalPerson" },
    // { label: "Opérateur sur Machines Spéciales de...", type: "NaturalPerson" },
    // { label: "Media Reporter", type: "NaturalPerson" },
    // { label: "Opérateur Technique", type: "NaturalPerson" },
    // { label: "Médiateur Social", type: "NaturalPerson" },
    // { label: "Opératrice Cablage Aéronautique", type: "NaturalPerson" },
    // { label: "Médiathécaire", type: "NaturalPerson" },
    // { label: "Opératrice de Saisie", type: "NaturalPerson" },
    // { label: "Medical Advisor Hospitalier", type: "NaturalPerson" },
    // { label: "Opératrice sur Machine à Coudre", type: "NaturalPerson" },
    // { label: "Membership Consultant", type: "NaturalPerson" },
    // { label: "Ophtalmologiste", type: "NaturalPerson" },
    // { label: "Menuisier Aluminium", type: "NaturalPerson" },
    // { label: "Opticien", type: "NaturalPerson" },
    // { label: "Menuisier Atelier", type: "NaturalPerson" },
    // { label: "Optométriste", type: "NaturalPerson" },
    // { label: "Menuisier Ebéniste", type: "NaturalPerson" },
    // { label: "Orfèvre en Argenterie", type: "NaturalPerson" },
    // { label: "Menuisier Poseur", type: "NaturalPerson" },
    // { label: "Orthodontiste", type: "NaturalPerson" },
    // { label: "Menuisier PVC", type: "NaturalPerson" },
    // { label: "Orthophoniste", type: "NaturalPerson" },
    // { label: "Mesureur", type: "NaturalPerson" },
    // { label: "Orthoprothésiste", type: "NaturalPerson" },
    // { label: "Météorologue", type: "NaturalPerson" },
    // { label: "Orthoptiste", type: "NaturalPerson" },
    // { label: "Métreur", type: "NaturalPerson" },
    // { label: "Ostéopathe", type: "NaturalPerson" },
    // { label: "Métrologue", type: "NaturalPerson" },
    // { label: "Outilleur", type: "NaturalPerson" },
    // { label: "Metteur au Point", type: "NaturalPerson" },
    // { label: "Outilleur Mouliste", type: "NaturalPerson" },
    // { label: "Metteur en Scène", type: "NaturalPerson" },
    // { label: "Ouvrier", type: "NaturalPerson" },
    // { label: "Meunier", type: "NaturalPerson" },
    // { label: "Ouvrier Agricole", type: "NaturalPerson" },
    // { label: "Minier", type: "NaturalPerson" },
    // { label: "Ouvrier Agroalimentaire", type: "NaturalPerson" },
    // { label: "Mixeur Son", type: "NaturalPerson" },
    // { label: "Ouvrier Bâtiment", type: "NaturalPerson" },
    // { label: "Modeleur", type: "NaturalPerson" },
    // { label: "Ouvrier Construction Métallique", type: "NaturalPerson" },
    // { label: "Modérateur Forum Réseaux Sociaux", type: "NaturalPerson" },
    // { label: "Ouvrier de Production", type: "NaturalPerson" },
    // { label: "Modérateur Site Web", type: "NaturalPerson" },
    // { label: "Ouvrier en Manutention", type: "NaturalPerson" },
    // { label: "Moniteur", type: "NaturalPerson" },
    // { label: "Ouvrier Maraîchage Horticulture", type: "NaturalPerson" },
    // { label: "Moniteur Auto-Ecole", type: "NaturalPerson" },
    // { label: "Ouvrier Polyvalent", type: "NaturalPerson" },
    // { label: "Moniteur de Voile", type: "NaturalPerson" },
    // { label: "Ouvrier Production Laitière", type: "NaturalPerson" },
    // { label: "Moniteur Educateur", type: "NaturalPerson" },
    // { label: "Ouvrier Qualifié", type: "NaturalPerson" },
    // { label: "Packageur Intégrateur", type: "NaturalPerson" },
    // { label: "Représentant des Ventes Cosmétiques", type: "NaturalPerson" },
    // { label: "Palefrenier", type: "NaturalPerson" },
    // { label: "Resource Manager", type: "NaturalPerson" },
    // { label: "Paramédical", type: "NaturalPerson" },
    // { label: "Responsable Achats", type: "NaturalPerson" },
    // { label: "Paramètreur", type: "NaturalPerson" },
    // { label: "Responsable Achats Approvisionnement", type: "NaturalPerson" },
    // { label: "Pâtissier", type: "NaturalPerson" },
    // { label: "Responsable Administratif et Comptable...", type: "NaturalPerson" },
    // { label: "Pâtissier Confiseur", type: "NaturalPerson" },
    // { label: "Responsable Administratif et Financier...", type: "NaturalPerson" },
    // { label: "Paysagiste", type: "NaturalPerson" },
    // { label: "Responsable Administration des Ventes...", type: "NaturalPerson" },
    // { label: "Pédiatre", type: "NaturalPerson" },
    // { label: "Responsable Administration du Personnel...", type: "NaturalPerson" },
    // { label: "Pédicure Podologue", type: "NaturalPerson" },
    // { label: "Responsable Affaires Réglementaires", type: "NaturalPerson" },
    // { label: "Pédologue", type: "NaturalPerson" },
    // { label: "Responsable Aire de Jeux", type: "NaturalPerson" },
    // { label: "Peintre Aéronautique", type: "NaturalPerson" },
    // { label: "Responsable Applicatif", type: "NaturalPerson" },
    // { label: "Peintre Automobile", type: "NaturalPerson" },
    // { label: "Responsable Approvisionnement", type: "NaturalPerson" },
    // { label: "Peintre Carrossier", type: "NaturalPerson" },
    // { label: "Responsable Archives", type: "NaturalPerson" },
    // { label: "Peintre Industriel", type: "NaturalPerson" },
    // { label: "Responsable Assurance Qualité", type: "NaturalPerson" },
    // { label: "Peintre Pistolet", type: "NaturalPerson" },
    // { label: "Responsable Atelier", type: "NaturalPerson" },
    // { label: "Peintre sur Bois", type: "NaturalPerson" },
    // { label: "Responsable Audit et Contrôle de Gestion...", type: "NaturalPerson" },
    // { label: "Peintre Bâtiment", type: "NaturalPerson" },
    // { label: "Responsable Boutique", type: "NaturalPerson" },
    // { label: "Pépiniériste", type: "NaturalPerson" },
    // { label: "Responsable Business Intelligence", type: "NaturalPerson" },
    // { label: "Pharmacien", type: "NaturalPerson" },
    // { label: "Responsable Centre de Beauté", type: "NaturalPerson" },
    // { label: "Pharmacien Affaires Réglementaires", type: "NaturalPerson" },
    // { label: "Responsable Commercial Automobile", type: "NaturalPerson" },
    // { label: "Pharmacien Assurance Qualité", type: "NaturalPerson" },
    // { label: "Responsable Commercial Export", type: "NaturalPerson" },
    // { label: "Pharmacien Assurance Qualité Système", type: "NaturalPerson" },
    // { label: "Responsable Commercial Immobilier", type: "NaturalPerson" },
    // { label: "Pharmacien Industriel", type: "NaturalPerson" },
    // { label: "Responsable Commercial Web", type: "NaturalPerson" },
    // { label: "Pharmacien Responsable", type: "NaturalPerson" },
    // { label: "Responsable Communication", type: "NaturalPerson" },
    // { label: "Pharmacien Responsable Production", type: "NaturalPerson" },
    // { label: "Responsable Communication Interne", type: "NaturalPerson" },
    // { label: "Photographe", type: "NaturalPerson" },
    // { label: "Responsable Comptabilité Clients", type: "NaturalPerson" },
    // { label: "Physicien", type: "NaturalPerson" },
    // { label: "Responsable Comptabilité Fournisseurs...", type: "NaturalPerson" },
    // { label: "Pigiste", type: "NaturalPerson" },
    // { label: "Responsable Comptable", type: "NaturalPerson" },
    // { label: "Pilote de Flux", type: "NaturalPerson" },
    // { label: "Responsable Comptoir", type: "NaturalPerson" },
    // { label: "Pilote de Ligne", type: "NaturalPerson" },
    // { label: "Responsable Conformité et Contrôle Interne...", type: "NaturalPerson" },
    // { label: "Pilote de Production", type: "NaturalPerson" },
    // { label: "Responsable Contrôle Qualité", type: "NaturalPerson" },
    // { label: "Pilote Exploitation", type: "NaturalPerson" },
    // { label: "Responsable Contrôle Qualité...", type: "NaturalPerson" },
    // { label: "Pilote Projet", type: "NaturalPerson" },
    // { label: "Responsable Crèche", type: "NaturalPerson" },
    // { label: "Pisciniste", type: "NaturalPerson" },
    // { label: "Responsable de Fabrication", type: "NaturalPerson" },
    // { label: "Pizzaiolo", type: "NaturalPerson" },
    // { label: "Responsable de Maintenance", type: "NaturalPerson" },
    // { label: "Plagiste", type: "NaturalPerson" },
    // { label: "Responsable de Zone", type: "NaturalPerson" },
    // { label: "Planificateur", type: "NaturalPerson" },
    // { label: "Responsable Débiteur", type: "NaturalPerson" },
    // { label: "Planneur Stratégique", type: "NaturalPerson" },
    // { label: "Responsable Déploiement", type: "NaturalPerson" },
    // { label: "Plaquiste", type: "NaturalPerson" },
    // { label: "Responsable Dépôt", type: "NaturalPerson" },
    // { label: "Plâtrier", type: "NaturalPerson" },
    // { label: "Responsable Développement Commercial", type: "NaturalPerson" },
    // { label: "Plombier", type: "NaturalPerson" },
    // { label: "Responsable Développement RH", type: "NaturalPerson" },
    // { label: "Plombier Chauffagiste", type: "NaturalPerson" },
    // { label: "Responsable Digital", type: "NaturalPerson" },
    // { label: "Plongeur", type: "NaturalPerson" },
    // { label: "Responsable d´Agence", type: "NaturalPerson" },
    // { label: "Poissonnier", type: "NaturalPerson" },
    // { label: "Responsable d´Exploitation", type: "NaturalPerson" },
    // { label: "Policier", type: "NaturalPerson" },
    // { label: "Responsable E-Commerce", type: "NaturalPerson" },
    // { label: "Polisseur", type: "NaturalPerson" },
    // { label: "Responsable Editorial", type: "NaturalPerson" },
    // { label: "Pompier", type: "NaturalPerson" },
    // { label: "Responsable Emailing", type: "NaturalPerson" },
    // { label: "Pompiste", type: "NaturalPerson" },
    // { label: "Responsable Environnement", type: "NaturalPerson" },
    // { label: "Pontier", type: "NaturalPerson" },
    // { label: "Responsable Equipe Commerciale", type: "NaturalPerson" },
    // { label: "Portier", type: "NaturalPerson" },
    // { label: "Responsable Espaces Verts", type: "NaturalPerson" },
    // { label: "Poseur Menuiserie", type: "NaturalPerson" },
    // { label: "Responsable Etudes", type: "NaturalPerson" },
    // { label: "Pré-Vendeur", type: "NaturalPerson" },
    // { label: "Responsable Evénementiel", type: "NaturalPerson" },
    // { label: "Préparateur", type: "NaturalPerson" },
    // { label: "Responsable Export", type: "NaturalPerson" },
    // { label: "Préparateur Automobile", type: "NaturalPerson" },
    // { label: "Responsable Fabrication Agroalimentaire...", type: "NaturalPerson" },
    // { label: "Préparateur de Commandes", type: "NaturalPerson" },
    // { label: "Responsable Fabrication Industrie", type: "NaturalPerson" },
    // { label: "Préparateur en Industrie Agroalimentaire...", type: "NaturalPerson" },
    // { label: "Responsable Fabrication Industrie...", type: "NaturalPerson" },
    // { label: "Préparateur en Pharmacie", type: "NaturalPerson" },
    // { label: "Responsable Facturation", type: "NaturalPerson" },
    // { label: "Préparateur Physique", type: "NaturalPerson" },
    // { label: "Responsable Financier", type: "NaturalPerson" },
    // { label: "Pressier", type: "NaturalPerson" },
    // { label: "Responsable Flux de Production", type: "NaturalPerson" },
    // { label: "Principal Business Consultant", type: "NaturalPerson" },
    // { label: "Responsable Food and Beverage", type: "NaturalPerson" },
    // { label: "Producteur Multimédia", type: "NaturalPerson" },
    // { label: "Responsable Galerie", type: "NaturalPerson" },
    // { label: "Professeur Art", type: "NaturalPerson" },
    // { label: "Responsable Gestion du Patrimoine", type: "NaturalPerson" },
    // { label: "Professeur Art Dramatique", type: "NaturalPerson" },
    // { label: "Responsable Hébergement", type: "NaturalPerson" },
    // { label: "Professeur Arts Plastiques", type: "NaturalPerson" },
    // { label: "Responsable Help Desk", type: "NaturalPerson" },
    // { label: "Professeur de Danse", type: "NaturalPerson" },
    // { label: "Responsable Informatique", type: "NaturalPerson" },
    // { label: "Professeur de Français", type: "NaturalPerson" },
    // { label: "Responsable Juridique", type: "NaturalPerson" },
    // { label: "Professeur de Mathématiques", type: "NaturalPerson" },
    // { label: "Responsable Laboratoire", type: "NaturalPerson" },
    // { label: "Professeur de Musique", type: "NaturalPerson" },
    // { label: "Responsable Lean Manufacturing", type: "NaturalPerson" },
    // { label: "Professeur de Sport", type: "NaturalPerson" },
    // { label: "Responsable Ligne de Fabrication", type: "NaturalPerson" },
    // { label: "Professeur de Technologie", type: "NaturalPerson" },
    // { label: "Responsable Logistique", type: "NaturalPerson" },
    // { label: "Professeur Economie", type: "NaturalPerson" },
    // { label: "Responsable Maintenance Bâtiment", type: "NaturalPerson" },
    // { label: "Professeur Education Musicale", type: "NaturalPerson" },
    // { label: "Responsable Maintenance Industrielle", type: "NaturalPerson" },
    // { label: "Professeur Education Physique et Sportive...", type: "NaturalPerson" },
    // { label: "Responsable Marchés Publics", type: "NaturalPerson" },
    // { label: "Professeur Histoire Géographie", type: "NaturalPerson" },
    // { label: "Responsable Marketing", type: "NaturalPerson" },
    // { label: "Professeur Langues Etrangères", type: "NaturalPerson" },
    // { label: "Responsable Marketing Digital", type: "NaturalPerson" },
    // { label: "Professeur Philosophie", type: "NaturalPerson" },
    // { label: "Responsable Marketing Direct", type: "NaturalPerson" },
    // { label: "Professeur Physique Chimie", type: "NaturalPerson" },
    // { label: "Responsable Marketing et Communication...", type: "NaturalPerson" },
    // { label: "Professeur Sciences de la Vie et de la...", type: "NaturalPerson" },
    // { label: "Responsable Marketing International", type: "NaturalPerson" },
    // { label: "Professeur Universitaire", type: "NaturalPerson" },
    // { label: "Responsable Marketing Opérationnel", type: "NaturalPerson" },
    // { label: "Programmeur", type: "NaturalPerson" },
    // { label: "Responsable Marketing Stratégique", type: "NaturalPerson" },
    // { label: "Project Control Manager", type: "NaturalPerson" },
    // { label: "Responsable Marketplace", type: "NaturalPerson" },
    // { label: "Projeteur Autoroutier", type: "NaturalPerson" },
    // { label: "Responsable Matériel", type: "NaturalPerson" },
    // { label: "Promoteur des Ventes", type: "NaturalPerson" },
    // { label: "Responsable Merchandising", type: "NaturalPerson" },
    // { label: "Property Consultant", type: "NaturalPerson" },
    // { label: "Responsable Moyens Généraux", type: "NaturalPerson" },
    // { label: "Prospecteur Foncier", type: "NaturalPerson" },
    // { label: "Responsable Opérationnel d´Activité", type: "NaturalPerson" },
    // { label: "Prospecteur Immobilier", type: "NaturalPerson" },
    // { label: "Responsable Ordonnancement Lancement", type: "NaturalPerson" },
    // { label: "Prothésiste", type: "NaturalPerson" },
    // { label: "Responsable Ordonnancement Planification...", type: "NaturalPerson" },
    // { label: "Prothésiste Céramiste", type: "NaturalPerson" },
    // { label: "Responsable Paie", type: "NaturalPerson" },
    // { label: "Prothésiste Dentaire", type: "NaturalPerson" },
    // { label: "Responsable Parc Automobile", type: "NaturalPerson" },
    // { label: "Proviseur", type: "NaturalPerson" },
    // { label: "Responsable Parc Informatique", type: "NaturalPerson" },
    // { label: "Proviseur de Lycée", type: "NaturalPerson" },
    // { label: "Responsable Partenariats", type: "NaturalPerson" },
    // { label: "Psychanalyste", type: "NaturalPerson" },
    // { label: "Responsable Pédagogique", type: "NaturalPerson" },
    // { label: "Psychologue", type: "NaturalPerson" },
    // { label: "Responsable Pilotage de Projets", type: "NaturalPerson" },
    // { label: "Psychologue Clinicien", type: "NaturalPerson" },
    // { label: "Responsable Planification", type: "NaturalPerson" },
    // { label: "Psychologue du Travail", type: "NaturalPerson" },
    // { label: "Responsable Plateau", type: "NaturalPerson" },
    // { label: "Psychothérapeute", type: "NaturalPerson" },
    // { label: "Responsable Point de Vente", type: "NaturalPerson" },
    // { label: "Puéricultrice", type: "NaturalPerson" },
    // { label: "Responsable Pôle Compétences", type: "NaturalPerson" },
    // { label: "Qualiticien", type: "NaturalPerson" },
    // { label: "Responsable Promotion Touristique", type: "NaturalPerson" },
    // { label: "Radiologue", type: "NaturalPerson" },
    // { label: "Responsable Prospection", type: "NaturalPerson" },
    // { label: "Radioprotectionniste", type: "NaturalPerson" },
    // { label: "Responsable QHSE", type: "NaturalPerson" },
    // { label: "Réalisateur 3D", type: "NaturalPerson" },
    // { label: "Responsable Qualité", type: "NaturalPerson" },
    // { label: "Réalisateur de Films", type: "NaturalPerson" },
    // { label: "Responsable Qualité et Process", type: "NaturalPerson" },
    // { label: "Réalisateur TV", type: "NaturalPerson" },
    // { label: "Responsable Qualité Usine", type: "NaturalPerson" },
    // { label: "Réceptionnaire Automobile", type: "NaturalPerson" },
    // { label: "Responsable Rayon Fruits Légumes", type: "NaturalPerson" },
    // { label: "Réceptionniste Hôtel", type: "NaturalPerson" },
    // { label: "Responsable Recherche et Développement...", type: "NaturalPerson" },
    // { label: "Receveur", type: "NaturalPerson" },
    // { label: "Responsable Recouvrement", type: "NaturalPerson" },
    // { label: "Rédacteur", type: "NaturalPerson" },
    // { label: "Responsable Recrutement", type: "NaturalPerson" },
    // { label: "Rédacteur de Scripts", type: "NaturalPerson" },
    // { label: "Responsable Référencement SEO SEM", type: "NaturalPerson" },
    // { label: "Rédacteur en Chef", type: "NaturalPerson" },
    // { label: "Responsable Régional", type: "NaturalPerson" },
    // { label: "Rédacteur Gestionnaire en Assurance", type: "NaturalPerson" },
    // { label: "Responsable Régional des Ventes", type: "NaturalPerson" },
    // { label: "Rédacteur Scientifique", type: "NaturalPerson" },
    // { label: "Responsable Relations Partenaires...", type: "NaturalPerson" },
    // { label: "Rédacteur Sinistres", type: "NaturalPerson" },
    // { label: "Responsable Relations Publiques", type: "NaturalPerson" },
    // { label: "Rédacteur Technique", type: "NaturalPerson" },
    // { label: "Responsable Relations Sociales", type: "NaturalPerson" },
    // { label: "Rédacteur Technique de Documentation", type: "NaturalPerson" },
    // { label: "Responsable Ressources Humaines", type: "NaturalPerson" },
    // { label: "Rédacteur Web", type: "NaturalPerson" },
    // { label: "Responsable Salle de Jeux", type: "NaturalPerson" },
    // { label: "Rééducateur", type: "NaturalPerson" },
    // { label: "Responsable Salle de Sport", type: "NaturalPerson" },
    // { label: "Référenceur", type: "NaturalPerson" },
    // { label: "Responsable Salle des Marchés", type: "NaturalPerson" },
    // { label: "Référent Technique", type: "NaturalPerson" },
    // { label: "Responsable Sécurité", type: "NaturalPerson" },
    // { label: "Régisseur", type: "NaturalPerson" },
    // { label: "Responsable Sécurité des SI", type: "NaturalPerson" },
    // { label: "Régisseur Audiovisuel", type: "NaturalPerson" },
    // { label: "Responsable Sécurité Informatique", type: "NaturalPerson" },
    // { label: "Régisseur de Spectacles", type: "NaturalPerson" },
    // { label: "Responsable Service Après-Vente", type: "NaturalPerson" },
    // { label: "Régleur", type: "NaturalPerson" },
    // { label: "Responsable Service Clients", type: "NaturalPerson" },
    // { label: "Régleur de Machines", type: "NaturalPerson" },
    // { label: "Responsable Services Généraux", type: "NaturalPerson" },
    // { label: "Régleur de Machines à Coudre", type: "NaturalPerson" },
    // { label: "Responsable Services Techniques", type: "NaturalPerson" },
    // { label: "Régleur Machines Outils", type: "NaturalPerson" },
    // { label: "Responsable Showroom", type: "NaturalPerson" },
    // { label: "Régleur Moule et Machine d´Injection...", type: "NaturalPerson" },
    // { label: "Responsable Sourcing", type: "NaturalPerson" },
    // { label: "Régleur Plasturgie", type: "NaturalPerson" },
    // { label: "Responsable Stratégie Mobile", type: "NaturalPerson" },
    // { label: "Régleur Presse", type: "NaturalPerson" },
    // { label: "Responsable Surveillance et Sécurité", type: "NaturalPerson" },
    // { label: "Régleur Sinistre", type: "NaturalPerson" },
    // { label: "Responsable Technique", type: "NaturalPerson" },
    // { label: "Régulateur", type: "NaturalPerson" },
    // { label: "Responsable Transit", type: "NaturalPerson" },
    // { label: "Relecteur", type: "NaturalPerson" },
    // { label: "Responsable Transport", type: "NaturalPerson" },
    // { label: "Relieur Doreur", type: "NaturalPerson" },
    // { label: "Responsable Trésorerie", type: "NaturalPerson" },
    // { label: "Réparateur Electroménager", type: "NaturalPerson" },
    // { label: "Restaurateur", type: "NaturalPerson" },
    // { label: "Reporter", type: "NaturalPerson" },
    // { label: "Retoucheur", type: "NaturalPerson" },
    // { label: "Reporter Radio", type: "NaturalPerson" },
    // { label: "Retoucheur Photo", type: "NaturalPerson" },
    // { label: "Représentant", type: "NaturalPerson" },
    // { label: "Revenue Manager", type: "NaturalPerson" },
    // { label: "Représentant Commercial", type: "NaturalPerson" },
    // { label: "Réviseur Comptable", type: "NaturalPerson" },
    // { label: "Sage Femme", type: "NaturalPerson" },
    // { label: "Technicien Laboratoire Granulats Béton...", type: "NaturalPerson" },
    // { label: "Salesman", type: "NaturalPerson" },
    // { label: "Technicien Laboratoire Industrie", type: "NaturalPerson" },
    // { label: "Sauveteur", type: "NaturalPerson" },
    // { label: "Technicien Laboratoires d´Analyses", type: "NaturalPerson" },
    // { label: "Scaphandrier", type: "NaturalPerson" },
    // { label: "Technicien Laiterie", type: "NaturalPerson" },
    // { label: "Scénariste", type: "NaturalPerson" },
    // { label: "Technicien Ligne à Haute Tension", type: "NaturalPerson" },
    // { label: "Scieur", type: "NaturalPerson" },
    // { label: "Technicien Livraison", type: "NaturalPerson" },
    // { label: "Sculpteur", type: "NaturalPerson" },
    // { label: "Technicien Logistique", type: "NaturalPerson" },
    // { label: "Sculpteur sur Pierre", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Aéronautique", type: "NaturalPerson" },
    // { label: "Secrétaire", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Automatisme", type: "NaturalPerson" },
    // { label: "Secrétaire Administratif", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Automobile", type: "NaturalPerson" },
    // { label: "Secrétaire Bilingue", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Avion", type: "NaturalPerson" },
    // { label: "Secrétaire Commercial", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Biomédicale", type: "NaturalPerson" },
    // { label: "Secrétaire Comptable", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Chauffage...", type: "NaturalPerson" },
    // { label: "Secrétaire de Direction", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Climatique", type: "NaturalPerson" },
    // { label: "Secrétaire de Rédaction", type: "NaturalPerson" },
    // { label: "Technicien Maintenance de Machines Outils...", type: "NaturalPerson" },
    // { label: "Secrétaire Général", type: "NaturalPerson" },
    // { label: "Technicien Maintenance d´Eoliennes", type: "NaturalPerson" },
    // { label: "Secrétaire Juridique", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Electricité", type: "NaturalPerson" },
    // { label: "Secrétaire Médicale", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Electricité...", type: "NaturalPerson" },
    // { label: "Secrétaire Paie et Facturation", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Electromécaniques...", type: "NaturalPerson" },
    // { label: "Secrétaire Polyvalent", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Electronique", type: "NaturalPerson" },
    // { label: "Secrétaire Réceptionniste", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Industrielle", type: "NaturalPerson" },
    // { label: "Secrétaire Standardiste", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Itinérant", type: "NaturalPerson" },
    // { label: "Secrétaire Technique", type: "NaturalPerson" },
    // { label: "Technicien Maintenance Mécanique", type: "NaturalPerson" },
    // { label: "Sérigraphe", type: "NaturalPerson" },
    // { label: "Technicien Mécanique", type: "NaturalPerson" },
    // { label: "Serrurier", type: "NaturalPerson" },
    // { label: "Technicien Médical", type: "NaturalPerson" },
    // { label: "Sertisseur", type: "NaturalPerson" },
    // { label: "Technicien Menuiserie", type: "NaturalPerson" },
    // { label: "Sertisseur en Joaillerie", type: "NaturalPerson" },
    // { label: "Technicien Mesure et Régulation", type: "NaturalPerson" },
    // { label: "Serveur", type: "NaturalPerson" },
    // { label: "Technicien Mesures 3D", type: "NaturalPerson" },
    // { label: "Serveur en Restaurant", type: "NaturalPerson" },
    // { label: "Technicien Métallurgie", type: "NaturalPerson" },
    // { label: "Sociologue", type: "NaturalPerson" },
    // { label: "Technicien Météo", type: "NaturalPerson" },
    // { label: "Sommelier", type: "NaturalPerson" },
    // { label: "Technicien Méthodes", type: "NaturalPerson" },
    // { label: "Soudeur", type: "NaturalPerson" },
    // { label: "Technicien Méthodes Maintenance", type: "NaturalPerson" },
    // { label: "Soudeur Arc", type: "NaturalPerson" },
    // { label: "Technicien Méthodes Ordonnancement", type: "NaturalPerson" },
    // { label: "Soudeur Inox", type: "NaturalPerson" },
    // { label: "Technicien Métrologue", type: "NaturalPerson" },
    // { label: "Soudeur Semi Automatique", type: "NaturalPerson" },
    // { label: "Technicien Meunier", type: "NaturalPerson" },
    // { label: "Soudeur Tig", type: "NaturalPerson" },
    // { label: "Technicien Montage", type: "NaturalPerson" },
    // { label: "Soudeur Tig Arc", type: "NaturalPerson" },
    // { label: "Technicien Multimedia", type: "NaturalPerson" },
    // { label: "Sous-Chef de Cuisine", type: "NaturalPerson" },
    // { label: "Technicien Opérateur Régleur", type: "NaturalPerson" },
    // { label: "Souscripteur Assurance", type: "NaturalPerson" },
    // { label: "Technicien Optique", type: "NaturalPerson" },
    // { label: "Spécialiste SEO", type: "NaturalPerson" },
    // { label: "Technicien Ordonnancement Lancement", type: "NaturalPerson" },
    // { label: "Staff Consultant", type: "NaturalPerson" },
    // { label: "Technicien Outillage", type: "NaturalPerson" },
    // { label: "Standardiste", type: "NaturalPerson" },
    // { label: "Technicien Ouvrage d´Art", type: "NaturalPerson" },
    // { label: "Stratifieur Mouliste", type: "NaturalPerson" },
    // { label: "Technicien Panneaux à Led", type: "NaturalPerson" },
    // { label: "Styliste", type: "NaturalPerson" },
    // { label: "Technicien Paysagiste", type: "NaturalPerson" },
    // { label: "Styliste de Mode", type: "NaturalPerson" },
    // { label: "Technicien Photocopieur", type: "NaturalPerson" },
    // { label: "Superviseur Caisse", type: "NaturalPerson" },
    // { label: "Technicien Piscine", type: "NaturalPerson" },
    // { label: "Superviseur Centre d´Appels", type: "NaturalPerson" },
    // { label: "Technicien Plombier", type: "NaturalPerson" },
    // { label: "Superviseur Commercial", type: "NaturalPerson" },
    // { label: "Technicien Poids Lourds", type: "NaturalPerson" },
    // { label: "Superviseur de Vols", type: "NaturalPerson" },
    // { label: "Technicien Polyculture Elevage", type: "NaturalPerson" },
    // { label: "Superviseur HSE", type: "NaturalPerson" },
    // { label: "Technicien Polyvalent", type: "NaturalPerson" },
    // { label: "Superviseur Pricing", type: "NaturalPerson" },
    // { label: "Technicien Poterie", type: "NaturalPerson" },
    // { label: "Superviseur Production", type: "NaturalPerson" },
    // { label: "Technicien Production", type: "NaturalPerson" },
    // { label: "Superviseur Qualité", type: "NaturalPerson" },
    // { label: "Technicien Production Industries de...", type: "NaturalPerson" },
    // { label: "Support Applicatif", type: "NaturalPerson" },
    // { label: "Technicien Production Pharmaceutique", type: "NaturalPerson" },
    // { label: "Support Fonctionnel", type: "NaturalPerson" },
    // { label: "Technicien Qualité", type: "NaturalPerson" },
    // { label: "Support Technique", type: "NaturalPerson" },
    // { label: "Technicien Qualité Eau", type: "NaturalPerson" },
    // { label: "Support Utilisateur", type: "NaturalPerson" },
    // { label: "Technicien Radio", type: "NaturalPerson" },
    // { label: "Surveillant", type: "NaturalPerson" },
    // { label: "Technicien Radioprotection", type: "NaturalPerson" },
    // { label: "Surveillant de Nuit", type: "NaturalPerson" },
    // { label: "Technicien Recherche et Développement...", type: "NaturalPerson" },
    // { label: "Surveillant Général", type: "NaturalPerson" },
    // { label: "Technicien Régulation Industrielle", type: "NaturalPerson" },
    // { label: "Tailleur", type: "NaturalPerson" },
    // { label: "Technicien Réseaux", type: "NaturalPerson" },
    // { label: "Tapissier Sellier", type: "NaturalPerson" },
    // { label: "Technicien Robotique", type: "NaturalPerson" },
    // { label: "Team Leader", type: "NaturalPerson" },
    // { label: "Technicien SAV", type: "NaturalPerson" },
    // { label: "Technical Support Agent", type: "NaturalPerson" },
    // { label: "Technicien Scierie", type: "NaturalPerson" },
    // { label: "Technicien", type: "NaturalPerson" },
    // { label: "Technicien Son", type: "NaturalPerson" },
    // { label: "Technicien Acousticien", type: "NaturalPerson" },
    // { label: "Technicien Spécialisé", type: "NaturalPerson" },
    // { label: "Technicien Aéronautique", type: "NaturalPerson" },
    // { label: "Technicien Stérilisation", type: "NaturalPerson" },
    // { label: "Technicien Aérostructure", type: "NaturalPerson" },
    // { label: "Technicien Superviseur", type: "NaturalPerson" },
    // { label: "Technicien Agricole", type: "NaturalPerson" },
    // { label: "Technicien Support", type: "NaturalPerson" },
    // { label: "Technicien Agroalimentaire", type: "NaturalPerson" },
    // { label: "Technicien Support Technique", type: "NaturalPerson" },
    // { label: "Technicien Agronome", type: "NaturalPerson" },
    // { label: "Technicien Surface", type: "NaturalPerson" },
    // { label: "Technicien Aluminium", type: "NaturalPerson" },
    // { label: "Technicien Systèmes", type: "NaturalPerson" },
    // { label: "Technicien Analyses Biomédicales", type: "NaturalPerson" },
    // { label: "Technicien Systèmes de Sécurité", type: "NaturalPerson" },
    // { label: "Technicien Antenniste", type: "NaturalPerson" },
    // { label: "Technicien Systèmes Réseaux", type: "NaturalPerson" },
    // { label: "Technicien Ascenseur", type: "NaturalPerson" },
    // { label: "Technicien Textile", type: "NaturalPerson" },
    // { label: "Technicien Assemblage de Faisceaux", type: "NaturalPerson" },
    // { label: "Technicien Thermicien", type: "NaturalPerson" },
    // { label: "Technicien Audiovisuel", type: "NaturalPerson" },
    // { label: "Technicien Tissage", type: "NaturalPerson" },
    // { label: "Technicien Automaticien", type: "NaturalPerson" },
    // { label: "Technicien Topographe", type: "NaturalPerson" },
    // { label: "Technicien Automobile", type: "NaturalPerson" },
    // { label: "Technicien Traitement des Déchets", type: "NaturalPerson" },
    // { label: "Technicien Avant-Vente", type: "NaturalPerson" },
    // { label: "Technicien Traitement des Eaux", type: "NaturalPerson" },
    // { label: "Technicien Aviculteur", type: "NaturalPerson" },
    // { label: "Technicien Travaux", type: "NaturalPerson" },
    // { label: "Technicien Bâtiment", type: "NaturalPerson" },
    // { label: "Technicien Usinage", type: "NaturalPerson" },
    // { label: "Technicien Biochimiste", type: "NaturalPerson" },
    // { label: "Technicien Ventilation", type: "NaturalPerson" },
    // { label: "Technicien Biologiste", type: "NaturalPerson" },
    // { label: "Technicien Vérificateur d´Extincteurs...", type: "NaturalPerson" },
    // { label: "Technicien Biomédical", type: "NaturalPerson" },
    // { label: "Technicien Vétérinaire", type: "NaturalPerson" },
    // { label: "Technicien Bureau d´Etudes", type: "NaturalPerson" },
    // { label: "Technicien Videosurveillance", type: "NaturalPerson" },
    // { label: "Technicien Câblage", type: "NaturalPerson" },
    // { label: "Technico-Commercial", type: "NaturalPerson" },
    // { label: "Technicien Câblage Structuré", type: "NaturalPerson" },
    // { label: "Technico-Commercial Assurance", type: "NaturalPerson" },
    // { label: "Technicien Caméraman Monteur", type: "NaturalPerson" },
    // { label: "Technico-Commercial B to B", type: "NaturalPerson" },
    // { label: "Technicien CAO", type: "NaturalPerson" },
    // { label: "Technico-Commercial B to C", type: "NaturalPerson" },
    // { label: "Technicien Cartographe", type: "NaturalPerson" },
    // { label: "Technico-Commercial Bâtiment", type: "NaturalPerson" },
    // { label: "Technicien Catenaire", type: "NaturalPerson" },
    // { label: "Technico-Commercial Bois", type: "NaturalPerson" },
    // { label: "Technicien Chantier", type: "NaturalPerson" },
    // { label: "Technico-Commercial BTP", type: "NaturalPerson" },
    // { label: "Technicien Chaudronnier", type: "NaturalPerson" },
    // { label: "Technico-Commercial Sédentaire", type: "NaturalPerson" },
    // { label: "Technicien Chauffage", type: "NaturalPerson" },
    // { label: "Technico-Fonctionnel", type: "NaturalPerson" },
    // { label: "Technicien Chimiste", type: "NaturalPerson" },
    // { label: "Teinturier", type: "NaturalPerson" },
    // { label: "Technicien Circulation des Trains", type: "NaturalPerson" },
    // { label: "Teinturier Blanchisseur", type: "NaturalPerson" },
    // { label: "Technicien Compresseur", type: "NaturalPerson" },
    // { label: "Téléassistant", type: "NaturalPerson" },
    // { label: "Technicien Comptabilité Gestion", type: "NaturalPerson" },
    // { label: "Téléassistant Technique", type: "NaturalPerson" },
    // { label: "Technicien Comptable", type: "NaturalPerson" },
    // { label: "Téléconseiller", type: "NaturalPerson" },
    // { label: "Technicien Conception Mécanique", type: "NaturalPerson" },
    // { label: "Téléconseiller Appels Entrants", type: "NaturalPerson" },
    // { label: "Technicien Construction Métallique", type: "NaturalPerson" },
    // { label: "Téléconseiller Financier", type: "NaturalPerson" },
    // { label: "Technicien Contrôle Qualité", type: "NaturalPerson" },
    // { label: "Téléenquêteur", type: "NaturalPerson" },
    // { label: "Technicien Contrôle Qualité...", type: "NaturalPerson" },
    // { label: "Téléopérateur", type: "NaturalPerson" },
    // { label: "Technicien Contrôle Qualité Chimie", type: "NaturalPerson" },
    // { label: "Téléphoniste", type: "NaturalPerson" },
    // { label: "Technicien Contrôle Qualité Industrie...", type: "NaturalPerson" },
    // { label: "Téléprospecteur", type: "NaturalPerson" },
    // { label: "Technicien Courant Faible", type: "NaturalPerson" },
    // { label: "Télésecrétaire", type: "NaturalPerson" },
    // { label: "Technicien Cuisiniste", type: "NaturalPerson" },
    // { label: "Télévendeur", type: "NaturalPerson" },
    // { label: "Technicien de Laboratoire", type: "NaturalPerson" },
    // { label: "Testeur Applicatif", type: "NaturalPerson" },
    // { label: "Technicien de Maintenance", type: "NaturalPerson" },
    // { label: "Testeur Fonctionnel", type: "NaturalPerson" },
    // { label: "Technicien de Paie", type: "NaturalPerson" },
    // { label: "Testeur Recetteur", type: "NaturalPerson" },
    // { label: "Technicien de Proximité", type: "NaturalPerson" },
    // { label: "Testeur Technique", type: "NaturalPerson" },
    // { label: "Technicien de Transport", type: "NaturalPerson" },
    // { label: "Thermicien", type: "NaturalPerson" },
    // { label: "Technicien Dentaire", type: "NaturalPerson" },
    // { label: "Tisserand", type: "NaturalPerson" },
    // { label: "Technicien Dessinateur Projeteur", type: "NaturalPerson" },
    // { label: "Topographe", type: "NaturalPerson" },
    // { label: "Technicien Développement Carroserie...", type: "NaturalPerson" },
    // { label: "Tourneur", type: "NaturalPerson" },
    // { label: "Technicien Développement Informatique...", type: "NaturalPerson" },
    // { label: "Tourneur Fraiseur", type: "NaturalPerson" },
    // { label: "Technicien Diagnostic Automobile", type: "NaturalPerson" },
    // { label: "Trade Marketing Manager", type: "NaturalPerson" },
    // { label: "Technicien Documentation", type: "NaturalPerson" },
    // { label: "Traducteur", type: "NaturalPerson" },
    // { label: "Technicien Eclairagiste", type: "NaturalPerson" },
    // { label: "Traducteur Technique", type: "NaturalPerson" },
    // { label: "Technicien Electricité", type: "NaturalPerson" },
    // { label: "Traffic Manager", type: "NaturalPerson" },
    // { label: "Technicien Electromécanicien", type: "NaturalPerson" },
    // { label: "Travailleur Social", type: "NaturalPerson" },
    // { label: "Technicien Electroménager", type: "NaturalPerson" },
    // { label: "Trésorier", type: "NaturalPerson" },
    // { label: "Technicien Electronicien", type: "NaturalPerson" },
    // { label: "Tuyauteur", type: "NaturalPerson" },
    // { label: "Technicien Electrotechnique", type: "NaturalPerson" },
    // { label: "Tuyauteur Aéronautique", type: "NaturalPerson" },
    // { label: "Technicien Elevage", type: "NaturalPerson" },
    // { label: "Tuyauteur Industriel", type: "NaturalPerson" },
    // { label: "Technicien Elevage de Chevaux", type: "NaturalPerson" },
    // { label: "Urbaniste", type: "NaturalPerson" },
    // { label: "Technicien en Agriculture", type: "NaturalPerson" },
    // { label: "Urbaniste Système d´Information SI", type: "NaturalPerson" },
    // { label: "Technicien en Architecture", type: "NaturalPerson" },
    // { label: "Valet de Chambre", type: "NaturalPerson" },
    // { label: "Technicien en Confiserie Industrielle...", type: "NaturalPerson" },
    // { label: "Valet de Lieu Public", type: "NaturalPerson" },
    // { label: "Technicien en Fluides de Construction...", type: "NaturalPerson" },
    // { label: "Veilleur Stratégique", type: "NaturalPerson" },
    // { label: "Technicien en Gestion des Entreprises...", type: "NaturalPerson" },
    // { label: "Veilleur Technologique", type: "NaturalPerson" },
    // { label: "Technicien en Impression Numérique", type: "NaturalPerson" },
    // { label: "Vendeur", type: "NaturalPerson" },
    // { label: "Technicien en Maintenance de Matériel de...", type: "NaturalPerson" },
    // { label: "Vendeur à Domicile", type: "NaturalPerson" },
    // { label: "Technicien en Matériaux Composite", type: "NaturalPerson" },
    // { label: "Vendeur Alimentation Générale", type: "NaturalPerson" },
    // { label: "Technicien Entretien Articles Textiles...", type: "NaturalPerson" },
    // { label: "Vendeur Animalier", type: "NaturalPerson" },
    // { label: "Technicien Environnement", type: "NaturalPerson" },
    // { label: "Vendeur Articles de Luxe", type: "NaturalPerson" },
    // { label: "Technicien Equipement", type: "NaturalPerson" },
    // { label: "Vendeur Articles de Sport", type: "NaturalPerson" },
    // { label: "Technicien Equipement Electrique...", type: "NaturalPerson" },
    // { label: "Vendeur Biens Equipement", type: "NaturalPerson" },
    // { label: "Technicien Essais", type: "NaturalPerson" },
    // { label: "Vendeur Boulangerie Patisserie", type: "NaturalPerson" },
    // { label: "Technicien Essais Mécaniques", type: "NaturalPerson" },
    // { label: "Vendeur Comptoir", type: "NaturalPerson" },
    // { label: "Technicien Etudes", type: "NaturalPerson" },
    // { label: "Vendeur Conseil", type: "NaturalPerson" },
    // { label: "Technicien Etudes Bâtiment", type: "NaturalPerson" },
    // { label: "Vendeur Conseil en Matériel Agricole", type: "NaturalPerson" },
    // { label: "Technicien Etudes de Prix", type: "NaturalPerson" },
    // { label: "Vendeur Conseil en Pièces Détachées", type: "NaturalPerson" },
    // { label: "Technicien Etudes en Electricité", type: "NaturalPerson" },
    // { label: "Vendeur Cuisine Equipée", type: "NaturalPerson" },
    // { label: "Technicien Etudes Génie Climatique", type: "NaturalPerson" },
    // { label: "Vendeur de Livres", type: "NaturalPerson" },
    // { label: "Technicien Exploitation", type: "NaturalPerson" },
    // { label: "Vendeur de Luxe", type: "NaturalPerson" },
    // { label: "Technicien Exploitation de Transport", type: "NaturalPerson" },
    // { label: "Vendeur de Matériel de Jardinage", type: "NaturalPerson" },
    // { label: "Technicien Fabrication", type: "NaturalPerson" },
    // { label: "Vendeur Détail", type: "NaturalPerson" },
    // { label: "Technicien Fabrication Chimie", type: "NaturalPerson" },
    // { label: "Vendeur Distributeur", type: "NaturalPerson" },
    // { label: "Technicien Fabrication Industrie...", type: "NaturalPerson" },
    // { label: "Vendeur Electroménager", type: "NaturalPerson" },
    // { label: "Technicien Fabrication Mécanique", type: "NaturalPerson" },
    // { label: "Vendeur en Equipement de la Personne", type: "NaturalPerson" },
    // { label: "Technicien Fibre Optique", type: "NaturalPerson" },
    // { label: "Vendeur en Librairie", type: "NaturalPerson" },
    // { label: "Technicien Fluides", type: "NaturalPerson" },
    // { label: "Vendeur en Magasin", type: "NaturalPerson" },
    // { label: "Technicien Foncier", type: "NaturalPerson" },
    // { label: "Vendeur en Magasin Spécialisé", type: "NaturalPerson" },
    // { label: "Technicien Forestier", type: "NaturalPerson" },
    // { label: "Vendeur en Outillage", type: "NaturalPerson" },
    // { label: "Technicien Frigoriste", type: "NaturalPerson" },
    // { label: "Vendeur en Téléphonie", type: "NaturalPerson" },
    // { label: "Technicien Froid Climatisation", type: "NaturalPerson" },
    // { label: "Vendeur Espaces Publicitaires", type: "NaturalPerson" },
    // { label: "Technicien Génie Civil", type: "NaturalPerson" },
    // { label: "Vendeur Fournitures", type: "NaturalPerson" },
    // { label: "Technicien Génie Climatique", type: "NaturalPerson" },
    // { label: "Vendeur Instruments de Musique", type: "NaturalPerson" },
    // { label: "Technicien Géomatique", type: "NaturalPerson" },
    // { label: "Vendeur Jouets", type: "NaturalPerson" },
    // { label: "Technicien Help Desk", type: "NaturalPerson" },
    // { label: "Vendeur Matériel Agricole", type: "NaturalPerson" },
    // { label: "Technicien HSE", type: "NaturalPerson" },
    // { label: "Vendeur Outillage Industriel", type: "NaturalPerson" },
    // { label: "Technicien Imprimante Copieur Laser", type: "NaturalPerson" },
    // { label: "Vendeur Outils Bricolage", type: "NaturalPerson" },
    // { label: "Technicien Industrialisation", type: "NaturalPerson" },
    // { label: "Vendeur Préparateur en Boulangerie", type: "NaturalPerson" },
    // { label: "Technicien Industrie Agroalimentaire", type: "NaturalPerson" },
    // { label: "Vendeur Produits de Beauté Cosmétiques...", type: "NaturalPerson" },
    // { label: "Technicien Industrie du Verre", type: "NaturalPerson" },
    // { label: "Vendeur Réseau Traditionnel", type: "NaturalPerson" },
    // { label: "Technicien Industries Graphiques", type: "NaturalPerson" },
    // { label: "Vendeur Spécialisé en Bricolage", type: "NaturalPerson" },
    // { label: "Technicien Inspection Electrique", type: "NaturalPerson" },
    // { label: "Vernisseur Laqueur", type: "NaturalPerson" },
    // { label: "Technicien Installateur Systèmes...", type: "NaturalPerson" },
    // { label: "Vernisseur sur Bois", type: "NaturalPerson" },
    // { label: "Technicien Installation de Matériels...", type: "NaturalPerson" },
    // { label: "Vétérinaire", type: "NaturalPerson" },
    // { label: "Technicien Installations", type: "NaturalPerson" },
    // { label: "Vétérinaire Equin", type: "NaturalPerson" },
    // { label: "Technicien Instrumentation", type: "NaturalPerson" },
    // { label: "Vigile", type: "NaturalPerson" },
    // { label: "Technicien Intervention Sociale et...", type: "NaturalPerson" },
    // { label: "Visiteur Médical", type: "NaturalPerson" },
    // { label: "Technicien Laboratoire Agroalimentaire...", type: "NaturalPerson" },
    // { label: "Visual Merchandiser", type: "NaturalPerson" },
    // { label: "Technicien Laboratoire Chimie", type: "NaturalPerson" },
    // { label: "Vitrier", type: "NaturalPerson" },
    // { label: "Technicien Laboratoire de Contrôle des...", type: "NaturalPerson" },
    // { label: "Vitrier Miroitier", type: "NaturalPerson" },
    // { label: "Technicien Laboratoire de Recherche", type: "NaturalPerson" },
    // { label: "Voiturier", type: "NaturalPerson" },
    // { label: "Technicien Laboratoire de Recherche des...", type: "NaturalPerson" },
    // { label: "Webdesigner", type: "NaturalPerson" },
    // { label: "Technicien Laboratoire d´Analyse des Eaux...", type: "NaturalPerson" },
    // { label: "Webmarketer", type: "NaturalPerson" },
    // { label: "Technicien Laboratoire d´Analyses...", type: "NaturalPerson" },
    // { label: "Webmaster", type: "NaturalPerson" },
    // { label: "Étudiant	", type: "NaturalPerson" },
    // { label: "Entrepreuneur	", type: "NaturalPerson" },
    // { label: "Ménagère	", type: "NaturalPerson" },
    // { label: "Femme au Foyer	", type: "NaturalPerson" },
    // { label: "Gestionnaire	", type: "NaturalPerson" },
    // { label: "Gestionnaire des Resssoureces Humaines	", type: "NaturalPerson" },
    // { label: "Sans Emploi	", type: "NaturalPerson" },
    // { label: "Fonctionnaire	", type: "NaturalPerson" }    
  ]
};

const PanelistReducer = function (state = initialState, action) {
  switch (action.type) {
    // Alert action
    case SHOW_ALERT: {
      return {
        ...state,
        showAlert: true,
        alertMessage: action.data,
        alertVariant: "success"
      };
    }
    case HIDE_ALERT: {
      return {
        ...state,
        showAlert: false,
        alertMessage: "",
        alertVariant: ""
      };
    }
    case PANELIST_ACTION_SUBMITTING: {
      return {
        ...state,
        submitting: true
      };
    }
    case PANELIST_ACTION_SUBMITED: {
      return {
        ...state,
        submitting: false
      };
    }
    case PANELIST_ACTION_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case PANELIST_ACTION_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }
    case PANELIST_ACTION_ERROR: {
      return {
        ...state,
        failed: true,
        success: false,
        loading: false,
        showAlert: true,
        alertMessage: action.data,
        alertVariant: "error"
      };
    }
    case PANELIST_ADVANCEDSEARCH_TABLE_CONTENT_LOADING: {
      return {
        ...state,
        panelistAdvancedSearchTableContentLoading: true
      };
    }
    case PANELIST_ADVANCEDSEARCH_TABLE_CONTENT_LOADED: {
      return {
        ...state,
        panelistAdvancedSearchTableContentLoading: false
      };
    }
    case GET_PANELIST: {
      return {
        ...state,
        currentPanelist: { ...action.data }
      };
    }
    case GET_PANELIST_LIST: {
      return {
        ...state,
        panelistList: [...action.data],
        panelistAsLegalPersonList: [...action.data.filter(p => p.Type == "LegalPerson")],
        panelistAsNaturalPersonList: [...action.data.filter(p => p.Type == "NaturalPerson")]
      };
    }
    case ADD_PANELIST_TO_LIST: {
      return {
        ...state,
        currentPanelist: { ...action.data },
        showAddedAlert: true,
        showUpdatedAlert: false,
        showDeletedAlert: false,
        submitting: false
      };
    }
    case DELETE_PANELIST_FROM_LIST: {
      return {
        ...state,
        panelistList: [...action.data],
        showDeletedAlert: true,
        showAddedAlert: false,
        showUpdatedAlert: false,
        submitting: false
      };
    }
    case UPDATE_PANELIST: {
      return {
        ...state,
        currentPanelist: { ...action.data },
        showUpdatedAlert: true,
        showAddedAlert: false,
        showDeletedAlert: false,
        submitting: false
      };
    }
    case GET_REGIONLIST: {
      return {
        ...state,
        regionList: [...action.data]
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default PanelistReducer;