import React from "react";

const PanelistGroupRoutes = [
    {
        path: "/panelist/groups",
        component: React.lazy(() => import("./PanelistGroupPage"))
    },
    {
        path: "/panelistgroup/detail/:id",
        component: React.lazy(() => import("./PanelistGroupDetail")),
    },
]
export default PanelistGroupRoutes;