import React from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Grid, CircularProgress, TextField, Icon } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { deleteRole, setTableContentLoading } from "app/redux/actions/RoleActions";


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6" className="delete-h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

class DeleteRole extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            role: {
                RoleId: "",
                Name: ""
            },
            RoleOptionList: []
        }


        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    componentDidMount() {
        if (this.props.roleList && this.props.roleList.length > 0) {
            this.getOptionsFromRoleList(this.props.roleList);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.storeRole.roleList != this.props.storeRole.roleList) {
            this.getOptionsFromRoleList(nextProps.storeRole.roleList);
        }
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    getOptionsFromRoleList = (roles) => {
        if (roles != undefined && roles.length > 0) {
            let result = [];
            roles.forEach(element => {
                if (element.Name !== "Administrateur") {
                    result.push({ label: element.Name, id: element.RoleId });
                }
            });
            this.setState({ RoleOptionList: result });
        }
    }

    handleSelectListChange = (event, value) => {
        if (value != undefined && value != null) {
            let roleCopy = this.state.role;
            roleCopy.RoleId = value.id;
            roleCopy.Name = value.label;
            this.setState({ role: roleCopy });
        }
    }

    handleConfirm = (e, value) => {
        this.props.deleteRole(value).then(data => {
            if (data.Success) {
                this.handleClose();
                this.getOptionsFromRoleList(this.props.roleList);
                this.props.setTableContentLoading(true);
                setTimeout(() => {
                    this.props.setTableContentLoading(false);
                }, 700);
            }
        });
    };


    render() {
        let { RoleId, Name } = this.state.role;
        return (
            <div>
                <IconButton
                    // variant="outlined"
                    color="primary"
                    className="btn-primary"
                    onClick={this.handleClickOpen}
                    title="Supprimer"
                >
                    <Icon>delete</Icon>
                </IconButton>
                <Dialog
                    disableBackdropClick
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                    fullWidth
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Supprimer un rôle
                    </DialogTitle>
                    <DialogContent dividers>
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit}
                            onError={errors => null}
                            style={{ marginTop: "10px" }}
                        >
                            <Grid container spacing={12}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        className="mb-16 w-100"
                                        options={this.state.RoleOptionList}
                                        getOptionLabel={option => option.label}
                                        onChange={(event, value) => this.handleSelectListChange(event, value)}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Liste de rôles"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" disabled={this.props.storeRole.submitting}>
                            Non
                        </Button>
                        <Button onClick={(e) => this.handleConfirm(e, this.state.role)} color="secondary" variant="contained" disabled={this.props.storeRole.submitting}>
                            Oui {this.props.storeRole.submitting ? <CircularProgress size={20} /> : <span></span>}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

//export default CreateEntityDialog;

const mapStateToProps = state => ({
    deleteRole: PropTypes.func.isRequired,
    setTableContentLoading: PropTypes.func.isRequired,
    storeRole: state.role,
});
export default (
    withRouter(connect(mapStateToProps, { deleteRole, setTableContentLoading })(DeleteRole))
);