import React, { useEffect } from "react";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRoleList, } from "app/redux/actions/RoleActions";
import { getGroupList, hideAlert, createGroup, deleteGroup } from "app/redux/actions/PanelistGroupActions";
import { Table, TableBody, TableCell, TableRow, TableHead, CircularProgress, TablePagination, Popover, Checkbox } from '@material-ui/core';
import { SimpleCard, Breadcrumb } from 'matx';
import SnackbarContentWrapper from "../alert/SnackbarContentWrapper"
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import moment from 'moment';
import 'moment/locale/fr';
import CreatePanelistGroup from "./dialog/CreatePanelistGroup";
import { Link } from "react-router-dom";
import { Icon } from "@material-ui/core";
import DeletePanelistGroup from "./dialog/DeletePanelistGroup";

const useStyles2 = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1)
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

let panelistGroupLoaded = false;

function PanelistGroupPage(props) {
    const {
        theme,
        storeUser = {},
        storePanelistGroup = {},
        getGroupList,
        createGroup,
        deleteGroup,
        hideAlert,
    } = props;

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const classes = useStyles2();
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [permissions, setPermissions] = React.useState([]);
    const [panelistGroupList, setPanelistGroupList] = React.useState([]);

    const openPop = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (storePanelistGroup.panelistGroupList.length == 0 && panelistGroupList.length == 0) {
            getGroupList().then(response => {
                if (response) {
                    setPanelistGroupList(response);
                }
            });
        }
        else if (storePanelistGroup.panelistGroupList.length > 0 && panelistGroupList.length == 0) {
            setPanelistGroupList(storePanelistGroup.panelistGroupList);
        }
    }, [])

    useEffect(() => {
        if (storeUser.currentUser.Permissions) {
            setPermissions(storeUser.currentUser.Permissions);
        }
        if (storeUser.loading != isLoading) {
            setIsLoading(storeUser.loading)
        }
    }, [storeUser])

    useEffect(() => {
        if (storePanelistGroup.showAlert) {
            setMessage(storePanelistGroup.alertMessage);
            setOpen(true);
            setTimeout(() => {
                hideAlert();
            }, 15000);
        }
        if (storePanelistGroup.loading != isLoading) {
            setIsLoading(storePanelistGroup.loading);
        }

    }, [storePanelistGroup])

    function handleClose(event) {
        setOpen(false);
        hideAlert();
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
    };

    let handleCreatePanelistGroup = (panelistGroup) => {
        return new Promise((resolve, reject) => {
            createGroup(panelistGroup).then(response => {
                if (response.Success) {
                    setPanelistGroupList(response.PanelistGroupList);
                    resolve(response);
                }
            })
        });
    }

    let handleDeletePanelistGroup = (panelistGroupId) => {
        return new Promise((resolve, reject) => {
            deleteGroup(panelistGroupId).then(response => {
                if (response.Success) {
                    setPanelistGroupList(response.PanelistGroupList);
                    resolve(response);
                }
            })
        });
    }


    return (
        <div className="m-sm-30">
            {isLoading ? (
                <div style={{ margin: "auto", textAlign: "center", fontSize: "18px", width: "50%", height: "50%", marginTop: "180px" }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {storePanelistGroup.showAlert && (
                        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                        >
                            <SnackbarContentWrapper onClose={handleClose} variant={storePanelistGroup.alertVariant} message={storePanelistGroup.alertMessage} />
                        </Snackbar>
                    )}
                    <div className="mb-sm-30">
                        <Breadcrumb routeSegments={[{ name: "Liste des groupes" }]} />
                    </div>
                    <SimpleCard title="Liste des groupes"
                    // icon={<span style={{ float: "right" }}>
                    //     {permissions.some(item => item == "AllowedToCreatePanelistGroup") && (
                    //         <CreatePanelistGroup onCreatePanelistGroup={handleCreatePanelistGroup} />
                    //     )}
                    // </span>}
                    >
                        <Table className="product-table" style={{ width: "100%", whiteSpace: "nowrap" }}>
                            <TableHead>
                                <TableRow>

                                    <TableCell style={{ width: "20%" }}>Nom</TableCell>
                                    <TableCell style={{ width: "28%" }}>Description</TableCell>
                                    <TableCell style={{ width: "20%" }}>Crée par </TableCell>
                                    <TableCell style={{ width: "15%" }}>Nombre de panélistes</TableCell>
                                    <TableCell style={{ width: "2%" }}></TableCell>
                                    <TableCell style={{ width: "2%" }}></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {panelistGroupList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((panelistgroup, index) =>
                                (
                                    <TableRow key={index}>
                                        <TableCell >{panelistgroup.Name}</TableCell>
                                        <TableCell >{panelistgroup.Description}</TableCell>
                                        <TableCell >{panelistgroup.Author}</TableCell>
                                        <TableCell>
                                            {panelistgroup.Panelists.length}

                                        </TableCell>
                                        <TableCell>
                                            {permissions?.some(item => item == "AllowedToUpdatePanelistGroup") && (
                                                <div>
                                                    <Link to={"/panelistgroup/detail/" + panelistgroup.PanelistGroupId} title="Modifier un groupe de panéliste">
                                                        <Icon color="primary">edit</Icon>
                                                    </Link>
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {permissions?.some(item => item == "AllowedToDeletePanelistGroup") && (
                                                <DeletePanelistGroup
                                                    panelistGroupToDelete={panelistgroup}
                                                    onDeletePanelistGroup={handleDeletePanelistGroup}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>

                                ))
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            className="px-4"
                            rowsPerPageOptions={[25]}
                            component="div"
                            count={panelistGroupList?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "Previous Page"
                            }}
                            nextIconButtonProps={{
                                "aria-label": "Next Page"
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </SimpleCard>
                </>
            )}

        </div>
    );
}

PanelistGroupPage.propTypes = {
    hideAlert: PropTypes.func.isRequired,
    storePanelistGroup: PropTypes.object.isRequired,
    storeUser: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getGroupList: PropTypes.func.isRequired,
    createGroup: PropTypes.func.isRequired,
    deleteGroup: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    storePanelistGroup: state.panelistgroup,
    storeUser: state.user,
});
export default withStyles({}, { withTheme: true })(connect(mapStateToProps,
    {
        getGroupList,
        getRoleList,
        createGroup,
        deleteGroup,
        hideAlert
    })(PanelistGroupPage));
