import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import utilitiesRoutes from "./views/utilities/UtilitiesRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";

import materialRoutes from "./views/material-kit/MaterialRoutes";
import dragAndDropRoute from "./views/Drag&Drop/DragAndDropRoute";

import formsRoutes from "./views/forms/FormsRoutes";
import mapRoutes from "./views/map/MapRoutes";

import UserRoutes from "./views/account/UserRoutes";
import RoleRoutes from "./views/roles/RoleRoutes";
import PanelistRoutes from "./views/panelists/PanelistRoot";
import PanelistGroupRoutes from "./views/groups/PanelistGroupRoutes";
import PanelistCategoryRoutes from "./views/panelistCategories/PanelistCategoryRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard/analytics" />
  },
  {
    path: "/account/roles",
    exact: true,
    component: () => <Redirect to="/account/roles" />
  },
  {
    path: "/account/users",
    exact: true,
    component: () => <Redirect to="/account/users" />
  },
  {
    path: "/panelist/AdvancedSearch",
    exact: true,
    component: () => <Redirect to="/panelist/AdvancedSearch" />
  },
  {
    path: "/category/list",
    exact: true,
    component: () => <Redirect to="/category/list" />
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionRoutes,
  ...dashboardRoutes,
  ...UserRoutes,
  ...RoleRoutes,
  ...materialRoutes,
  ...utilitiesRoutes,
  ...dragAndDropRoute,
  ...formsRoutes,
  ...mapRoutes,
  ...redirectRoute,
  ...errorRoute,
  ...PanelistRoutes,
  ...PanelistGroupRoutes,
  ...PanelistCategoryRoutes
];

export default routes;
