import axios from "axios";
import { api } from "app/services/apiHelpers";
import history from "history.js";

// Quiz Actions
export const GET_QUIZ_LIST = "GET_QUIZ_LIST";
export const QUIZ_ACTION_LOADING = "QUIZ_ACTION_LOADING";
export const QUIZ_ACTION_ERROR = "QUIZ_ACTION_ERROR";
export const QUIZ_ACTION_SUCCESS = "QUIZ_ACTION_SUCCESS";

// Response Actions
export const GET_QUIZ_RESPONSE_LIST = "GET_QUIZ_RESPONSE_LIST";

// Project Actions
export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECT_LIST = "GET_PROJECT_LIST";
export const PROJECT_ACTION_LOADING = "PROJECT_ACTION_LOADING";
export const PROJECT_ACTION_SUCCESS = "PROJECT_ACTION_SUCCESS";
export const PROJECT_ACTION_ERROR = "PROJECT_ACTION_ERROR";
export const PROJECT_ACTION_SUBMITING = "PROJECT_ACTION_SUBMITING"; //
export const PROJECT_ACTION_SUBMITED = "PROJECT_ACTION_SUBMITED";

// Generic Actions
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export const getProject = (projectId) => dispatch => {
    dispatch({
        type: PROJECT_ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.get(`${api}` + "project/getproject/" + projectId).then(res => {
                dispatch({
                    type: GET_PROJECT,
                    data: res.data.Project
                });
                dispatch({
                    type: GET_QUIZ_LIST,
                    data: res.data.QuizList
                });
                dispatch({
                    type: PROJECT_ACTION_SUCCESS
                });
                resolve(res.data.Project);
            }).catch(error => {
                return dispatch({
                    type: PROJECT_ACTION_ERROR,
                    data: error
                });
            });
        }, 0);
    }).then(data => {
        if (data !== null) {
            dispatch({
                type: PROJECT_ACTION_SUCCESS
            });
        }
        else {
            dispatch({
                type: PROJECT_ACTION_ERROR,
                data: "aucun projet correspondant n'a ete trouve"
            });
        }
        return data;
    });
};

export const getProjectList = () => dispatch => {
    dispatch({
        type: PROJECT_ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        axios.get(`${api}` + "project/list").then(res => {
            dispatch({
                type: GET_PROJECT_LIST,
                data: res.data.ProjectList
            });
            dispatch({
                type: GET_QUIZ_LIST,
                data: res.data.QuizList
            });
            dispatch({
                type: PROJECT_ACTION_SUCCESS
            });
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: PROJECT_ACTION_ERROR,
                data: error
            });
        });
    });


};

export const mergeKoboExcelResponsesWithPanelistsData = (file, projectId) => dispatch => {
    dispatch({
      type: PROJECT_ACTION_LOADING
    });
    let formData = new FormData();
    formData.append("file", file);
    formData.append("projectId", projectId);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        axios.post(`${api}` + "project/mergeKoboExcelResponsesWithPanelistsData", formData).then(res => {
          console.log(res);
          if (res.data.Success) {
            window.open(res.data.Url, '_blank');
            resolve(res.data);
          }
          else {
            resolve(res.data);
          }
        })
          .catch(error => {
            return dispatch({
              type: PROJECT_ACTION_ERROR,
              data: error
            });
          });
      }, 0);
    })
      .then(data => {
        if (data.Success) {
          dispatch({
            type: PROJECT_ACTION_SUCCESS
          });
          
        }        
        return data;
      });
  };

export const SynchronizeQuizzesFromKobotoolbox = () => dispatch => {
    dispatch({
        type: PROJECT_ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        axios.get(`${api}` + "project/synchronizequizzesfromkobotoolbox").then(res => {
            dispatch({
                type: GET_PROJECT_LIST,
                data: res.data.ProjectList
            });
            dispatch({
                type: GET_QUIZ_LIST,
                data: res.data.QuizList
            });
            dispatch({
                type: PROJECT_ACTION_SUCCESS
            });
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: PROJECT_ACTION_ERROR,
                data: error
            });
        });
    });
};

export const createProject = (project) => dispatch => {
    dispatch({ // informe le reducer du rôle qu'un formulaire le concernant est en cours de soumission 
        type: PROJECT_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.post(`${api}` + "project/createproject", project).then(res => {
                if (res.data.Success) { // Vérifie que le service a fournit une réponse souhaitée
                    dispatch({ // Fournit la nouvelle liste de rôles au reducer pour sa mise à jour 
                        type: GET_PROJECT_LIST,
                        data: res.data.ProjectList
                    });
                    resolve(res.data);
                }
                else {
                    resolve(res.data);
                }
            }).catch(error => {
                return dispatch({
                    type: PROJECT_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        dispatch({ // informe le reducer du rôle que le formulaire le concernant est traité
            type: PROJECT_ACTION_SUBMITED
        });
        if (data.Success) {
            dispatch({ // informe le reducer du rôle qu'une alerte doit être affiché'
                type: SHOW_ALERT,
                data: "Un projet a été ajouté avec succès"
            });
            history.push({
                pathname: "/projet/list"
            });
        }
        else {
            dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                type: PROJECT_ACTION_ERROR,
                data: data.Message
            });
        }
        return data;
    });
};

export const updateProject = (project) => dispatch => {
    dispatch({
        type: PROJECT_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.post(`${api}` + "project/updateproject", project).then(res => {
                if (res.data.Success) {
                    dispatch({
                        type: GET_PROJECT,
                        data: res.data.Project
                    });
                    dispatch({
                        type: GET_PROJECT_LIST,
                        data: res.data.ProjectList
                    });
                    dispatch({
                        type: PROJECT_ACTION_SUBMITED
                    });

                    resolve(res.data);
                }
                else {
                    resolve(res.data);
                }
            }).catch(error => {
                return dispatch({
                    type: PROJECT_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data.Success) {
            dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
                type: SHOW_ALERT,
                data: "Un projet  a été modifié avec succès"
            });
        }
        else {
            dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                type: PROJECT_ACTION_ERROR,
                data: data.Message
            });
        }

        return data;
    });
};

export const deleteProject = projectId => dispatch => {
    dispatch({
        type: PROJECT_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.get(`${api}` + "project/deleteproject/" + projectId).then(res => {
                if (res.data.Success) {
                    dispatch({
                        type: GET_PROJECT_LIST,
                        data: res.data.ProjectList
                    });

                    resolve(res.data);
                }
                else {
                    resolve(res.data);
                }
            }).catch(error => {
                return dispatch({
                    type: PROJECT_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data.Success) {
            dispatch({
                type: PROJECT_ACTION_SUBMITED
            });
            dispatch({
                type: SHOW_ALERT,
                data: "Un projet a été supprimer avec succès"
            });
        }
        else {
            dispatch({
                type: PROJECT_ACTION_SUBMITED
            });
            dispatch({
                type: PROJECT_ACTION_ERROR,
                data: data.Message
            });
        }
        return data;
    });

};

export const affectQuizAndPanelistGroupToProject = (request) => dispatch => {
    dispatch({
        type: PROJECT_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.post(`${api}` + "project/affectpanelistgrouptoproject", request).then(res => {
                if (res.data.Success) {
                    dispatch({
                        type: GET_PROJECT,
                        data: res.data.Project
                    });
                    dispatch({
                        type: GET_PROJECT_LIST,
                        data: res.data.ProjectList
                    });
                    dispatch({
                        type: PROJECT_ACTION_SUBMITED
                    });

                    resolve(res.data);
                }
                else {
                    resolve(res.data);
                }
            }).catch(error => {
                return dispatch({
                    type: PROJECT_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data.Success) {
            dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
                type: SHOW_ALERT,
                data: "Un projet  a été modifié avec succès"
            });
        }
        else {
            dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                type: PROJECT_ACTION_ERROR,
                data: data.Message
            });
        }

        return data;
    });
};

export const updateQuiz = (request) => dispatch => {
    dispatch({
        type: PROJECT_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.post(`${api}` + "project/updatequiz", request).then(res => {
                if (res.data.Success) {
                    dispatch({
                        type: GET_PROJECT,
                        data: res.data.Project
                    });
                    dispatch({
                        type: GET_PROJECT_LIST,
                        data: res.data.ProjectList
                    });
                    dispatch({
                        type: PROJECT_ACTION_SUBMITED
                    });

                    resolve(res.data);
                }
                else {
                    resolve(res.data);
                }
            }).catch(error => {
                return dispatch({
                    type: PROJECT_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data.Success) {
            dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
                type: SHOW_ALERT,
                data: "Un questionnaire a été modifié avec succès"
            });
        }
        else {
            dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                type: PROJECT_ACTION_ERROR,
                data: data.Message
            });
        }

        return data;
    });
};

export const updateQuizResponseUrl = (request) => dispatch => {
    dispatch({
        type: PROJECT_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.post(`${api}` + "project/updatequizresponseurl", request).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_PROJECT,
                    data: res.data.Project
                });
                dispatch({
                    type: GET_PROJECT_LIST,
                    data: res.data.ProjectList
                });
                dispatch({
                    type: PROJECT_ACTION_SUBMITED
                });

                resolve(res.data);
            }
            else {
                resolve(res.data);
            }
        }).catch(error => {
            return dispatch({
                type: PROJECT_ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        if (data.Success) {
            dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
                type: SHOW_ALERT,
                data: "Un projet  a été modifié avec succès"
            });
        }
        else {
            dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                type: PROJECT_ACTION_ERROR,
                data: data.Message
            });
        }

        return data;
    });
};

export const affectPanelistToUser = (request) => dispatch => {
    dispatch({
        type: PROJECT_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.post(`${api}` + "project/affectpanelisttouser", request).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_PROJECT,
                    data: res.data.Project
                });
                dispatch({
                    type: GET_PROJECT_LIST,
                    data: res.data.ProjectList
                });
                dispatch({
                    type: PROJECT_ACTION_SUBMITED
                });
                dispatch({
                    type: SHOW_ALERT,
                    data: "Un projet  a été modifié avec succès"
                });
            }
            else {
                dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                    type: PROJECT_ACTION_ERROR,
                    data: res.data.Message
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: PROJECT_ACTION_ERROR,
                data: error
            });
        });
    });
}

export const getQuizList = () => dispatch => {
    dispatch({
        type: QUIZ_ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        axios.get(`${api}` + "quiz/list").then(res => {

            dispatch({
                type: GET_QUIZ_LIST,
                data: res.data.QuizList
            });
            dispatch({
                type: QUIZ_ACTION_SUCCESS
            });
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: QUIZ_ACTION_ERROR,
                data: error
            });
        });
    });
};

export const getQuizResponsesList = () => dispatch => {
    dispatch({
        type: QUIZ_ACTION_LOADING
    });

    return new Promise((resolve, reject) => {
        axios.get(`${api}` + "project/syncproject").then(res => {
            dispatch({
                type: GET_PROJECT_LIST,
                data: res.data
            });
            dispatch({
                type: QUIZ_ACTION_SUCCESS
            });
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: QUIZ_ACTION_ERROR,
                data: error
            });
        });
    });
};
export const exportQuizResponsesListToExcel = async (id) => {

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { 'Content-Type': 'blob' };
    const config: AxiosRequestConfig = { method: 'GET', url: (`${api}` +"project/responses/export/"+ id), responseType: 'arraybuffer', headers };

    try {
        const response = await axios(config);

        const outputFilename = `${Date.now()}.xls`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
         link.setAttribute('hidden', true);
        document.body.appendChild(link);
        link.click();
        link.remove();
       
        // OR you can save/write file locally.
        // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
        throw Error(error);
    }

    
}

export const hideAlert = () => dispatch => {
    dispatch({
        type: HIDE_ALERT
    });
};