import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Grid, CircularProgress, Icon, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { updateCategory } from "app/redux/actions/PanelistCategoryActions";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);


function UpdatePanelistCategory(props) {

    const {
        storeUser = {},
        panelistCategoryToUpdate = {},
        onUpdateCategory
    } = props;

    const [open, setOpen] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [panelistCategory, setPanelistCategory] = React.useState(panelistCategoryToUpdate);

    useEffect(() => {
        if (panelistCategory.Name == "") {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);

        }
    }, [panelistCategory])

    let handleClickOpen = () => {
        setOpen(true);
    };

    let handleClose = () => {
        setOpen(false);
    };

    let handleSubmit = value => {
        setIsSubmitting(true);
        onUpdateCategory(panelistCategory).then(data => {
            if (data.Success) {
                setPanelistCategory(prevState => { return { ...prevState, Name: "" } });
                setIsSubmitting(false);
                handleClose();
            }
        });
    };


    return (
        <div>
            {/* <IconButton
                // variant="outlined"
                color="primary"
                className="btn-primary"
                onClick={handleClickOpen}
                title="Ajouter "
            // disabled={disabled}
            >
                <Icon>edit</Icon>
            </IconButton> */}
            <a href="javascript:void(0);" onClick={handleClickOpen} className="" title="Supprimer">
                <EditIcon />
            </a>
            <Dialog
                disableBackdropClick
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Création d'une nouvelle catégorie
                </DialogTitle>
                <DialogContent dividers>
                    <ValidatorForm onSubmit={handleSubmit} onError={errors => null} style={{ marginTop: "10px" }}>
                        <Grid container spacing={12}>
                            <Grid item xs={12}>
                                <TextValidator
                                    className="mb-16 w-100"
                                    label="Nom de la catégorie"
                                    onChange={(e) => {
                                        let val = e.target?.value;
                                        setPanelistCategory(prevState => { return { ...prevState, Name: val } })
                                    }}
                                    type="text"
                                    name="Name"
                                    value={panelistCategory?.Name}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    className="mb-16 w-100"
                                    label="Description"
                                    onChange={(e) => {
                                        let val = e.target?.value;
                                        setPanelistCategory(prevState => { return { ...prevState, Description: val } })
                                    }}
                                    type="text"
                                    name="Description"
                                    value={panelistCategory?.Description}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    className="mb-16 w-100"
                                    value={[{ label: "Entreprise", id: "LegalPerson" }, { label: "Panéliste", id: "NaturalPerson" }].find(item => item.id == panelistCategory.Type)}
                                    options={[{ label: "Entreprise", id: "LegalPerson" }, { label: "Panéliste", id: "NaturalPerson" }]}
                                    getOptionLabel={option => option.label}
                                    onChange={(event, value) => {
                                        setPanelistCategory(prevState => {
                                            return { ...prevState, Type: value?.id }
                                        })
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Type"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="secondary" variant="contained" disabled={isSubmitting || hasMissingValue}>
                        Soumettre {isSubmitting ? <CircularProgress size={20} /> : <span></span>}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => ({
    updateCategory: PropTypes.func.isRequired,
    storePanelistCategory: state.panelistcategory,
    storeUser: state.user
});
export default (
    withRouter(connect(mapStateToProps, { updateCategory })(UpdatePanelistCategory))
);