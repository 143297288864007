import axios from "axios";
import { api } from "app/services/apiHelpers";
import history from "history.js";


export const GET_DASHBOARD = "GET_DASHBOARD";
export const DASHBOARD_ACTION_LOADING = "DASHBOARD_ACTION_LOADING";
export const DASHBOARD_ACTION_SUCCESS = "DASHBOARD_ACTION_SUCCESS";
export const DASHBOARD_ACTION_ERROR = "DASHBOARD_ACTION_ERROR";


export const getDashboard = (panelistId) => dispatch => {
    dispatch({
        type: DASHBOARD_ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.get(`${api}` + "dashboard/getdashboard").then(res => {
                dispatch({
                    type: GET_DASHBOARD,
                    data: res.data
                });
                resolve(res.data);
            })
                .catch(error => {
                    return dispatch({
                        type: DASHBOARD_ACTION_ERROR,
                        data: error
                    });
                });
        }, 500);
    }).then(data => {
        if (data != null) {
            dispatch({
                type: DASHBOARD_ACTION_SUCCESS
            });
        }
        else {
            dispatch({
                type: DASHBOARD_ACTION_ERROR,
                data: "Aucune données n'a ete trouvée"
            });
        }
        return data;
    });
};