import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Grid, CircularProgress, Icon, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getUserList } from "app/redux/actions/UserActions";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import guid from "guid";

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);



function AffectPanelistToUser(props) {
    const {
        storeUser = {},
        disabled = false,
        panelistGroupId = "",
        userListOptions = [],
        onAffectPanelistToUserSubmit,
    } = props;

    const [open, setOpen] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [userId, setUserId] = React.useState("");

    useEffect(() => {
        if (userId == "" || userId == undefined) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);

        }
    }, [userId])

    let handleClickOpen = () => {
        setOpen(true);
    };

    let handleClose = () => {
        setOpen(false);
    };

    let handleSubmit = value => {
        setIsSubmitting(true);
        onAffectPanelistToUserSubmit(userId, panelistGroupId).then(resp => {
            if (resp) {
                setUserId("");
                setIsSubmitting(false);
                handleClose();
            }
        });
    };


    return (
        <div>
            <Button
                size="small"
                color="primary"
                className="btn-primary"
                onClick={handleClickOpen}
                disabled={disabled}>
                Affecter
            </Button>
            <Dialog
                disableBackdropClick
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Affectation de panélistes
                </DialogTitle>
                <DialogContent dividers>
                    <ValidatorForm
                        // ref="form"
                        onSubmit={handleSubmit}
                        onError={errors => null}
                        style={{ marginTop: "10px" }}
                    >
                        <Grid container spacing={12}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    className="mb-16 w-100"
                                    options={userListOptions}
                                    getOptionLabel={option => option.label}
                                    onChange={(event, value) => {
                                        setUserId(value?.id);
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Choisissez une personne"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="secondary" variant="contained" disabled={isSubmitting || hasMissingValue}>
                        Soumettre {isSubmitting ? <CircularProgress size={20} /> : <span></span>}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );


}



const mapStateToProps = state => ({
    getUserList: PropTypes.func.isRequired,
    storePanelistGroup: state.panelistgroup,
    storeUser: state.user
});
export default (
    withRouter(connect(mapStateToProps, { getUserList })(AffectPanelistToUser))
);