import React from "react";
import { Breadcrumb, SimpleCard } from "matx";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Icon, Grid, CircularProgress, Box, Typography, LinearProgress, TextField, Table, TableBody, TableCell, TableRow, TableHead, FormControlLabel, FormLabel, FormControl } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import SnackbarContentWrapper from "./alert/SnackbarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import _, { without } from 'underscore';
import { getPanelist, updatePanelist, hideAlert, getRegions } from "app/redux/actions/PanelistActions";
import { getCategories } from "app/redux/actions/PanelistCategoryActions";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import history from "history.js";



const styles = theme => ({
    button: {
        margin: theme.spacing(1)
    },
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

class PanelistDetailPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            SnackbarOpen: false,
            panelistToUpdate: {
                PanelistId: "",
                CreateDate: new Date(),
                LastModifiedDate: new Date(),
                BirthDate: new Date(),
                PanelistId: "",
                FirstName: "",
                LastName: "",
                Email: "",
                EducationLevel: "",
                Profession: "",
                Income: "",
                Type: "",
                Categories: [
                    {
                        CategoryId: "",
                        Name: ""
                    }
                ],
                LocationName: "",
                Gender: "",
                PhoneNumber1: "",
                PhoneNumber2: "",
                RegionId: "",
                TownId: "",
                DistrictId: "",
                PanelistGroups: [],
            },
            RegionList: this.props.storePanelist.regionList,
            PanelistCategories: this.props.storePanelistCategory.categories,
            RegionOptions: [],
            PanelistCategoryOption: [],
            TownToDisplayOption: [],
            DistrictToDisplayOption: [],
            PanelistGroupIsNamesDefaultOption: [],
            PanelistCategoryDefaultOption: [],
            id: "",
            showSnackbar: false,
            message: "",
            isLoading: true,
            isSubmitting: false,
            ProfessionList: [
                { label: "Agriculture, pêche et foresterie (Production primaire et transformation des produits de base, protéines animales)" },
                { label: "Agrobusiness (aliments conditionnés, boissons...)" },
                { label: "Services financiers (banque commerciale, assurance et retraites, microfinance, etc. - Incluant la fintech)" },
                { label: "Véhicules d'investissement collectif (exemples : fonds de private equity, fonds de fonds, capital risque)" },
                { label: "Industries de fabrication" },
                { label: "Tourisme" },
                { label: "Commerce de détail" },
                { label: "Construction et immobilier" },
                { label: "Santé" },
                { label: "Éducation" },
                { label: "Industries extractives (pétrole, gaz et mines)" },
                { label: "Infrastructures (Énergie et équipements)" },
                { label: "Infrastructures de transport" },
                { label: "Télécommunications, Médias et Technologie" },
                { label: "Prestation de services" },
                { label: "Autre" }
            ],
            EducationLevelList: [{ label: "Pas d'éducation scolaire" }, { label: "Ecole coranique" }, { label: "Primaire" }, { label: "Secondaire" }, { label: "Universitaire" }]

        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
        this.handleSelectListChange = this.handleSelectListChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleAddChip = this.handleAddChip.bind(this);
        this.handleDeleteChip = this.handleDeleteChip.bind(this);
        this.getRegionOption = this.getRegionOption.bind(this);
        this.getTownToDisplayOption = this.getTownToDisplayOption.bind(this);
        this.getDistrictToDisplayOption = this.getDistrictToDisplayOption.bind(this);
        this.getPanelistCategoryDefaultOption = this.getPanelistCategoryDefaultOption.bind(this);
    }

    componentDidMount() {
        let match = this.props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params != undefined && params.id != undefined) {
                if (this.props.storePanelist.panelistList
                    && this.props.storePanelist.panelistList.length
                    && this.props.storePanelist.regionList
                    && this.props.storePanelist.regionList.length) {
                    this.getRegionOption();
                    let panelist = this.props.storePanelist.panelistList.find(p => p.PanelistId == params.id);
                    if (panelist != null) {
                        this.setState({ panelistToUpdate: panelist }, () => {
                            if (this.state.panelistToUpdate.RegionId != "") {
                                this.getTownToDisplayOption(this.state.panelistToUpdate.RegionId);
                            }
                            if (this.state.panelistToUpdate.RegionId != "" && this.state.panelistToUpdate.TownId != "") {
                                this.getDistrictToDisplayOption(this.state.panelistToUpdate.RegionId, this.state.panelistToUpdate.TownId);
                            }
                            this.getPanelistGroupIsNamesDefaultOption();
                            this.setState({ isLoading: false });
                        });
                    }
                }
                else {
                    this.setState({ id: params.id }, () => {
                        this.props.getPanelist(params.id);
                        this.props.getRegions();
                    });

                }

                if (this.props.storePanelistCategory.categories && this.props.storePanelistCategory.categories.length) {
                    this.getPanelistCategoryDefaultOption();
                }
                else {
                    this.props.getCategories();
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.storePanelist !== this.props.storePanelist) {
            if (nextProps.storePanelist.currentPanelist !== this.props.storePanelist.currentPanelist) {
                this.setState({ panelistToUpdate: nextProps.storePanelist.currentPanelist }, () => {
                    console.log(this.state.panelistToUpdate);
                    this.getPanelistCategoryDefaultOption();
                    if (this.state.panelistToUpdate.RegionId != "") {
                        console.log("hello");
                        this.getTownToDisplayOption(this.state.panelistToUpdate.RegionId);
                    }
                    if (this.state.panelistToUpdate.RegionId != "" && this.state.panelistToUpdate.TownId != "") {
                        this.getDistrictToDisplayOption(this.state.panelistToUpdate.RegionId, this.state.panelistToUpdate.TownId);
                    }
                    this.getPanelistGroupIsNamesDefaultOption();
                    // this.setState({ isLoading: false });
                });
                console.log("nextProps", nextProps);
            }
            if (nextProps.storePanelist.submitting !== this.state.isSubmitting) {
                this.setState({ isSubmitting: nextProps.storePanelist.submitting });
            }
            if (nextProps.storePanelist.showAlert === true) {
                this.setState({ SnackbarOpen: true });
                setTimeout(() => {
                    this.props.hideAlert();
                }, 15000);
            }
            if ((nextProps.storePanelist.regionList != this.props.storePanelist.regionList) || (nextProps.storePanelist.regionList.length > 0 && this.state.RegionList.length == 0)) {
                this.setState({ RegionList: nextProps.storePanelist.regionList }, () => {
                    this.getRegionOption();
                    if (this.state.panelistToUpdate.RegionId != "") {
                        console.log("hello");
                        this.getTownToDisplayOption(this.state.panelistToUpdate.RegionId);
                    }
                    if (this.state.panelistToUpdate.RegionId != "" && this.state.panelistToUpdate.TownId != "") {
                        this.getDistrictToDisplayOption(this.state.panelistToUpdate.RegionId, this.state.panelistToUpdate.TownId);
                    }
                    this.setState({ isLoading: false });
                    console.log("nextProps 2", nextProps);
                })
            }
        }
        if (nextProps.storePanelistCategory.categories != null && this.props.storePanelistCategory.categories.length > 0 && this.state.PanelistCategories.length == 0) {
            this.setState({ PanelistCategories: nextProps.storePanelistCategory.categories }, () => {
                this.getPanelistCategoryDefaultOption();

            });
        }
    }

    handleClose = (event) => {
        this.setState({ SnackbarOpen: false })
        hideAlert();
    }

    handleSubmit = value => {
        this.props.updatePanelist(this.state.panelistToUpdate).then(data => {
            if (data.Success) {
                history.push({
                    pathname: this.state.panelistToUpdate.Type == "NaturalPerson" ? "/panelist/list" : "/panelist/company"
                });
            }
        });
    };

    handleAddChip = (chip) => {
        let panelistCopy = this.state.panelistToUpdate;
        if (panelistCopy.Category != null) {
            let extendedValue = panelistCopy.Category.concat(chip);
            panelistCopy['Category'] = extendedValue.filter((v, i, a) => a.indexOf(v) === i);
            this.setState({ panelistToUpdate: panelistCopy });
        }
    };

    handleDeleteChip = (chip, index) => {
        let panelistCopy = this.state.panelistToUpdate;
        panelistCopy['Category'] = panelistCopy.Category.filter(item => item != chip);
        this.setState({ panelistToUpdate: panelistCopy });
    }

    handleChange = event => {
        event.persist();
        let panelistCopy = this.state.panelistToUpdate;
        for (let key in panelistCopy) {
            if (key === event.target.name) {
                panelistCopy[`${key}`] = event.target.value
            }
        }
        this.setState({ panelistToUpdate: panelistCopy });
    };

    handleSelectListChange = (event, value, name) => {
        if (value != null) {
            let panelistCopy = this.state.panelistToUpdate;
            for (let key in panelistCopy) {
                // console.log("key", key);
                if (key == name) {

                    if (name == "Profession" || name == "Income" || name == "EducationLevel") {
                        panelistCopy[`${key}`] = value.label;
                        this.setState({ panelistToUpdate: panelistCopy });
                    }
                    else if (name == "RegionId" || name == "TownId" || name == "DistrictId") {
                        console.log("region change");
                        panelistCopy[`${key}`] = value.id;
                        if (name == "RegionId") {
                            this.setState({ panelistToUpdate: panelistCopy }, () => {
                                this.getTownToDisplayOption(value.id);
                                console.log("region change");
                            });
                        }
                        else if (name == "TownId") {
                            this.setState({ panelistToUpdate: panelistCopy }, () => {
                                this.getDistrictToDisplayOption(value.regionId, value.id);
                            });
                        }
                        else {
                            this.setState({ panelistToUpdate: panelistCopy });
                        }
                    }
                    else if (name == "Categories") {
                        let result = [];
                        value.forEach(item => {
                            result.push({ CategoryId: item.id, Name: item.label });
                        })
                        panelistCopy[`${key}`] = result;
                        // this.setState({ panelistToUpdate: panelistCopy });
                        console.log("Categories", result);
                    }
                }
            }

        }
    };

    handleDateChange = (date, name) => {
        let panelistCopy = this.state.panelistToUpdate;
        for (let key in panelistCopy) {
            if (key === name) {
                panelistCopy[`${key}`] = date
            }
        }
        this.setState({ panelistToUpdate: panelistCopy });
    };

    filter = createFilterOptions();
    filterOptions = (options, params) => {
        let filtered = this.filter(options, params);
        if (params.inputValue !== "") {
            filtered.push({
                inputValue: params.inputValue,
                label: `Add "${params.inputValue}"`
            });
        }
        return filtered;
    };

    handleAutocompleteChange = (event, newValue) => {
        if (newValue && newValue.inputValue) {
            this.setState({ value: { label: newValue.inputValue } })
            return;
        }
        let panelistCopy = this.state.panelistToUpdate;
        panelistCopy.Status = newValue.label;
        this.setState({ value: newValue, panelistToUpdate: panelistCopy });

    };

    getRegionOption = () => {
        let result = [];
        this.state.RegionList.forEach(region => {
            result.push({ label: region.Name, id: region.RegionId })
        })
        this.setState({ RegionOptions: result });
    }

    getTownToDisplayOption = (regionId) => {
        let result = [];
        let region = this.state.RegionList.find(r => r.RegionId == regionId);
        console.log("region list", this.state.RegionList);
        if (region != null && region.Towns != null && region.Towns.length) {
            region.Towns.forEach(town => {
                result.push({ label: town.Name, id: town.TownId, regionId: regionId })
            })
        }
        this.setState({ TownToDisplayOption: result }, () => {
            console.log(this.state.TownToDisplayOption);
        });
    }

    getDistrictToDisplayOption = (regionId, townId) => {
        let result = [];
        let region = this.state.RegionList.find(r => r.RegionId == regionId);
        if (region != null && region.Towns != null && region.Towns.length) {
            let town = region.Towns.find(t => t.TownId == townId);
            if (town != null && town.Districts != null && town.Districts.length) {
                town.Districts.forEach(district => {
                    result.push({ label: district.Name, id: district.DistrictId })
                })
            }
        }
        this.setState({ DistrictToDisplayOption: result });
    }

    getPanelistGroupIsNamesDefaultOption = () => {
        let result = [];
        this.state.panelistToUpdate.PanelistGroups.forEach(element => {
            result.push({ label: element.Name })
        })
        this.setState({ PanelistGroupIsNamesDefaultOption: result });
    }

    getPanelistCategoryDefaultOption = () => {
        let result = [];
        this.state.PanelistCategories.forEach(element => {
            result.push({ label: element.Name, id: element.CategoryId })
        });
        this.setState({ PanelistCategoryOption: result }, () => {
            // if(this.state.panelistToUpdate && this.state.panelistToUpdate.)
        });
        // console.log("nextProps", this.state.panelistToUpdate);
    }

    render() {
        let { PanelistId, Type, FirstName, LastName, PhoneNumber1, PhoneNumber2, Gender, Email, Profession, Income, LocationName, BirthDate, CreatedDate, LastModifiedDate, EducationLevel, EmployeesCount } = this.state.panelistToUpdate;
        return (
            <div className="m-sm-30">
                {this.state.isLoading ? (
                    <div style={{
                        margin: "auto", textAlign: "center", fontSize: "18px",
                        width: "50%", height: "50%", marginTop: "180px"
                    }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {this.props.storePanelist.showAlert && (
                            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                open={this.state.SnackbarOpen}
                                autoHideDuration={6000}
                                onClose={this.handleClose}
                            >
                                <SnackbarContentWrapper onClose={this.handleClose} variant={this.props.storePanelist.alertVariant} message={this.props.storePanelist.alertMessage} />
                            </Snackbar>
                        )}
                        <div className="mb-sm-30">
                            <Breadcrumb
                                routeSegments={[
                                    { name: Type == "NaturalPerson" ? "Panélistes" : "Entreprises", path: "/panelist/list" },
                                    { name: Type == "NaturalPerson" ? FirstName + " " + LastName : LastName }
                                ]}
                            />
                        </div>
                        <SimpleCard title={Type == "NaturalPerson" ? "Detail de panéliste:" + " " + `${FirstName}` + " " + `${LastName}` : "Detail de l'entreprise:" + " " + `${LastName}`}>
                            <div>
                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleSubmit}
                                    onError={errors => null}
                                >
                                    <Grid container spacing={6}>
                                        {this.state.panelistToUpdate.Type == "NaturalPerson" && (
                                            <>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label="Prénom"
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        name="FirstName"
                                                        value={FirstName}
                                                        validators={["required"]}
                                                        errorMessages={["Le prénom du panéliste est recquis"]}
                                                        fullWidth

                                                    />
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            className="mb-16 w-100"
                                                            margin="none"
                                                            id="mui-pickers-date"
                                                            label="Date de naissance"
                                                            inputVariant="standard"
                                                            type="text"
                                                            autoOk={true}
                                                            format="dd/MM/yyyy"
                                                            value={BirthDate}
                                                            onChange={(date) => this.handleDateChange(date, "BirthDate")}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label="Numéro de téléphone 1"
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        name="PhoneNumber1"
                                                        value={PhoneNumber1}
                                                        fullWidth

                                                    />
                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label="Email"
                                                        onChange={this.handleChange}
                                                        type="mail"
                                                        name="Email"
                                                        value={Email}
                                                        validators={["isEmail"]}
                                                        errorMessages={["Le format de l'email est incorrecte"]}
                                                        fullWidth

                                                    />
                                                    <Autocomplete
                                                        className="mb-16 w-100"
                                                        options={[{ label: "Moins de 150 000 FCFA" }, { label: "150 000 à 450 000 FCFA" }, { label: "450 000 à 750 000 FCFA" }, { label: "750 000 à 1 500 000 FCFA" }, { label: "Plus de 1 500 000 FCFA" }]}
                                                        getOptionLabel={option => option.label}
                                                        defaultValue={{ label: this.state.panelistToUpdate.Income }}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "Income")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Revenu"
                                                                variant="outlined"
                                                                fullWidth

                                                            />
                                                        )}
                                                    />
                                                    <Autocomplete
                                                        className="mb-16 w-100"
                                                        options={this.state.RegionOptions}
                                                        defaultValue={this.state.RegionOptions.find(o => o.id == this.state.panelistToUpdate.RegionId)}
                                                        getOptionLabel={option => option.label}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "RegionId")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Ville/Région"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <Autocomplete
                                                        className="mb-16 w-100"
                                                        options={this.state.DistrictToDisplayOption}
                                                        getOptionLabel={option => option.label}
                                                        defaultValue={this.state.DistrictToDisplayOption.find(o => o.id == this.state.panelistToUpdate.DistrictId)}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "DistrictId")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Quartier/Village"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            className="mb-16 w-100"
                                                            margin="none"
                                                            id="mui-pickers-date"
                                                            label="Dernière date de modification"
                                                            inputVariant="standard"
                                                            type="text"
                                                            autoOk={true}
                                                            format="dd/MM/yyyy"
                                                            value={CreatedDate}
                                                            disabled
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label="Nom"
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        name="LastName"
                                                        value={LastName}
                                                        validators={["required"]}
                                                        errorMessages={["Le nom du panéliste est recquis"]}
                                                        fullWidth

                                                    />
                                                    <FormControl component="fieldset" >
                                                        <FormLabel component="legend"></FormLabel>
                                                        <RadioGroup
                                                            className="mb-4"
                                                            value={Gender}
                                                            name="Gender"
                                                            onChange={this.handleChange}
                                                            row
                                                            fullWidth
                                                        >
                                                            <FormControlLabel
                                                                value={"Male"}
                                                                control={<Radio color="secondary" />}
                                                                label="Masculin"
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value={"Female"}
                                                                control={<Radio color="secondary" />}
                                                                label="Féminin"
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label="Numéro de téléphone 2"
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        name="PhoneNumber2"
                                                        value={PhoneNumber2}
                                                        fullWidth
                                                    />
                                                    <Autocomplete
                                                        className="mb-16 w-100"
                                                        options={this.state.EducationLevelList}
                                                        getOptionLabel={option => option.label}
                                                        defaultValue={this.state.EducationLevelList.find(e => e.label == EducationLevel)}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "EducationLevel")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Niveau d'étude"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <Autocomplete
                                                        multiple
                                                        className="mb-16 w-100"
                                                        options={this.state.PanelistCategoryOption}
                                                        getOptionLabel={option => option.label}
                                                        defaultValue={this.state.PanelistCategoryOption.filter(
                                                            option => this.state.panelistToUpdate.Categories.some(cat => cat.CategoryId == option.id)
                                                        )}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "Categories")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Catégorie"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <Autocomplete
                                                        className="mb-16 w-100"
                                                        options={this.state.TownToDisplayOption}
                                                        getOptionLabel={option => option.label}
                                                        defaultValue={this.state.TownToDisplayOption.find(o => o.id == this.state.panelistToUpdate.TownId)}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "TownId")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Commune"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <Autocomplete
                                                        multiple
                                                        disabled
                                                        className="mb-16 w-100"
                                                        options={this.state.PanelistGroupIsNamesDefaultOption}
                                                        getOptionLabel={option => option.label}
                                                        defaultValue={this.state.PanelistGroupIsNamesDefaultOption}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "GroupId")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Groupe"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <Autocomplete
                                                        className="mb-16 w-100"
                                                        options={this.state.ProfessionList}
                                                        getOptionLabel={option => option.label}
                                                        defaultValue={this.state.ProfessionList.find(p => p.label == Profession)}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "Profession")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Profession"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            className="mb-16 w-100"
                                                            margin="none"
                                                            id="mui-pickers-date"
                                                            label="Date de création"
                                                            inputVariant="standard"
                                                            type="text"
                                                            autoOk={true}
                                                            format="dd/MM/yyyy"
                                                            value={CreatedDate}
                                                            disabled
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </>
                                        )}
                                        {this.state.panelistToUpdate.Type == "LegalPerson" && (
                                            <>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label="Nom"
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        name="LastName"
                                                        value={LastName}
                                                        validators={["required"]}
                                                        errorMessages={["Le nom du panéliste est recquis"]}
                                                        fullWidth
                                                    />
                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label="Numéro de téléphone 1"
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        name="PhoneNumber1"
                                                        value={PhoneNumber1}
                                                        fullWidth

                                                    />
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            className="mb-16 w-100"
                                                            margin="none"
                                                            id="mui-pickers-date"
                                                            label="Date de création de l'entreprise"
                                                            inputVariant="standard"
                                                            type="text"
                                                            autoOk={true}
                                                            format="dd/MM/yyyy"
                                                            value={BirthDate}
                                                            onChange={(date) => this.handleDateChange(date, "BirthDate")}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label="Email"
                                                        onChange={this.handleChange}
                                                        type="mail"
                                                        name="Email"
                                                        value={Email}
                                                        validators={["isEmail"]}
                                                        errorMessages={["Le format de l'email est incorrecte"]}
                                                        fullWidth

                                                    />
                                                    <Autocomplete
                                                        className="mb-16 w-100"
                                                        options={this.state.RegionOptions}
                                                        defaultValue={this.state.RegionOptions.find(o => o.id == this.state.panelistToUpdate.RegionId)}
                                                        getOptionLabel={option => option.label}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "RegionId")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Ville/Région"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <Autocomplete
                                                        className="mb-16 w-100"
                                                        options={this.state.DistrictToDisplayOption}
                                                        getOptionLabel={option => option.label}
                                                        defaultValue={this.state.DistrictToDisplayOption.find(o => o.id == this.state.panelistToUpdate.DistrictId)}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "DistrictId")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Quartier/Village"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            className="mb-16 w-100"
                                                            margin="none"
                                                            id="mui-pickers-date"
                                                            label="Dernière date de modification"
                                                            inputVariant="standard"
                                                            type="text"
                                                            autoOk={true}
                                                            format="dd/MM/yyyy"
                                                            value={CreatedDate}
                                                            disabled
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>

                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label="Nombre d'employés"
                                                        onChange={this.handleChange}
                                                        type="number"
                                                        name="EmployeesCount"
                                                        value={EmployeesCount}
                                                        fullWidth
                                                    />
                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label="Numéro de téléphone 2"
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        name="PhoneNumber2"
                                                        value={PhoneNumber2}
                                                        fullWidth
                                                    />
                                                    <Autocomplete
                                                        className="mb-16 w-100"
                                                        options={this.state.ProfessionList}
                                                        getOptionLabel={option => option.label}
                                                        defaultValue={this.state.ProfessionList.find(p => p.label == Profession)}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "Profession")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Secteur d'activité"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <Autocomplete
                                                        multiple
                                                        className="mb-16 w-100"
                                                        options={this.state.PanelistCategoryOption}
                                                        getOptionLabel={option => option.label}
                                                        defaultValue={this.state.PanelistCategoryOption.filter(
                                                            option => this.state.panelistToUpdate.Categories.some(cat => cat.CategoryId == option.id)
                                                        )}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "Categories")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Catégorie"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <Autocomplete
                                                        className="mb-16 w-100"
                                                        options={this.state.TownToDisplayOption}
                                                        getOptionLabel={option => option.label}
                                                        defaultValue={this.state.TownToDisplayOption.find(o => o.id == this.state.panelistToUpdate.TownId)}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "TownId")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Commune"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <Autocomplete
                                                        multiple
                                                        disabled
                                                        className="mb-16 w-100"
                                                        options={this.state.PanelistGroupIsNamesDefaultOption}
                                                        getOptionLabel={option => option.label}
                                                        defaultValue={this.state.PanelistGroupIsNamesDefaultOption}
                                                        onChange={(event, value) => this.handleSelectListChange(event, value, "GroupId")}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Groupe"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            className="mb-16 w-100"
                                                            margin="none"
                                                            id="mui-pickers-date"
                                                            label="Date de création"
                                                            inputVariant="standard"
                                                            type="text"
                                                            autoOk={true}
                                                            format="dd/MM/yyyy"
                                                            value={CreatedDate}
                                                            disabled
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                    <Button color="secondary" variant="contained" type="submit" disabled={this.props.storePanelist.submitting}>
                                        <Icon>send</Icon>
                                        <span className="pl-2 capitalize">Sauvegarder</span> {this.props.storePanelist.submitting ? <CircularProgress size={20} /> : <span></span>}
                                    </Button>
                                </ValidatorForm>
                            </div>
                        </SimpleCard>

                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    updatePanelist: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    getPanelist: PropTypes.func.isRequired,
    getRegions: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
    storePanelist: state.panelist,
    storePanelistCategory: state.panelistcategory,

});
export default (
    withRouter(connect(mapStateToProps, {
        getPanelist,
        getRegions,
        getCategories,
        updatePanelist, hideAlert
    })(PanelistDetailPage))
);