import React, { useEffect } from "react";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRoleList } from "app/redux/actions/RoleActions";
import {
  getPanelistList,
  getPanelist,
  hideAlert,
  getRegions,
  importPanelistFromExcel,
  exportPanelistListToExcel,
  createPanelist,
  deletePanelist,
} from "app/redux/actions/PanelistActions";
import { getCategories } from "app/redux/actions/PanelistCategoryActions";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
  TablePagination,
  Popover,
  Checkbox,
  Button,
  IconButton,
  TextField,
} from "@material-ui/core";
import { SimpleCard, Breadcrumb } from "matx";
import SnackbarContentWrapper from "../alert/SnackbarContentWrapper";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import {
  DataGrid,
  GridColDef,
  GridApi,
  GridCellValue,
} from "@material-ui/data-grid";
import CreateNaturalPersonPanellist from "./dialogs/CreateNaturalPersonPanellist";
import { Link } from "react-router-dom";
import DeletePanelist from "./dialogs/DeletePanelist";
import { Icon } from "@material-ui/core";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";
import { api } from "app/services/apiHelpers";

const useStyles2 = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

function PanelistAsNaturalPersonPage(props) {
  const {
    theme,
    storeUser = {},
    storePanelist = {},
    storePanelistCategory = {},
    getRoleList,
    getPanelistList,
    getPanelist,
    createPanelist,
    deletePanelist,
    importPanelistFromExcel,
    // exportPanelistListToExcel,
    getRegions,
    getCategories,
    hideAlert,
  } = props;

  const columns = [
    { field: "PanelistId", hide: true },
    { field: "FirstName", headerName: "Prénon", flex: 0.5 },
    { field: "LastName", headerName: "Nom", flex: 0.5 },
    {
      field: "BirthDate",
      headerName: "Date de naissance",
      flex: 0.3,
      renderCell: (params) => {
        let displayName = "";
        displayName = moment(`${params.formattedValue}`).format("Do MMMM YYYY");
        return (
          <div
            className="d-flex justify-content-between align-items-right"
            style={{ cursor: "pointer", textAlign: "right" }}
          >
            <span>{displayName}</span>
          </div>
        );
      },
    },
    {
      field: "Gender",
      headerName: "Sexe",
      flex: 0.3,
      renderCell: (params) => {
        let displayName = "";
        if (params.formattedValue == "Male") {
          displayName = "Masculin";
        } else {
          displayName = "Féminin";
        }
        return (
          <div
            className="d-flex justify-content-between align-items-right"
            style={{ cursor: "pointer", textAlign: "right" }}
          >
            <span>{displayName}</span>
          </div>
        );
      },
    },
    { field: "PhoneNumber1", headerName: "Téléphone", flex: 0.3 },
    {
      field: "UpdatePanelistId",
      headerName: "  ",
      sortable: false,
      flex: 0.2,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        let id = params.formattedValue;
        return (
          <div
            className="d-flex justify-content-between align-items-right"
            style={{ cursor: "pointer", textAlign: "right" }}
          >
            {permissions.some((item) => item == "AllowedToUpdatePanelist") && (
              <Link to={"/panelist/detail/" + id} title="Modifier un panéliste">
                <Icon color="primary">edit</Icon>
              </Link>
            )}
          </div>
        );
      },
    },
    {
      field: "DeletePanelistId",
      headerName: "  ",
      sortable: false,
      flex: 0.2,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        let id = params.formattedValue;
        return (
          <div
            className="d-flex justify-content-between align-items-right"
            style={{ cursor: "pointer", textAlign: "right" }}
          >
            {permissions.some((item) => item == "AllowedToDeletePanelist") && (
              <DeletePanelist
                panelistToDelete={panelistList.find((p) => p.PanelistId == id)}
              />
            )}
          </div>
        );
      },
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const classes = useStyles2();
  const [selectedPanelist, setSelectedPanelist] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [regionList, setRegionList] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [panelistList, setPanelistList] = React.useState([]);
  const [fileName, setFileName] = React.useState("Panélistes");
  const [initialPanelistList, setInitialPanelistList] = React.useState([]);
  const importInputRef = React.useRef();
  const [showSearch, setShowSearch] = React.useState(false);
  const [filteredObjects, setFilteredObjects] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  useEffect(() => {
    if (storePanelist.panelistAsNaturalPersonList.length == 0) {
      getPanelistList().then((response) => {
        if (response) {
          setPanelistList(response.filter((p) => p.Type == "NaturalPerson"));
          setInitialPanelistList(
            response.filter((p) => p.Type == "NaturalPerson")
          );
          setIsLoading(false);
          if (storePanelist.regionList.length > 0) {
            setRegionList(storePanelist.regionList);
          } else if (storePanelist.regionList.length == 0) {
            getRegions().then((response) => {
              if (response && response.Regions) {
                setRegionList(response.Regions);
              }
            });
          }
          if (
            storePanelistCategory.categories &&
            storePanelistCategory.categories.length == 0
          ) {
            getCategories().then((response) => {
              if (response) {
                setCategories(response);
              }
            });
          } else if (storePanelistCategory.categories.length > 0) {
            setCategories(storePanelistCategory.categories);
          }
        }
      });
    } else if (storePanelist.panelistAsNaturalPersonList.length > 0) {
      setPanelistList(storePanelist.panelistAsNaturalPersonList);
      setInitialPanelistList(storePanelist.panelistAsNaturalPersonList);
      setIsLoading(false);
      if (storePanelist.regionList.length > 0) {
        setRegionList(storePanelist.regionList);
      } else if (storePanelist.regionList.length == 0) {
        getRegions().then((response) => {
          if (response && response.Regions) {
            setRegionList(response.Regions);
          }
        });
      }
      if (
        storePanelistCategory.categories &&
        storePanelistCategory.categories.length == 0
      ) {
        getCategories().then((response) => {
          if (response) {
            setCategories(response);
          }
        });
      } else if (storePanelistCategory.categories.length > 0) {
        setCategories(storePanelistCategory.categories);
      }
    }
  }, []);

  useEffect(() => {
    console.log("permission", permissions);
  }, [permissions]);

  useEffect(() => {
    if (
      storeUser.currentUser.Permissions &&
      storeUser.currentUser.Permissions != permissions
    ) {
      setPermissions(storeUser.currentUser.Permissions);
    }
  }, [storeUser]);

  useEffect(() => {
    if (storePanelist.showAlert) {
      setMessage(storePanelist.alertMessage);
      setOpen(true);
      setTimeout(() => {
        hideAlert();
      }, 15000);
    }
    // if (panelistList.length > 0 && storePanelist.panelistAsNaturalPersonList) {
    //     if (!(Object.keys(storePanelist.panelistAsNaturalPersonList).length === Object.keys(panelistList).length
    //         && Object.keys(storePanelist.panelistAsNaturalPersonList).every(p => storePanelist.panelistAsNaturalPersonList[p] === panelistList[p]))) {
    //         setPanelistList(storePanelist.panelistAsNaturalPersonList);
    //     }
    // }
  }, [storePanelist]);

  const handleClose = (event) => {
    setOpen(false);
    hideAlert();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const handleImportPanelist = (e) => {
    setSelectedFile(e.target.files[0]);
    importPanelistFromExcel(e.target.files[0], "NaturalPerson").then(
      (response) => {
        if (response.Success) {
          setPanelistList(response.PanelistList);
          setInitialPanelistList(response.PanelistList);
          setSelectedFile(undefined);
        }
      }
    );
  };

  const handleCreatePanelist = (panelist) => {
    return new Promise((resolve, reject) => {
      createPanelist(panelist).then((data) => {
        if (data.Success) {
          setPanelistList(
            data.PanelistList.filter((p) => p.Type == "NaturalPerson")
          );
          setInitialPanelistList(
            data.PanelistList.filter((p) => p.Type == "NaturalPerson")
          );
        }
        resolve(data);
      });
    });
  };

  const handleDeletePanelist = (panelistId) => {
    return new Promise((resolve, reject) => {
      deletePanelist(panelistId).then((data) => {
        if (data.Success) {
          setPanelistList(
            data.PanelistList.filter((p) => p.Type == "NaturalPerson")
          );
          setInitialPanelistList(
            data.PanelistList.filter((p) => p.Type == "NaturalPerson")
          );
        }
        resolve(data);
      });
    });
  };

  const exportPanelistListToExcel = async (type) => {
    const a = window.document.createElement("a");
    a.href = `${api}` + "panelist/export/" + type;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleClick = (event) => {
    setShowSearch((current) => !current);
  };

  const SearchItems = (searchTerm) => {
    setSearchValue(searchTerm);
    let result = [];
    if (
      initialPanelistList.filter((p) =>
        p.FirstName.toLowerCase().includes(searchTerm.toLowerCase())
      ).length
    ) {
      result.push(
        ...initialPanelistList.filter((p) =>
          p.FirstName.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
    if (
      initialPanelistList.filter((p) =>
        p.LastName.toLowerCase().includes(searchTerm.toLowerCase())
      ).length
    ) {
      result.push(
        ...initialPanelistList.filter((p) =>
          p.LastName.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (
      initialPanelistList.filter((p) => p.PhoneNumber1.includes(searchTerm))
        .length
    ) {
      result.push(
        ...initialPanelistList.filter((p) =>
          p.PhoneNumber1.includes(searchTerm)
        )
      );
    }
    if (
      initialPanelistList.filter((p) => p.BirthDate.includes(searchTerm)).length
    ) {
      result.push(
        ...initialPanelistList.filter((p) => p.BirthDate.includes(searchTerm))
      );
    }
    if (
      initialPanelistList.filter((p) =>
        p.Gender.includes(getGenderInEnglish(searchTerm))
      ).length
    ) {
      result.push(
        ...initialPanelistList.filter((p) =>
          p.Gender.includes(getGenderInEnglish(searchTerm))
        )
      );
    }
    console.log("filtred", result);
    const panelistUniqueByKey = [
      ...new Map(result.map((item) => [item["PanelistId"], item])).values(),
    ];
    setPanelistList(panelistUniqueByKey);
  };

  const getGenderInFrench = (gender) => {
    return gender === "Male" ? "Masculin" : gender == "Female" ? "Féminin" : "";
  };

  const getGenderInEnglish = (gender) => {
    let masculin = "masculin";
    let feminin = "féminin";
    if (masculin.includes(gender.toLowerCase())) {
      return "Male";
    }
    if (feminin.includes(gender.toLowerCase())) {
      return "Female";
    }
  };

  return (
    <div className="m-sm-30">
      {isLoading ? (
        <div
          style={{
            margin: "auto",
            textAlign: "center",
            fontSize: "18px",
            width: "50%",
            height: "50%",
            marginTop: "180px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {storePanelist.showAlert && (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <SnackbarContentWrapper
                onClose={handleClose}
                variant={storePanelist.alertVariant}
                message={storePanelist.alertMessage}
              />
            </Snackbar>
          )}
          <div className="mb-sm-30">
            <Breadcrumb routeSegments={[{ name: "Liste des panélistes" }]} />
          </div>
          <SimpleCard
            title="Liste des panélistes"
            icon={
              <span style={{ float: "right" }}>
                <>
                  <IconButton
                    color="primary"
                    className="btn-primary"
                    title="Rechercher "
                    onClick={handleClick}
                  >
                    <Icon>search</Icon>
                  </IconButton>

                  {permissions.some(
                    (item) => item == "AllowedToCreatePanelist"
                  ) && (
                    <CreateNaturalPersonPanellist
                      regionList={regionList}
                      categories={categories}
                      onCreatePanelist={handleCreatePanelist}
                    />
                  )}
                  {permissions.some(
                    (item) => item == "AllowedToImportPanelist"
                  ) && (
                    <>
                      <input
                        accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        style={{ display: "none" }}
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => handleImportPanelist(e)}
                        // value={selectedFile}
                        ref={importInputRef}
                      />
                      <label htmlFor="contained-button-file">
                        <IconButton
                          color="primary"
                          className="btn-primary"
                          title="Importer "
                          onClick={(e) => {
                            importInputRef.current.click();
                          }}
                        >
                          <Icon>import_export</Icon>
                        </IconButton>
                      </label>
                    </>
                  )}
                  <IconButton
                    color="primary"
                    className="btn-primary"
                    title="Exporter "
                    onClick={(e) => {
                      exportPanelistListToExcel("NaturalPerson");
                    }}
                  >
                    <Icon>file_download</Icon>
                  </IconButton>
                </>
              </span>
            }
          >
            {showSearch && (
              <TextField
                label="Recherche"
                variant="outlined"
                onChange={(e) => {
                  if (e.target.value != "") {
                    SearchItems(e.target.value);
                  } else if (e.target.value == "") {
                    setPanelistList(initialPanelistList);
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value == "") {
                    setShowSearch(false);
                    setPanelistList(initialPanelistList);
                  }
                }}
                fullWidth
              />
            )}
            <Table
              className="product-table"
              style={{ width: "100%", whiteSpace: "nowrap" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Prénom</TableCell>
                  <TableCell style={{ width: "20%" }}>Nom</TableCell>
                  <TableCell style={{ width: "20%" }}>
                    Date de naissance{" "}
                  </TableCell>
                  <TableCell style={{ width: "15%" }}>Sexe</TableCell>
                  <TableCell style={{ width: "12%" }}>Téléphone</TableCell>
                  <TableCell style={{ width: "2%" }}></TableCell>
                  <TableCell style={{ width: "2%" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {panelistList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((panelist, index) => (
                    <TableRow key={index}>
                      <TableCell>{panelist.FirstName}</TableCell>
                      <TableCell>{panelist.LastName}</TableCell>
                      <TableCell>
                        {moment(panelist.BirthDate).format("Do MMMM YYYY")}
                      </TableCell>
                      <TableCell>
                        {getGenderInFrench(panelist.Gender)}
                      </TableCell>
                      <TableCell>{panelist.PhoneNumber1} </TableCell>
                      <TableCell>
                        {permissions.some(
                          (item) => item == "AllowedToUpdatePanelist"
                        ) && (
                          <div>
                            <Link
                              to={"/panelist/detail/" + panelist.PanelistId}
                              title="Modifier un panéliste"
                              style={{ marginLeft: "-3px" }}
                            >
                              <Icon color="primary">edit</Icon>
                            </Link>
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        {permissions.some(
                          (item) => item == "AllowedToDeletePanelist"
                        ) && (
                          <DeletePanelist
                            panelistToDelete={panelist}
                            onDeletePanelist={handleDeletePanelist}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              className="px-4"
              rowsPerPageOptions={[25]}
              component="div"
              count={panelistList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {/* <div style={{ width: "100%" }}>
                            <DataGrid
                                rows={panelistList}
                                columns={columns}
                                pageSize={25}
                                autoHeight={true}
                            // checkboxSelection
                            // onSelectionModelChange={(e) => { setSelectedPanelist(e.selectionModel) }}
                            />
                        </div> */}
          </SimpleCard>
        </>
      )}
    </div>
  );
}

PanelistAsNaturalPersonPage.propTypes = {
  hideAlert: PropTypes.func.isRequired,
  storePanelist: PropTypes.object.isRequired,
  storeUser: PropTypes.object.isRequired,
  storePanelistCategory: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  getPanelist: PropTypes.func.isRequired,
  createPanelist: PropTypes.func.isRequired,
  deletePanelist: PropTypes.func.isRequired,
  getPanelistList: PropTypes.func.isRequired, //
  importPanelistFromExcel: PropTypes.func.isRequired,
  // exportPanelistListToExcel: PropTypes.func.isRequired,
  getRoleList: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  storePanelist: state.panelist,
  storeUser: state.user,
  storePanelistCategory: state.panelistcategory,
});
export default withStyles(
  {},
  { withTheme: true }
)(
  connect(mapStateToProps, {
    getCategories,
    getRegions,
    getPanelistList,
    importPanelistFromExcel,
    // exportPanelistListToExcel,
    getRoleList,
    getPanelist,
    createPanelist,
    deletePanelist,
    hideAlert,
  })(PanelistAsNaturalPersonPage)
);
