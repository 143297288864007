import React from "react";
import { Breadcrumb, SimpleCard } from "matx";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Icon, Grid, CircularProgress, Table, TableBody, TableCell, TableRow, TableHead, TextField, Checkbox, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { ROLE_IS_EDITING, ROLE_IS_EDITED, roleIsEditing, updateRole, setTableContentLoading } from ".././../../redux/actions/RoleActions";


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    SnackbarColor: {
        background: "#7467EE"
    },
    button: {
        margin: theme.spacing(1)
    },
});

class RoleAndPermissionLineItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            permission: this.props.permission,
            roleList: this.props.roleList,
            initialLineItem: [],
            updatingLineItem: [],
            valuesAreChange: false,
            isSubmitting: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkboxHandleChange = this.checkboxHandleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.permission && this.props.roleList && this.props.roleList.length > 0) {
            let result = [];
            this.props.roleList.forEach(element => {
                result.push({
                    PermissionId: this.props.permission.PermissionId,
                    PermissionName: this.props.permission.Name,
                    RoleId: element.RoleId,
                    Checked: element.Permissions.some(p => p.Name == this.props.permission.Name && p.PermissionId == this.props.permission.PermissionId)
                })
            });
            this.setState({ initialLineItem: result, updatingLineItem: result });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.storeRole.roleList != this.props.storeRole.roleList) {
            this.setState({ roleList: nextProps.storeRole.roleList }, () => {
                this.setInitialLineItemValue(this.state.roleList);
            });
        }
    }

    checkboxHandleChange = (event, roleId) => {
        let result = [];
        this.state.updatingLineItem.forEach(element => {
            if (element.RoleId == roleId) {
                result.push({ PermissionId: element.PermissionId, PermissionName: element.PermissionName, RoleId: element.RoleId, Checked: event.target.checked })
            }
            else {
                result.push(element);
            }
        });
        this.setState({ updatingLineItem: result }, () => {
            if (JSON.stringify(result) === JSON.stringify(this.state.initialLineItem)) {
                this.setState({ valuesAreChange: false }, () => {
                    this.props.roleIsEditing(false, "");
                });
            }
            else {
                this.setState({ valuesAreChange: true }, () => {
                    this.props.roleIsEditing(true, this.props.permission.PermissionId);
                });
            }
        })
    }

    setInitialLineItemValue = (roleList) => {
        if (roleList != null) {
            let result = [];
            roleList.forEach(element => {
                result.push({
                    PermissionId: this.props.permission.PermissionId,
                    PermissionName: this.props.permission.Name,
                    RoleId: element.RoleId,
                    Checked: element.Permissions.some(p => p.Name == this.props.permission.Name && p.PermissionId == this.props.permission.PermissionId)
                })
            });
            this.setState({ initialLineItem: result, updatingLineItem: result });
        }
    }

    handleSubmit = value => {
        this.setState({ isSubmitting: true }, () => {
            this.props.updateRole(this.state.updatingLineItem).then(data => {
                if (data) {
                    this.setState({ isSubmitting: false });
                }
                this.props.setTableContentLoading(true);
                setTimeout(() => {
                    this.props.setTableContentLoading(false);
                }, 700);
            })
        })
    };

    render() {
        const { classes } = this.props;
        return (
            <>
                {this.state.roleList.sort((a, b) => (a.Name > b.Name) ? -1 : 1).map((role, index) => (
                    <TableCell>
                        <Checkbox
                            // disabled={(this.props.storeRole.someRoleisEditing && this.props.permission.PermissionId != this.props.storeRole.rolesPermissionId) || (this.state.isSubmitting) || (role.Name == "Administrateur") || (this.state.permission.Name == "AllowedToManageAccount")}
                            onChange={(e) => this.checkboxHandleChange(e, role.RoleId)}
                            defaultChecked={role.Permissions.some(r => r.Name == this.state.permission.Name)} />
                    </TableCell>
                ))}
                <TableCell>
                    <IconButton onClick={this.handleSubmit}
                        aria-label="Save"

                        disabled={(this.props.storeRole.someRoleisEditing && (this.props.permission.PermissionId != this.props.storeRole.rolesPermissionId)) || (!this.state.valuesAreChange) || (this.state.isSubmitting)} title="Enregistrer">

                        {this.state.isSubmitting ? <CircularProgress size={20} /> : <Icon title="Enregistrer">save</Icon>}
                    </IconButton>
                </TableCell>
            </>
        );
    }
}

const mapStateToProps = state => ({
    roleIsEditing: PropTypes.func.isRequired,
    updateRole: PropTypes.func.isRequired,
    setTableContentLoading: PropTypes.func.isRequired,
    storeRole: state.role,
});
export default (
    withRouter(connect(mapStateToProps, { roleIsEditing, updateRole, setTableContentLoading })(RoleAndPermissionLineItem))
);