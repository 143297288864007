import React from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Grid, CircularProgress, TextField, Icon } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getRoleList } from "app/redux/actions/RoleActions";
import { createUser, setTableContentLoading } from "app/redux/actions/UserActions";


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

class CreateUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            user: {
                UserId: "",
                FirstName: "",
                LastName: "",
                PhoneNumber1: "",
                PhoneNumber2: "",
                Email: "",
                Password: "",
                ConfirmPassword: "",
            },
            RoleList: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectListChange = this.handleSelectListChange.bind(this);
        this.getOptionsFromRoleList = this.getOptionsFromRoleList.bind(this);

    }

    componentDidMount() {
        ValidatorForm.addValidationRule("isPasswordMatch", value => {
            if (value !== this.state.user.Password) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule("isPasswordComplex", value => {
            if (value.match(/[0-9]/g) && value.match(/[A-Z]/g) && value.match(/[a-z]/g) && value.match(/[^a-zA-Z\d]/g) && value.length >= 8) {
                return true;
            }
            return false;
        });

        if (this.props.storeRole.roleList.length > 0) {
            this.getOptionsFromRoleList(this.props.storeRole.roleList);
        }
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleSave = event => {
        this.handleSubmit(this.state.user)
    }

    handleSubmit = value => {
        this.props.createUser(value).then(data => {
            if (data) {
                this.handleClose();
                this.setState({
                    user: {
                        UserId: "",
                        FirstName: "",
                        LastName: "",
                        PhoneNumber1: "",
                        PhoneNumber2: "",
                        Email: "",
                        Password: "",
                        ConfirmPassword: "",
                        Roles: [
                            {

                                RoleId: "",
                                Name: ""
                            }
                        ]
                    }
                }, () => {
                    this.props.setTableContentLoading(true);
                    setTimeout(() => {
                        this.props.setTableContentLoading(false);
                    }, 700);
                });
            }
        });
    };

    handleChange = event => {
        event.persist();
        let userCopy = this.state.user;
        for (let key in userCopy) {
            if (key === event.target.name) {
                userCopy[`${key}`] = event.target.value
            }
        }
        this.setState({ user: userCopy });
    };

    handleSelectListChange = (event, value) => {
        let result = [];
        if (value !== []) {
            value.forEach(element => {
                result.push({ RoleId: element.id, Name: element.title });
            });
            let userCopy = this.state.user;
            userCopy.Roles = result;
            this.setState({ user: userCopy });
        }
    }

    getOptionsFromRoleList = (roles) => {
        if (roles !== undefined && roles.length > 0) {
            let result = [];
            roles.forEach(element => {
                result.push({ title: element.Name, id: element.RoleId });
            });
            this.setState({ RoleList: result });
        }
    }



    render() {
        let { UserId, FirstName, LastName, PhoneNumber1, PhoneNumber2, Email, Password, ConfirmPassword } = this.state.user;
        return (
            <div>
                <IconButton
                    color="primary"
                    className="btn-primary"
                    onClick={this.handleClickOpen}
                    title="Ajouter "
                >
                    <Icon>add</Icon>
                </IconButton>
                <Dialog
                    disableBackdropClick
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Création d'un nouvel utilisateur
                    </DialogTitle>
                    <DialogContent dividers>
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit}
                            onError={errors => null}
                            style={{ marginTop: "10px" }}
                        >
                            <Grid container spacing={12}>

                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Prénom"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="FirstName"
                                        value={FirstName}
                                        validators={["required"]}
                                        errorMessages={["Le nom de l'utilisateur est recquis"]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Nom"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="LastName"
                                        value={LastName}
                                        validators={["required"]}
                                        errorMessages={["Le prénom de l'utilisateur est recquis"]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Email"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="Email"
                                        value={Email}
                                        validators={["required", "isEmail"]}
                                        errorMessages={["this field is required", "email is not valid"]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Mot de passe"
                                        onChange={this.handleChange}
                                        type="password"
                                        name="Password"
                                        value={Password}
                                        validators={["required", "isPasswordComplex"]}
                                        errorMessages={["Le mot de passe de l'utilisateur est recquis", "Mot de passe faible"]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Confirmer le mot de passe"
                                        onChange={this.handleChange}
                                        name="ConfirmPassword"
                                        type="password"
                                        value={ConfirmPassword}
                                        validators={["required", "isPasswordMatch"]}
                                        errorMessages={["Ce champs est recquis", "les mot de passe ne correspondent pas"]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Numéro de téléphone 1"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="PhoneNumber1"
                                        value={PhoneNumber1}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Numéro de téléphone 2"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="PhoneNumber2"
                                        value={PhoneNumber2}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={this.state.RoleList}
                                        getOptionLabel={option => option.title}
                                        filterSelectedOptions
                                        onChange={(event, value) => this.handleSelectListChange(event, value)}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Rôles"
                                                fullWidth

                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSave} color="secondary" variant="contained" disabled={this.props.storeUser.submitting}>
                            Soumettre {this.props.storeUser.submitting ? <CircularProgress size={20} /> : <span></span>}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    createUser: PropTypes.func.isRequired,
    setTableContentLoading: PropTypes.func.isRequired,
    getRoleList: PropTypes.func.isRequired,
    storeRole: state.role,
    storeUser: state.user,
});
export default (
    withRouter(connect(mapStateToProps, { getRoleList, createUser, setTableContentLoading })(CreateUser))
);