import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Grid, CircularProgress, Icon, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { createProject } from "app/redux/actions/ProjectActions";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import guid from "guid";

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);



function CreateProject(props) {

    const {
        storeUser = {},
        ProjectList = [],
        createProject,
        disabled = false
    } = props;

    const [open, setOpen] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [projectListOption, setProjectListOption] = React.useState([]);
    const [request, setRequest] = React.useState({
        Author: storeUser.currentUser.User?.FirstName + " " + storeUser.currentUser.User?.LastName,
        Name: "",
        Description: ""
    });

    useEffect(() => {
        if (request.Name == "") {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);

        }
    }, [request])

    let handleClickOpen = () => {
        setOpen(true);
    };

    let handleClose = () => {
        setOpen(false);
    };

    let handleSubmit = value => {
        setIsSubmitting(true);
        createProject(request).then(data => {
            if (data) {
                setRequest(prevState => { return { ...prevState, Name: "", Description: "" } });
                setIsSubmitting(false);
                handleClose();
            }
        });
    };

    let getProjectListOption = () => {
        let result = [];
        let projectOrder = 0;
        if (ProjectList?.length && storeUser.currentUser?.Permissions?.some(p => p == "AllowedToUpdateProject")) {
            ProjectList.forEach(project => {
                projectOrder += 1;
                result.push({ label: project.Name, id: project.ProjectId, order: projectOrder })
            })
            if (storeUser.currentUser?.Permissions?.some(p => p == "AllowedToCreateProject")) {
                result.push({ label: "Autre", id: "other" })
            }
        }
        else {
            result.push({ label: "Autre", id: "other" })
        }
        setProjectListOption(result);
    }

    return (
        <>
            <IconButton
                // variant="outlined"
                color="primary"
                className="btn-primary"
                onClick={handleClickOpen}
                title="Ajouter "
                disabled={disabled}
            >
                <Icon>add</Icon>
            </IconButton>
            <Dialog
                disableBackdropClick
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Création d'un nouveau projet
                </DialogTitle>
                <DialogContent dividers>
                    <ValidatorForm
                        // ref="form"
                        onSubmit={handleSubmit}
                        onError={errors => null}
                        style={{ marginTop: "10px" }}
                    >
                        <Grid container spacing={12}>

                            <>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Nom du projet"
                                        onChange={(e) => {
                                            let val = e.target?.value;
                                            setRequest(prevState => { return { ...prevState, Name: val } })
                                        }}
                                        type="text"
                                        name="Name"
                                        value={request?.Name}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Description"
                                        onChange={(e) => {
                                            let val = e.target?.value;
                                            setRequest(prevState => { return { ...prevState, Description: val } })
                                        }}
                                        type="text"
                                        name="Description"
                                        value={request?.Description}
                                        fullWidth
                                    />
                                </Grid>
                            </>

                        </Grid>
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="secondary" variant="contained" disabled={isSubmitting || hasMissingValue}>
                        Soumettre {isSubmitting ? <CircularProgress size={20} /> : <span></span>}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}



const mapStateToProps = state => ({
    createProject: PropTypes.func.isRequired,
    storeProject: state.project,
    storeUser: state.user
});
export default (
    withRouter(connect(mapStateToProps, {
        createProject
    })(CreateProject))
);