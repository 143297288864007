import history from "history.js";
import authentificationService from "../../services/authentificationService";
import axios from "axios";
import { api } from "app/services/apiHelpers";

export const GET_ROLE_LIST = "GET_ROLE_LIST";
export const GET_PERMISSION_LIST = "GET_PERMISSION_LIST";

export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export const ROLE_ACTION_LOADING = "ROLE_ACTION_LOADING";
export const ROLE_ACTION_SUCCESS = "ROLE_ACTION_SUCCESS";
export const ROLE_ACTION_ERROR = "ROLE_ACTION_ERROR";
export const ROLE_ACTION_SUBMITING = "ROLE_ACTION_SUBMITING"; //
export const ROLE_ACTION_SUBMITED = "ROLE_ACTION_SUBMITED";
export const ROLE_IS_EDITING = "ROLE_IS_EDITING";
export const ROLE_IS_EDITED = "ROLE_IS_EDITED";
export const ROLE_TABLE_CONTENT_LOADING = "ROLE_TABLE_CONTENT_LOADING";
export const ROLE_TABLE_CONTENT_LOADED = "ROLE_TABLE_CONTENT_LOADED";


export const getRoleList = () => dispatch => {
    dispatch({
        type: ROLE_ACTION_LOADING
    });
    setTimeout(() => {
        axios.get(`${api}` + "role/getroles").then(res => {
            dispatch({
                type: GET_ROLE_LIST,
                data: res.data.RoleList
            });
            dispatch({
                type: GET_PERMISSION_LIST,
                data: res.data.PermissionList
            });
            dispatch({
                type: ROLE_ACTION_SUCCESS
            });
        }).catch(error => {
            return dispatch({
                type: ROLE_ACTION_ERROR,
                data: error
            });
        });
    }, 500);

};

export const createRole = (role) => dispatch => {
    dispatch({ // informe le reducer du rôle qu'un formulaire le concernant est en cours de soumission 
        type: ROLE_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.post(`${api}` + "role/createrole", role).then(res => {
                if (res.data.Success) { // Vérifie que le service a fournit une réponse souhaitée
                    dispatch({ // Fournit la nouvelle liste de rôles au reducer pour sa mise à jour 
                        type: GET_ROLE_LIST,
                        data: res.data.RoleList
                    });
                    dispatch({ // Fournit la nouvelle liste de permission au reducer pour sa mise à jour 
                        type: GET_PERMISSION_LIST,
                        data: res.data.PermissionList
                    });
                    resolve(res.data);
                }
                else {
                    resolve(res.data);
                }
            }).catch(error => {
                return dispatch({
                    type: ROLE_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data.Success) {
            dispatch({ // informe le reducer du rôle que le formulaire le concernant est traité
                type: ROLE_ACTION_SUBMITED
            });
            dispatch({ // informe le reducer du rôle qu'une alerte doit être affiché'
                type: SHOW_ALERT,
                data: "Un rôle a été ajouté avec succès"
            });
            history.push({ // Redirige vers la page de la liste de rôles
                pathname: "/account/roles"
            });
        }
        else {
            dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                type: ROLE_ACTION_ERROR,
                data: data.Message
            });
        }
        return data;
    });
};

export const roleIsEditing = (value, id) => dispatch => {
    if (value) {
        dispatch({
            type: ROLE_IS_EDITING,
            data: id
        });
    }
    else {
        dispatch({
            type: ROLE_IS_EDITED
        });
    }
};

export const updateRole = (role) => dispatch => {
    dispatch({
        type: ROLE_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.post(`${api}` + "role/updaterole", role).then(res => {
                if (res.data.Success) {
                    dispatch({
                        type: GET_ROLE_LIST,
                        data: res.data.RoleList
                    });
                    dispatch({ // Fournit la nouvelle liste de permission au reducer pour sa mise à jour 
                        type: GET_PERMISSION_LIST,
                        data: res.data.PermissionList
                    });
                    resolve(res.data);
                }
                else {
                    resolve(null);
                }
            }).catch(error => {
                return dispatch({
                    type: ROLE_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data != null) {
            dispatch({
                type: ROLE_ACTION_SUBMITED
            });
            dispatch({
                type: SHOW_ALERT,
                data: "Une permission a été modifié avec succès"
            });
            history.push({
                pathname: "/account/roles"
            });
        }
        else {
            dispatch({
                type: ROLE_ACTION_ERROR,
                data: "Erreur de réponse server, merci d'actualiser la page et rassurez-vous que l'operation n'a pas reussit avant de la reprendre"
            });
        }

        return data;
    });
};

export const renameRole = (request) => dispatch => {
    dispatch({
        type: ROLE_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.post(`${api}` + "role/renamerole", request).then(res => {
                if (res.data.Success) {
                    dispatch({
                        type: GET_ROLE_LIST,
                        data: res.data.RoleList
                    });
                    dispatch({ // Fournit la nouvelle liste de permission au reducer pour sa mise à jour 
                        type: GET_PERMISSION_LIST,
                        data: res.data.PermissionList
                    });

                }
                resolve(res.data);
            }).catch(error => {
                return dispatch({
                    type: ROLE_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data != null && data.Success) {
            dispatch({
                type: ROLE_ACTION_SUBMITED
            });
            dispatch({
                type: SHOW_ALERT,
                data: "Un rôle a été modifié avec succès"
            });
            history.push({
                pathname: "/account/roles"
            });
        }
        else {
            dispatch({
                type: ROLE_ACTION_ERROR,
                data: data.Message
            });
        }
        return data;
    });
};

export const deleteRole = role => dispatch => {
    dispatch({
        type: ROLE_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.post(`${api}` + "role/deleterole", role).then(res => {
                if (res.data.Success) {
                    dispatch({
                        type: GET_ROLE_LIST,
                        data: res.data.RoleList
                    });
                    dispatch({ // Fournit la nouvelle liste de permission au reducer pour sa mise à jour 
                        type: GET_PERMISSION_LIST,
                        data: res.data.PermissionList
                    });
                    resolve(res.data);
                }
                else {
                    resolve(res.data);
                }
            }).catch(error => {
                return dispatch({
                    type: ROLE_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data.Success) {
            dispatch({
                type: ROLE_ACTION_SUBMITED
            });
            dispatch({
                type: SHOW_ALERT,
                data: "Un rôle a été supprimer avec succès"
            });
        }
        else {
            dispatch({
                type: ROLE_ACTION_SUBMITED
            });
            dispatch({
                type: ROLE_ACTION_ERROR,
                data: data.Message
            });
        }

        return data;
    });

};


export const hideAlert = () => dispatch => {
    dispatch({
        type: HIDE_ALERT
    });
};

export const setTableContentLoading = (value) => dispatch => {
    if (value) {
        dispatch({
            type: ROLE_TABLE_CONTENT_LOADING
        });
    }
    else {
        dispatch({
            type: ROLE_TABLE_CONTENT_LOADED
        });
    }
};


