import {
    GET_DASHBOARD,
    DASHBOARD_ACTION_LOADING,
    DASHBOARD_ACTION_SUCCESS,
    DASHBOARD_ACTION_ERROR,
} from "../actions/DashboardActions";

const initialState = {
    submitting: false,
    success: false,
    loading: false,
    failed: false,
    dashboard: {},

};

const DashboardReducer = function (state = initialState, action) {
    switch (action.type) {
        case DASHBOARD_ACTION_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                failed: false
            };
        }
        case DASHBOARD_ACTION_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                failed: false
            };
        }
        case DASHBOARD_ACTION_ERROR: {
            return {
                ...state,
                failed: true,
                success: false,
                loading: false
            };
        }
        case GET_DASHBOARD: {
            return {
                ...state,
                dashboard: { ...action.data }
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default DashboardReducer;