import React from "react";

const PanelistRoutes = [
    {
        path: "/panelist/list",
        component: React.lazy(() => import("./PanelistAsNaturalPersonPage"))
    },
    {
        path: "/panelist/company",
        component: React.lazy(() => import("./PanelistAsLegalPersonPage"))
    },
    {
        path: "/panelist/detail/:id",
        component: React.lazy(() => import("./PanelistDetailPage")),
    },
    {
        path: "/panelist/AdvancedSearch",
        component: React.lazy(() => import("./PanelistAdvancedSearchPage")),
    },
]
export default PanelistRoutes;