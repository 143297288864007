export const api = getApiUrl();

function getApiUrl() {
    var host = window.location.hostname;
    switch (host.toLowerCase()) {
        case "localhost":
            //return "http://gninigali-001-site1.ftempurl.com/api/";
            return "https://localhost:44390/api/"

        case "doniyaconseil-gninigali-demo.s3-website.eu-west-3.amazonaws.com":
            return "http://gninigali-001-site1.ftempurl.com/api/";

        case "gninigali-001-site3.ftempurl.com":
            return "http://gninigali-001-site2.ftempurl.com/api/";

        case "gninigali.com":
            return "https://gninigali-services.com/api/";
    }
}