import React, { useEffect } from "react";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRoleList, hideAlert, setTableContentLoading } from "app/redux/actions/RoleActions";
import { Table, TableBody, TableCell, TableRow, TableHead, CircularProgress, TablePagination } from '@material-ui/core';
import { SimpleCard, Breadcrumb } from 'matx';
import CreateRole from "./dialogs/CreateRole";
import RenameRole from "./dialogs/RenameRole";
import DeleteRole from "./dialogs/DeleteRole";
import RoleAndPermissionLineItem from "./components/RoleAndPermissionLineItem";
// import UpdateRole from "./dialogs/UpdateRole";RoleAndPermissionLineItem 
import SnackbarContentWrapper from "../alert/SnackbarContentWrapper"
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles2 = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1)
    }
}));


let roleListLoaded = false;

function RoleListPage(props) {
    const {
        theme,
        storeRole = {},
        getRoleList,
        hideAlert,
        setTableContentLoading,
    } = props;

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const classes = useStyles2();
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);

    useEffect(() => {
        if (storeRole.showAlert) {
            setMessage(storeRole.alertMessage);
            setOpen(true);
            setTimeout(() => {
                hideAlert();
            }, 15000);

        }
        if (storeRole.loading) {
            setIsLoading(true);
        }
        else if (storeRole.success) {
            setIsLoading(false);
        }

    }, [storeRole])

    if (!roleListLoaded) {
        getRoleList();
        roleListLoaded = true;
    }


    function handleClose(event) {
        setOpen(false);
        hideAlert();
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
    };
    return (
        <div className="m-sm-30">
            {isLoading ? (
                <div style={{ margin: "auto", textAlign: "center", fontSize: "18px", width: "50%", height: "50%", marginTop: "180px" }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {storeRole.showAlert && (
                        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                        >
                            <SnackbarContentWrapper onClose={handleClose} variant={storeRole.alertVariant} message={storeRole.alertMessage} />
                        </Snackbar>
                    )}
                    <div className="mb-sm-30">
                        <Breadcrumb routeSegments={[{ name: "Liste des rôles" }]} />
                    </div>
                    <SimpleCard title="Liste des rôles"
                        icon={
                            <>
                                <span style={{ float: "right" }}>
                                    <DeleteRole roleList={storeRole.roleList} />
                                </span>
                                <span style={{ float: "right" }}>
                                    <RenameRole roleList={storeRole.roleList} />
                                </span>
                                <span style={{ float: "right" }}>
                                    <CreateRole permissionList={storeRole.permissionList} />
                                </span>
                            </>}
                    >
                        <Table className="product-table" style={{ width: "100%", whiteSpace: "nowrap" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Permissions / Rôles</TableCell>
                                    {storeRole.roleList.sort((a, b) => (a.Name > b.Name) ? -1 : 1).map((role, index) => (
                                        <TableCell>{role.Name}</TableCell>
                                    ))}
                                    <TableCell style={{ width: "4%" }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {storeRole.roleListTableContentLoading ?
                                    (
                                        <>

                                            <TableRow>

                                            </TableRow>
                                        </>
                                    )
                                    : (
                                        <>
                                            {storeRole.permissionList.sort((a, b) => (a.ShortDescription < b.ShortDescription) ? -1 : 1).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((permission, index) => (
                                                <TableRow key={index}>
                                                    <TableCell >{permission.ShortDescription}</TableCell>
                                                    <RoleAndPermissionLineItem permission={permission} roleList={storeRole.roleList} />
                                                </TableRow>
                                            ))}
                                        </>
                                    )}

                            </TableBody>

                        </Table>
                        <TablePagination
                            className="px-4"
                            rowsPerPageOptions={[25]}
                            component="div"
                            count={storeRole.roleList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "Previous Page"
                            }}
                            nextIconButtonProps={{
                                "aria-label": "Next Page"
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </SimpleCard>
                </>
            )}

        </div>
    );
}

RoleListPage.propTypes = {
    hideAlert: PropTypes.bool.isRequired,
    storeRole: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getRoleList: PropTypes.func.isRequired,
    setTableContentLoading: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    storeRole: state.role,
});

export default withStyles({}, { withTheme: true })(connect(mapStateToProps, { getRoleList, hideAlert, setTableContentLoading })(RoleListPage));
