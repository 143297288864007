import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PanelistGroupExpansionPanel from "./PanelistGroupExpansionPanel";
import UpdateQuizKoboLink from "../dialog/UpdateQuizKoboLink";
import UpdateQuiz from "../dialog/UpdateQuiz";
import { Button, Grid, Icon, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import ViewListIcon from '@material-ui/icons/ViewList';
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from 'react-router';
import { affectPanelistToUser, updateQuiz, exportQuizResponsesListToExcel, mergeKoboExcelResponsesWithPanelistsData } from "app/redux/actions/ProjectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { set } from "lodash";
import axios from "axios";
import { api } from "app/services/apiHelpers";


const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

function QuizExpansionPanel(props) {
    const classes = useStyles();
    const {
        projectId = "",
        quizzes = [],
        onSyncHandleChange,
        permissions = [],
        logedUser = {},
        updateQuiz, 
        mergeKoboExcelResponsesWithPanelistsData,
        // exportQuizResponsesListToExcel
    } = props;

    const [isLoading, setIsLoading] = React.useState(false);
    const [quizzesList, setQuizzesList] = React.useState([]);
    const importInputRef = React.useRef();
    const [selectedFile, setSelectedFile] = React.useState(null);

    useEffect(() => {
        if (quizzesList.length == 0 && quizzes?.length > 0) {
            setQuizzesList(quizzes);
            setIsLoading(false);
        }
    }, []);

    const editButtonClick = (e) => {
        e.stopPropagation();
        alert("TODODODODODO");
    }

    const routerHistory = useHistory();

    const seeQuizResponseButtonClick = (projectId, quizId) => {
        var url = "/projet/quizResponse/" + projectId + "/" + quizId;
        console.log(url);
        routerHistory.push(url);
    }

    const syncButtonClick = (e) => {
        e.stopPropagation();
        onSyncHandleChange();
    }

    let handleUpdateQuiz = value => {
        setIsLoading(true);
        return new Promise((resolve, reject) => {
            updateQuiz(value).then(data => {
                resolve(data);
                setQuizzesList(data.Project?.Quizzes)
                setIsLoading(false);
            });
        });
    };

    const exportQuizResponsesListToExcel = async (e, id) => {
        e.stopPropagation();
        const a = window.document.createElement('a');
        a.href = `${api}` +"project/responses/export/"+ id;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); 
    }

    const handleMergeKoboExcelResponses = (e, id) => {          
        e.stopPropagation();
        setSelectedFile(e.target.files[0]);
        mergeKoboExcelResponsesWithPanelistsData(e.target.files[0], id).then(
          (response) => {
            setSelectedFile(null);
          }
        );
      };

    return (
        <div className={classes.root}>
            {isLoading ?
                (
                    <div style={{
                        margin: "auto", textAlign: "center", fontSize: "18px",
                        width: "50%", height: "50%", marginTop: "180px"
                    }}>
                        <CircularProgress />
                    </div>
                )
                :
                (
                    <>
                        {quizzesList.map((quiz, index) => (
                            <ExpansionPanel key={"expansion-panel-" + quiz.QuizKoboId}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={quiz.QuizKoboId + "content"}
                                    id={quiz.QuizKoboId + "header"}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Grid item xs={10}>
                                        <Typography className={classes.heading} style={{ marginTop: '12px', 'fontWeight': 'bold' }}>{quiz.Name}</Typography>
                                    </Grid>
                                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                                        <div>
                                            <div>
                                                {permissions?.some(item => item == "AllowedToUpdateQuiz") && (
                                                    // <UpdateQuizKoboLink projectId={projectId} quizId={quiz.QuizId} currentLink={quiz.ResponseUrl} />
                                                    <UpdateQuiz
                                                        projectId={projectId}
                                                        quizId={quiz.QuizId}
                                                        quizList={quizzesList}
                                                        currentLink={quiz.ResponseUrl}
                                                        panelistGroupList={quiz.PanelistGroups}
                                                        onHandleUpdateQuiz={handleUpdateQuiz}
                                                    />
                                                )}
                                                <input
                                                    accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                                    style={{ display: "none" }}
                                                    id="contained-button-file"
                                                    type="file"
                                                    onChange={(e) => {
                                                        e.stopPropagation();
                                                        handleMergeKoboExcelResponses(e, projectId);
                                                    }}
                                                    onClick={(e) => {
                                                        e.target.value = null;
                                                    }}
                                                    // value={selectedFile}
                                                    ref={importInputRef}
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <IconButton
                                                        color="primary"
                                                        className="btn-primary"
                                                        title="Importer "
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            importInputRef.current.click();
                                                        }}
                                                    >
                                                        <Icon>file_upload</Icon>
                                                    </IconButton>
                                                </label> 
                                                {window.location.hostname.includes("localhost") && (
                                                    <IconButton
                                                        color="primary"
                                                        className="btn-primary"
                                                        title="Synchroniser"
                                                        onClick={(e) => syncButtonClick(e)}
                                                    >
                                                        <Icon>sync</Icon>
                                                    </IconButton>
                                                )}
                                            </div>
                                        </div>
                                    </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <PanelistGroupExpansionPanel
                                        projectId={projectId}
                                        quizId={quiz.QuizId}
                                        panelistGroupList={quiz.PanelistGroups}
                                        responseUrl={quiz.ResponseUrl}
                                        permissions={permissions}
                                        logedUser={logedUser}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))}
                    </>
                )}
        </div>
    );
}


const mapStateToProps = state => ({
    updateQuiz: PropTypes.func.isRequired,
    mergeKoboExcelResponsesWithPanelistsData:PropTypes.func.isRequired,
    storeProject: state.project,
    storeUser: state.user,
    storePanelistGroup: state.panelistgroup,
});
export default (withRouter(connect(mapStateToProps, {
    updateQuiz,
    mergeKoboExcelResponsesWithPanelistsData
})(QuizExpansionPanel)));
