import React, { useEffect } from "react";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRoleList, } from "app/redux/actions/RoleActions";
import { getQuizResponsesList, hideAlert } from "app/redux/actions/ProjectActions";
import { Table, TableBody, TableCell, TableRow, TableHead, CircularProgress, TablePagination, Popover, Checkbox } from '@material-ui/core';
import { SimpleCard, Breadcrumb } from 'matx';
import SnackbarContentWrapper from "../alert/SnackbarContentWrapper"
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import moment from 'moment';
import 'moment/locale/fr';
import CreateProject from "./dialog/CreateProject";
import { Link } from "react-router-dom";
import { Icon } from "@material-ui/core";
import DeleteProject from "./dialog/DeleteProject";
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import guid from "guid";
import { XGrid } from '@material-ui/x-grid';

const useStyles2 = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1)
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));
let projectLoaded = false;
const columns = [
    { field: 'Question', headerName: 'Questions' },
    { field: 'Response', headerName: 'Réponses', width: 500 },

    // { field: 'Profession', headerName: 'Profession', width: 260 },
];
const rows = [
    {
        id: "71216460-9de2-4aef-980a-015440045ce1",
        Question: "Aliou",
        Response: "Samaké",


    },
    {
        id: "02ec9255-9705-446a-b709-088afb6c53e2",
        Question: "Hamidou",
        Response: "Soumaré",


    },


]

function QuizResponsePage(props) {
    const {
        theme,
        storeUser = {},
        storeProject = {},
        getQuizResponsesList,
        hideAlert,
        project = {},
        quizzes = []
    } = props;

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const classes = useStyles2();
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [quiz, setQuiz] = React.useState(null);
    const [quizId, setQuizId] = React.useState("");
    const [quizColumns, setQuizColumns] = React.useState([]);
    const [quizRows, setQuizRows] = React.useState([]);
    const [quizResponses, setQuizResponses] = React.useState([]);
    const [projectId, setProjectId] = React.useState("");
    const [projects, setProjects] = React.useState([]);
    const openPop = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (projects.length > 0 && quiz == null && quizId != "" && projectId != "") {
            let project = projects.find(p => p.ProjectId == projectId);
            let quiz = project.Quizzes.find(q => q.QuizKoboId == quizId);
            if (quiz != undefined) {
                setQuiz(quiz);
                console.log("storeProject 123456", storeProject);
            }
        }
    }, [quizId, projectId, projects])

    useEffect(() => {
        let match = props.match;
        if (match != undefined) {
            let params = match.params;
            if (params != undefined && params.id != undefined) {
                setQuizId(params.id);
            }
            if (params != undefined && params.projectId != undefined) {
                setProjectId(params.projectId);
            }
        }
        if (storeProject.projectList.length == 0) {
            getQuizResponsesList().then(resp => {
                setProjects(resp);
            });
        }
        else {
            setProjects(storeProject.projectList);
        }
    }, [])

    useEffect(() => {

    }, [storeProject])

    useEffect(() => {
        if (quiz != null) {
            if (quiz.QuizColumns?.length > 0 && quizColumns.length == 0) {
                let result = [];
                quiz.QuizColumns.forEach(element => {
                    result.push({ field: element, width: 120, resizable: true });
                })
                setQuizColumns(result);
            }
            if (quiz.QuizResponses?.length > 0 && quizRows.length == 0) {
                setQuizResponses(quiz.QuizResponses)
            }
        }
    }, [quiz])

    useEffect(() => {
        if (quizResponses.length > 0) {
            let result = [];
            quizResponses.forEach(response => {
                let row = {};
                row["id"] = guid.raw();
                if (response.QuizResponseLineItems.length) {
                    response.QuizResponseLineItems.forEach(item => {
                        row[item.Label] = item.Value;
                    })
                }
                result.push(row);
            })
            setQuizRows(result);
            setIsLoading(false);
        }
    }, [quizResponses])

    return (
        <div className="m-sm-30">
            {isLoading ? (
                <div style={{ margin: "auto", textAlign: "center", fontSize: "18px", width: "50%", height: "50%", marginTop: "180px" }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="mb-sm-30">
                        <Breadcrumb routeSegments={[
                            { name: "Projets", path: "/projet/list" },
                            { name: "Détails du projet", path: "/projet/detail/" + projectId, },
                            { name: "Réponses aux questions" },
                        ]} />
                    </div>
                    <SimpleCard title="Réponses aux questions">
                        <div style={{ width: "100%" }}>
                            <DataGrid
                                rows={quizRows}
                                columns={quizColumns}
                                pageSize={25}
                                autoHeight={true}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                            />
                        </div>
                    </SimpleCard>
                </>
            )}

        </div>
    );
}

QuizResponsePage.propTypes = {
    hideAlert: PropTypes.func.isRequired,
    storeProject: PropTypes.object.isRequired,
    storeUser: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getQuizResponsesList: PropTypes.func.isRequired, // getRegions    
    hideAlert: PropTypes.func.isRequired,
    storeProject: state.project,
    storeUser: state.user,
});
export default withStyles({}, { withTheme: true })(connect(mapStateToProps, { getQuizResponsesList, getRoleList, hideAlert })(QuizResponsePage));
