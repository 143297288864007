import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@material-ui/data-grid';
import { Button, Grid, Icon, Table, TableBody, TableCell, TableRow, TableHead, TablePagination, Checkbox } from "@material-ui/core";
import { getRoleList, } from "app/redux/actions/RoleActions";
import { getUserList, } from "app/redux/actions/UserActions";
import { getGroupList, hideAlert } from "app/redux/actions/PanelistGroupActions";
import { affectPanelistToUser } from "app/redux/actions/ProjectActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AffectPanelistToUser from '../dialog/AffectPanelistToUser';
import moment from 'moment';



const ExpansionPanel = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0
        },
        "&:before": {
            display: "none"
        },
        "&$expanded": {
            margin: "auto"
        }
    },
    expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56
        }
    },
    content: {
        "&$expanded": {
            margin: "12px 0"
        }
    },
    expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiExpansionPanelDetails);





function PanelistGroupExpansionPanel(props) {
    const {
        projectId = "",
        quizId = "",
        responseUrl = "",
        panelistGroupList = [],
        permissions = [],
        storeUser = {},
        logedUser = {},
        storePanelistGroup = {},
        affectPanelistToUser,
        getUserList,
    } = props;

    const allCheckRef = React.useRef();

    const [expanded, setExpanded] = React.useState("");
    const [selectedPanelist, setSelectedPanelist] = React.useState([]);
    const [userListOption, setUserListOption] = React.useState([]);
    const [panelistGroups, setPanelistGroups] = React.useState(panelistGroupList);
    const [currentUser, setCurrentUser] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);
    const [allChecked, setAllChecked] = React.useState(false);

    const handleChange = panel => (event, expanded) => {
        if (allChecked) {
            setAllChecked(false);
            setSelectedPanelist([]);
            console.log("hi hi hi ");
        }
        else {


        }
        setExpanded(expanded ? panel : false);
    };

    useEffect(() => {
        if (storeUser.currentUser.User != null && currentUser == null) {
            setCurrentUser(storeUser.currentUser.User);
        }
        if (storeUser.userList.length == 0 && userListOption.length == 0) {
            getUserList().then(response => {
                if (response.Success) {
                    getUserListOptions(response.UserList);
                }
            })
        }
        else if (storeUser.userList.length > 0 && userListOption.length == 0) {
            getUserListOptions(storeUser.userList);
        }
    }, [])

    const handleAffectPanelistToUserSubmit = (userId, panelistGroupId) => {
        return new Promise((resolve, reject) => {
            let request = { UserId: userId, ProjectId: projectId, QuizId: quizId, PanelistGroupId: panelistGroupId, PanelistIds: selectedPanelist };
            affectPanelistToUser(request).then(data => {
                if (data.Success) {
                    let quiz = data.QuizList.find(q => q.QuizId == quizId);
                    if (quiz) {
                        setPanelistGroups(quiz.PanelistGroups)
                        setAllChecked(false);
                        setSelectedPanelist([]);
                    }

                }
                resolve(true);
            });
        });
    }

    const getUserListOptions = (userList) => {
        let result = [];
        userList.forEach(element => {
            result.push({ label: element.FirstName + " " + element.LastName, id: element.UserId })
        });
        setUserListOption(result);
    }



    return (
        <div style={{ width: "100%" }}>
            {panelistGroups?.map((panelistGroup, index) => (
                <ExpansionPanel
                    square
                    expanded={expanded === panelistGroup.Name}
                    onChange={handleChange(panelistGroup.Name)}
                    key={"expansion-panel-" + panelistGroup.PanelistGroupId}
                >
                    <ExpansionPanelSummary
                        aria-controls={panelistGroup.PanelistGroupId + "-content"}
                        id={panelistGroup.PanelistGroupId + "-header"}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Grid item xs={12}>
                            <Typography>{panelistGroup.Name}</Typography>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelActions>
                        {permissions?.some(item => item == "AllowedToAffectPanelist") && (
                            <AffectPanelistToUser
                                disabled={!selectedPanelist.length > 0}
                                panelistGroupId={panelistGroup.PanelistGroupId} onClick={() => { setExpanded(!expanded) }}
                                onAffectPanelistToUserSubmit={(userId, panelistGroupId) => handleAffectPanelistToUserSubmit(userId, panelistGroupId)}
                                userListOptions={userListOption}
                            />
                        )}

                    </ExpansionPanelActions>
                    <ExpansionPanelDetails>
                        <div className="project-groups-expansionpanel-container" style={{ width: "100%" }}>

                            <Table className="product-table" style={{ width: "100%", whiteSpace: "nowrap" }}>
                                <TableHead>
                                    <TableRow>
                                        {panelistGroup.Type == "NaturalPerson" ?
                                            (
                                                <>
                                                    {permissions?.some(item => item == "AllowedToAffectPanelist") && (
                                                        <TableCell style={{ width: "8%" }}>
                                                            <Checkbox
                                                                key={"all-check-" + panelistGroup.PanelistGroupId}
                                                                value={allChecked}
                                                                checked={allChecked || (selectedPanelist.length == panelistGroup.Panelists.length && selectedPanelist.length > 0)}
                                                                className="firstcheckbox"
                                                                ref={allCheckRef}
                                                                onChange={(e) => {
                                                                    let val = e.target?.checked;
                                                                    setAllChecked(val);
                                                                    if (val) {
                                                                        let result = [];
                                                                        panelistGroup.Panelists.forEach(p => {
                                                                            result.push(p.PanelistId)
                                                                        });
                                                                        setSelectedPanelist(result);
                                                                    }
                                                                    else {
                                                                        setSelectedPanelist([]);
                                                                    }

                                                                }}
                                                            />

                                                        </TableCell>
                                                    )}

                                                    <TableCell style={{ width: "16%" }}>Prénom</TableCell>
                                                    <TableCell style={{ width: "16%" }}>Nom</TableCell>
                                                    {permissions?.some(item => item == "AllowedToAffectPanelist") && (
                                                        <>
                                                            <TableCell style={{ width: "16%" }}>Date de naissance </TableCell>
                                                            <TableCell style={{ width: "12%" }}>Sexe</TableCell>
                                                        </>
                                                    )}
                                                    <TableCell style={{ width: "12%" }}>Téléphone</TableCell>
                                                    <TableCell style={{ width: "10%" }}>Assigné à</TableCell>
                                                    <TableCell style={{ width: "10%" }}></TableCell>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    {permissions?.some(item => item == "AllowedToAffectPanelist") && (
                                                        <TableCell style={{ width: "8%" }}>
                                                            <Checkbox
                                                                value={allChecked}
                                                                className="firstcheckbox"
                                                                onChange={(e) => {
                                                                    let val = e.target?.checked;
                                                                    setAllChecked(val);
                                                                    if (val) {
                                                                        let result = [];
                                                                        panelistGroup.Panelists.forEach(p => {
                                                                            result.push(p.PanelistId)
                                                                        });
                                                                        setSelectedPanelist(result);
                                                                    }
                                                                    else {
                                                                        setSelectedPanelist([]);
                                                                    }

                                                                }}
                                                            />

                                                        </TableCell>
                                                    )}

                                                    <TableCell style={{ width: "20%" }}>Nom</TableCell>
                                                    <TableCell style={{ width: "20%" }}>Email </TableCell>
                                                    <TableCell style={{ width: "20%" }}>Téléphone</TableCell>
                                                    {permissions?.some(item => item == "AllowedToAffectPanelist") && (
                                                        <>
                                                            <TableCell style={{ width: "20%" }}>Date de création</TableCell>
                                                            <TableCell style={{ width: "10%" }}>Assigné à</TableCell>
                                                        </>
                                                    )}
                                                    <TableCell style={{ width: "10%" }}></TableCell>
                                                </>
                                            )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(permissions.some(item => item == "AllowedToAffectPanelist") ?
                                        panelistGroup.Panelists :
                                        panelistGroup.Panelists.filter(p => p?.PanelistAssignments.some(r => r?.UserId == logedUser?.UserId && r?.QuizId == quizId)))                                        
                                        .map((panelist, index) =>
                                        (
                                            <TableRow key={index}>
                                                {panelistGroup.Type == "NaturalPerson" ?
                                                    (
                                                        <>
                                                            {permissions?.some(item => item == "AllowedToAffectPanelist") && (
                                                                <TableCell style={{ width: "8%" }}>
                                                                    <Checkbox
                                                                        checked={selectedPanelist.some(id => id == panelist.PanelistId)}
                                                                        className="firstcheckbox"
                                                                        onChange={(e) => {
                                                                            let val = e.target?.checked;
                                                                            if (val) {
                                                                                let newArray = [...selectedPanelist];
                                                                                newArray.push(panelist.PanelistId);
                                                                                setSelectedPanelist(newArray);
                                                                            }
                                                                            else {
                                                                                let newArray = [...selectedPanelist];
                                                                                setSelectedPanelist(newArray.filter(id => id != panelist.PanelistId));
                                                                                if (allChecked) {
                                                                                    setAllChecked(false);
                                                                                }
                                                                            }
                                                                        }}
                                                                    />

                                                                </TableCell>
                                                            )}
                                                            <TableCell >{panelist.FirstName}</TableCell>
                                                            <TableCell >{panelist.LastName}</TableCell>
                                                            {permissions?.some(item => item == "AllowedToAffectPanelist") && (
                                                                <>
                                                                    <TableCell >{moment(panelist.BirthDate).format('Do MMMM YYYY')}</TableCell>
                                                                    <TableCell >{panelist.Gender == "Male" ? "Masculin" : "Féminin"} </TableCell>
                                                                </>
                                                            )}
                                                            <TableCell >{panelist.PhoneNumber1} </TableCell>
                                                            <TableCell >{panelist.AssignedTo} </TableCell>
                                                            <TableCell style={{ textAlign: 'center' }}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    disabled={panelist.Replied}
                                                                    onClick={(e) => {
                                                                        let quizIdParam = "d[QuizId]=" + quizId;
                                                                        let panelistIdParam = "d[PanelistId]=" + panelist.PanelistId;
                                                                        let panelistGroupIdParam = "d[PanelistGroupId]=" + panelistGroup.PanelistGroupId;
                                                                        window.open(responseUrl + "/?" + quizIdParam + "&" + panelistGroupIdParam + "&" + panelistIdParam, '_blank');
                                                                    }}
                                                                >
                                                                    {panelist.Replied ? "Répondu" : "Répondre"}
                                                                </Button>
                                                            </TableCell>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            {permissions?.some(item => item == "AllowedToAffectPanelist") && (
                                                                <TableCell style={{ width: "8%" }}>
                                                                    <Checkbox
                                                                        value={selectedPanelist.some(id => id == panelist.PanelistId)}
                                                                        className="firstcheckbox"
                                                                        onChange={(e) => {
                                                                            let val = e.target?.checked;
                                                                            if (val) {
                                                                                let newArray = [...selectedPanelist];
                                                                                newArray.push(panelist.PanelistId);
                                                                                setSelectedPanelist(newArray);
                                                                            }
                                                                            else {
                                                                                let newArray = [...selectedPanelist];
                                                                                setSelectedPanelist(newArray.filter(id => id != panelist.PanelistId));
                                                                                if (allChecked) {
                                                                                    setAllChecked(false);
                                                                                }
                                                                            }
                                                                        }}
                                                                    />

                                                                </TableCell>
                                                            )}
                                                            <TableCell >{panelist.LastName}</TableCell>
                                                            <TableCell >{panelist.Email}</TableCell>
                                                            <TableCell >{panelist.PhoneNumber1} </TableCell>
                                                            {permissions?.some(item => item == "AllowedToAffectPanelist") && (
                                                                <>
                                                                    <TableCell >{moment(panelist.BirthDate).format('Do MMMM YYYY')}</TableCell>
                                                                    <TableCell >{panelist.AssignedTo} </TableCell>
                                                                </>
                                                            )}

                                                            <TableCell style={{ textAlign: 'center' }}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    disabled={panelist.Replied}
                                                                    onClick={(e) => {
                                                                        let quizIdParam = "d[QuizId]=" + quizId;
                                                                        let panelistIdParam = "d[PanelistId]=" + panelist.PanelistId;
                                                                        let panelistGroupIdParam = "d[PanelistGroupId]=" + panelistGroup.PanelistGroupId;
                                                                        window.open(responseUrl + "/?" + quizIdParam + "&" + panelistGroupIdParam + "&" + panelistIdParam, '_blank');
                                                                    }}
                                                                >
                                                                    {panelist.Replied ? "Répondu" : "Répondre"}
                                                                </Button>
                                                            </TableCell>
                                                        </>
                                                    )}
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))}
        </div>
    );
}

PanelistGroupExpansionPanel.propTypes = {
    hideAlert: PropTypes.func.isRequired,
    getUserList: PropTypes.func.isRequired,
    storePanelistGroup: PropTypes.object.isRequired,
    storeUser: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    affectPanelistToUser: PropTypes.func.isRequired,
    getUserList: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    storePanelistGroup: state.panelistgroup,
    storeUser: state.user,
});

export default withStyles({}, { withTheme: true })(connect(mapStateToProps, { getUserList, affectPanelistToUser, getRoleList, hideAlert })(PanelistGroupExpansionPanel));

