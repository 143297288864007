import React from "react";
import { MatxLoadable } from "matx";

const UserListPage = MatxLoadable({
    loader: () => import("./UserListPage")
})

const UserRoutes = [
    {
        path: "/account/users",
        component: UserListPage,
        auth: ['AllowedToManageAccount']
    },
    {
        path: "/account/profile",
        component: React.lazy(() => import("./UserProfilePage"))
    }
]
export default UserRoutes;