import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCategories, getCategory, createCategory, updateCategory, deleteCategory, hideAlert } from "app/redux/actions/PanelistCategoryActions";
import { Table, TableBody, TableCell, TableRow, TableHead, CircularProgress, TablePagination } from '@material-ui/core';
import { SimpleCard, Breadcrumb } from 'matx';
import SnackbarContentWrapper from "../alert/SnackbarContentWrapper"
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import CreatePanelistCategory from "./dialog/CreatePanelistCategory";
import { Link } from "react-router-dom";
import DeletePanelistCategory from "./dialog/DeletePanelistCategory";
import { Icon } from "@material-ui/core";
import moment from 'moment';
import 'moment/locale/fr';
import UpdatePanelistCategory from "./dialog/UpdatePanelistCategory";

const useStyles2 = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1)
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

let panelistCategoryLoaded = false;

function PanelistCategoryPage(props) {
    const {
        theme,
        storeUser = {},
        storePanelistCategory = {},
        getCategories,
        createCategory,
        updateCategory,
        deleteCategory,
        hideAlert,
    } = props;

    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const classes = useStyles2();
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);
    const [permissions, setPermissions] = React.useState([]);
    const [categories, setCategories] = React.useState([]);

    useEffect(() => {
        if (storePanelistCategory.categories.length == 0 && categories.length == 0) {
            getCategories().then(response => {
                if (response) {
                    setCategories(response);
                }
            });
        }
        else if (storePanelistCategory.categories.length > 0 && categories.length == 0) {
            setCategories(storePanelistCategory.categories);
        }

    }, []);

    useEffect(() => {
        if (storeUser.currentUser.Permissions) {
            setPermissions(storeUser.currentUser.Permissions);
        }
        if (storeUser.loading != isLoading) {
            setIsLoading(storeUser.loading);
        }
    }, [storeUser])

    useEffect(() => {
        if (storePanelistCategory.showAlert) {
            setOpen(true);
            setTimeout(() => {
                hideAlert();
            }, 15000);

        }
        if (storePanelistCategory.loading != isLoading) {
            setIsLoading(storePanelistCategory.loading);
        }
    }, [storePanelistCategory])

    function handleClose(event) {
        setOpen(false);
        hideAlert();
    }

    const handleUpdateCategory = (category) => {
        return new Promise((resolve, reject) => {
            updateCategory(category).then(data => {
                if (data.Success) {
                    setCategories(data.Categories);
                    resolve(data)
                }
            });
        })
    }

    const handleCreateCategory = (category) => {
        return new Promise((resolve, reject) => {
            createCategory(category).then(data => {
                if (data.Success) {
                    setCategories(data.Categories);
                    resolve(data)
                }
            });
        })
    }

    const handleDeleteCategory = (categoryId) => {
        return new Promise((resolve, reject) => {
            deleteCategory(categoryId).then(data => {
                if (data.Success) {
                    setCategories(data.Categories);
                    resolve(data)
                }
            });
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
    };



    return (
        <div className="m-sm-30">
            {isLoading ? (
                <div style={{ margin: "auto", textAlign: "center", fontSize: "18px", width: "50%", height: "50%", marginTop: "180px" }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {storePanelistCategory.showAlert && (
                        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                        >
                            <SnackbarContentWrapper onClose={handleClose} variant={storePanelistCategory.alertVariant} message={storePanelistCategory.alertMessage} />
                        </Snackbar>
                    )}
                    <div className="mb-sm-30">
                        <Breadcrumb routeSegments={[{ name: "Liste des catégories" }]} />
                    </div>
                    <SimpleCard title="Liste des catégories"
                        icon={<span style={{ float: "right" }}>
                            {permissions?.some(item => item == "AllowedToCreatePanelistCategory") && (
                                <CreatePanelistCategory onCreateCategory={handleCreateCategory} />
                            )}
                        </span>}
                    >
                        <Table className="product-table" style={{ width: "100%", whiteSpace: "nowrap" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: "40%" }}>Catégorie</TableCell>
                                    <TableCell style={{ width: "26%" }}>Date de création</TableCell>
                                    <TableCell style={{ width: "26%" }}>Date de modification</TableCell>
                                    <TableCell style={{ width: "2%" }}></TableCell>
                                    <TableCell style={{ width: "2%" }}></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {categories
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((panelistCategorie, index) =>
                                    (
                                        <TableRow key={index}>
                                            <TableCell >{panelistCategorie.Name}</TableCell>
                                            <TableCell >
                                                {moment(panelistCategorie.CreatedDate).format('Do MMMM YYYY')}
                                            </TableCell>
                                            <TableCell >
                                                {moment(panelistCategorie.LastModifiedDate).format('Do MMMM YYYY')}
                                            </TableCell>
                                            <TableCell>
                                                {permissions?.some(item => item == "AllowedToUpdatePanelistCategory") && (
                                                    <UpdatePanelistCategory
                                                        panelistCategoryToUpdate={panelistCategorie}
                                                        onUpdateCategory={handleUpdateCategory}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {permissions?.some(item => item == "AllowedToDeletePanelistCategory") && (
                                                    <DeletePanelistCategory
                                                        panelistCategoryToDelete={panelistCategorie}
                                                        onDeleteCategory={handleDeleteCategory}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            className="px-4"
                            rowsPerPageOptions={[25]}
                            component="div"
                            count={categories?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "Previous Page"
                            }}
                            nextIconButtonProps={{
                                "aria-label": "Next Page"
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </SimpleCard>
                </>
            )}

        </div>
    );
}

PanelistCategoryPage.propTypes = {
    hideAlert: PropTypes.func.isRequired,
    storePanelistCategory: PropTypes.object.isRequired,
    storeUser: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getCategory: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    updateCategory: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    storePanelistCategory: state.panelistcategory,
    storeUser: state.user,
});
export default withStyles({}, { withTheme: true })(connect(mapStateToProps,
    {
        getCategories,
        getCategory,
        createCategory,
        updateCategory,
        deleteCategory,
        hideAlert
    })(PanelistCategoryPage));
