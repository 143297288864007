import React, { useEffect } from "react";
import { Breadcrumb, SimpleCard } from "matx";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Icon, Grid, CircularProgress, LinearProgress, Table, TableBody, TableCell, TableRow, TableHead, MenuItem, Checkbox } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import SnackbarContentWrapper from "../panelists/alert/SnackbarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import _, { without } from 'underscore';
import { getProject, updateProject, hideAlert, getQuizResponsesList, getProjectList, updateQuiz } from "app/redux/actions/ProjectActions";
import guid from "guid";
import moment from 'moment';
import QuizExpansionPanel from "./accordion/QuizExpansionPanel";
import AddQuizToProject from "./dialog/AddQuizToProject";
import { MatxMenu, MatxSearchBox } from "matx";
import { reject } from "lodash";
import IconButton from "@material-ui/core/IconButton";
import ProjectDashboardLineChart from "./ProjectDashboardLineChart";


const styles = theme => ({
    button: {
        margin: theme.spacing(1)
    },
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);



function ProjectDetail(props) {

    const {
        storeUser = {},
        storeProject = {},
        getProjectList,
        getQuizResponsesList,
        hideAlert,
        updateProject,
        updateQuiz
    } = props;

    const [isLoading, setIsLoading] = React.useState(true);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [projectId, setProjectId] = React.useState("");
    const [quizList, setQuizList] = React.useState([]);
    const [quizListOption, setQuizListOption] = React.useState([]);
    const [panelistGroupListOption, setPanelistGroupListOption] = React.useState([]);

    const [projectToUpdate, setProjectToUpdate] = React.useState({
        ProjectId: "",
        Name: "",
        Description: "",
        Author: "",
        ResponsesCountByMonthInYear: [],
        Quizzes: [
            {
                QuizId: "",
                QuizKoboId: "",
                Name: "",
                Url: "",
                PanelistGroups: [
                    {
                        PanelistGroupId: "",
                        Name: "",
                        Panelists: []
                    }
                ]
            }
        ]
    });

    useEffect(() => {
        let match = props.match;
        if (match != undefined) {
            let params = match.params;
            if (params != undefined && params.id != undefined) {
                setProjectId(params.id);
            }
        }
    }, [])

    useEffect(() => {
        if (projectId != "" && projectToUpdate.ProjectId == "") {
            if (storeProject.projectList.length > 0 && storeProject.quizList.length == 0) {
                setQuizList(storeProject.quizList);
                let projectFromStore = storeProject.projectList.find(p => p.ProjectId == projectId);
                if (projectFromStore) {
                    setProjectToUpdate(projectFromStore);
                }
            }
            else {
                getProjectList().then(response => {
                    if (response.Success) {
                        setQuizList(response.QuizList);
                        let projectFromRequest = response.ProjectList.find(p => p.ProjectId == projectId);
                        if (projectFromRequest) {
                            setProjectToUpdate(projectFromRequest);
                        }
                    }
                });
            }
        }
    }, [projectId])

    useEffect(() => {
        if (projectToUpdate && projectToUpdate.ProjectId != "" && isLoading) {
            setIsLoading(false);
            console.log("project", projectToUpdate);
        }
    }, [projectToUpdate])

    let handleClose = () => {

    }

    let handleSave = event => {
        handleSubmit(projectToUpdate)
    }

    let handleSubmit = value => {
        setIsSubmitting(true);
        updateProject(projectToUpdate).then(data => {
            setIsSubmitting(false);
            if (data) {
            }
        });
    };

    let handleChange = event => {
        // event.persist();
        // let projectCopy = this.state.projectToUpdate;
        // for (let key in projectCopy) {
        //     if (key === event.target.name) {
        //         projectCopy[`${key}`] = event.target.value
        //     }
        // }
        // this.setState({ projectToUpdate: projectCopy });
    };

    let snackbarHandleClose = () => {
        setSnackbarOpen(false);
    };

    let syncHandleChange = () => {
        setIsLoading(true);
        getQuizResponsesList().then(resp => {
            let project = resp.find(p => p.ProjectId == projectId);
            if (project != undefined) {
                setProjectToUpdate(project);
                setIsLoading(false);
            }
        });
    };



    return (
        <div className="m-sm-30">
            {isLoading ? (
                <div style={{
                    margin: "auto", textAlign: "center", fontSize: "18px",
                    width: "50%", height: "50%", marginTop: "180px"
                }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {storeProject.showAlert && (
                        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={snackbarOpen}
                            autoHideDuration={6000}
                            onClose={handleClose}
                        >
                            <SnackbarContentWrapper onClose={handleClose} variant={storeProject.alertVariant} message={storeProject.alertMessage} />
                        </Snackbar>
                    )}
                    <div className="mb-sm-30">
                        <Breadcrumb
                            routeSegments={[
                                { name: "Projets", path: "/projet/list" },
                                { name: projectToUpdate?.Name }
                            ]}
                        />
                    </div>
                    {storeUser.currentUser.Permissions?.some(item => item == "AllowedToAccessProjectDashboard") && (
                        <div className="pb-52">
                            <SimpleCard>
                                <ProjectDashboardLineChart
                                    height="280px"
                                    data={projectToUpdate?.ResponsesCountByMonthInYear}
                                />
                            </SimpleCard>
                        </div>
                    )}
                    {storeUser.currentUser.Permissions?.some(item => item == "AllowedToUpdateProject") && (
                        <SimpleCard title={"Détail du projet:" + " " + `${projectToUpdate?.Name}`}>
                            <div>
                                <ValidatorForm
                                    // ref="form"
                                    onSubmit={handleSubmit}
                                    onError={errors => null}
                                >
                                    <Grid container spacing={6}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label="Nom"
                                                onChange={(e) => {
                                                    let val = e.target?.value;
                                                    setProjectToUpdate(prevState => { return { ...prevState, Name: val } })
                                                }}
                                                type="text"
                                                name="Name"
                                                value={projectToUpdate?.Name}
                                                fullWidth
                                            />
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label="Date de création"
                                                onChange={handleChange}
                                                type="text"
                                                name="CreatedDate"
                                                value={moment(projectToUpdate?.CreatedDate).format('Do MMMM YYYY')}
                                                fullWidth
                                                disabled
                                            />

                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label="Description"
                                                onChange={(e) => {
                                                    let val = e.target?.value;
                                                    setProjectToUpdate(prevState => { return { ...prevState, Description: val } })
                                                }}
                                                type="text"
                                                name="Description"
                                                value={projectToUpdate?.Description}
                                                fullWidth
                                            />
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label="Créé(e) par"
                                                onChange={handleChange}
                                                type="text"
                                                name="Author"
                                                value={projectToUpdate?.Author}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button color="secondary" variant="contained" type="submit" disabled={isSubmitting}>
                                        <Icon>send</Icon>
                                        <span className="pl-8 capitalize">Sauvegarder</span> {isSubmitting ? <CircularProgress size={20} /> : <span></span>}
                                    </Button>
                                </ValidatorForm>
                            </div>
                        </SimpleCard>
                    )}

                    <div className="py-12" />
                    <div className="py-12" />
                    <SimpleCard title="Questionnaires"
                        icon={<span style={{ float: "right" }}>
                            {storeUser.currentUser.Permissions?.some(item => item == "AllowedToCreateQuiz") && (
                                <AddQuizToProject quizList={quizList} projectId={projectId} />
                            )}
                        </span>}
                    >
                        <div style={{ marginTop: "25px" }}>
                            <QuizExpansionPanel
                                projectId={projectToUpdate?.ProjectId}
                                quizzes={projectToUpdate?.Quizzes}
                                onSyncHandleChange={syncHandleChange}
                                permissions={storeUser.currentUser.Permissions}
                                logedUser={storeUser.currentUser.User}
                            />
                        </div>
                    </SimpleCard>
                </>
            )}
        </div>
    );

}

const mapStateToProps = state => ({
    updateProject: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    getProjectList: PropTypes.func.isRequired,
    getProject: PropTypes.func.isRequired,
    updateQuiz: PropTypes.func.isRequired,
    getQuizResponsesList: PropTypes.func.isRequired,
    storeProject: state.project,
    storeUser: state.user,
});
export default (withRouter(connect(mapStateToProps, {
    getProject,
    updateProject,
    hideAlert,
    getQuizResponsesList,
    getProjectList,
    updateQuiz,
})(ProjectDetail)));