import SignUp from "./SignUp";
import SignIn from "./SignIn";
import UserListPage from "../account/UserListPage";
import RoleListPage from "../roles/RoleListPage";
import NotFound from "./NotFound";
import ForgotPassword from "./ForgotPassword";
import UserProfilePage from "../account/UserProfilePage";
import PanelistAsNaturalPersonPage from "../panelists/PanelistAsNaturalPersonPage";
import PanelistAsLegalPersonPage from "../panelists/PanelistAsLegalPersonPage";
import PanelistDetailPage from "../panelists/PanelistDetailPage";
import PanelistAdvancedSearchPage from "../panelists/PanelistAdvancedSearchPage";
import PanelistGroupPage from "../groups/PanelistGroupPage";
import PanelistGroupDetail from "../groups/PanelistGroupDetail";
import PanelistCategoryPage from "../panelistCategories/PanelistCategoryPage";
import ProjectPage from "../projet/ProjectPage";
import ProjectDetailPage from "../projet/ProjectDetailPage";
import QuizResponsePage from "../projet/QuizResponsePage";

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false
    },
    navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};

const sessionRoutes = [
  {
    path: "/session/signup",
    component: SignUp,
    settings
  },
  {
    path: "/session/signin",
    component: SignIn,
    settings
  },
  {
    path: "/session/forgot-password",
    component: ForgotPassword,
    settings
  },
  {
    path: "/session/404",
    component: NotFound,
    settings
  },
  // {
  //   path: "/account/roles",
  //   component: RoleListPage,
  // },
  // {
  //   path: "/account/users",
  //   component: UserListPage
  // },
  {
    path: "/account/profile",
    component: UserProfilePage
  },
  {
    path: "/panelist/list",
    component: PanelistAsNaturalPersonPage
  },
  {
    path: "/panelist/company",
    component: PanelistAsLegalPersonPage
  },
  {
    path: "/panelist/detail/:id",
    component: PanelistDetailPage,

  },
  {
    path: "/panelist/AdvancedSearch",
    component: PanelistAdvancedSearchPage,

  },
  {
    path: "/panelist/groups",
    component: PanelistGroupPage,
  },
  {
    path: "/panelistgroup/detail/:id",
    component: PanelistGroupDetail,
  },
  {
    path: "/category/list",
    component: PanelistCategoryPage,
  },
  {
    path: "/projet/list",
    component: ProjectPage
  },
  {
    path: "/projet/detail/:id",
    component: ProjectDetailPage,

  },
  {
    path: "/projet/quizResponse/:projectId/:id",
    component: QuizResponsePage,
  }
];

export default sessionRoutes;
