import axios from "axios";
import { api } from "app/services/apiHelpers";
import history from "history.js";


export const GET_PANELISTGROUP = "GET_PANELISTGROUP";
export const GET_PANELISTGROUP_LIST = "GET_PANELISTGROUP_LIST";

export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export const PANELISTGROUP_ACTION_LOADING = "PANELISTGROUP_ACTION_LOADING";
export const PANELISTGROUP_ACTION_SUCCESS = "PANELISTGROUP_ACTION_SUCCESS";
export const PANELISTGROUP_ACTION_ERROR = "PANELISTGROUP_ACTION_ERROR";
export const PANELISTGROUP_ACTION_SUBMITING = "PANELISTGROUP_ACTION_SUBMITING"; //
export const PANELISTGROUP_ACTION_SUBMITED = "PANELISTGROUP_ACTION_SUBMITED";
export const PANELISTGROUP_IS_EDITING = "PANELISTGROUP_IS_EDITING";
export const PANELISTGROUP_IS_EDITED = "PANELISTGROUP_IS_EDITED";
export const PANELISTGROUP_TABLE_CONTENT_LOADING = "PANELISTGROUP_TABLE_CONTENT_LOADING";
export const PANELISTGROUP_TABLE_CONTENT_LOADED = "PANELISTGROUP_TABLE_CONTENT_LOADED";


export const getGroup = (panelistgroupId) => dispatch => {
    dispatch({
        type: PANELISTGROUP_ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.get(`${api}` + "panelistgroup/getpanelistgroup/" + panelistgroupId).then(res => {
                dispatch({
                    type: GET_PANELISTGROUP,
                    data: res.data.PanelistGroup
                });
                dispatch({
                    type: PANELISTGROUP_ACTION_SUCCESS
                });
                resolve(res.data.PanelistGroup);
            }).catch(error => {
                return dispatch({
                    type: PANELISTGROUP_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data !== null) {
            dispatch({
                type: PANELISTGROUP_ACTION_SUCCESS
            });
        }
        else {
            dispatch({
                type: PANELISTGROUP_ACTION_ERROR,
                data: "aucun groupe correspondant n'a ete trouve"
            });
        }
        return data;
    });
};

export const getGroupList = () => dispatch => {
    dispatch({
        type: PANELISTGROUP_ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        axios.get(`${api}` + "panelistgroup/list").then(res => {
            dispatch({
                type: GET_PANELISTGROUP_LIST,
                data: res.data.PanelistGroupList
            });

            dispatch({
                type: PANELISTGROUP_ACTION_SUCCESS
            });
            resolve(res.data.PanelistGroupList);
        }).catch(error => {
            return dispatch({
                type: PANELISTGROUP_ACTION_ERROR,
                data: error
            });
        });
    })


};

export const createGroup = (panelistgroup) => dispatch => {
    dispatch({ // informe le reducer du rôle qu'un formulaire le concernant est en cours de soumission 
        type: PANELISTGROUP_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.post(`${api}` + "panelistgroup/createpanelistgroup", panelistgroup).then(res => {
            if (res.data.Success) { // Vérifie que le service a fournit une réponse souhaitée
                dispatch({ // Fournit la nouvelle liste de rôles au reducer pour sa mise à jour 
                    type: GET_PANELISTGROUP_LIST,
                    data: res.data.PanelistGroupList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: PANELISTGROUP_ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({ // informe le reducer du rôle que le formulaire le concernant est traité
            type: PANELISTGROUP_ACTION_SUBMITED
        });
        if (data.Success) {
            dispatch({ // informe le reducer du rôle qu'une alerte doit être affiché'
                type: SHOW_ALERT,
                data: "Un groupe a été ajouté avec succès"
            });
            history.push({
                pathname: "/panelist/groups"
            });
        }
        else {
            dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                type: PANELISTGROUP_ACTION_ERROR,
                data: data.Message
            });
        }
        return data;
    });
};

export const updateGroup = (panelistgroup) => dispatch => {
    dispatch({
        type: PANELISTGROUP_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.post(`${api}` + "panelistgroup/updatepanelistgroup", panelistgroup).then(res => {
                if (res.data.Success) {
                    dispatch({
                        type: GET_PANELISTGROUP,
                        data: res.data.PanelistGroup
                    });
                    dispatch({
                        type: GET_PANELISTGROUP_LIST,
                        data: res.data.PanelistGroupList
                    });
                    dispatch({
                        type: PANELISTGROUP_ACTION_SUBMITED
                    });

                    resolve(res.data);
                }
                else {
                    resolve(res.data);
                }
            }).catch(error => {
                return dispatch({
                    type: PANELISTGROUP_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data.Success) {
            dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
                type: SHOW_ALERT,
                data: "Un groupe de panéliste a été modifié avec succès"
            });
        }
        else {
            dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                type: PANELISTGROUP_ACTION_ERROR,
                data: data.Message
            });
        }

        return data;
    });
};

export const deleteGroup = panelistgroupId => dispatch => {
    dispatch({
        type: PANELISTGROUP_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.get(`${api}` + "panelistgroup/deletepanelistgroup/" + panelistgroupId).then(res => {
                if (res.data.Success) {
                    dispatch({
                        type: GET_PANELISTGROUP_LIST,
                        data: res.data.PanelistGroupList
                    });

                    resolve(res.data);
                }
                else {
                    resolve(res.data);
                }
            }).catch(error => {
                return dispatch({
                    type: PANELISTGROUP_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data.Success) {
            dispatch({
                type: PANELISTGROUP_ACTION_SUBMITED
            });
            dispatch({
                type: SHOW_ALERT,
                data: "Un groupe a été supprimer avec succès"
            });
        }
        else {
            dispatch({
                type: PANELISTGROUP_ACTION_SUBMITED
            });
            dispatch({
                type: PANELISTGROUP_ACTION_ERROR,
                data: data.Message
            });
        }
        return data;
    });

};

export const deletePanelistFromPanelistGroup = (request) => dispatch => {
    dispatch({
        type: PANELISTGROUP_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.post(`${api}` + "panelistgroup/deletepanelistfromgroup", request).then(res => {
                if (res.data.Success) {
                    dispatch({
                        type: GET_PANELISTGROUP,
                        data: res.data.PanelistGroup
                    });
                    dispatch({
                        type: GET_PANELISTGROUP_LIST,
                        data: res.data.PanelistGroupList
                    });
                    dispatch({
                        type: PANELISTGROUP_ACTION_SUBMITED
                    });
                    resolve(res.data);
                }
                else {
                    resolve(res.data);
                }
            }).catch(error => {
                return dispatch({
                    type: PANELISTGROUP_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data.Success) {
            dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
                type: SHOW_ALERT,
                data: "Un panéliste a été supprimé de ce groupe avec succès"
            });
        }
        else {
            dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                type: PANELISTGROUP_ACTION_ERROR,
                data: data.Message
            });
        }
        return data;
    });
};

export const affectPanelistToUser = (request) => dispatch => {
    dispatch({
        type: PANELISTGROUP_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.post(`${api}` + "panelistgroup/affectpanelisttouser", request).then(res => {
                if (res.data.Success) {
                    dispatch({
                        type: GET_PANELISTGROUP,
                        data: res.data.PanelistGroup
                    });
                    dispatch({
                        type: GET_PANELISTGROUP_LIST,
                        data: res.data.PanelistGroupList
                    });
                    dispatch({
                        type: PANELISTGROUP_ACTION_SUBMITED
                    });
                    resolve(res.data);
                }
                else {
                    resolve(res.data);
                }
            }).catch(error => {
                return dispatch({
                    type: PANELISTGROUP_ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        if (data.Success) {
            dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
                type: SHOW_ALERT,
                data: "Panéliste(s) affecté(s) avec succès"
            });
        }
        else {
            dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                type: PANELISTGROUP_ACTION_ERROR,
                data: data.Message
            });
        }
        return data;
    });
};

export const hideAlert = () => dispatch => {
    dispatch({
        type: HIDE_ALERT
    });
};



