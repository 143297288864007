import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";
import MatxVerticalNavExpansionPanel from "./MatxVerticalNavExpansionPanel";
import { withStyles } from "@material-ui/styles";
import localStorageService from "app/services/localStorageService";

const styles = theme => ({
  expandIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(90deg)"
  },
  collapseIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)"
  }
});

class MatxVerticalNav extends Component {
  state = {
    collapsed: true,
    logedUserInformation: null
  };

  componentDidMount() {
    this.setState({ logedUserInformation: localStorageService.getItem("auth_user") })
    console.log("bbbbbbb 12 3.1", localStorageService.getItem("auth_user"));
  }

  renderLevels = data => {
    return data.map((item, index) => {
      if (this.state.logedUserInformation?.Permissions?.some(p => p == item.authorization)) {
        if (item.children) {
          return (
            <MatxVerticalNavExpansionPanel item={item} key={index}>
              {this.renderLevels(item.children)}
            </MatxVerticalNavExpansionPanel>
          );

        } else {
          return (
            <NavLink key={index} to={item.path} className="nav-item">
              <TouchRipple key={item.name} name="child" className="w-100">
                {(() => {
                  if (item.icon) {
                    return (
                      <Icon className="item-icon text-middle">{item.icon}</Icon>
                    );
                  } else {
                    return (
                      <span className="item-icon icon-text">{item.iconText}</span>
                    );
                  }
                })()}
                <span className="text-middle pl-20 item-text">{item.name}</span>
                <div className="mx-auto"></div>
                {item.badge && (
                  <div className={`badge bg-${item.badge.color}`}>
                    {item.badge.value}
                  </div>
                )}
              </TouchRipple>
            </NavLink>
          );
        }
      }

    });
  };

  handleClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <div className="navigation" style={{ marginTop: "15px" }}>
        {this.renderLevels(this.props.navigation)}
      </div>
    );
  }
}

export default withStyles(styles)(MatxVerticalNav);

// export default withStyles(styles)(connect(mapStateToProps, {})(MatxVerticalNav));
