import {
  SET_USER_DATA,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
  GET_USER_LIST,
  USER_ACTION_LOADING,
  USER_ACTION_SUCCESS,
  USER_ACTION_ERROR,
  USER_ACTION_SUBMITING,
  USER_ACTION_SUBMITED,
  SHOW_ALERT,
  HIDE_ALERT,
  USER_TABLE_CONTENT_LOADING,
  USER_TABLE_CONTENT_LOADED, CHANGE_USER_PASSWORD
} from "../actions/UserActions";

const initialState = {
  currentUser: {},
  userList: [],
  loading: false,
  success: false,
  failed: false,
  submitting: false,
  errorMessage: "",
  showAlert: false,
  alertMessage: "",
  alertVariant: "",
  userListTableContentLoading: false
};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        currentUser: { ...action.data }
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state
      };
    }
    case USER_LOGGED_OUT: {
      return state;
    }
    case USER_ACTION_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        failed: false
      };
    }
    case USER_ACTION_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        failed: false,
      };
    }
    case USER_ACTION_ERROR: {
      return {
        ...state,
        failed: true,
        success: false,
        loading: false,
        showAlert: true,
        alertMessage: action.data,
        alertVariant: "error"
      };
    }
    case USER_ACTION_SUBMITING: {
      return {
        ...state,
        submitting: true
      };
    }
    case USER_ACTION_SUBMITED: {
      return {
        ...state,
        submitting: false
      };
    }
    case GET_USER_LIST: {
      return {
        ...state,
        userList: [...action.data]
      };
    }
    // Alert action
    case SHOW_ALERT: {
      return {
        ...state,
        showAlert: true,
        alertMessage: action.data,
        alertVariant: "success"
      };
    }
    case HIDE_ALERT: {
      return {
        ...state,
        showAlert: false,
        alertMessage: "",
        alertVariant: ""
      };
    }
    case USER_TABLE_CONTENT_LOADING: {
      return {
        ...state,
        userListTableContentLoading: true
      };
    }
    case USER_TABLE_CONTENT_LOADED: {
      return {
        ...state,
        userListTableContentLoading: false
      };
    }
    case CHANGE_USER_PASSWORD: {
      return {
        ...state,
        showUpdatedAlert: true,
        showAddedAlert: false,
        showDeletedAlert: false
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
