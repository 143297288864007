import React from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Grid, CircularProgress, TextField, Icon } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { renameRole, setTableContentLoading } from "app/redux/actions/RoleActions";


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

class RenameRole extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            role: {
                RoleId: "",
                Name: "",
                NewName: ""
            },
            RoleOptionList: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.roleList && this.props.roleList.length > 0) {
            this.getOptionsFromRoleList(this.props.roleList);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.storeRole.roleList != this.props.storeRole.roleList) {
            this.getOptionsFromRoleList(nextProps.storeRole.roleList);
        }
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleSave = event => {
        this.handleSubmit(this.state.role)
    }

    handleSubmit = value => {
        this.props.renameRole(value).then(data => {
            if (data) {
                // this.props.getPermissionList();
                this.handleClose();
                this.setState({
                    role: {
                        RoleId: "",
                        Name: "",
                        NewName: ""
                    }
                }, () => {
                    this.getOptionsFromRoleList(this.props.roleList);
                    this.props.setTableContentLoading(true);
                    setTimeout(() => {
                        this.props.setTableContentLoading(false);
                    }, 700);
                });
            }
        });
    };

    handleChange = event => {
        event.persist();
        let roleCopy = this.state.role;
        for (let key in roleCopy) {
            if (key === event.target.name) {
                roleCopy[`${key}`] = event.target.value
            }
        }
        this.setState({ role: roleCopy });
    };

    getOptionsFromRoleList = (roles) => {
        if (roles != undefined && roles.length > 0) {
            let result = [];
            roles.forEach(element => {
                if (element.Name !== "Administrateur") {
                    result.push({ label: element.Name, id: element.RoleId });
                }
            });
            this.setState({ RoleOptionList: result });
        }
    }

    handleSelectListChange = (event, value) => {
        if (value !== undefined && value !== null) {
            let roleCopy = this.state.role;
            roleCopy.RoleId = value.id;
            roleCopy.Name = value.label;
            this.setState({ role: roleCopy });
        }
    }


    render() {
        let { RoleId, Name, NewName } = this.state.role;
        return (
            <div>
                <IconButton
                    // variant="outlined"
                    color="primary"
                    className="btn-primary"
                    onClick={this.handleClickOpen}
                    title="Renommer"
                >
                    <Icon>edit</Icon>
                </IconButton>
                <Dialog
                    disableBackdropClick
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                    fullWidth
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Renommer un rôle
                    </DialogTitle>
                    <DialogContent dividers>
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit}
                            onError={errors => null}
                            style={{ marginTop: "10px" }}
                        >
                            <Grid container spacing={12}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        className="mb-16 w-100"
                                        options={this.state.RoleOptionList}
                                        getOptionLabel={option => option.label}
                                        onChange={(event, value) => this.handleSelectListChange(event, value)}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Liste de rôles"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Nouveau nom"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="NewName"
                                        value={NewName}
                                        validators={["required"]}
                                        errorMessages={["Le nom du rôle est recquis"]}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSave} color="secondary" variant="contained" disabled={this.props.storeRole.submitting}>
                            Soumettre {this.props.storeRole.submitting ? <CircularProgress size={20} /> : <span></span>}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

//export default CreateEntityDialog;

const mapStateToProps = state => ({
    renameRole: PropTypes.func.isRequired,
    setTableContentLoading: PropTypes.func.isRequired,
    storeRole: state.role,
});
export default (
    withRouter(connect(mapStateToProps, { renameRole, setTableContentLoading })(RenameRole))
);