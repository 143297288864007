import React from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { changeUserPassword } from "app/redux/actions/UserActions";


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

class ChangeUserPasswordDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            userToUpdate: this.props.storeUser,
            RoleList: [],
            defaultSelectedRoleList: [],
            passwordItem: {
                OldPassword: "",
                NewPassword: "",
                UserId: "",
                ConfirmPassword: ""
            },
            time: {},
            seconds: 5,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    componentDidMount() {
        ValidatorForm.addValidationRule("isPasswordMatch", value => {
            if (value !== this.state.passwordItem.NewPassword) {
                return false;
            }
            return true;
        });
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
    }
    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
        }
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleSave = event => {
        this.handleSubmit(this.state.passwordItem)
    }

    handleSubmit = value => {
        this.props.changeUserPassword(value).then(data => {
            if (data) {
                this.handleClose();
            }
        });
    };

    handleChange = event => {
        event.persist();
        let itemCopy = this.state.passwordItem;
        for (let key in itemCopy) {
            if (key === event.target.name) {
                itemCopy[`${key}`] = event.target.value;
            }
            if (itemCopy[`UserId`] == "") {
                itemCopy[`UserId`] = this.props.storeUser.currentUser.User.UserId;
            }
        }
        this.setState({ passwordItem: itemCopy });
    };


    render() {
        let { OldPassword, NewPassword, ConfirmPassword, UserId } = this.state.passwordItem;
        return (
            <div>
                <Button variant="outlined" color="default" onClick={this.handleClickOpen}>
                    Changer le mot de passe
                </Button>
                <Dialog disableBackdropClick onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.open} fullWidth={true}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Changement de mot de passe
                    </DialogTitle>
                    <DialogContent dividers>
                        <ValidatorForm ref="form" onSubmit={this.handleSubmit} onError={errors => null}>
                            <Grid container spacing={12}>
                                <Grid item xs={12}>
                                    <TextValidator
                                        fullWidth
                                        className="mb-16 w-100"
                                        label="Ancien mot de passe"
                                        onChange={this.handleChange}
                                        type="password"
                                        name="OldPassword"
                                        value={OldPassword}
                                        validators={["required"]}
                                        errorMessages={["Ce champs est recquis", "les mot de passe ne correspondent pas"]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        fullWidth
                                        className="mb-16 w-100"
                                        label="Nouveau mot de passe"
                                        onChange={this.handleChange}
                                        type="password"
                                        name="NewPassword"
                                        value={NewPassword}
                                        validators={["required"]}
                                        errorMessages={["Le mot de passe de l'utilisateur est recquis"]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        fullWidth
                                        className="mb-16 w-100"
                                        label="Confirmer le nouveau mot de passe"
                                        onChange={this.handleChange}
                                        name="ConfirmPassword"
                                        type="password"
                                        value={ConfirmPassword}
                                        validators={["required", "isPasswordMatch"]}
                                        errorMessages={[
                                            "Ce champs est recquis",
                                            "les mot de passe ne correspondent pas"
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSave} color="secondary" variant="contained" disabled={this.props.storeUser.submitting}>
                            <span className="pl-2 capitalize">Soumettre </span>{this.props.storeUser.submitting ? <CircularProgress size={20} /> : <span></span>}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    changeUserPassword: PropTypes.func.isRequired,
    storeUser: state.user,
    storeRole: state.role
});
export default (
    withRouter(connect(mapStateToProps, {
        changeUserPassword
    })(ChangeUserPasswordDialog))
);