import axios from "axios";
import { api } from "app/services/apiHelpers";
import history from "history.js";

// Action mener au serveur (les appels API)
export const GET_PANELIST = "GET_PANELIST";
export const GET_PANELIST_LIST = "GET_PANELIST_LIST";
export const ADD_PANELIST_TO_LIST = "ADD_PANELIST_TO_LIST";
export const DELETE_PANELIST_FROM_LIST = "DELETE_PANELIST_FROM_LIST";
export const UPDATE_PANELIST = "UPDATE_PANELIST";
export const GET_REGIONLIST = "GET_REGIONLIST";

// Type d'action pour la synchronisation lors des appels API (ils sont utilisees pour resoudre les spiner des boutons submits)
export const PANELIST_ACTION_SUBMITED = "PANELIST_ACTION_SUBMITED";
export const PANELIST_ACTION_SUBMITTING = "PANELIST_ACTION_SUBMITTING";
export const PANELIST_ACTION_LOADING = "PANELIST_ACTION_LOADING";
export const PANELIST_ACTION_SUCCESS = "PANELIST_ACTION_SUCCESS";
export const PANELIST_ACTION_ERROR = "PANELIST_ACTION_ERROR";

// Action sur le statut de reponse server (pour afficher ou cacher le snackbar alert)
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const PANELIST_ADVANCEDSEARCH_TABLE_CONTENT_LOADING = "PANELIST_ADVANCEDSEARCH_TABLE_CONTENT_LOADING";
export const PANELIST_ADVANCEDSEARCH_TABLE_CONTENT_LOADED = "PANELIST_ADVANCEDSEARCH_TABLE_CONTENT_LOADED";

export const getPanelist = (panelistId) => dispatch => {
  dispatch({
    type: PANELIST_ACTION_LOADING
  });
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios.get(`${api}` + "panelist/getpanelist/" + panelistId).then(res => {
        dispatch({
          type: GET_PANELIST,
          data: res.data.Panelist
        });
        dispatch({
          type: GET_PANELIST_LIST,
          data: res.data.PanelistList
        });
        dispatch({
          type: PANELIST_ACTION_SUCCESS
        });
        resolve(res.data.PanelistList);
      })
        .catch(error => {
          return dispatch({
            type: PANELIST_ACTION_ERROR,
            data: error
          });
        });
    }, 500);
  })
    .then(data => {
      if (data !== null) {
        dispatch({
          type: PANELIST_ACTION_SUCCESS
        });
      }
      else {
        dispatch({
          type: PANELIST_ACTION_ERROR,
          data: "aucun panéliste correspondant n'a ete trouve"
        });
      }
      return data;
    });
};

export const getPanelistList = () => dispatch => {
  dispatch({
    type: PANELIST_ACTION_LOADING
  });
  return new Promise((resolve, reject) => {
    axios.get(`${api}` + "panelist/list").then(res => {
      dispatch({
        type: GET_PANELIST_LIST,
        data: res.data.PanelistList
      });
      dispatch({
        type: PANELIST_ACTION_SUCCESS
      });
      resolve(res.data.PanelistList);
    });
  });
};

export const createPanelist = (panelist) => dispatch => {
  dispatch({
    type: PANELIST_ACTION_SUBMITTING
  });
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios.post(`${api}` + "panelist/createpanelist", panelist).then(res => {
        if (res.data.Success) {
          dispatch({
            type: GET_PANELIST_LIST,
            data: res.data.PanelistList
          });
          resolve(res.data);
        }
        else {
          resolve(res.data);
        }
      })
        .catch(error => {
          return dispatch({
            type: PANELIST_ACTION_ERROR,
            data: error
          });
        });
    }, 500);
  })
    .then(data => {
      if (data.Success) {
        dispatch({
          type: PANELIST_ACTION_SUBMITED
        });
        dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
          type: SHOW_ALERT,
          data: "Un panéliste a été ajouté avec succès"
        });
      }
      else {
        dispatch({
          type: PANELIST_ACTION_SUBMITED
        });
        dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
          type: PANELIST_ACTION_ERROR,
          data: data.Message
        });
      }
      return data;
    });
};

export const importPanelistFromExcel = (file, panelistType) => dispatch => {
  dispatch({
    type: PANELIST_ACTION_SUBMITTING
  });
  let formData = new FormData();
  formData.append("file", file);
  formData.append("type", panelistType);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios.post(`${api}` + "panelist/ImportPanelistFromExcel", formData).then(res => {
        if (res.data.Success) {
          dispatch({
            type: GET_PANELIST_LIST,
            data: res.data.PanelistList
          });
          resolve(res.data);
        }
        else {
          resolve(res.data);
        }
      })
        .catch(error => {
          return dispatch({
            type: PANELIST_ACTION_ERROR,
            data: error
          });
        });
    }, 500);
  })
    .then(data => {
      if (data.Success) {
        dispatch({
          type: PANELIST_ACTION_SUBMITED
        });
        dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
          type: SHOW_ALERT,
          data: "Un panéliste a été ajouté avec succès"
        });
      }
      else {
        dispatch({
          type: PANELIST_ACTION_SUBMITED
        });
        dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
          type: PANELIST_ACTION_ERROR,
          data: data.Message
        });
      }
      return data;
    });
};

export const exportPanelistListToExcel = async (type) => {

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    
    const headers = { 'Content-Type': 'blob' };
    const config: AxiosRequestConfig = { method: 'GET', url: (`${api}` +"panelist/export/"+ type), responseType: 'arraybuffer', headers };

    try {
        const response = await axios(config);

        const outputFilename = `${Date.now()}.xls`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        link.setAttribute('hidden', outputFilename)

        // OR you can save/write file locally.
        // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
        throw Error(error);
    }

    
}

export const deletePanelist = panelistId => dispatch => {
  dispatch({
    type: PANELIST_ACTION_SUBMITTING
  });
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios.get(`${api}` + "panelist/deletepanelist/" + panelistId).then(res => {
        if (res.data.Success) {
          dispatch({
            type: DELETE_PANELIST_FROM_LIST,
            data: res.data.PanelistList
          });
        }
        resolve(res.data);
      })
        .catch(error => {
          return dispatch({
            type: PANELIST_ACTION_ERROR,
            data: error
          });
        });
    }, 500);
  })
    .then(data => {
      if (data.Success) {
        dispatch({
          type: PANELIST_ACTION_SUBMITED
        });
        dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
          type: SHOW_ALERT,
          data: "Un panéliste a été supprimé avec succès"
        });
      }
      else {
        dispatch({
          type: PANELIST_ACTION_SUBMITED
        });
        dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
          type: PANELIST_ACTION_ERROR,
          data: data.Message
        });
      }
      return data;
    });

};

export const updatePanelist = (panelist) => dispatch => {
  dispatch({
    type: PANELIST_ACTION_SUBMITTING
  });
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios.post(`${api}` + "panelist/updatepanelist", panelist).then(res => {
        if (res.data.Panelist != null && res.data.PanelistList != null) {
          dispatch({
            type: GET_PANELIST,
            data: res.data.Panelist
          });
          dispatch({
            type: GET_PANELIST_LIST,
            data: res.data.PanelistList
          });
          resolve(res.data);
        }
        else {
          resolve(res.data);
        }
        dispatch({
          type: PANELIST_ACTION_SUBMITED
        });
      })
        .catch(error => {
          return dispatch({
            type: PANELIST_ACTION_ERROR,
            data: error
          });
        });
    }, 500);
  })
    .then(data => {
      if (data.Success) {
        dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
          type: SHOW_ALERT,
          data: "Un panéliste a été modifié avec succès"
        });
      }
      else {
        dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
          type: PANELIST_ACTION_ERROR,
          data: data.Message
        });
      }
      return data;
    });

};

export const setTableContentLoading = (value) => dispatch => {
  if (value) {
    dispatch({
      type: PANELIST_ADVANCEDSEARCH_TABLE_CONTENT_LOADING
    });
  }
  else {
    dispatch({
      type: PANELIST_ADVANCEDSEARCH_TABLE_CONTENT_LOADED
    });
  }
};

export const getRegions = () => dispatch => {
  dispatch({
    type: PANELIST_ACTION_LOADING
  });
  return new Promise((resolve, reject) => {
    axios.get(`${api}` + "location/list").then(res => {
      if (res.data.Success && res.data.Regions) {
        dispatch({
          type: GET_REGIONLIST,
          data: res.data.Regions
        });
        dispatch({
          type: PANELIST_ACTION_SUCCESS
        });
      }
      resolve(res.data);
    })
  }).then(response => {
    return response;
  });
};

export const hideAlert = () => dispatch => {
  dispatch({
    type: HIDE_ALERT
  });
};