import React from "react";
import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const RoleListPage = MatxLoadable({
    loader: () => import("./RoleListPage")
})

const RoleRoutes = [
    {
        path: "/account/roles",
        component: RoleListPage,
        auth: ['AllowedToManageAccount']
    }
]
export default RoleRoutes;