import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Grid, CircularProgress, Icon, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { createGroup } from "app/redux/actions/PanelistGroupActions";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import guid from "guid";

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);


function CreatePanelistGroup(props) {
    const {
        theme,
        disabled = false,
        storePanelistGroup = {},
        panelistIdList = [],
        panelistGroupOptions = [],
        storeUser = {},
        createGroup,
        getUsertList,
        hideAddedAlert,
    } = props;

    // Les variables en state
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [panelistgroup, setPanelistgroup] = React.useState(
        {
            PanelistGroupId: "",
            Name: "",
            Description: "",
        }
    );

    useEffect(() => {
        console.log("panelistGroupOptions", panelistGroupOptions);
    }, [panelistGroupOptions])

    let handleClickOpen = () => {
        setOpen(true);
    };

    let handleClose = () => {
        setOpen(false);
    };

    let handleSave = event => {
        let request = {
            Name: panelistgroup.Name,
            Description: panelistgroup.Description,
            PanelistGroupId: panelistgroup.PanelistGroupId,
            Author: storeUser.currentUser.User.FirstName + " " + storeUser.currentUser.User.LastName,
            PanelistIds: panelistIdList
        }
        handleSubmit(request);
    }

    let handleSubmit = value => {
        createGroup(value).then(data => {
            if (data.Success) {
                setPanelistgroup({ PanelistGroupId: "", Name: "", Description: "" });
            }
            handleClose();
        });
    };



    return (
        <div>
            <IconButton
                // variant="outlined"
                color="primary"
                className="btn-primary"
                onClick={handleClickOpen}
                title="Ajouter "
                disabled={disabled}
            >
                <Icon>add</Icon>
            </IconButton>
            <Dialog
                disableBackdropClick
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {panelistgroup.PanelistGroupId == "other" ? "Création d'un nouveau groupe" : "Mise à jour d'un groupe"}
                </DialogTitle>
                <DialogContent dividers>
                    <ValidatorForm
                        // ref="form"
                        onSubmit={handleSubmit}
                        onError={errors => null}
                        style={{ marginTop: "10px" }}
                    >
                        <Grid container spacing={12}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    // key={guid.raw()}
                                    className="mb-16 w-100"
                                    options={panelistGroupOptions}
                                    getOptionLabel={option => option.label}
                                    onChange={(e, value) => {
                                        setPanelistgroup(prevState => { return { ...prevState, PanelistGroupId: value?.id } });
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Groupes"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            {panelistgroup.PanelistGroupId == "other" && (
                                <>
                                    <Grid item xs={12}>
                                        <TextValidator
                                            className="mb-16 w-100"
                                            label="Nom du groupe"
                                            onChange={(e) => {
                                                setPanelistgroup(prevState => { return { ...prevState, Name: e.target?.value } });
                                            }}
                                            type="text"
                                            name="Name"
                                            value={panelistgroup?.Name}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextValidator
                                            className="mb-16 w-100"
                                            label="Description"
                                            onChange={(e) => {
                                                setPanelistgroup(prevState => { return { ...prevState, Description: e.target?.value } });
                                            }}
                                            type="text"
                                            name="Description"
                                            value={panelistgroup?.Description}
                                            fullWidth
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave} color="secondary" variant="contained" disabled={storePanelistGroup.submitting}>
                        Soumettre {storePanelistGroup.submitting ? <CircularProgress size={20} /> : <span></span>}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => ({
    createGroup: PropTypes.func.isRequired,
    storePanelistGroup: state.panelistgroup,
    storeUser: state.user
});
export default (
    withRouter(connect(mapStateToProps, { createGroup })(CreatePanelistGroup))
);