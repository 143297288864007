import React from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Button, CircularProgress, Icon } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { deleteUser, setTableContentLoading } from "app/redux/actions/UserActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
        
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6" className="delete-h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

class DeleteUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            userToDelete: this.props.userToDelete,
        }

        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }


    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleConfirm = (e) => {
        this.props.deleteUser(this.props.userToDelete.UserId).then(data => {
            if (data) {
                this.handleClose();
                this.props.setTableContentLoading(true);
                setTimeout(() => {
                    this.props.setTableContentLoading(false);
                }, 700);
            }
        });
    };


    render() {
        return (
            <div>
                <a href="javascript:void(0);" onClick={this.handleClickOpen} className="" title="Supprimer">
                    <DeleteIcon />
                </a> 
                <Dialog
                    disableBackdropClick
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                    fullWidth
                    
                >
                    <DialogTitle id="customized-dialog-title" className="dialog-title"  onClose={this.handleClose}>
                        Suppression de l'utilisateur : {this.props.userToDelete.FirstName + " " + this.props.userToDelete.LastName}
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description">
                            Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" disabled={this.props.storeUser.submitting}>
                            Non
                        </Button>
                        <Button onClick={this.handleConfirm} color="secondary" variant="contained" disabled={this.props.storeUser.submitting}>
                            Oui {this.props.storeUser.submitting ? <CircularProgress size={20} /> : <span></span>}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    deleteUser: PropTypes.func.isRequired,
    setTableContentLoading: PropTypes.func.isRequired,
    storeRole: state.role,
    storeUser: state.user,
});
export default (
    withRouter(connect(mapStateToProps, { deleteUser, setTableContentLoading })(DeleteUser))
);