import {
    GET_PANELISTCATEGORY,
    GET_PANELISTCATEGORY_LIST,
    ADD_PANELISTCATEGORY_TO_LIST,
    DELETE_PANELISTCATEGORY_FROM_LIST,
    UPDATE_PANELISTCATEGORY,
    PANELISTCATEGORY_ACTION_SUBMITTING,
    PANELISTCATEGORY_ACTION_SUBMITED,
    PANELISTCATEGORY_ACTION_LOADING,
    PANELISTCATEGORY_ACTION_SUCCESS,
    PANELISTCATEGORY_ACTION_ERROR,
    SHOW_ALERT,
    HIDE_ALERT,
} from "../actions/PanelistCategoryActions";

const initialState = {
    submitting: false,
    success: false,
    loading: false,
    failed: false,
    showAlert: false,
    alertMessage: "",
    alertVariant: "",
    categories: [],
};

const PanelistCategoryReducer = function (state = initialState, action) {
    switch (action.type) {
        // Alert action
        case SHOW_ALERT: {
            return {
                ...state,
                showAlert: true,
                alertMessage: action.data,
                alertVariant: "success"
            };
        }
        case HIDE_ALERT: {
            return {
                ...state,
                showAlert: false,
                alertMessage: "",
                alertVariant: ""
            };
        }
        case PANELISTCATEGORY_ACTION_SUBMITTING: {
            return {
                ...state,
                submitting: true
            };
        }
        case PANELISTCATEGORY_ACTION_SUBMITED: {
            return {
                ...state,
                submitting: false
            };
        }
        case PANELISTCATEGORY_ACTION_LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case PANELISTCATEGORY_ACTION_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false
            };
        }
        case PANELISTCATEGORY_ACTION_ERROR: {
            return {
                ...state,
                failed: true,
                success: false,
                loading: false,
                showAlert: true,
                alertMessage: action.data,
                alertVariant: "error"
            };
        }
        case GET_PANELISTCATEGORY_LIST: {
            return {
                ...state,
                categories: [...action.data]
            };
        }
        case ADD_PANELISTCATEGORY_TO_LIST: {
            return {
                ...state,
                showAddedAlert: true,
                showUpdatedAlert: false,
                showDeletedAlert: false,
                submitting: false
            };
        }
        case DELETE_PANELISTCATEGORY_FROM_LIST: {
            return {
                ...state,
                categories: [...action.data],
                showDeletedAlert: true,
                showAddedAlert: false,
                showUpdatedAlert: false,
                submitting: false
            };
        }
        case UPDATE_PANELISTCATEGORY: {
            return {
                ...state,
                showUpdatedAlert: true,
                showAddedAlert: false,
                showDeletedAlert: false,
                submitting: false
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default PanelistCategoryReducer;