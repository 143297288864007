import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Grid, CircularProgress, Icon, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { createProject, affectQuizAndPanelistGroupToProject, updateQuiz } from "app/redux/actions/ProjectActions";
import { getGroupList } from "app/redux/actions/PanelistGroupActions";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import guid from "guid";

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);


function UpdateQuiz(props) {

    const {
        storeUser = {},
        storePanelistGroup = {},
        quizList = [],
        panelistGroupList = [],
        projectId = "",
        quizId = "",
        currentLink = "",
        getGroupList,
        affectQuizAndPanelistGroupToProject,
        updateQuiz,
        onHandleUpdateQuiz,

    } = props;

    const [open, setOpen] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [quizListOption, setQuizListOption] = React.useState([]);
    const [panelistGroupListOption, setPanelistGroupListOption] = React.useState([]);
    const [request, setRequest] = React.useState({
        ProjectId: projectId,
        QuizId: quizId,
        ResponseUrl: currentLink,
        PanelistGroupIds: panelistGroupList.map(a => a.PanelistGroupId)
    });

    useEffect(() => {
        if (storePanelistGroup.panelistGroupList.length == 0 && panelistGroupListOption.length == 0) {
            getGroupList().then(response => {
                if (response.Success) {
                    getPanelistGroupListOption(response.PanelistGroupList);
                }
            })
        }
        else if (storePanelistGroup.panelistGroupList.length > 0 && panelistGroupListOption.length == 0) {
            getPanelistGroupListOption(storePanelistGroup.panelistGroupList);
        }
        console.log("request", request);
        console.log("panelistGroupList", panelistGroupList);
    }, [])

    useEffect(() => {
        if (request.QuizId == "" ||
            request.QuizId == undefined ||
            request.ResponseUrl == "" ||
            request.ProjectId == "" ||
            request.PanelistGroupIds.length == 0) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
        console.log("request", request);
    }, [request])

    useEffect(() => {
        if (panelistGroupList.length > 0 && request.PanelistGroupIds.length == 0) {
            let result = [];

        }
        console.log("quizList", quizList);
    }, [quizList])

    useEffect(() => {
        if (quizList.length > 0 && quizListOption.length == 0) {
            getQuizListOption();
        }
        console.log("quizList", quizList);
    }, [quizList])

    useEffect(() => {
        if (storePanelistGroup.panelistGroupList.length > 0 && panelistGroupListOption.length == 0) {
            getPanelistGroupListOption(storePanelistGroup.panelistGroupList);
        }
    }, [storePanelistGroup])

    let handleClickOpen = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    let handleClose = () => {
        setOpen(false);
    };

    let handleSubmit = value => {
        setIsSubmitting(true);
        onHandleUpdateQuiz(request).then(data => {
            if (data) {
                // setRequest(prevState => { return { ...prevState, Name: "", Description: "" } });
                setIsSubmitting(false);
                handleClose();
            }
        });
    };

    let getQuizListOption = () => {
        let result = [];
        quizList.forEach(element => {
            result.push({ label: element.Name, id: element.QuizId })
        });
        setQuizListOption(result);
    }

    let getPanelistGroupListOption = (panelistGroupList) => {
        let result = [];
        panelistGroupList.forEach(element => {
            result.push({ label: element.Name, id: element.PanelistGroupId })
        });
        setPanelistGroupListOption(result);
    }


    return (
        <>
            <IconButton
                color="primary"
                className="btn-primary"
                title="Modifier le questionnaire"
                onClick={(e) => handleClickOpen(e)}                
            >
                <Icon>edit</Icon>
            </IconButton>
            <Dialog
                disableBackdropClick
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Modifier un questionnaire
                </DialogTitle>
                <DialogContent dividers>
                    <ValidatorForm
                        // ref="form"
                        onSubmit={handleSubmit}
                        onError={errors => null}
                        style={{ marginTop: "10px" }}
                    >
                        <Grid container spacing={12}>
                            <>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        disabled
                                        defaultValue={quizListOption.find(opt => opt.id == request.QuizId)}
                                        className="mb-16 w-100"
                                        options={quizListOption}
                                        getOptionLabel={option => option.label}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Questionnaires"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        defaultValue={panelistGroupListOption.filter(opt => request.PanelistGroupIds.some(item => item == opt.id))}
                                        className="mb-16 w-100"
                                        options={panelistGroupListOption}
                                        getOptionLabel={option => option.label}
                                        onChange={(event, value) => {
                                            let result = [];
                                            value.forEach(element => {
                                                result.push(element.id);
                                            });
                                            setRequest(prevState => { return { ...prevState, PanelistGroupIds: result } });
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Groupes"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label="Lien vers Kobotoolbox"
                                        onChange={(e) => {
                                            let val = e.target?.value;
                                            setRequest(prevState => { return { ...prevState, ResponseUrl: val } })
                                        }}
                                        type="text"
                                        name="ResponseUrl"
                                        value={request?.ResponseUrl}
                                        fullWidth
                                    />
                                </Grid>
                            </>
                        </Grid>
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="secondary" variant="contained" disabled={isSubmitting || hasMissingValue}>
                        Soumettre {isSubmitting ? <CircularProgress size={20} /> : <span></span>}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}




const mapStateToProps = state => ({
    createProject: PropTypes.func.isRequired,
    getGroupList: PropTypes.func.isRequired,
    affectQuizAndPanelistGroupToProject: PropTypes.func.isRequired,
    updateQuiz: PropTypes.func.isRequired,
    storeProject: state.project,
    storeUser: state.user,
    storePanelistGroup: state.panelistgroup,
});
export default (withRouter(connect(mapStateToProps, {
    createProject,
    getGroupList,
    affectQuizAndPanelistGroupToProject,
    updateQuiz
})(UpdateQuiz)));