import React, { useEffect } from "react";
import { Breadcrumb, SimpleCard } from "matx";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Checkbox, IconButton, Button, Icon, Grid, CircularProgress, FormControlLabel, TextField, Table, TableBody, TableCell, TableRow, TableHead, TablePagination } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes, { any } from "prop-types";
import { withRouter } from "react-router-dom";
import { getPanelistList, getRegions } from "app/redux/actions/PanelistActions";
import { getGroupList, hideAlert } from "app/redux/actions/PanelistGroupActions";
import { getCategories, } from "app/redux/actions/PanelistCategoryActions";
import _, { all, result, without } from 'underscore';
import moment from 'moment';
import CreatePanelistGroup from "./dialogs/CreatePanelistGroup";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@material-ui/data-grid';
import { createGroup } from "app/redux/actions/PanelistGroupActions";


function PanelistAdvancedSearchPage(props) {
    const {
        theme,
        storePanelist = {},
        storePanelistCategory = {},
        storeUser = {},
        storePanelistGroup = {},
        getPanelistList,
        getCategories,
        getRegions,
        getGroupList,
        getUsertList,
        hideAddedAlert,
    } = props;

    const panelistAsLegalPersonColumns = [
        { field: 'PanelistId', hide: true },
        { field: 'FirstName', headerName: 'Prénon', width: 220, hide: true },
        { field: 'LastName', headerName: "Nom de l'entreprise", width: 280 },
        {
            field: 'age',
            headerName: 'Date de création',
            width: 220,
            valueGetter: (params) =>
                moment(`${params.getValue('BirthDate')}`).format('Do MMMM YYYY')
        },
        { field: 'PhoneNumber1', headerName: 'Téléphone', width: 200 },
        { field: 'Email', headerName: 'Email', width: 220 },

    ];

    const panelistAsNaturalPersonColumns = [
        { field: 'PanelistId', hide: true },
        { field: 'FirstName', headerName: 'Prénon', width: 220 },
        { field: 'LastName', headerName: 'Nom', width: 220 },
        {
            field: 'age',
            headerName: 'Date de naissance',
            width: 200,
            valueGetter: (params) =>
                moment(`${params.getValue('BirthDate')}`).format('Do MMMM YYYY')
        },
        {
            field: 'Gender',
            headerName: 'Sexe',
            width: 140,
            renderCell: (params) => {
                let displayName = "";
                params.api.getAllColumns()
                    .map((c) => c.field)
                    .filter((c) => c !== "__check__" && !!c).forEach((f) => {
                        if (f == "Gender") {
                            if (params.getValue(f) == "Male") {
                                displayName = "Masculin"
                            }
                            else {
                                displayName = "Féminin"
                            }
                        }

                    });
                return (
                    <div className="d-flex justify-content-between align-items-right" style={{ cursor: "pointer", textAlign: "right" }}>
                        <span>{displayName}</span>
                    </div>
                );
            }
        },
        { field: 'PhoneNumber1', headerName: 'Téléphone', width: 160 }
    ];


    const [allChecked, setAllChecked] = React.useState(false);
    const [isResetting, setIsResetting] = React.useState(false);
    const [isSearching, setIsSearching] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [panelistList, setPanelistList] = React.useState([]);
    const [panelistGroupList, setPanelistGroupList] = React.useState([]);
    const [selectedPanelist, setSelectedPanelist] = React.useState([]);
    const [filteredPanelist, setFilteredPanelist] = React.useState([]); // 
    const [currentUserPermissions, setCurrentUserPermissions] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [regionList, setRegionList] = React.useState([]);
    const [advancedSearchTermOptions, setAdvancedSearchTermOptions] = React.useState([]);
    const [categoryOptions, setCategoryOptions] = React.useState([]);
    const [townToDisplayOptions, setTownToDisplayOptions] = React.useState([]);
    const [districtToDisplayOptions, setDistrictToDisplayOptions] = React.useState([]);
    const [districtDefaultValue, setDistrictDefaultValue] = React.useState({ label: "", id: "" });
    const [townDefaultValue, setTownDefaultValue] = React.useState({ label: "", id: "" });
    const [regionOptions, setRegionOptions] = React.useState([]);
    const [panelistGroupOptions, setPanelistGroupOptions] = React.useState([{ label: "Autre", id: "other" }]);
    const [panelistType, setPanelistType] = React.useState({ key: "Type", value1: "NaturalPerson", value2: "" });
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);

    const [advancedSearchLineItems, setAdvancedSearchLineItems] = React.useState(
        [
            { key: "", value1: "", value2: "", label: "" }
        ]);

    const [columns, setColumns] = React.useState([]);
    // const [, ] = React.useState([]); 

    useEffect(() => {
        console.log("selectedPanelist", selectedPanelist);
    }, [selectedPanelist])

    useEffect(() => {
        if (storePanelist.panelistList.length == 0) {
            setIsLoading(true);
            getPanelistList().then(response => {
                if (response) {
                    setPanelistList(response);
                    setIsLoading(false);
                    if (storePanelist.regionList.length > 0) {
                        setRegionList(storePanelist.regionList);
                    }
                    else if (storePanelist.regionList.length == 0) {
                        getRegions().then(response => {
                            if (response && response.Regions) {
                                setRegionList(response.Regions);
                            }
                        })
                    }
                    if (storePanelistCategory.categories && storePanelistCategory.categories.length == 0) {
                        getCategories().then(response => {
                            if (response) {
                                setCategories(response);
                            }
                        })
                    }
                    else if (storePanelistCategory.categories.length > 0) {
                        setCategories(storePanelistCategory.categories);
                    }
                    if (storePanelistGroup.panelistGroupList && storePanelistGroup.panelistGroupList.length == 0) {
                        getGroupList().then(response => {
                            if (response) {
                                setPanelistGroupList(response);
                            }
                        })
                    }
                    else if (storePanelistGroup.panelistGroupList.length > 0) {
                        setPanelistGroupList(storePanelistGroup.panelistGroupList);
                    }
                }
                setIsLoading(false);
            });
        }
        else if (storePanelist.panelistList.length > 0) {
            setPanelistList(storePanelist.panelistList);
            setIsLoading(false);
            if (storePanelist.regionList.length > 0) {
                setRegionList(storePanelist.regionList);
            }
            else if (storePanelist.regionList.length == 0) {
                getRegions().then(response => {
                    if (response && response.Regions) {
                        setRegionList(response.Regions);
                    }
                })
            }
            if (storePanelistCategory.categories && storePanelistCategory.categories.length == 0) {
                getCategories().then(response => {
                    if (response) {
                        setCategories(response);
                    }
                })
            }
            else if (storePanelistCategory.categories.length > 0) {
                setCategories(storePanelistCategory.categories);
            }
            if (storePanelistGroup.panelistGroupList && storePanelistGroup.panelistGroupList.length == 0) {
                getGroupList().then(response => {
                    if (response) {
                        setPanelistGroupList(response);
                    }
                })
            }
            else if (storePanelistGroup.panelistGroupList.length > 0) {
                setPanelistGroupList(storePanelistGroup.panelistGroupList);
            }
        }
        getAdvancedSearchTermOptions(storePanelist.advancedSearchTermList);
    }, [])

    useEffect(() => {
        if (panelistList.length > 0 && storePanelist.panelistList) {
            if (!(Object.keys(storePanelist.panelistList).length === Object.keys(panelistList).length
                && Object.keys(storePanelist.panelistList).every(p => storePanelist.panelistList[p] === panelistList[p]))) {
                setPanelistList(storePanelist.panelistList);
            }
        }
    }, [storePanelist])

    useEffect(() => {
        if (storeUser.currentUser.Permissions && currentUserPermissions.length == 0) {
            setCurrentUserPermissions(storeUser.currentUser.Permissions);
        }
    }, [storeUser])

    useEffect(() => {
        if (isResetting) {
            setIsResetting(false);
        }
        console.log("advanced", advancedSearchLineItems);
    }, [advancedSearchLineItems])

    useEffect(() => {
        if (categories && categories.length) {
            getCategoryOptions();
        }
    }, [categories])

    useEffect(() => {
        if (panelistGroupList && panelistGroupList.length) {
            getPanelistGroupOptions();
        }
    }, [panelistGroupList])

    useEffect(() => {
        if (regionList && regionList.length) {
            getRegionOption();
        }
    }, [regionList])

    useEffect(() => {
        if (panelistType) {
            setIsSearching(true);
            setAllChecked(false);
            setAdvancedSearchLineItems([{ key: "", value1: "", value2: "", label: "" }]);
            if (panelistType.value1 == "LegalPerson") {
                setColumns(panelistAsLegalPersonColumns);
                setFilteredPanelist(panelistList.filter(p => p.Type == "LegalPerson"));
            }
            else {
                setColumns(panelistAsNaturalPersonColumns);
                setFilteredPanelist(panelistList.filter(p => p.Type == "NaturalPerson"));
            }
            setTimeout(() => {
                setIsSearching(false);
            }, 300);
        }
    }, [panelistType])

    useEffect(() => {
        if (townToDisplayOptions && townToDisplayOptions.length) {
            if (advancedSearchLineItems.some(term => term.key == "TownName")) {
                let newArray = [...advancedSearchLineItems];
                let regionLine = newArray.find(term => term.key == "RegionName");
                let townLine = newArray.find(term => term.key == "TownName");
                let townLineIndex = newArray.findIndex(term => term.key == "TownName");
                newArray[townLineIndex] = { key: townLine.key, value1: townToDisplayOptions[0].label, value2: townToDisplayOptions[0].id, label: townLine.label };
                setAdvancedSearchLineItems(newArray);
                if (regionLine) {
                    getDistrictToDisplayOption(regionLine.value2, townToDisplayOptions[0].id);
                }
            }
        }
    }, [townToDisplayOptions])

    useEffect(() => {
        if (districtToDisplayOptions && districtToDisplayOptions.length) {
            if (advancedSearchLineItems.some(term => term.key == "DistictName")) {
                let newArray = [...advancedSearchLineItems];
                let districtLine = newArray.find(term => term.key == "DistictName");
                let districtLineIndex = newArray.findIndex(term => term.key == "DistictName");
                newArray[districtLineIndex] = { key: districtLine.key, value1: districtToDisplayOptions[0].label, value2: districtToDisplayOptions[0].id, label: districtLine.label };
                setAdvancedSearchLineItems(newArray);
            }
        }
    }, [districtToDisplayOptions])

    let getAdvancedSearchTermOptions = (value) => {
        if (value && value.length > 0) {
            let result = [];
            value.forEach(element => {
                if (element.value == "TownName" || element.value == "DistictName") {
                    result.push({ label: element.key, key: element.value });
                }
                else {
                    result.push({ label: element.key, key: element.value });
                }
            });
            setAdvancedSearchTermOptions(result);
        }
    };

    let handleReset = () => {
        setAdvancedSearchLineItems({ key: "", value1: "", value2: "" });
    }

    let handleSubmit = () => {
        let panelistListCopy = panelistList;
        let filteredResult = panelistListCopy.filter(p => p.Type == panelistType.value1);
        let newArray = [...advancedSearchLineItems].filter(term => term.key != "" && term.value1 != "");
        newArray.forEach(element => {
            var res = filteredResult.filter((item) => {
                if (element.key == "BirthDate") {
                    return Object.entries(item).some(([key, value]) =>
                        key == element.key &&
                        value != null &&
                        moment(element.value1 + "-01-01").isSameOrBefore(value, 'year') &&
                        moment(element.value2 + "-01-01").isSameOrAfter(value, 'year')
                    );
                }
                else if (element.key == "RegionName" || element.key == "TownName" || element.key == "DistictName") {
                    return Object.entries(item).some(([key, value]) =>
                        (key == "RegionId" || key == "TownId" || key == "DistrictId")
                        && value != null && value.toString().includes(element.value2));
                }
                else if (element.key == "PhoneNumber1" || element.key == "PhoneNumber2") {
                    return Object.entries(item).some(([key, value]) =>
                        (key == "PhoneNumber1" || key == "PhoneNumber2")
                        && value != null && value.toString().startsWith(element.value1)); // 
                }
                else if (element.key == "EmployeesCount") {
                    return Object.entries(item).some(([key, value]) =>
                        (key == "EmployeesCount")
                        && value != null && parseInt(value) >= parseInt(element.value1) && parseInt(value) <= parseInt(element.value2));
                }
                else if (element.key == "Category") {
                    return Object.entries(item).some(([key, value]) =>
                        (key == "Categories")
                        && value?.some(cat => cat.CategoryId == element.value1)
                    );
                }
                else {
                    console.log("advance search", item);
                    return Object.entries(item).some(([key, value]) => key == element.key && value != null && value.toString().includes(element.value1));
                }
            });
            filteredResult = res;
        });
        setFilteredPanelist(filteredResult);
    }

    let handleAddSearchTerm = () => {
        setAdvancedSearchLineItems([...advancedSearchLineItems, { key: "", value1: "", value2: "" }]);
    }

    let getCategoryOptions = () => {
        let result = [];
        categories.forEach(category => {
            result.push({ label: category.Name, id: category.CategoryId, type: category?.Type })
        })
        setCategoryOptions(result);
    }

    let getTownToDisplayOption = (regionId) => {
        let result = [];
        let region = regionList.find(r => r.RegionId == regionId);
        if (region != null && region.Towns != null && region.Towns.length) {
            region.Towns.forEach(town => {
                result.push({ label: town.Name, id: town.TownId, regionId: regionId })
            })
        }
        setTownToDisplayOptions(result);
    }

    let getDistrictToDisplayOption = (regionId, townId) => {
        let result = [];
        let region = regionList.find(r => r.RegionId == regionId);
        if (region != null && region.Towns != null && region.Towns.length) {
            let town = region.Towns.find(t => t.TownId == townId);
            if (town != null && town.Districts != null && town.Districts.length) {
                town.Districts.forEach(district => {
                    result.push({ label: district.Name, id: district.DistrictId })
                })
            }
        }
        setDistrictToDisplayOptions(result);
    }

    let getRegionOption = () => {
        let result = [];
        regionList.forEach(region => {
            result.push({ label: region.Name, id: region.RegionId })
        })
        setRegionOptions(result);
    }

    let getPanelistGroupOptions = () => {
        let result = [{ label: "Autre", id: "other" }];
        panelistGroupList.forEach(group => {
            result.push({ label: group.Name, id: group.PanelistGroupId });
        })
        setPanelistGroupOptions(result);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
    };

    let refreshPage = () => {
        window.location.reload(false);
    };


    return (
        <div className="m-sm-30">
            {isLoading ? (
                <div style={{
                    margin: "auto", textAlign: "center", fontSize: "18px",
                    width: "50%", height: "50%", marginTop: "180px"
                }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="mb-sm-30">
                        <Breadcrumb
                            routeSegments={[
                                { name: "Panélistes", path: "/panelist/list" },
                                { name: "Recherche avancée" }
                            ]}
                        />
                    </div>
                    <SimpleCard title="Recherche avancée"
                        icon={<span style={{ float: "right" }}>
                            <IconButton
                                color="primary"
                                className="btn-primary"
                                title="Ajouter "
                                onClick={handleAddSearchTerm}
                                disabled={advancedSearchLineItems.some(line => line.key == "" || line.value1 == "")}
                            >
                                <Icon>add</Icon>
                            </IconButton></span>}
                    >
                        <div className="advanced-search-container">
                            <ValidatorForm onSubmit={handleSubmit} onError={errors => null}>
                                <Grid container spacing={6}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <RadioGroup
                                            className="mb-4"
                                            value={panelistType?.value1}
                                            onChange={(e) => {
                                                setPanelistType(prevState => { return { ...prevState, value1: e.target?.value } });
                                                setSelectedPanelist([]);
                                                // refreshPage();
                                            }}
                                            row
                                        >
                                            <FormControlLabel
                                                value={"NaturalPerson"}
                                                control={<Radio color="secondary" />}
                                                label="Personne physique"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value={"LegalPerson"}
                                                control={<Radio color="secondary" />}
                                                label="Personne morale"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </Grid>

                                    {advancedSearchLineItems.map((searchItem, index) => (
                                        <>
                                            <Grid item lg={5} md={5} sm={12} xs={12}>
                                                <Autocomplete
                                                    value={{ label: searchItem?.label, key: searchItem?.key }}
                                                    loading={isResetting}
                                                    className="mb-16 w-100"
                                                    options={advancedSearchTermOptions}
                                                    getOptionLabel={option => option.label}
                                                    getOptionDisabled={option => {
                                                        if (option.key == "TownName" && !advancedSearchLineItems.some(t => t.key == "RegionName")) {
                                                            return true;
                                                        }
                                                        else if (option.key == "DistictName" && (!advancedSearchLineItems.some(t => t.key == "TownName"))) {
                                                            return true;
                                                        }
                                                        else if (panelistType.value1 == "LegalPerson" && (option.key == "EducationLevel"
                                                            || option.key == "Income"
                                                            || option.key == "Gender"
                                                            || option.key == "FirstName"
                                                            || option.key == "Type"
                                                            || option.key == "Profession")) {

                                                            return true;
                                                        }
                                                        else if (panelistType.value1 == "NaturalPerson" && (option.key == "EmployeesCount" || option.key == "Type")) {
                                                            return true;
                                                        }
                                                        else {
                                                            return option.key != searchItem.key && advancedSearchLineItems.some(t => t.key == option.key);
                                                        }

                                                    }}
                                                    onChange={(event, value) => {
                                                        let newArray = [...advancedSearchLineItems];
                                                        newArray[index] = { key: value?.key, value1: "", value2: "", label: value?.label }
                                                        setAdvancedSearchLineItems(newArray);
                                                    }}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label="Option de recherche"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            {(searchItem.key == "PhoneNumber1" || searchItem.key == "PhoneNumber2") ? (
                                                <>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <TextField
                                                            className="mb-16 w-100"
                                                            label="Entrer l'information ici"
                                                            type={"number"}
                                                            name="value"
                                                            value={searchItem?.value1}
                                                            onInput={(e) => {
                                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8)
                                                            }}
                                                            onChange={(event) => {
                                                                let newArray = [...advancedSearchLineItems];
                                                                let currentValue = newArray[index];
                                                                newArray[index] = { key: currentValue.key, value1: event.target?.value, value2: currentValue.value2, label: currentValue.label }
                                                                setAdvancedSearchLineItems(newArray);
                                                            }}
                                                            disabled={searchItem.key === ""}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </>
                                            ) : searchItem.key == "Email" ?
                                                (
                                                    <>
                                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                                            <TextValidator
                                                                className="mb-16 w-100"
                                                                label="Entrer l'information ici"
                                                                type={"email"}
                                                                name="value"
                                                                value={searchItem?.value1}
                                                                onChange={(event) => {
                                                                    let newArray = [...advancedSearchLineItems];
                                                                    let currentValue = newArray[index];
                                                                    newArray[index] = { key: currentValue.key, value1: event.target?.value, value2: currentValue.value2, label: currentValue.label }
                                                                    setAdvancedSearchLineItems(newArray);
                                                                }}
                                                                disabled={searchItem.key === ""}
                                                                validators={["isEmail"]}
                                                                errorMessages={["l'email est incorrecte"]}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                                : (searchItem.key == "FirstName" || searchItem.key == "LastName") ?
                                                    (
                                                        <>
                                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                <TextValidator
                                                                    className="mb-16 w-100"
                                                                    label="Entrer l'information ici"
                                                                    type={"text"}
                                                                    name="value"
                                                                    value={searchItem?.value1}
                                                                    onChange={(event) => {
                                                                        let newArray = [...advancedSearchLineItems];
                                                                        let currentValue = newArray[index];
                                                                        newArray[index] = { key: currentValue.key, value1: event.target?.value, value2: currentValue.value2, label: currentValue.label }
                                                                        setAdvancedSearchLineItems(newArray);
                                                                    }}
                                                                    disabled={advancedSearchLineItems.some(line => line.key === "")}
                                                                    fullWidth
                                                                />
                                                            </Grid>

                                                        </>
                                                    ) : searchItem.key == "BirthDate" ?
                                                        (
                                                            <>
                                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardDatePicker
                                                                            fullWidth
                                                                            name="startDate"
                                                                            className="mb-16 w-100"
                                                                            margin="none"
                                                                            label="Date de debut"
                                                                            inputVariant="standard"
                                                                            type="text"
                                                                            autoOk={true}
                                                                            views={["year"]}
                                                                            maxDate={searchItem.value2 != "" ? searchItem.value2 : "2100-01-01"}
                                                                            value={searchItem.value1}
                                                                            onChange={(date) => {
                                                                                let newArray = [...advancedSearchLineItems];
                                                                                let currentValue = newArray[index];
                                                                                newArray[index] = { key: currentValue.key, value1: moment(date).format("YYYY"), value2: currentValue.value2, label: currentValue.label }
                                                                                setAdvancedSearchLineItems(newArray);
                                                                            }}
                                                                            KeyboardButtonProps={{
                                                                                "aria-label": "change date"
                                                                            }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Grid>
                                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardDatePicker
                                                                            fullWidth
                                                                            name="endDate"
                                                                            className="mb-16 w-100"
                                                                            margin="none"
                                                                            label="Date de fin"
                                                                            inputVariant="standard"
                                                                            type="text"
                                                                            autoOk={true}
                                                                            views={["year"]}
                                                                            minDate={searchItem.value != "" ? searchItem.value : "1900-01-01"}
                                                                            value={searchItem.value2}
                                                                            onChange={(date) => {
                                                                                let newArray = [...advancedSearchLineItems];
                                                                                let currentValue = newArray[index];
                                                                                newArray[index] = { key: currentValue.key, value1: currentValue.value1, value2: moment(date).format("YYYY"), label: currentValue.label }
                                                                                setAdvancedSearchLineItems(newArray);
                                                                            }}
                                                                            KeyboardButtonProps={{
                                                                                "aria-label": "change date"
                                                                            }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Grid>
                                                            </>
                                                        ) : searchItem.key == "Gender" ?
                                                            (
                                                                <>
                                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                        <RadioGroup
                                                                            className="mb-4"
                                                                            value={searchItem.value}
                                                                            name="Gender"
                                                                            onChange={(e) => {
                                                                                let newArray = [...advancedSearchLineItems];
                                                                                let currentValue = newArray[index];
                                                                                newArray[index] = { key: currentValue.key, value1: e.target?.value, value2: currentValue.value2, label: currentValue.label }
                                                                                setAdvancedSearchLineItems(newArray);
                                                                            }}
                                                                            row
                                                                        >
                                                                            <FormControlLabel
                                                                                value={"Male"}
                                                                                control={<Radio color="secondary" />}
                                                                                label="Masculin"
                                                                                labelPlacement="end"
                                                                            />
                                                                            <FormControlLabel
                                                                                value={"Female"}
                                                                                control={<Radio color="secondary" />}
                                                                                label="Féminin"
                                                                                labelPlacement="end"
                                                                            />
                                                                        </RadioGroup>
                                                                    </Grid>

                                                                </>
                                                            ) : (searchItem.key == "Income" || searchItem.key == "EducationLevel" || searchItem.key == "Profession") ?
                                                                (
                                                                    <>
                                                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                            <Autocomplete
                                                                                className="mb-16 w-100"
                                                                                options={searchItem.key == "Income" ? storePanelist.incomeOptions
                                                                                    : searchItem.key == "EducationLevel" ? storePanelist.educationLevelOptions
                                                                                        : storePanelist.professionOptions}
                                                                                getOptionLabel={option => option.label}
                                                                                onChange={(e, value) => {
                                                                                    let newArray = [...advancedSearchLineItems];
                                                                                    let currentValue = newArray[index];
                                                                                    newArray[index] = { key: currentValue.key, value1: value.label, value2: currentValue.value2, label: currentValue.label }
                                                                                    setAdvancedSearchLineItems(newArray);
                                                                                }}
                                                                                // defaultValue={{ label: this.state.lineItem.value }}
                                                                                // onChange={(event, value) => this.SelectListChange(event, value, "Income")}
                                                                                // onBlur={this.fieldHandleBlur}
                                                                                renderInput={params => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        label="Entrer l'information ici"
                                                                                        variant="outlined"
                                                                                        fullWidth

                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Grid>

                                                                    </>
                                                                ) : searchItem.key == "Category" ?
                                                                    (
                                                                        <>
                                                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                                <Autocomplete
                                                                                    className="mb-16 w-100"
                                                                                    options={panelistType?.value1 == "NaturalPerson" ? categoryOptions?.filter(item => item.type == "NaturalPerson") : categoryOptions?.filter(item => item.type == "LegalPerson")}
                                                                                    getOptionLabel={option => option.label}
                                                                                    onChange={(e, value) => {
                                                                                        let newArray = [...advancedSearchLineItems];
                                                                                        let currentValue = newArray[index];
                                                                                        newArray[index] = { key: currentValue.key, value1: value.id, value2: currentValue.value2, label: currentValue.label }
                                                                                        setAdvancedSearchLineItems(newArray);
                                                                                    }}
                                                                                    renderInput={params => (
                                                                                        <TextField
                                                                                            {...params}
                                                                                            label="Entrer l'information ici"
                                                                                            variant="outlined"
                                                                                            fullWidth

                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </Grid>

                                                                        </>
                                                                    ) : searchItem.key == "EmployeesCount" ?
                                                                        (
                                                                            <>
                                                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                                    <TextValidator
                                                                                        className="mb-16 w-100"
                                                                                        label="Entrer l'information ici"
                                                                                        type={"number"}
                                                                                        name="value"
                                                                                        value={searchItem?.value1}
                                                                                        onChange={(event) => {
                                                                                            let newArray = [...advancedSearchLineItems];
                                                                                            let currentValue = newArray[index];
                                                                                            newArray[index] = { key: currentValue.key, value1: event.target?.value, value2: currentValue.value2, label: currentValue.label }
                                                                                            setAdvancedSearchLineItems(newArray);
                                                                                        }}
                                                                                        disabled={searchItem.key === ""}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                                    <TextValidator
                                                                                        className="mb-16 w-100"
                                                                                        label="Entrer l'information ici"
                                                                                        type={"number"}
                                                                                        name="value"
                                                                                        value={searchItem?.value2}
                                                                                        onChange={(event) => {
                                                                                            let newArray = [...advancedSearchLineItems];
                                                                                            let currentValue = newArray[index];
                                                                                            newArray[index] = { key: currentValue.key, value1: currentValue.value1, value2: event.target?.value, label: currentValue.label }
                                                                                            setAdvancedSearchLineItems(newArray);
                                                                                        }}
                                                                                        disabled={searchItem.key === ""}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                            </>
                                                                        )

                                                                        : (searchItem.key == "RegionName" || searchItem.key == "TownName" || searchItem.key == "DistictName") &&
                                                                        (
                                                                            <>
                                                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                                    <Autocomplete
                                                                                        value={{ label: searchItem?.value1, id: searchItem?.value2 }}
                                                                                        className="mb-16 w-100"
                                                                                        options={
                                                                                            searchItem.key == "RegionName" ? regionOptions
                                                                                                : searchItem.key == "TownName" ? townToDisplayOptions
                                                                                                    : districtToDisplayOptions
                                                                                        }
                                                                                        getOptionLabel={option => option.label}
                                                                                        onChange={(e, value) => {
                                                                                            let newArray = [...advancedSearchLineItems];
                                                                                            let currentValue = newArray[index];
                                                                                            newArray[index] = { key: currentValue?.key, value1: value?.label, value2: value?.id, label: currentValue.label }
                                                                                            setAdvancedSearchLineItems(newArray);
                                                                                            if (searchItem.key == "RegionName") {
                                                                                                getTownToDisplayOption(value?.id)
                                                                                            }
                                                                                            else if (searchItem.key == "TownName") {
                                                                                                let regionLine = newArray.find(t => t.key == "RegionName");
                                                                                                if (regionLine) {
                                                                                                    getDistrictToDisplayOption(regionLine.value2, value.id);
                                                                                                }
                                                                                            }

                                                                                        }}
                                                                                        renderInput={params => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                label="Entrer l'information ici"
                                                                                                variant="outlined"
                                                                                                fullWidth

                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid>

                                                                            </>
                                                                        )}
                                            {searchItem.key != "" && (
                                                <Grid item lg={1} md={1} sm={12} xs={12}>
                                                    <IconButton
                                                        aria-label="Delete"
                                                        disabled={advancedSearchLineItems.length > 1 ? false : true}
                                                        onClick={(e) => {
                                                            let newArray = [...advancedSearchLineItems];
                                                            setAdvancedSearchLineItems(newArray.filter(term => term.key != searchItem.key));
                                                        }}
                                                    >
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </Grid>
                                            )}

                                        </>
                                    ))}
                                    <Grid item xs={12} style={{ textAlign: 'right', marginBottom: '20px' }}>
                                        <Button variant="outlined" color="default" type="submit" disabled={isResetting} onClick={(e) => {
                                            setIsResetting(true);
                                            setAdvancedSearchLineItems([{ key: "", value1: "", value2: "", label: "" }]);
                                        }}>
                                            <span className="pl-2 capitalize">Réinitialiser</span> {isResetting ? <CircularProgress size={20} /> : <span></span>}
                                        </Button>
                                        <Button color="secondary" variant="contained" onClick={handleSubmit} disabled={isSearching} style={{ marginLeft: '15px' }}>
                                            <Icon>search</Icon>
                                            <span className="pl-2 capitalize">Lancer la recherche</span> {isSearching ? <CircularProgress size={20} /> : <span></span>}
                                        </Button>
                                    </Grid>
                                </Grid>

                            </ValidatorForm>
                        </div>

                    </SimpleCard>
                    <div className="py-12" />
                    <div className="py-12" />
                    <SimpleCard
                        title="Liste des panélistes"
                        icon={
                            <span style={{ float: "right" }}  >
                                {currentUserPermissions.some(item => item == "AllowedToCreatePanelistGroup" || item == "AllowedToUpdatePanelistGroup") && (
                                    <CreatePanelistGroup
                                        panelistIdList={selectedPanelist}
                                        disabled={selectedPanelist.length == 0}
                                        panelistGroupOptions={panelistGroupOptions}
                                    />
                                )}
                            </span>}
                    >
                        {isSearching ? (
                            <div style={{
                                margin: "auto", textAlign: "center", fontSize: "18px",
                                width: "50%", height: "50%", marginTop: "80px"
                            }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <>
                                <Table className="product-table advanced-search-panelist-results-container" style={{ width: "100%", whiteSpace: "nowrap" }}>
                                    <TableHead>
                                        <TableRow>
                                            {panelistType?.value1 == "NaturalPerson" ?
                                                (
                                                    <>
                                                        <TableCell style={{ width: "8%" }}>
                                                            <Checkbox
                                                                value={allChecked}
                                                                checked={allChecked || (selectedPanelist.length == filteredPanelist.length && selectedPanelist.length > 0)}
                                                                className="firstcheckbox"
                                                                onChange={(e) => {
                                                                    let val = e.target?.checked;
                                                                    setAllChecked(val);
                                                                    if (val) {
                                                                        let result = [];
                                                                        filteredPanelist.forEach(p => {
                                                                            result.push(p.PanelistId)
                                                                        });
                                                                        setSelectedPanelist(result);
                                                                    }
                                                                    else {
                                                                        setSelectedPanelist([]);
                                                                    }

                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ width: "20%" }}>Prénom</TableCell>
                                                        <TableCell style={{ width: "20%" }}>Nom</TableCell>
                                                        <TableCell style={{ width: "20%" }}>Date de naissance </TableCell>
                                                        <TableCell style={{ width: "15%" }}>Sexe</TableCell>
                                                        <TableCell style={{ width: "17%" }}>Téléphone</TableCell>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <TableCell style={{ width: "8%" }}>
                                                            <Checkbox
                                                                value={allChecked}
                                                                checked={allChecked || (selectedPanelist.length == filteredPanelist.length && selectedPanelist.length > 0)}
                                                                className="firstcheckbox"
                                                                onChange={(e) => {
                                                                    let val = e.target?.checked;
                                                                    setAllChecked(val);
                                                                    if (val) {
                                                                        let result = [];
                                                                        filteredPanelist.forEach(p => {
                                                                            result.push(p.PanelistId)
                                                                        });
                                                                        setSelectedPanelist(result);
                                                                    }
                                                                    else {
                                                                        setSelectedPanelist([]);
                                                                    }

                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ width: "26%" }}>Nom</TableCell>
                                                        <TableCell style={{ width: "26%" }}>Email </TableCell>
                                                        <TableCell style={{ width: "20%" }}>Téléphone</TableCell>
                                                        <TableCell style={{ width: "20%" }}>Date de création</TableCell>
                                                    </>
                                                )}

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredPanelist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((panelist, index) =>
                                        (
                                            <TableRow key={index}>
                                                {panelistType?.value1 == "NaturalPerson" ?
                                                    (
                                                        <>
                                                            <TableCell style={{ width: "8%" }}>
                                                                <Checkbox
                                                                    checked={selectedPanelist.some(id => id == panelist.PanelistId)}
                                                                    className="firstcheckbox"
                                                                    onChange={(e) => {
                                                                        let val = e.target?.checked;
                                                                        if (val) {
                                                                            let newArray = [...selectedPanelist];
                                                                            newArray.push(panelist.PanelistId);
                                                                            setSelectedPanelist(newArray);
                                                                        }
                                                                        else {
                                                                            let newArray = [...selectedPanelist];
                                                                            setSelectedPanelist(newArray.filter(id => id != panelist.PanelistId));
                                                                            if (allChecked) {
                                                                                setAllChecked(false);
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell >{panelist.FirstName}</TableCell>
                                                            <TableCell >{panelist.LastName}</TableCell>
                                                            <TableCell >{moment(panelist.BirthDate).format('Do MMMM YYYY')}</TableCell>
                                                            <TableCell >{panelist.Gender == "Male" ? "Masculin" : "Féminin"} </TableCell>
                                                            <TableCell >{panelist.PhoneNumber1} </TableCell>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <TableCell style={{ width: "8%" }}>
                                                                <Checkbox
                                                                    checked={selectedPanelist.some(id => id == panelist.PanelistId)}
                                                                    className="firstcheckbox"
                                                                    onChange={(e) => {
                                                                        let val = e.target?.checked;
                                                                        if (val) {
                                                                            let newArray = [...selectedPanelist];
                                                                            newArray.push(panelist.PanelistId);
                                                                            setSelectedPanelist(newArray);
                                                                        }
                                                                        else {
                                                                            let newArray = [...selectedPanelist];
                                                                            setSelectedPanelist(newArray.filter(id => id != panelist.PanelistId));
                                                                            if (allChecked) {
                                                                                setAllChecked(false);
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell >{panelist.LastName}</TableCell>
                                                            <TableCell >{panelist.Email}</TableCell>
                                                            <TableCell >{panelist.PhoneNumber1} </TableCell>
                                                            <TableCell >{moment(panelist.BirthDate).format('Do MMMM YYYY')}</TableCell>
                                                        </>
                                                    )}

                                            </TableRow>

                                        ))
                                        }
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    className="px-4"
                                    rowsPerPageOptions={[25]}
                                    component="div"
                                    count={filteredPanelist?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </>
                        )}
                    </SimpleCard>
                </>
            )}
        </div>
    );
}

const mapStateToProps = state => ({
    getPanelistList: PropTypes.func.isRequired,
    getRegions: PropTypes.func.isRequired, // 
    getGroupList: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
    createGroup: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,

    storePanelist: state.panelist,
    storePanelistGroup: state.panelistgroup,
    storeUser: state.user,
    storePanelistCategory: state.panelistcategory,
    storePanelistGroup: state.panelistgroup

});
export default (withRouter(connect(mapStateToProps,
    {
        getRegions,
        getPanelistList,
        getGroupList,
        createGroup,
        hideAlert,
        getCategories
    })(PanelistAdvancedSearchPage)));