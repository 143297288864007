import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Grid, Checkbox, FormControlLabel, CircularProgress, TextField, Icon } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ChipInput from "material-ui-chip-input";
import { Email } from "@material-ui/icons";

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);


function CreateNaturalPersonPanellist(props) {
    const {
        theme,
        regionList = [],
        categories = [],
        storePanelist = {},
        storePanelistCategory = {},
        storeUser = {},
        getUsertList,
        onCreatePanelist,
        hideAddedAlert,
    } = props;


    // Les variables en state
    const [open, setOpen] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [regionOptions, setRegionOptions] = React.useState([]);
    const [townToDisplayOptions, setTownToDisplayOptions] = React.useState([]);
    const [districtToDisplayOptions, setDistrictToDisplayOptions] = React.useState([]);
    const [categoryOptions, setCategoryOptions] = React.useState([]);
    const [districtDefaultValue, setDistrictDefaultValue] = React.useState({ label: "", id: "" });
    const [townDefaultValue, setTownDefaultValue] = React.useState({ label: "", id: "" });
    const [panelist, setPanelist] = React.useState(
        {
            PanelistId: "",
            Type: "NaturalPerson",
            EmployeesCount: "",
            FirstName: "",
            LastName: "",
            Gender: "",
            BirthDate: new Date(),
            PhoneNumber1: "",
            PhoneNumber2: "",
            Email: "",
            EducationLevel: "",
            Profession: "",
            Income: "",
            Category: [],
            RegionId: "",
            TownId: "",
            DistrictId: "",
        }
    );

    useEffect(() => {
        if (regionList && regionList.length) {
            getRegionOption();
        }
    }, [regionList])

    useEffect(() => {
        if (categories && categories.length) {
            getCategoryOptions();
        }
    }, [categories])

    useEffect(() => {
        if (townToDisplayOptions && townToDisplayOptions.length) {
            setTownDefaultValue(townToDisplayOptions[0]);
            getDistrictToDisplayOption(panelist?.RegionId, townToDisplayOptions[0]?.id);
            setPanelist(prevState => { return { ...prevState, TownId: townToDisplayOptions[0]?.id } });
        }
        else {
            setTownDefaultValue({ label: "", id: "" });
            setDistrictDefaultValue({ label: "", id: "" });
            setPanelist(prevState => { return { ...prevState, TownId: "" } });
        }
    }, [townToDisplayOptions])

    useEffect(() => {
        if (districtToDisplayOptions && districtToDisplayOptions.length) {
            setDistrictDefaultValue(districtToDisplayOptions[0]);
            setPanelist(prevState => { return { ...prevState, DistrictId: districtToDisplayOptions[0]?.id } });
        }
        else {
            setDistrictDefaultValue({ label: "", id: "" });
            setPanelist(prevState => { return { ...prevState, DistrictId: "" } });
        }
    }, [districtToDisplayOptions])

    useEffect(() => {
        if (panelist.Type == ""
            || panelist.LastName == ""
            || panelist.FirstName == ""
            || panelist.BirthDate == ""
            || panelist.PhoneNumber1 == ""
            || panelist.Gender == ""
            || panelist.EducationLevel == ""
            || panelist.Profession == ""
            || panelist.Income == ""
            || panelist.RegionId == ""
            || panelist.TownId == ""
            || panelist.DistrictId == "") {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);

        }
    }, [panelist])

    let handleClickOpen = () => {
        setOpen(true);
    };

    let handleClose = () => {
        setOpen(false);
    };

    let handleSave = event => {
        handleSubmit(panelist)
    }

    let handleSubmit = value => {
        setSubmitting(true);
        onCreatePanelist(value).then(data => {
            if (data.Success) {
                setPanelist(
                    {
                        PanelistId: "",
                        Type: "NaturalPerson",
                        EmployeesCount: "",
                        FirstName: "",
                        LastName: "",
                        Gender: "",
                        BirthDate: new Date(),
                        PhoneNumber1: "",
                        PhoneNumber2: "",
                        Email: "",
                        EducationLevel: "",
                        Profession: "",
                        Income: "",
                        Category: [],
                        RegionId: "",
                        TownId: "",
                        DistrictId: "",
                    }
                );
            }
            setSubmitting(false);
            handleClose();
        });
    };

    let getRegionOption = () => {
        let result = [];
        regionList.forEach(region => {
            result.push({ label: region.Name, id: region.RegionId })
        })
        setRegionOptions(result);
    }

    let getTownToDisplayOption = (regionId) => {
        let result = [];
        let region = regionList.find(r => r.RegionId == regionId);
        if (region != null && region.Towns != null && region.Towns.length) {
            region.Towns.forEach(town => {
                result.push({ label: town.Name, id: town.TownId, regionId: regionId })
            })
        }
        setTownToDisplayOptions(result);
    }

    let getDistrictToDisplayOption = (regionId, townId) => {
        let result = [];
        let region = regionList.find(r => r.RegionId == regionId);
        if (region != null && region.Towns != null && region.Towns.length) {
            let town = region.Towns.find(t => t.TownId == townId);
            if (town != null && town.Districts != null && town.Districts.length) {
                town.Districts.forEach(district => {
                    result.push({ label: district.Name, id: district.DistrictId })
                })
            }
        }
        setDistrictToDisplayOptions(result);
    }

    let getCategoryOptions = () => {
        let result = [];
        categories.filter(item => item?.Type == "NaturalPerson").forEach(category => {
            result.push({ label: category.Name, id: category.CategoryId })
        })
        setCategoryOptions(result);
    }

    return (
        <>
            <IconButton
                // variant="outlined"
                color="primary"
                className="btn-primary"
                onClick={handleClickOpen}
                title="Ajouter "
            >
                <Icon>add</Icon>
            </IconButton>
            <Dialog
                disableBackdropClick
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Création d'un nouveau panéliste
                </DialogTitle>
                <DialogContent dividers>
                    <ValidatorForm
                        // ref="form"
                        onSubmit={handleSubmit}
                        onError={errors => null}
                        style={{ marginTop: "10px" }}
                    >
                        <Grid container spacing={12}>
                            <Grid item xs={12}>
                                <TextValidator
                                    className="mb-16 w-100"
                                    label="Prénom"
                                    onChange={(e) => {
                                        setPanelist(prevState => { return { ...prevState, FirstName: e.target?.value } });
                                    }}
                                    type="text"
                                    name="FirstName"
                                    value={panelist?.FirstName}
                                    validators={["required"]}
                                    errorMessages={["Le prénom de panéliste est recquis"]}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    className="mb-16 w-100"
                                    label="Nom"
                                    onChange={(e) => {
                                        setPanelist(prevState => { return { ...prevState, LastName: e.target?.value } });
                                    }}
                                    type="text"
                                    name="LastName"
                                    value={panelist?.LastName}
                                    validators={["required"]}
                                    errorMessages={["Le nom de panéliste est recquis"]}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup
                                    className="mb-4"
                                    value={panelist?.Gender}
                                    name="Gender"
                                    onChange={(e) => {
                                        setPanelist(prevState => { return { ...prevState, Gender: e.target?.value } });
                                    }}
                                    row

                                >
                                    <FormControlLabel
                                        value={"Male"}
                                        control={<Radio color="secondary" />}
                                        label="Masculin"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={"Female"}
                                        control={<Radio color="secondary" />}
                                        label="Féminin"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        className="mb-16 w-100"
                                        margin="none"
                                        id="mui-pickers-date"
                                        label={"Date de naissance"}
                                        inputVariant="standard"
                                        type="text"
                                        autoOk={true}
                                        format="dd/MM/yyyy"
                                        value={panelist?.BirthDate}
                                        disableFuture
                                        onChange={(date) => {
                                            setPanelist(prevState => { return { ...prevState, BirthDate: date } });
                                        }}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date"
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    className="mb-16 w-100"
                                    label="Numéro de téléphone 1"
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8)
                                    }}
                                    onChange={(e) => {
                                        setPanelist(prevState => { return { ...prevState, PhoneNumber1: e.target?.value } });
                                    }}
                                    type="text"
                                    name="PhoneNumber1"
                                    value={panelist?.PhoneNumber1}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    className="mb-16 w-100"
                                    label="Numéro de téléphone 2"
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8)
                                    }}
                                    onChange={(e) => {
                                        setPanelist(prevState => { return { ...prevState, PhoneNumber2: e.target?.value } });
                                    }}
                                    type="text"
                                    name="PhoneNumber2"
                                    value={panelist?.PhoneNumber2}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    className="mb-16 w-100"
                                    label="Email"
                                    onChange={(e) => {
                                        setPanelist(prevState => { return { ...prevState, Email: e.target?.value } });
                                    }}
                                    type="text"
                                    name="Email"
                                    value={panelist?.Email}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    className="mb-16 w-100"
                                    options={storePanelist.educationLevelOptions}
                                    getOptionLabel={option => option.label}
                                    onChange={(event, value) => {
                                        setPanelist(prevState => { return { ...prevState, EducationLevel: value?.label } });
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Niveau d'étude"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    loading={storePanelistCategory.loading}
                                    className="mb-16 w-100"
                                    options={categoryOptions}
                                    getOptionLabel={option => option.label}
                                    onChange={(event, value) => {
                                        let result = [];
                                        if (value.length) {
                                            value.forEach(element => {
                                                result.push(element.id);
                                            });
                                        }
                                        setPanelist(prevState => { return { ...prevState, Category: result } });
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Catégorie"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    className="mb-16 w-100"
                                    options={storePanelist.incomeOptions}
                                    getOptionLabel={option => option.label}
                                    onChange={(event, value) => {
                                        setPanelist(prevState => { return { ...prevState, Income: value?.label } });
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Revenu"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    className="mb-16 w-100"
                                    options={storePanelist.professionOptions}
                                    getOptionLabel={option => option.label}
                                    onChange={(event, value) => {
                                        setPanelist(prevState => { return { ...prevState, Profession: value?.label } });
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Profession"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    className="mb-16 w-100"
                                    options={regionOptions}
                                    loading={storePanelist.loading}
                                    defaultValue={{ label: "" }}
                                    getOptionLabel={option => option.label}
                                    onChange={(event, value) => {
                                        setPanelist(prevState => { return { ...prevState, RegionId: value?.id } });
                                        getTownToDisplayOption(value?.id);
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Region/Ville"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    value={townDefaultValue}
                                    className="mb-16 w-100"
                                    options={townToDisplayOptions}
                                    getOptionLabel={option => option.label}
                                    onChange={(event, value) => {
                                        setPanelist(prevState => { return { ...prevState, TownId: value?.id } });
                                        getDistrictToDisplayOption(panelist?.RegionId, value?.id);
                                        setTownDefaultValue(townToDisplayOptions.find(o => o.id == value?.id));
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Commune"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    value={districtDefaultValue}
                                    className="mb-16 w-100"
                                    options={districtToDisplayOptions}
                                    getOptionLabel={option => option.label}
                                    onChange={(event, value) => {
                                        setPanelist(prevState => { return { ...prevState, DistrictId: value?.id } });
                                        setDistrictDefaultValue(districtToDisplayOptions.find(o => o.id == value?.id));
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Quartier/Village"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave} color="secondary" variant="contained" disabled={submitting || hasMissingValue}>
                        Soumettre &nbsp; {submitting ? <CircularProgress size={20} /> : <span></span>}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}





const mapStateToProps = state => ({
    storePanelist: state.panelist,
    storePanelistCategory: state.panelistcategory,
});

export default (withRouter(connect(mapStateToProps, {})(CreateNaturalPersonPanellist)));