import React, { useEffect } from "react";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRoleList, } from "app/redux/actions/RoleActions";
import { getProjectList, hideAlert } from "app/redux/actions/ProjectActions";
import { Table, TableBody, TableCell, TableRow, TableHead, CircularProgress, TablePagination, Popover, Checkbox } from '@material-ui/core';
import { SimpleCard, Breadcrumb } from 'matx';
import SnackbarContentWrapper from "../alert/SnackbarContentWrapper"
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import moment from 'moment';
import 'moment/locale/fr';
import CreateProject from "./dialog/CreateProject";
import SynchronizeProjects from "./dialog/SynchronizeProjects";
import { Link } from "react-router-dom";
import { Icon } from "@material-ui/core";
import DeleteProject from "./dialog/DeleteProject";

const useStyles2 = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1)
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

let projectLoaded = false;

function ProjectPage(props) {
    const {
        theme,
        storeUser = {},
        storeProject = {},
        getProjectList,
        hideAlert,
    } = props;

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const classes = useStyles2();
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isChecked, setIsChecked] = React.useState(false);
    const [selectedProject, setSelectedProject] = React.useState([]);
    const [regionList, setRegionList] = React.useState([]);
    const [selectedProjectCount, setSelectedProjectCount] = React.useState(0);
    const [isAllChecked, setIsAllChecked] = React.useState(false);
    const [isLoadingTableBody, setIsLoadingTableBody] = React.useState(false);
    const [isLoadingTableHead, setIsLoadingTableHead] = React.useState(false);
    const [permissions, setPermissions] = React.useState([]);

    const openPop = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (storeProject.showAlert) {
            setMessage(storeProject.alertMessage);
            setOpen(true);
            setTimeout(() => {
                hideAlert();
            }, 15000);

        }
        if (storeProject.loading || storeUser.loading || storeUser.currentUser.Permissions == undefined) {
            setIsLoading(true);
        }
        else if (storeProject.success && storeUser.currentUser && storeUser.currentUser.Permissions && storeUser.currentUser.Permissions.length > 0) {
            setIsLoading(false);
            if (storeUser.currentUser && storeUser.currentUser.Permissions) {
                setPermissions(storeUser.currentUser.Permissions);
            }
        }
        if (isAllChecked) {
            setIsLoadingTableBody(true);
            setIsLoadingTableHead(true);
        }
        else {
            setIsLoadingTableBody(true);
            setIsLoadingTableHead(true);
        }

    }, [storeProject, storeUser, isAllChecked])

    useEffect(() => {
        console.log("permission", permissions);
    })

    if (!projectLoaded) {
        getProjectList();
        projectLoaded = true;
    }

    function handleClose(event) {
        setOpen(false);
        hideAlert();
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
    };

    const handleCheckbox = (e, projectId) => {
        let selectedProjectCountCopy = selectedProjectCount;
        if (e.target.checked) {
            let result = [...selectedProject];
            result.push(projectId);
            setSelectedProject(result);
            setSelectedProjectCount(selectedProjectCountCopy + 1);
        } else {
            if (isAllChecked) {
                setIsAllChecked(false);
            }
            let result = selectedProject.filter((x) => x !== projectId.toString());
            setSelectedProject(result);
            setSelectedProjectCount(selectedProjectCountCopy - 1);
        }
    };

    const handleSelectAllClick = e => {
        if (e.target.checked) {
            let result = [];
            storeProject.ProjectList.forEach(element => {
                result.push(element.ProjectId);
            });
            setSelectedProject(result);
            setIsAllChecked(true);
        }
        else {
            setSelectedProject([]);
            setIsAllChecked(false);
        }
    }

    return (
        <div className="m-sm-30">
            {isLoading ? (
                <div style={{ margin: "auto", textAlign: "center", fontSize: "18px", width: "50%", height: "50%", marginTop: "180px" }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {storeProject.showAlert && (
                        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                        >
                            <SnackbarContentWrapper onClose={handleClose} variant={storeProject.alertVariant} message={storeProject.alertMessage} />
                        </Snackbar>
                    )}
                    <div className="mb-sm-30">
                        <Breadcrumb routeSegments={[{ name: "Liste des projets" }]} />
                    </div>
                    <SimpleCard title="Liste des projets"
                        icon={<span style={{ float: "right" }}>
                            {permissions?.some(item => item == "AllowedToSynchronizeProjectFromKobo") && (
                                <SynchronizeProjects />
                            )}
                            {permissions?.some(item => item == "AllowedToCreateProject") && (
                                <CreateProject />
                            )}
                        </span>}
                    >
                        <Table className="product-table" style={{ width: "100%", whiteSpace: "nowrap" }}>
                            <TableHead>
                                <TableRow>

                                    <TableCell style={{ width: "20%" }}>Nom</TableCell>
                                    <TableCell style={{ width: "28%" }}>Description</TableCell>
                                    <TableCell style={{ width: "20%" }}>Crée par </TableCell>
                                    <TableCell style={{ width: "2%" }}></TableCell>
                                    <TableCell style={{ width: "2%" }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {storeProject.projectList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project, index) =>
                                (
                                    <TableRow key={index}>
                                        <TableCell >{project.Name}</TableCell>
                                        <TableCell >{project.Description}</TableCell>
                                        <TableCell >{project.Author}</TableCell>
                                        <TableCell>
                                            <div>
                                                <Link to={"/projet/detail/" + project.ProjectId} title="Modifier un projet">
                                                    <Icon color="primary">edit</Icon>
                                                </Link>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {storeUser.currentUser.Permissions.some(item => item == "AllowedToDeleteProject") && (
                                                <DeleteProject projectToDelete={project} />
                                            )}
                                        </TableCell>
                                    </TableRow>

                                ))
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            className="px-4"
                            rowsPerPageOptions={[25]}
                            component="div"
                            count={storeProject.projectList?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "Previous Page"
                            }}
                            nextIconButtonProps={{
                                "aria-label": "Next Page"
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </SimpleCard>
                </>
            )}

        </div>
    );
}

ProjectPage.propTypes = {
    hideAlert: PropTypes.func.isRequired,
    storeProject: PropTypes.object.isRequired,
    storeUser: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getProjectList: PropTypes.func.isRequired, // getRegions    
    hideAlert: PropTypes.func.isRequired,
    storeProject: state.project,
    storeUser: state.user,
});
export default withStyles({}, { withTheme: true })(connect(mapStateToProps, { getProjectList, getRoleList, hideAlert })(ProjectPage));
