import React from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Button, CircularProgress, Icon } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { deleteGroup } from "app/redux/actions/PanelistGroupActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6" className="delete-h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);


function DeletePanelistGroup(props) {

    const {
        storeUser = {},
        panelistGroupToDelete = {},
        onDeletePanelistGroup
    } = props;

    const [open, setOpen] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);


    let handleClickOpen = () => {
        setOpen(true);
    };

    let handleClose = () => {
        setOpen(false);
    };

    let handleSubmit = value => {
        setIsSubmitting(true);
        onDeletePanelistGroup(panelistGroupToDelete.PanelistGroupId).then(data => {
            if (data.Success) {
                setIsSubmitting(false);
                handleClose();
            }
        });
    };

    return (
        <div>
            <a href="javascript:void(0);" onClick={handleClickOpen} className="" title="Supprimer un groupe">
                <DeleteIcon />
            </a>
            <Dialog
                disableBackdropClick
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" className="dialog-title" onClose={handleClose}>
                    Suppression du groupe de panéliste : {panelistGroupToDelete?.Name}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        Êtes-vous sûr de vouloir supprimer ce groupe de  panéliste ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" disabled={isSubmitting}>
                        Non
                    </Button>
                    <Button onClick={handleSubmit} color="secondary" variant="contained" disabled={isSubmitting}>
                        Oui {isSubmitting ? <CircularProgress size={20} /> : <span></span>}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}




const mapStateToProps = state => ({
    deleteGroup: PropTypes.func.isRequired,
    storePanelistGroup: state.panelistgroup,
});
export default (
    withRouter(connect(mapStateToProps, { deleteGroup })(DeletePanelistGroup))
);