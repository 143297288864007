import axios from "axios";
import { api } from "app/services/apiHelpers";
import history from "history.js";

// Action mener au serveur (les appels API)
export const GET_PANELISTCATEGORY = "GET_PANELISTCATEGORY";
export const GET_PANELISTCATEGORY_LIST = "GET_PANELISTCATEGORY_LIST";
export const ADD_PANELISTCATEGORY_TO_LIST = "ADD_PANELISTCATEGORY_TO_LIST";
export const DELETE_PANELISTCATEGORY_FROM_LIST = "DELETE_PANELISTCATEGORY_FROM_LIST";
export const UPDATE_PANELISTCATEGORY = "UPDATE_PANELISTCATEGORY";

// Type d'action pour la synchronisation lors des appels API (ils sont utilisees pour resoudre les spiner des boutons submits)
export const PANELISTCATEGORY_ACTION_SUBMITED = "PANELISTCATEGORY_ACTION_SUBMITED";
export const PANELISTCATEGORY_ACTION_SUBMITTING = "PANELISTCATEGORY_ACTION_SUBMITTING";
export const PANELISTCATEGORY_ACTION_LOADING = "PANELISTCATEGORY_ACTION_LOADING";
export const PANELISTCATEGORY_ACTION_SUCCESS = "PANELISTCATEGORY_ACTION_SUCCESS";
export const PANELISTCATEGORY_ACTION_ERROR = "PANELISTCATEGORY_ACTION_ERROR";

// Action sur le statut de reponse server (pour afficher ou cacher le snackbar alert)
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export const getCategory = (categoryId) => dispatch => {
  dispatch({
    type: PANELISTCATEGORY_ACTION_LOADING
  });
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios.get(`${api}` + "category/getcategory/" + categoryId).then(res => {
        dispatch({
          type: GET_PANELISTCATEGORY,
          data: res.data.Category
        });
        dispatch({
          type: GET_PANELISTCATEGORY_LIST,
          data: res.data.Categories
        });
        dispatch({
          type: PANELISTCATEGORY_ACTION_SUCCESS
        });
        resolve(res.data.Categories);
      })
        .catch(error => {
          return dispatch({
            type: PANELISTCATEGORY_ACTION_ERROR,
            data: error
          });
        });
    }, 500);
  })
    .then(data => {
      if (data !== null) {
        dispatch({
          type: PANELISTCATEGORY_ACTION_SUCCESS
        });
      }
      else {
        dispatch({
          type: PANELISTCATEGORY_ACTION_ERROR,
          data: "aucun panéliste correspondant n'a ete trouve"
        });
      }
      return data;
    });
};

export const getCategories = () => dispatch => {
  dispatch({
    type: PANELISTCATEGORY_ACTION_LOADING
  });
  return new Promise((resolve, reject) => {
    axios.get(`${api}` + "category/list").then(res => {
      dispatch({
        type: GET_PANELISTCATEGORY_LIST,
        data: res.data.Categories
      });
      dispatch({
        type: PANELISTCATEGORY_ACTION_SUCCESS
      });
      resolve(res.data.Categories);
    });
  });
};

export const createCategory = (category) => dispatch => {
  dispatch({
    type: PANELISTCATEGORY_ACTION_SUBMITTING
  });
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios.post(`${api}` + "category/createcategory", category).then(res => {
        if (res.data.Success) {
          dispatch({
            type: GET_PANELISTCATEGORY_LIST,
            data: res.data.Categories
          });
          resolve(res.data);
        }
        else {
          resolve(res.data);
        }
        dispatch({
          type: PANELISTCATEGORY_ACTION_SUBMITED
        });
      })
        .catch(error => {
          return dispatch({
            type: PANELISTCATEGORY_ACTION_ERROR,
            data: error
          });
        });
    }, 500);
  })
    .then(data => {
      if (data.Success) {
        dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
          type: SHOW_ALERT,
          data: "Une catégorie a été ajouté avec succès"
        });
      }
      else {
        dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
          type: PANELISTCATEGORY_ACTION_ERROR,
          data: data.Message
        });
      }
      return data;
    });
};

export const deleteCategory = categoryId => dispatch => {
  dispatch({
    type: PANELISTCATEGORY_ACTION_SUBMITTING
  });
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios.get(`${api}` + "category/deletecategory/" + categoryId).then(res => {
        if (res.data.Success) {
          dispatch({
            type: DELETE_PANELISTCATEGORY_FROM_LIST,
            data: res.data.Categories
          });
          resolve(res.data);
        }
        else {
          resolve(res.data);
        }
        dispatch({
          type: PANELISTCATEGORY_ACTION_SUBMITED
        });

      })
        .catch(error => {
          return dispatch({
            type: PANELISTCATEGORY_ACTION_ERROR,
            data: error
          });
        });
    }, 500);
  })
    .then(data => {
      if (data.Success) {
        dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
          type: SHOW_ALERT,
          data: "Une catégorie a été supprimé avec succès"
        });
      }
      else {
        dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
          type: PANELISTCATEGORY_ACTION_ERROR,
          data: data.Message
        });
      }
      return data;
    });

};

export const updateCategory = (category) => dispatch => {
  dispatch({
    type: PANELISTCATEGORY_ACTION_SUBMITTING
  });
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios.post(`${api}` + "category/updatecategory", category).then(res => {
        if (res.data.Success) {
          dispatch({
            type: GET_PANELISTCATEGORY,
            data: res.data.Category
          });
          dispatch({
            type: GET_PANELISTCATEGORY_LIST,
            data: res.data.Categories
          });
          resolve(res.data);
        }
        else {
          resolve(res.data);
        }
        dispatch({
          type: PANELISTCATEGORY_ACTION_SUBMITED
        });

      })
        .catch(error => {
          return dispatch({
            type: PANELISTCATEGORY_ACTION_ERROR,
            data: error
          });
        });
    }, 500);
  })
    .then(data => {
      if (data.Success) {
        dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
          type: SHOW_ALERT,
          data: "Une catégorie a été modifié avec succès"
        });
      }
      else {
        dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
          type: PANELISTCATEGORY_ACTION_ERROR,
          data: data.Message
        });
      }
      return data;
    });

};

export const hideAlert = () => dispatch => {
  dispatch({
    type: HIDE_ALERT
  });
};