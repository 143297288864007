import React from "react";
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Grid, CircularProgress, Icon, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { updateQuizResponseUrl } from "app/redux/actions/ProjectActions";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import guid from "guid";

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2),
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);



function UpdateQuizKoboLink(props) {

    const {
        projectId = "",
        quizId = "",
        currentLink = "",
        updateQuizResponseUrl
    } = props;

    const [open, setOpen] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [request, setRequest] = React.useState({
        ProjectId: projectId,
        QuizId: quizId,
        QuizResponseUrl: ""
    });

    let handleClickOpen = (e) => {
        setOpen(true);
    };

    let handleClose = (e) => {
        setOpen(false);
    };

    let handleSubmit = value => {
        setIsSubmitting(true);
        updateQuizResponseUrl(request).then(resp => {
            if (resp) {
                setRequest(prevState => { return { ...prevState, QuizResponseUrl: "" } });
            }
            setIsSubmitting(false);
            handleClose();
        });
    };

    return (
        <>
            <IconButton
                color="primary"
                className="btn-primary"
                title="Modifier le questionnaire"
                onClick={handleClickOpen}
            >
                <Icon>edit</Icon>
            </IconButton>
            <Dialog
                disableBackdropClick
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Modification du lien Kobo
                </DialogTitle>
                <DialogContent dividers>
                    <ValidatorForm onSubmit={handleSubmit} onError={errors => null} style={{ marginTop: "10px" }}>
                        <Grid container spacing={12}>
                            <Grid item xs={12}>
                                <TextValidator
                                    className="mb-16 w-100"
                                    label="Lien actuel"
                                    type="text"
                                    value={currentLink}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={12}>
                            <Grid item xs={12}>
                                <TextValidator
                                    className="mb-16 w-100"
                                    label="Nouveau lien kobo"
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        let val = e.target.value;
                                        setRequest(prevState => {
                                            return { ...prevState, QuizResponseUrl: val }
                                        });
                                    }}
                                    type="text"
                                    name="newLink"
                                    value={request?.QuizResponseUrl}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="secondary" variant="contained" disabled={isSubmitting}>
                        Soumettre {isSubmitting ? <CircularProgress size={20} /> : <span></span>}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const mapStateToProps = state => ({
    updateQuizResponseUrl: PropTypes.func.isRequired,
    storePanelistGroup: state.panelistgroup,
    storeUser: state.user
});
export default (
    withRouter(connect(mapStateToProps, { updateQuizResponseUrl })(UpdateQuizKoboLink))
);