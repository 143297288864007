import {
    GET_PANELISTGROUP_LIST,
    PANELISTGROUP_ACTION_LOADING,
    PANELISTGROUP_ACTION_SUCCESS,
    PANELISTGROUP_ACTION_ERROR,
    PANELISTGROUP_ACTION_SUBMITING,
    PANELISTGROUP_ACTION_SUBMITED,
    SHOW_ALERT,
    HIDE_ALERT,
    PANELISTGROUP_TABLE_CONTENT_LOADING,
    PANELISTGROUP_TABLE_CONTENT_LOADED,
    GET_PANELISTGROUP
} from "../actions/PanelistGroupActions";

const initialState = {
    panelistGroupList: [],
    storeCurrentPanelistGroup: {},
    loading: false,
    success: false,
    failed: false,
    submitting: false,
    errorMessage: "",
    showAlert: false,
    alertMessage: "",
    alertVariant: "",
    groupListTableContentLoading: false
};

const PanelistGroupReducer = function (state = initialState, action) {
    switch (action.type) {
        // Statut des actions
        case PANELISTGROUP_ACTION_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                failed: false
            };
        }
        case PANELISTGROUP_ACTION_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                failed: false,
            };
        }
        case PANELISTGROUP_ACTION_SUBMITING: {
            return {
                ...state,
                submitting: true
            };
        }
        case PANELISTGROUP_ACTION_SUBMITED: {
            return {
                ...state,
                submitting: false
            };
        }
        case PANELISTGROUP_TABLE_CONTENT_LOADING: {
            return {
                ...state,
                groupListTableContentLoading: true
            };
        }
        case PANELISTGROUP_TABLE_CONTENT_LOADED: {
            return {
                ...state,
                groupListTableContentLoading: false
            };
        }
        case PANELISTGROUP_ACTION_ERROR: {
            return {
                ...state,
                failed: true,
                success: false,
                loading: false,
                showAlert: true,
                alertMessage: action.data,
                alertVariant: "error"
            };
        }
        case GET_PANELISTGROUP: {
            return {
                ...state,
                storeCurrentPanelistGroup: { ...action.data }
            };
        }
        case GET_PANELISTGROUP_LIST: { // Ce cas de reducer est appelé pour 4 actions: getGroupList, createGroup, updateGroup et removeGroup car le service repond toujours avec la list mise à jour
            return {
                ...state,
                panelistGroupList: [...action.data],
                someGroupisEditing: false,

            };
        }
        // Alert action
        case SHOW_ALERT: {
            return {
                ...state,
                showAlert: true,
                alertMessage: action.data,
                alertVariant: "success"
            };
        }
        case HIDE_ALERT: {
            return {
                ...state,
                showAlert: false,
                alertMessage: "",
                alertVariant: ""
            };
        }
        default: {
            return state;
        }
    }
};

export default PanelistGroupReducer;
