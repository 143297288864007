import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import authentificationService from "../../services/authentificationService";
import axios from "axios";
import { api } from "app/services/apiHelpers";


export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const GET_USER_LIST = "GET_USER_LIST";

export const USER_ACTION_LOADING = "USER_ACTION_LOADING";
export const USER_ACTION_SUCCESS = "USER_ACTION_SUCCESS";
export const USER_ACTION_ERROR = "USER_ACTION_ERROR";
export const USER_ACTION_SUBMITING = "USER_ACTION_SUBMITING"; //
export const USER_ACTION_SUBMITED = "USER_ACTION_SUBMITED";

export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export const USER_TABLE_CONTENT_LOADING = "USER_TABLE_CONTENT_LOADING";
export const USER_TABLE_CONTENT_LOADED = "USER_TABLE_CONTENT_LOADED";

export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      data: user
    });
  };
}

export function logoutUser() {
  return dispatch => {
    authentificationService.logout();

    history.push({
      pathname: "/session/signin"
    });

    dispatch({
      type: USER_LOGGED_OUT
    });
    dispatch({
      type: REMOVE_USER_DATA
    });
  };
}

export const getUserList = () => dispatch => {
  dispatch({
    type: USER_ACTION_LOADING
  });
  return new Promise((resolve, reject) => {
    axios.get(`${api}` + "account/getusers").then(res => {
      dispatch({
        type: GET_USER_LIST,
        data: res.data.UserList
      });
      dispatch({
        type: USER_ACTION_SUCCESS
      });
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: USER_ACTION_ERROR,
        data: error
      });
    });
  });

};

export const createUser = (user) => dispatch => {
  dispatch({ // informe le reducer de user qu'un formulaire le concernant est en cours de soumission 
    type: USER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    axios.post(`${api}` + "account/createuser", user).then(res => {
      if (res.data.Success) { // Vérifie que le service a fournit une réponse souhaitée
        dispatch({ // Fournit la nouvelle liste d'utilisateur au reducer pour sa mise à jour 
          type: GET_USER_LIST,
          data: res.data.UserList
        });
        dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
          type: SHOW_ALERT,
          data: "Un utilisateur a été ajouté avec succès"
        });
      }
      else {
        dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
          type: USER_ACTION_ERROR,
          data: res.data.Message
        });
      }
      dispatch({ // informe le reducer de user que le formulaire le concernant est traité
        type: USER_ACTION_SUBMITED
      });
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: USER_ACTION_ERROR,
        data: error
      });
    });
  });
};

export const updateUser = (user) => dispatch => {
  dispatch({ // informe le reducer de user qu'un formulaire le concernant est en cours de soumission 
    type: USER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    axios.post(`${api}` + "account/updateuser", user).then(res => {
      if (res.data.Success) { // Vérifie que le service a fournit une réponse souhaitée
        dispatch({ // Fournit la nouvelle liste d'utilisateur au reducer pour sa mise à jour 
          type: GET_USER_LIST,
          data: res.data.UserList
        });
        dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
          type: SHOW_ALERT,
          data: "Un utilisateur a été modifier avec succès"
        });
      }
      else {
        dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
          type: USER_ACTION_ERROR,
          data: res.data.Message
        });
      }
      dispatch({ // informe le reducer de user que le formulaire le concernant est traité
        type: USER_ACTION_SUBMITED
      });
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: USER_ACTION_ERROR,
        data: error
      });
    });
  });
};

export const deleteUser = (userId) => dispatch => {
  dispatch({ // informe le reducer de user qu'un formulaire le concernant est en cours de soumission 
    type: USER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    axios.get(`${api}` + "account/deleteuser/" + userId).then(res => {
      if (res.data.Success) { // Vérifie que le service a fournit une réponse souhaitée
        dispatch({ // Fournit la nouvelle liste d'utilisateur au reducer pour sa mise à jour 
          type: GET_USER_LIST,
          data: res.data.UserList
        });
        dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
          type: SHOW_ALERT,
          data: "Un utilisateur a été supprimé avec succès"
        });
      }
      else {
        dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
          type: USER_ACTION_ERROR,
          data: res.data.Message
        });
      }
      dispatch({ // informe le reducer de user que le formulaire le concernant est traité
        type: USER_ACTION_SUBMITED
      });
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: USER_ACTION_ERROR,
        data: error
      });
    });
  });
};

export const hideAlert = () => dispatch => {
  dispatch({
    type: HIDE_ALERT
  });
};

export const setTableContentLoading = (value) => dispatch => {
  if (value) {
    dispatch({
      type: USER_TABLE_CONTENT_LOADING
    });
  }
  else {
    dispatch({
      type: USER_TABLE_CONTENT_LOADED
    });
  }
};

export const changeUserPassword = (request) => dispatch => {
  dispatch({
    type: USER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios.post(`${api}` + "account/changeuserpassword", request).then(res => {
        if (res.data.Success) {
          dispatch({
            type: CHANGE_USER_PASSWORD
          });
          resolve(true);
        }
        else {
          resolve(false);
        }
      })
        .catch(error => {
          return dispatch({
            type: USER_ACTION_ERROR,
            payload: error
          });
        });
    }, 500);
  })
    .then(data => {
      if (data) {
        dispatch({
          type: USER_ACTION_SUBMITED
        });
        logoutUser();
      }


      return data;
    });
};
