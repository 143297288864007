import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import RoleReducer from "./RoleReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import PanelistReducer from "./PanelistReducer";
import PanelistGroupReducer from "./PanelistGroupReducer";
import PanelistCategoryReducer from "./PanelistCategoryReducer";
import ProjectReducer from "./ProjectReducer";
import DashboardReducer from "./DashboardReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  role: RoleReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  panelist: PanelistReducer,
  panelistgroup: PanelistGroupReducer,
  panelistcategory: PanelistCategoryReducer,
  project: ProjectReducer,
  dashboard: DashboardReducer,
});

export default RootReducer;
